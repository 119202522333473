import React from 'react';
import _ from 'lodash';

import {
  Box,
  Button,
  Typography,
  Alert
} from '@mui/material';
import {
  errorUtil,
  LoadingOverlay
} from 'modules/layer0';
import {
  ButtonRow,
  FileInputField,
  IFileData
} from 'modules/layer2';

import { ColumnsModal } from './ColumnsModal/ColumnsModal';
import { useTranslation } from 'react-i18next';
import * as csvParser from './csvParser';

import { BaseModal } from 'modules/layer1';

export type IColumnDescription = csvParser.IColumnDescription;



export interface IImportCsvModalProps {
  isOpen: boolean;
  columnDescriptions: IColumnDescription[];
  fnImport: (rows: any[]) => Promise<void>;
  isLoading: boolean;
  onAccept: () => void;
  onCancel: () => void;
}

export const ImportCsvModal: React.FC<IImportCsvModalProps> = props => {
  const {t} = useTranslation();

  const [isColumnsModalOpen, setIsColumnsModalOpen] = React.useState(false);
  const [hasSelectedFile, setHasSelectedFile] = React.useState(false);
  const [parseResult, setParseResult] = React.useState<csvParser.IParseReturn | null>(null);

  const isLoading = props.isLoading;



  async function handleSelectFile(fileData: IFileData) {
    setHasSelectedFile(true);
    setParseResult(await csvParser.parse(props.columnDescriptions, fileData.file));
  }



  async function importData() {
    await errorUtil.errorWrap(async () => {
      await props.fnImport(parseResult?.rows ?? []);
      props.onAccept();
    });
  }



  return (
    <>
      <BaseModal
        title={t('importCsvModal.title')}
        isOpen={props.isOpen}
        disableDefaultWidth={!!parseResult}>

        {() => (
          <>
            <LoadingOverlay active={isLoading} />

            <Typography>
              {t('importCsvModal.description')}
            </Typography>

            {parseResult && (
              <>
                {parseResult.formatErrors.length > 0 && (
                  <Box mt={2}>
                    <Alert severity="error">
                      <div>
                        {t('importCsvModal.errorsDescription')}
                      </div>

                      <ul>
                        {_.map(parseResult.formatErrors, (error, index) => (
                          <li key={index}>
                            {error}
                          </li>
                        ))}
                      </ul>
                    </Alert>
                  </Box>
                )}

                {parseResult.extraColumnIds.length > 0 && (
                  <Box mt={2}>
                    <Alert severity="warning">
                      <div>
                        {t('importCsvModal.warningsDescription')}
                      </div>

                      <ul>
                        {_.map(parseResult.extraColumnIds, (columnId, index) => (
                          <li key={index}>
                            {columnId}
                          </li>
                        ))}
                      </ul>
                    </Alert>
                  </Box>
                )}

                {parseResult.formatErrors.length === 0 && parseResult.extraColumnIds.length === 0 && (
                  <Box mt={2}>
                    <Alert severity="success">
                      {t('importCsvModal.youMayProceed')}
                    </Alert>
                  </Box>
                )}
              </>
            )}

            <ButtonRow
              elButtons={[
                (
                  <Button
                    key="cancel"
                    variant="outlined"
                    fullWidth
                    onClick={props.onCancel}
                    disabled={isLoading}>
                    {t('cancel')}
                  </Button>
                ),
                (
                  <Button
                    key="columns"
                    variant="outlined"
                    fullWidth
                    onClick={() => setIsColumnsModalOpen(true)}
                    disabled={isLoading}>
                    {t('importCsvModal.columns')}
                  </Button>
                ),
                (hasSelectedFile && parseResult && parseResult.formatErrors.length === 0) ? (
                  <Button
                    key="import"
                    variant="contained"
                    fullWidth
                    onClick={importData}
                    disabled={isLoading}>
                    {t('importCsvModal.import')}
                  </Button>

                ) : (
                  <FileInputField
                    key="selectFile"
                    acceptTypes={['text/csv']}
                    onSelectFile={handleSelectFile}>
                    {handleClick => (
                      <Button
                        key="import"
                        variant="contained"
                        fullWidth
                        onClick={handleClick}
                        disabled={isLoading}>
                        {!hasSelectedFile ?
                          t('importCsvModal.selectFile') :
                          t('importCsvModal.selectFileAgain')}
                      </Button>
                    )}
                  </FileInputField>
                )
              ]} />
          </>
        )}

      </BaseModal>

      {isColumnsModalOpen && (
        <ColumnsModal
          columnDescriptions={props.columnDescriptions}
          onClose={() => setIsColumnsModalOpen(false)} />
      )}
    </>
  );
};
