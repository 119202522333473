import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  paletteColors,
  IAppBarMode
} from 'modules';
import {
  Box,
  Button
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { styled } from '@mui/material/styles';

import { ThreadBar } from './ThreadBar/ThreadBar';
import { ThreadContent } from './ThreadContent/ThreadContent';
import { NewMessageModal } from './NewMessageModal/NewMessageModal';
import * as NewDisciplineMessageModal from './NewDisciplineMessageModal/NewDisciplineMessageModal';



const BodyBox = styled(Box)(() => ({
  flex: '1 0',
  overflowY: 'auto',
  display: 'flex',
  alignItems: 'stretch'
}));



interface IMessengerActivityProps {
  mode: IAppBarMode;
}

const MessengerActivity: React.FC<IMessengerActivityProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery();

  const isLoading =
    queryTuple.loading;

  const [selectedThreadId, setSelectedThreadId] = React.useState('');
  const [isNewMessageModalOpen, setIsNewMessageModalOpen] = React.useState(false);
  const [isNewDisciplineMessageModalOpen, setIsNewDisciplineMessageModalOpen] = React.useState(false);



  function handleClickNewMessage() {
    setSelectedThreadId('');
    setIsNewMessageModalOpen(true);
  }



  function handleClickNewDisciplineMessage() {
    setSelectedThreadId('');
    setIsNewDisciplineMessageModalOpen(true);
  }



  function handleAcceptNewMessageModal(threadId: string) {
    setIsNewMessageModalOpen(false);
    setSelectedThreadId(threadId);
  }



  function handleAcceptNewDisciplineMessageModal(threadId: string) {
    setIsNewDisciplineMessageModalOpen(false);
    setSelectedThreadId(threadId);
  }



  return (
    <BaseActivityTemplate
      mode={props.mode}
      isLoading={isLoading}
      disableBodyPadding
      headerOptions={{
        backgroundColor: paletteColors.messenger,
        title: t('messenger'),
        elRightButtons: _.compact([
          queryTuple.data.userType === 'admin' ? (
            <Button
              key="newDisciplineMessage"
              variant="contained"
              onClick={handleClickNewDisciplineMessage}>
              {t('newDisciplineMessage')}
            </Button>
          ) : null,
          (
            <Button
              key="newMessage"
              variant="contained"
              onClick={handleClickNewMessage}>
              {t('newMessage')}
            </Button>
          )
        ])
      }}>

      <BodyBox>
        <ThreadBar
          selectedThreadId={selectedThreadId}
          onClickThread={setSelectedThreadId} />

        {selectedThreadId && (
          <ThreadContent
            key={selectedThreadId}
            threadId={selectedThreadId} />
        )}

        {isNewMessageModalOpen && (
          <NewMessageModal
            onAccept={handleAcceptNewMessageModal}
            onClose={() => setIsNewMessageModalOpen(false)} />
        )}

        {isNewDisciplineMessageModalOpen && (
          <NewDisciplineMessageModal.Comp
            onAccept={handleAcceptNewDisciplineMessageModal}
            onClose={() => setIsNewDisciplineMessageModalOpen(false)} />
        )}
      </BodyBox>

    </BaseActivityTemplate>
  );
};



export const AdminMessengerActivity: React.FC = () => (
  <MessengerActivity
    mode="admin" />
);



export const EmployeeMessengerActivity: React.FC = () => (
  <MessengerActivity
    mode="employee" />
);
