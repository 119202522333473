import React from 'react';
import moment from 'moment-timezone';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import {
  DatePicker,
  LocalizationProvider
} from '@mui/lab';
import {
  Grid,
  TextField
} from '@mui/material';

import AdapterMoment from '@mui/lab/AdapterMoment';
import { useTranslation } from 'react-i18next';

import { FieldContainer } from 'modules/layer1';
import { selectFields } from 'modules/layer3';



const __dateFormat = 'YYYY-MM-DD';



export interface IDateWithTimeZoneFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isDisabled?: boolean;
  disableTimeZone?: boolean;
}

export const DateWithTimeZoneField: React.FC<IDateWithTimeZoneFieldProps> = props => {
  function handleChangeDate(date: moment.Moment | null) {
    if (!date) {
      props.onChange('');

    } else {
      const tz = props.value.split(',')[1] ?? '';
      const timeZone = tz || moment.tz.guess();
      const dateString = date.format(__dateFormat);
      props.onChange(`${dateString},${timeZone}`);
    }
  }



  function handleChangeTimeZone(timeZone: string) {
    if (!props.value || !timeZone) {
      props.onChange('');

    } else {
      const dateString = props.value.split(',')[0] ?? '';
      props.onChange(`${dateString},${timeZone}`);
    }
  }



  const [dateString, timeZone] = React.useMemo(
    () => props.value ?
      props.value.split(',') :
      ['', ''],
    [props.value]
  );



  return (
    <FieldContainer.Comp>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Grid container>
          <Grid
            item
            md={6}>
            <FieldContainer.Comp>
              <DatePicker
                showToolbar={false}
                inputFormat={__dateFormat}
                mask="____-__-__"
                label={props.label}
                value={dateString ?
                  moment(dateString, __dateFormat) :
                  null}
                onChange={handleChangeDate}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    error={!!props.errorMessage}
                    helperText={props.errorMessage} />
                )}
                disabled={props.isDisabled} />
            </FieldContainer.Comp>
          </Grid>

          <Grid
            item
            md={6}>
            <selectFields.TimeZoneSelectField
              label="Time Zone"
              value={timeZone || moment.tz.guess()}
              onChange={handleChangeTimeZone}
              isDisabled={props.isDisabled || props.disableTimeZone} />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </FieldContainer.Comp>
  );
};



export interface IDateWithTimeZoneFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  label: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  disableTimeZone?: boolean;
}

export function genDateWithTimeZoneFieldControllerComponent<TFormState extends FieldValues>() {
  const DateWithTimeZoneFieldController: React.FC<IDateWithTimeZoneFieldControllerProps<TFormState>> = props => {
    const {t} = useTranslation();



    return (
      <Controller
        name={props.fieldKey}
        control={props.formControl}
        rules={{
          validate: props.isRequired ?
            value => {
              const [timestamp] = value.split(',');
              const mTimestamp = moment(timestamp, __dateFormat);
              return !mTimestamp.isValid() ?
                t('errors.required') as string :
                true;
            } :
            undefined
        }}
        render={({field, fieldState}) => (
          <DateWithTimeZoneField
            label={props.label}
            value={field.value}
            onChange={value => field.onChange(value)}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled}
            disableTimeZone={props.disableTimeZone} />
        )} />
    );
  };

  return DateWithTimeZoneFieldController;
}
