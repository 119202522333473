import _ from 'lodash';

import { authService } from '../layer0';
import { apolloService } from '../layer1';



const __throttleMs = 2 * 1000;
const __checkIntervalMs = 1 * 60 * 1000;
const __maxInactivityTimeMs = 10 * 60 * 1000;



export async function init() {
  await __check();
  __updateLastActivityTimestamp();
  const fnThrottledUpdateLastActivityTimestamp = _.throttle(__updateLastActivityTimestamp, __throttleMs);
  document.addEventListener('mousemove', fnThrottledUpdateLastActivityTimestamp);
  document.addEventListener('keypress', fnThrottledUpdateLastActivityTimestamp);
  window.setInterval(__check, __checkIntervalMs);
}



function __updateLastActivityTimestamp() {
  window.localStorage.setItem('lastActivityTimestampMs', Date.now().toString());
}



async function __check() {
  const lastActivityTimestampMs = parseInt(window.localStorage.getItem('lastActivityTimestampMs') ?? '', 10);
  if (lastActivityTimestampMs) {
    const diff = Date.now() - lastActivityTimestampMs;
    if (diff > __maxInactivityTimeMs) {
      await authService.logOut();
      (await apolloService.getClient())?.clearStore();
      window.location.href = '/';
    }
  }
}
