import React from 'react';

import {
  BaseModal,
  enumCache,
  errorUtil,
  Form,
  genEnumSelectFieldControllerComponent,
  LoadingOverlay,
  selectFields
} from 'modules';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as dataService from './dataService';



interface IFormState {
  missedVisitReasonName: enumCache.IMissedVisitReasonName | '';
}

const MissedVisitReasonSelectFieldController = genEnumSelectFieldControllerComponent<IFormState, enumCache.IMissedVisitReasonName>(selectFields.MissedVisitReasonSelectField);



export interface IPatientAbsentModalProps {
  visitId: string;
  onAccept: () => void;
  onCancel: () => void;
}

export const PatientAbsentModal: React.FC<IPatientAbsentModalProps> = props => {
  const {t} = useTranslation();

  const [fnMarkAsMissed, markAsMissedMutationTuple] = dataService.useEmployeeMarkVisitAsMissedMutation(props.visitId);

  const form = useForm<IFormState>({
    defaultValues: {
      missedVisitReasonName: ''
    }
  });

  const isLoading =
    markAsMissedMutationTuple.loading;



  async function handleSubmit(formState: IFormState) {
    await errorUtil.errorWrap(async () => {
      if (!formState.missedVisitReasonName) {
        throw new Error(t('visits:errors.invalidMissedVisitReason'));
      }
      await fnMarkAsMissed(formState.missedVisitReasonName);
      props.onAccept();
    });
  }



  return (
    <BaseModal
      title={t('visits:markPatientAsAbsent')}
      isOpen
      showButtons
      isLoading={isLoading}
      onClose={props.onCancel}>

      {buttons => (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          <LoadingOverlay active={isLoading} />

          <MissedVisitReasonSelectFieldController
            formControl={form.control}
            fieldKey="missedVisitReasonName"
            isRequired
            isDisabled={isLoading} />

          {buttons}
        </Form>
      )}

    </BaseModal>
  );
};
