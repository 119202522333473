import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  usePatientProfileActivityEpisodesTabQuery as useBaseQuery,
  useSaveEpisodeMutation as useBaseSaveEpisodeMutation
} from 'generated/graphql';
import {
  enumCache,
  errorUtil
} from 'modules';



export interface IEpisode {
  id: string;
  ranking: number;
  statusName: enumCache.IEpisodeStatusName;
  inactiveType: enumCache.IEpisodeInactiveTypeName | '';
  startTimestamp: moment.Moment | null;
  endTimestamp: moment.Moment | null;
  transferTimestamp: moment.Moment | null;
}

export interface IData {
  episodes: IEpisode[];
}

export function useQuery(patientId: string) {
  const tuple = useBaseQuery({
    variables: {
      patientId
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  return React.useMemo(
    () => {
      const gqlEpisodes = tuple.data?.patient?.episodes ?? [];
      const data: IData = {
        episodes: _.map(gqlEpisodes, (gqlEpisode): IEpisode => ({
          id: gqlEpisode.id,
          ranking: gqlEpisode.ranking,
          statusName: gqlEpisode.status.name,
          inactiveType: gqlEpisode.inactiveType?.name ?? '',
          startTimestamp: gqlEpisode.startTimestamp ? moment(gqlEpisode.startTimestamp, moment.ISO_8601) : null,
          endTimestamp: gqlEpisode.endTimestamp ? moment(gqlEpisode.endTimestamp, moment.ISO_8601) : null,
          transferTimestamp: gqlEpisode.transferTimestamp ? moment(gqlEpisode.transferTimestamp, moment.ISO_8601) : null
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useAddEpisodeMutation(patientId: string, fnRefetch: () => Promise<void>) {
  const [fnMutate, tuple] = useBaseSaveEpisodeMutation({
    variables: {
      input: {
        patientId
      }
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      await errorUtil.errorWrap(async () => {
        await fnMutate();
        await fnRefetch();
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
