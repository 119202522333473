import _ from 'lodash';

import {
  cyan,
  teal
} from '@mui/material/colors';

import { enumCache } from 'modules/layer2';
import * as chipGenerator from '../shared/chipGenerator';
import { map } from 'rxjs';



export const EpisodeContinuationTypeChip = chipGenerator.genChipComponent<enumCache.IEpisodeContinuationTypeName>({
  obsValueId2DisplayData: enumCache.subjAllEnums.asObservable()
    .pipe(map((value): Record<enumCache.IEpisodeContinuationTypeName, chipGenerator.IValueDisplayData> => {
      const enumValues = value.episodeContinuationTypes;
      const enumValuesByName = _.keyBy(enumValues, enumValue => enumValue.name) as Record<enumCache.IEpisodeContinuationTypeName, enumCache.IEpisodeContinuationType>;

      return {
        recertification: {
          label: enumValuesByName.recertification?.displayName ?? "",
          backgroundColor: cyan[500]
        },
        resumption: {
          label: enumValuesByName.resumption?.displayName ?? "",
          backgroundColor: teal[500]
        }
      };
    }))
});
