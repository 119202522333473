import React from 'react';

import {
  Control,
  useFieldArray
} from 'react-hook-form';
import {
  Grid,
  Button
} from '@mui/material';
import {
  fields,
  genEditableListFormFragment
} from 'modules';

import * as types from '../types';
import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';



const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();
const EditableListFormFragment = genEditableListFormFragment<types.ITask>();



export interface ITaskListProps {
  formControl: Control<types.IFormState>;
  isDisabled?: boolean;
  onClickAddTaskSetTemplate: () => void;
}

export const TaskList: React.FC<ITaskListProps> = props => {
  const {t} = useTranslation();

  const fields = useFieldArray({
    control: props.formControl,
    name: 'tasks',
    keyName: '_key'
  });



  function handleClickAdd() {
    const id = `*${uuid.v4()}`;
    fields.append({
      _key: id,
      id,
      title: "",
      description: ""
    } as types.ITask);
  }



  function handleClickDelete(index: number) {
    fields.remove(index);
  }



  return (
    <EditableListFormFragment
      title={t('visits:tasks')}
      noItemsLabel={t('visits:noTasks')}
      addLabel={t('visits:addTask')}
      items={fields.fields}
      onClickAdd={handleClickAdd}
      onClickDelete={handleClickDelete}
      elHeaderRight={(
        <Button
          size="small"
          onClick={props.onClickAddTaskSetTemplate}>
          {t('visits:addFromTemplate')}
        </Button>
      )}
      fnGenElItem={(item, index) => (
        <>
          <Grid
            item
            md={4}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey={`tasks.${index}.title`}
              label={t('visits:title')}
              isRequired
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={7}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey={`tasks.${index}.description`}
              label={t('visits:description')}
              isDisabled={props.isDisabled} />
          </Grid>
        </>
      )} />
  );
};
