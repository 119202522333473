import React from 'react';

import {
  Box,
  colors
} from '@mui/material';

import { ErrorOutline } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import { styled } from '@mui/material/styles';



const BarDiv = styled('div')(({theme}) => ({
  backgroundColor: colors.red[700],
  color: theme.palette.getContrastText(colors.red[700]),
  padding: theme.spacing(1),
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center'
}));



export const TestModeWarningBar: React.FC = () => {
  return (
    <BarDiv>

      <ErrorOutline />

      <Box mx={1}>
        <Trans i18nKey="nonProdWarning">
          This is NOT the production environment. <strong>DO NOT ENTER SENSITIVE INFORMATION.</strong> Only test data is allowed here.
        </Trans>
      </Box>

      <ErrorOutline />

    </BarDiv>
  );
};
