import React from 'react';
import _ from 'lodash';

import {
  usePharmaciesActivityQuery,
  useImportPharmaciesMutation,
  useArchivePharmacyMutation,
  ImportPharmacyInput
} from 'generated/graphql';



export interface IPharmacy {
  id: string;
  name: string;
  primaryPhone: string;
  secondaryPhone: string;
  faxNumber: string;
  address: string;
}

export interface IData {
  pharmacies: IPharmacy[];
}

export function useQuery() {
  const tuple = usePharmaciesActivityQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  return React.useMemo(
    () => {
      const gqlPharmacies = tuple.data?.pharmacies ?? [];
      const data: IData= {
        pharmacies: _.map(gqlPharmacies, (gqlPharmacy): IPharmacy => ({
          id: gqlPharmacy.id,
          name: gqlPharmacy.name,
          primaryPhone: gqlPharmacy.primaryPhone,
          secondaryPhone: gqlPharmacy.secondaryPhone,
          faxNumber: gqlPharmacy.faxNumber,
          address: gqlPharmacy.address
        }))
      };

      return {
        ...tuple,
        data,
        refetch: async () => {
          await tuple.refetch();
        }
      };
    },
    [tuple]
  );
}



export interface IImportPharmacy {
  name: string;
  primaryPhone: string;
  secondaryPhone: string;
  faxNumber: string;
  address: string;
}

export function useImportMutation() {
  const [fnMutate, tuple] = useImportPharmaciesMutation();

  const fnWrappedMutate = React.useCallback(
    async (pharmacies: IImportPharmacy[]) => {
      await fnMutate({
        variables: {
          input: {
            pharmacies: _.map(pharmacies, (pharmacy): ImportPharmacyInput => ({
              name: pharmacy.name,
              primaryPhone: pharmacy.primaryPhone,
              secondaryPhone: pharmacy.secondaryPhone,
              faxNumber: pharmacy.faxNumber,
              address: pharmacy.address
            }))
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useArchiveMutation() {
  const [fnMutate, tuple] = useArchivePharmacyMutation();

  const fnWrappedMutate = React.useCallback(
    async (id: string) => {
      await fnMutate({
        variables: {
          id
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
