import { IColumnDescription } from 'modules';



// TODO incorporate useTranslation()
export const columnDescriptions: IColumnDescription[] = [
  {
    id: 'firstName',
    label: "First Name",
    isRequired: true
  },
  {
    id: 'lastName',
    label: "Last Name",
    isRequired: true
  },
  {
    id: 'primaryPhone',
    label: "Primary Phone",
    formatType: 'phone'
  },
  {
    id: 'secondaryPhone',
    label: "Secondary Phone",
    formatType: 'phone'
  },
  {
    id: 'faxNumber',
    label: "Fax Number",
    formatType: 'phone'
  }
];
