import _ from 'lodash';

import { green } from '@mui/material/colors';
import { enumCache } from 'modules/layer2';
import * as chipGenerator from '../shared/chipGenerator';
import { map } from 'rxjs';



export const TimesheetVisitApprovalStatusChip = chipGenerator.genChipComponent<enumCache.ITimesheetVisitApprovalStatusName>({
  obsValueId2DisplayData: enumCache.subjAllEnums.asObservable()
    .pipe(map((value): Record<enumCache.ITimesheetVisitApprovalStatusName, chipGenerator.IValueDisplayData> => {
      const enumValues = value.timesheetVisitApprovalStatuses;
      const enumValuesByName = _.keyBy(enumValues, enumValue => enumValue.name) as Record<enumCache.ITimesheetVisitApprovalStatusName, enumCache.ITimesheetVisitApprovalStatus>;

      return {
        pending: {
          label: enumValuesByName.pending?.displayName ?? "",
          backgroundColor: '#317FF4'
        },
        denied: {
          label: enumValuesByName.denied?.displayName ?? "",
          backgroundColor: '#000'
        },
        approved: {
          label: enumValuesByName.approved?.displayName ?? "",
          backgroundColor: green['A700']
        }
      };
    }))
});
