import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  Box,
  Badge
} from '@mui/material';

import { ProtectedImage } from 'modules';
import { useTranslation } from 'react-i18next';
import { Group } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import * as types from '../types';



interface IContainerBoxProps {
  isSelected: boolean;
}

const ContainerBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isSelected'
})<IContainerBoxProps>(({theme, isSelected}) => ({
  flex: `0 0 ${theme.spacing(18)}`,
  backgroundColor: isSelected ? '#fff' : 'transparent',
  padding: theme.spacing(3),
  borderRight: '1px solid #F4F9FD',
  borderBottom: '1px solid #ddd',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));

const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const AvatarAndTitleBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const AvatarBox = styled(Box)(({theme}) => ({
  marginRight: theme.spacing(2)
}));

const TitleBox = styled(Box)(() => ({
  fontSize: 14,
  fontWeight: 400,
  letterSpacing: '0.25px',
  display: 'flex',
  flexDirection: 'column'
}));

const AndMoreDiv = styled('div')(() => ({
  fontStyle: 'italic'
}));

const TimeAgoBox = styled(Box)(() => ({
  fontSize: 10,
  fontWeight: 500,
  letterSpacing: '1.5px',
  textAlign: 'right',
  textTransform: 'uppercase'
}));

const MessageBox = styled(Box)(({theme}) => ({
  marginTop: theme.spacing(2),
  color: '#7C7E88'
}));



const __maxMessageLength = 72;
const __maxNumNames = 4;



export interface IThreadProps {
  displayName: string;
  users: types.IUser[];
  lastMessageTimestamp: moment.Moment | null;
  lastMessage: string;
  numUnreadMessages: number;
  isSelected: boolean;
  onClick: () => void;
}

export const Thread: React.FC<IThreadProps> = props => {
  const {t} = useTranslation();



  const truncatedMessage = props.lastMessage.length > __maxMessageLength ?
    `${props.lastMessage.slice(0, __maxMessageLength).trim()}...` :
    props.lastMessage;



  const userNames = _.map(props.users, user => user.name);
  const firstProfilePictureFileKey = _.first(
    _.filter(props.users, user => !!user.profilePictureFileKey)
  )?.profilePictureFileKey ?? '';



  const elTitle = React.useMemo(
    () => (
      <TitleBox
        title={_.join(userNames, ", ")}>

        {_.map(_.take(userNames, __maxNumNames), (userName, index) => (
          <div key={index}>
            {userName}
          </div>
        ))}

        {userNames.length > __maxNumNames && (
          <AndMoreDiv>
            {t('andMore', {count: userNames.length - __maxNumNames})}
          </AndMoreDiv>
        )}

      </TitleBox>
    ),
    [userNames]
  );



  return (
    <ContainerBox
      isSelected={props.isSelected}
      onClick={props.onClick}>
      <HeaderBox>
        <AvatarAndTitleBox>
          <AvatarBox>
            <Badge
              badgeContent={props.numUnreadMessages}
              color="error"
              max={9}>
              <ProtectedImage
                fileKey={firstProfilePictureFileKey}
                asAvatar
                size="40px"
                disableSpinner>
                {props.users.length > 1 ? (
                  <Group />
                ) : null}
              </ProtectedImage>
            </Badge>
          </AvatarBox>

          {elTitle}
        </AvatarAndTitleBox>

        <TimeAgoBox title={props.lastMessageTimestamp?.toISOString(true) ?? ""}>
          {props.lastMessageTimestamp &&
            props.lastMessageTimestamp.fromNow()}
        </TimeAgoBox>
      </HeaderBox>

      <MessageBox>
        {truncatedMessage}
      </MessageBox>
    </ContainerBox>
  );
};
