import React from 'react';

import {
  SiteUserTypeName,
  useMessengerActivityQuery
} from 'generated/graphql';



export type IUserType =
  'admin' |
  'employee';

export interface IData {
  userType: IUserType | '';
}

export function useQuery() {
  const tuple = useMessengerActivityQuery({fetchPolicy: 'no-cache'});

  return React.useMemo(
    () => {
      const gqlTypeName2Client: Partial<Record<SiteUserTypeName | '', IUserType>> = {
        administrator: 'admin',
        employee: 'employee'
      };

      const data: IData = {
        userType: gqlTypeName2Client[tuple.data?.loggedInUser?.typeName ?? ''] ?? ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
