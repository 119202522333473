import React from 'react';

import {
  Box,
  Typography
} from '@mui/material';

import { BaseModal } from 'modules/layer1';
import { styled } from '@mui/material/styles';



const MessageTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '16px',
  color: '#979797'
}));



export interface IConfirmationModalProps {
  isOpen: boolean;
  title: string;
  message: string;
  acceptButtonLabel?: string;
  cancelButtonLabel?: string;
  onAccept: () => void;
  onCancel: () => void;
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = props => {
  return (
    <BaseModal
      title={props.title}
      isOpen={props.isOpen}
      onAccept={props.onAccept}
      onClose={props.onCancel}
      showButtons
      acceptButtonLabel={props.acceptButtonLabel}
      cancelButtonLabel={props.cancelButtonLabel}>

      {buttons => (
        <>
          <Box>
            <MessageTypography>
              {props.message}
            </MessageTypography>
          </Box>

          {buttons}
        </>
      )}

    </BaseModal>
  );
};
