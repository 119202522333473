import React from 'react';

import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover
} from '@mui/material';

import { MoreHoriz } from '@mui/icons-material';



export interface IRowOptionsButtonProps {
  onClickDelete: () => void;
}

export const RowOptionsButton: React.FC<IRowOptionsButtonProps> = props => {
  const [isOpen, setIsOpen] = React.useState(false);
  const refButton = React.useRef<HTMLButtonElement>(null);



  function handleClickItem(id: string) {
    setIsOpen(false);

    if (id === 'delete') {
      props.onClickDelete();
    }
  }



  return (
    <>
      <IconButton
        ref={refButton}
        size="small"
        onClick={() => setIsOpen(true)}>
        <MoreHoriz />
      </IconButton>

      <Popover
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorEl={refButton.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>

        <Box width="200px">
          <List>
            <ListItem
              button
              onClick={() => handleClickItem('delete')}>
              <ListItemText primary="Delete" />
            </ListItem>
          </List>
        </Box>

      </Popover>
    </>
  );
};
