import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { SectionHeaderBox } from 'modules';

import { DetailsSection } from './DetailsSection/DetailsSection';
import { AddressSection } from './AddressSection/AddressSection';
import { ContactDetailsSection } from './ContactDetailsSection/ContactDetailsSection';
import { IntakeSection } from './IntakeSection/IntakeSection';
import { PharmacySection } from './PharmacySection/PharmacySection';
import { EmergencyContactSection } from './EmergencyContactSection/EmergencyContactSection';



export interface IPatientDetailsTabProps {
  patientId: string;
  showSsn?: boolean;
}

export const PatientDetailsTab: React.FC<IPatientDetailsTabProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>
      <Box mb={4}>
        <Typography variant="h2">
          {t('patients:demographics')}
        </Typography>
      </Box>

      <Grid
        container
        spacing={10}>

        <Grid
          item
          md={4}>

          <DetailsSection
            patientId={props.patientId}
            showSsn={props.showSsn} />

        </Grid>

        <Grid
          item
          md={4}>

          <Box mb={6}>
            <AddressSection
              patientId={props.patientId} />
          </Box>

          <ContactDetailsSection
            patientId={props.patientId} />

        </Grid>

        <Grid
          item
          md={4}>

          <Box mb={4}>
            <SectionHeaderBox>
              <Typography variant="h2">
                {t('patients:pharmacy')}
              </Typography>
            </SectionHeaderBox>

            <PharmacySection
              patientId={props.patientId} />
          </Box>

          <Box mb={4}>
            <SectionHeaderBox>
              <Typography variant="h2">
                {t('patients:emergencyContact')}
              </Typography>
            </SectionHeaderBox>

            <EmergencyContactSection
              patientId={props.patientId} />
          </Box>

        </Grid>

        <Grid
          item
          md={8}>

          <Box mb={4}>
            <Typography variant="h2">
              {t('patients:intake')}
            </Typography>
          </Box>

          <IntakeSection
            patientId={props.patientId} />

        </Grid>

      </Grid>
    </Box>
  );
};
