import {
  genEnumSelectFieldComponent,
  enumCache
} from 'modules/layer2';

import i18next from 'i18next';
import * as dataService from './dataService';

export type IGenderName = dataService.IGenderName;



export const GenderSelectField = genEnumSelectFieldComponent<IGenderName, enumCache.IGender>(dataService.useGenderSelectFieldQuery, () => i18next.t('gender'));
