import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  paletteColors,
  chips,
  PatientDetailsTab,
  HeaderLabelWithValue,
  PatientVisitsTab
} from 'modules';

import { Button } from '@mui/material';
import { useParams } from 'react-router';
import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';
import { Create } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { EpisodesTab } from './EpisodesTab/EpisodesTab';



interface IParams {
  patientId: string;
}

export const PatientProfileActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.usePatientProfileActivityQuery(params.patientId ?? '');

  const [selectedTabId, setSelectedTabId] = React.useState('patientDetails');

  const isLoading = queryTuple.loading;



  function handleClickEditData() {
    navigate(`/admin/patients/edit/${params.patientId}`);
  }



  return (
    <BaseActivityTemplate
      mode="admin"
      navigationBarSelectedLinkId="patients"
      isLoading={isLoading}
      requireOneOfPermissionCategories={[
        'fullAdmin',
        'supervisor'
      ]}
      headerOptions={{
        backgroundColor: paletteColors.patients,
        title: queryTuple.data.fullName,
        hasBackButton: true,
        elRight: (
          <HeaderLabelWithValue
            label={t('status')}>
            <chips.PatientStatusChip
              valueId={queryTuple.data.patientStatusName} />
          </HeaderLabelWithValue>
        ),
        elRightButtons: _.compact([
          selectedTabId === 'patientDetails' ? (
            <Button
              key="editData"
              variant="outlined"
              onClick={handleClickEditData}
              color="primary"
              startIcon={(
                <Create />
              )}>
              {t('editData')}
            </Button>
          ) : null
        ]),
        tabOptions: {
          tabs: [
            {
              id: 'patientDetails',
              label: t('patients:patientDetails')
            },
            {
              id: 'episodes',
              label: t('patients:episodes')
            },
            {
              id: 'visits',
              label: t('patients:visits')
            }
          ],
          selectedTabId,
          onSelectTab: setSelectedTabId
        }
      }}>

      {selectedTabId === 'patientDetails' && (
        <PatientDetailsTab
          patientId={params.patientId ?? ''}
          showSsn={queryTuple.data.showSsn} />
      )}

      {selectedTabId === 'episodes' && (
        <EpisodesTab
          patientId={params.patientId ?? ''} />
      )}

      {selectedTabId === 'visits' && (
        <PatientVisitsTab
          patientId={params.patientId ?? ''}
          userType="administrator" />
      )}

    </BaseActivityTemplate>
  );
};
