import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useCreateRequirementAssignmentRecurrencesMutation
} from 'generated/graphql';

import * as types from './types';



function __isValidAnchor(anchor: moment.Moment) {
  return anchor.isValid() && !anchor.isBefore(moment());
}



export function genUpcomingAssignmentDates(formState: types.IFormState, numDates = 5): moment.Moment[] {
  if (!formState.timeZone) {
    return [];
  }

  const recurrenceTypeName = formState.recurrenceTypeName;

  if (recurrenceTypeName === 'weekly' && formState.recurWeekly.dayOfWeekName) {
    const anchor = moment().tz(formState.timeZone);
    if (!__isValidAnchor(anchor)) {
      return [];
    }

    const dayOfWeekName = formState.recurWeekly.dayOfWeekName;
    const firstDate = anchor.clone().day(dayOfWeekName).endOf('day');
    if (firstDate.isBefore(anchor)) {
      firstDate.add(1, 'week');
    }

    return _.times(numDates, index =>
      firstDate.clone().add(index, 'weeks'));

  } else if (recurrenceTypeName === 'monthly') {
    const mAnchor = moment(formState.recurMonthly.anchorTimestamp, formState.timeZone);
    const intervalAnchor = mAnchor.clone().startOf('month');
    if (!__isValidAnchor(intervalAnchor)) {
      return [];
    }

    const iNumMonthsInterval = Math.trunc(+formState.recurMonthly.numMonthsInterval || 1);
    let iMonthOffset = Math.trunc(+formState.recurMonthly.monthOffset || 0);
    if (iMonthOffset >= iNumMonthsInterval) {
      iMonthOffset = iNumMonthsInterval - 1;
    }
    const iDayOfMonth = Math.trunc(+formState.recurMonthly.dayOfMonth || 1);

    return _.times(numDates, index => {
      const intervalStart = intervalAnchor.clone().add(index * iNumMonthsInterval, 'months');
      const targetMonth = intervalStart.clone().add(iMonthOffset, 'months');
      const daysInTargetMonth = targetMonth.daysInMonth();
      const dayOfMonth = iDayOfMonth > daysInTargetMonth ?
        daysInTargetMonth :
        iDayOfMonth;
      return targetMonth.clone()
        .date(dayOfMonth)
        .hours(mAnchor.hours())
        .minutes(mAnchor.minutes())
        .seconds(mAnchor.seconds());
    });

  } else if (recurrenceTypeName === 'yearly') {
    const mAnchor = moment(formState.recurYearly.anchorTimestamp, formState.timeZone);
    if (!__isValidAnchor(mAnchor)) {
      return [];
    }

    const iNumYearsInterval = Math.trunc(+formState.recurYearly.numYearsInterval || 1);

    return _.times(numDates, index =>
      mAnchor.clone().add(index * iNumYearsInterval, 'years'));

  } else {
    return [];
  }
}



export function useSave(presetId: string) {
  const [fnMutate, tuple] = useCreateRequirementAssignmentRecurrencesMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            requirementPresetId: presetId,
            assigneeEmployeeIds: _.map(formState.assignedEmployees, employee => employee.id),
            timeZone: formState.timeZone,

            recurOnce: formState.recurrenceTypeName === 'once' ? {
              anchorTimestamp: moment.tz(formState.recurOnce.anchorTimestamp, formState.timeZone).startOf('minute').toISOString(true)
            } : undefined,

            recurWeekly: formState.recurrenceTypeName === 'weekly' && formState.recurWeekly.dayOfWeekName ? {
              dayOfWeek: formState.recurWeekly.dayOfWeekName
            } : undefined,

            recurMonthly: formState.recurrenceTypeName === 'monthly' ? {
              anchorTimestamp: moment.tz(formState.recurMonthly.anchorTimestamp, formState.timeZone).toISOString(true),
              numMonthsInterval: +formState.recurMonthly.numMonthsInterval || 0,
              monthOffset: +formState.recurMonthly.monthOffset || 0,
              dayOfMonth: +formState.recurMonthly.dayOfMonth || 0
            } : undefined,

            recurYearly: formState.recurrenceTypeName === 'yearly' ? {
              anchorTimestamp: moment.tz(formState.recurYearly.anchorTimestamp, formState.timeZone).startOf('minute').toISOString(true),
              numYearsInterval: +formState.recurYearly.numYearsInterval || 0
            } : undefined
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
