import React from 'react';
import _ from 'lodash';

import {
  BaseModal,
  Form,
  selectFields
} from 'modules';
import {
  Box,
  Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';



interface IFormState {
  taskSetTemplate: selectFields.ITaskSetTemplate | null;
}

const TaskSetTemplateSelectFieldController = selectFields.genTaskSetTemplateSelectFieldControllerComponent<IFormState>();



export interface IAddTasksFromTemplateModalProps {
  onAccept: (taskSetTemplate: selectFields.ITaskSetTemplate) => void;
  onClose: () => void;
}

export const AddTasksFromTemplateModal: React.FC<IAddTasksFromTemplateModalProps> = props => {
  const {t} = useTranslation();

  const form = useForm<IFormState>({
    defaultValues: {
      taskSetTemplate: null
    }
  });

  const taskSetTemplate = form.watch('taskSetTemplate');



  function handleSubmit(formState: IFormState) {
    if (formState.taskSetTemplate) {
      props.onAccept(formState.taskSetTemplate);
    }
  }



  return (
    <BaseModal
      title={t('visits:addTaskTemplateModal.title')}
      isOpen
      showButtons
      onClose={props.onClose}>

      {buttons => (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          <Box mb={3}>
            <Typography variant="body1">
              {t('visits:addTaskTemplateModal.description')}
            </Typography>
          </Box>

          <Box mb={3}>
            <TaskSetTemplateSelectFieldController
              formControl={form.control}
              fieldKey="taskSetTemplate"
              isRequired />
          </Box>

          {taskSetTemplate && (
            <Box mb={3}>
              {taskSetTemplate.tasks.length === 0 ? (
                <div>
                  {t('visits:noTasks')}
                </div>

              ) : (
                <ul>
                  {_.map(taskSetTemplate.tasks, (task, index) => (
                    <li key={index}>
                      {task.title}

                      {task.description && (
                        <ul>
                          <li>
                            {task.description}
                          </li>
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </Box>
          )}

          {buttons}
        </Form>
      )}

    </BaseModal>
  );
};
