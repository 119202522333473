import React from 'react';

import {
  BaseModal,
  Form,
  fields
} from 'modules';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from '../types';



const TextFieldControllerComponent = fields.genTextFieldControllerComponent<IFormState>();



interface IFormState {
  note: string;
}


export interface ISetToInactiveModalProps {
  type: types.IInactiveType;
  onAccept: (note: string) => void;
  onCancel: () => void;
}

export const SetToInactiveModal: React.FC<ISetToInactiveModalProps> = props => {
  const {t} = useTranslation();

  const form = useForm<IFormState>({
    defaultValues: {
      note: ""
    }
  });



  function handleSubmit(formState: IFormState) {
    props.onAccept(formState.note);
  }



  return (
    <BaseModal
      isOpen
      title={props.type === 'death' ?
        t('adminEpisodes:setToDeathStatus') :
        t('adminEpisodes:setToDischargeStatus')}
      onClose={props.onCancel}
      showButtons>

      {buttons => (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>

          <TextFieldControllerComponent
            formControl={form.control}
            fieldKey="note"
            label={t('notes')}
            numLines={5}
            isRequired />

          {buttons}

        </Form>
      )}

    </BaseModal>
  );
};
