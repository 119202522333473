import React from 'react';

import {
  BaseModal,
  fields,
  Form,
  validationUtil
} from 'modules';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';



interface IFormState {
  ssn: string;
}

const TextFieldController = fields.genTextFieldControllerComponent<IFormState>();



export interface IEditSsnModalProps {
  isOpen: boolean;
  initSsn: string;
  onAccept: (ssn: string) => void;
  onClose: () => void;
}

export const EditSsnModal: React.FC<IEditSsnModalProps> = props => {
  const {t} = useTranslation();

  const form = useForm<IFormState>({
    defaultValues: {
      ssn: ""
    }
  });



  React.useEffect(
    () => {
      form.setValue('ssn', props.initSsn);
    },
    [props.initSsn]
  );



  function handleSubmit(formState: IFormState) {
    props.onAccept(formState.ssn);
  }



  return (
    <BaseModal
      isOpen={props.isOpen}
      title={t('patients:editSsn')}
      showButtons
      onClose={props.onClose}>

      {buttons => (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          <TextFieldController
            formControl={form.control}
            fieldKey="ssn"
            label={t('ssn')}
            extraValidationRules={{
              pattern: validationUtil.rules.ssn
            }} />

          {buttons}
        </Form>
      )}

    </BaseModal>
  );
};
