import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useRequirementsActivityAssignmentsTabQuery as useBaseQuery,
  useArchiveAssignedRequirementMutation as useBaseArchiveAssignedRequirementMutation
} from 'generated/graphql';

import { enumCache } from 'modules';



export interface IUser {
  id: string;
  fullName: string;
}

export interface IRequirement {
  id: string;
  type: enumCache.IRequirementTypeName;
  displayName: string;
  status: enumCache.IRequirementStatusName;
  assignedUser: IUser;
  dueTimestamp: moment.Moment;
}

export interface IData {
  requirements: IRequirement[];
}

export function useQuery() {
  const tuple = useBaseQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  return React.useMemo(
    () => {
      const gqlRequirements = tuple.data?.assignedRequirements ?? [];
      const data: IData = {
        requirements: _.map(gqlRequirements, (gqlRequirement): IRequirement => ({
          id: gqlRequirement.id,
          type: gqlRequirement.preset.type.name,
          displayName: gqlRequirement.preset.displayName,
          status: gqlRequirement.status.name,
          assignedUser: {
            id: gqlRequirement.assignedUser.id,
            fullName: gqlRequirement.assignedUser.fullName
          },
          dueTimestamp: moment(gqlRequirement.dueTimestamp, moment.ISO_8601)
        }))
      };

      return {
        ...tuple,
        data,
        refetch: async () => {
          await tuple.refetch();
        }
      };
    },
    [tuple]
  );
}



export function useArchiveRequirementMutation(fnRefetch: () => Promise<void>) {
  const [fnMutate, tuple] = useBaseArchiveAssignedRequirementMutation();

  const fnWrappedMutate = React.useCallback(
    async (id: string) => {
      await fnMutate({
        variables: {
          id
        }
      });
      await fnRefetch();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
