import React from 'react';

import {
  Box,
  Typography
} from '@mui/material';
import {
  BaseModal,
  LoadingOverlay,
  Signature
} from 'modules';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import * as dataService from './dataService';



const ContainerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));



export interface IUseLatestSignatureModalProps {
  visitId: string;
  onAccept: (fileKey: string) => void;
  onCancel: () => void;
}

export const UseLatestSignatureModal: React.FC<IUseLatestSignatureModalProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.visitId);



  return (
    <BaseModal
      isOpen
      showButtons
      isLoading={queryTuple.loading}
      title={t('visits:confirmSignature')}
      onAccept={() => props.onAccept(queryTuple.data.fileKey)}
      onClose={props.onCancel}>

      {buttons => (
        <>
          <LoadingOverlay
            active={queryTuple.loading} />

          <ContainerBox>
            <Box mb={3}>
              <Typography variant="body1">
                {t('visits:confirmSignatureDescription')}
              </Typography>
            </Box>

            <Box mb={3}>
              <Signature
                source={{
                  type: 'fileKey',
                  value: queryTuple.data.fileKey
                }} />
            </Box>

            {buttons}
          </ContainerBox>
        </>
      )}

    </BaseModal>
  );
};
