import React from 'react';

import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import {
  FieldPath,
  useFieldArray,
  UseFormReturn
} from 'react-hook-form';
import {
  genTextFieldControllerComponent,
  selectFields
} from 'modules';

import { Delete } from '@mui/icons-material';
import * as types from '../types';
import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';
import { styled } from '@mui/material/styles';



const Row = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
}));

const Cell = styled(Box)(({theme}) => ({
  flex: '1 0',
  marginRight: theme.spacing(1)
}));



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();
const MedicationSelectFieldController = selectFields.MedicationSelectField.genMedicationSelectFieldControllerComponent<types.IFormState>();



export interface IMedicationsProps {
  form: UseFormReturn<types.IFormState>;
  fieldKey: FieldPath<types.IFormState>;
  isDisabled?: boolean;
}

export const Medications: React.FC<IMedicationsProps> = props => {
  const {t} = useTranslation();

  const fields = useFieldArray({
    control: props.form.control,
    name: props.fieldKey,
    keyName: 'id'
  });



  function handleClickAddMedication() {
    fields.append({
      id: `*${uuid.v4()}`,
      name: "",
      dosage: "",
      instructions: ""
    } as types.IMedication);
  }



  function handleClickDelete(index: number) {
    fields.remove(index);
  }



  return (
    <>
      <Box mb={3}>
        <Typography variant="h2">
          {t('adminEpisodes:medications.medications')}
        </Typography>
      </Box>

      <Box mb={3}>
        <Box mb={3}>
          <Button onClick={handleClickAddMedication}>
            {t('adminEpisodes:medications.addMedication')}
          </Button>
        </Box>

        {fields.fields.map((field, index) => (
          <Row key={field.id}>
            <Cell>
              <MedicationSelectFieldController
                formControl={props.form.control}
                fieldKey={`${props.fieldKey}.${index}.name`}
                label={t('adminEpisodes:medications.name')}
                isDisabled={props.isDisabled} />
            </Cell>

            <Cell>
              <TextFieldController
                formControl={props.form.control}
                fieldKey={`${props.fieldKey}.${index}.dosage`}
                label={t('adminEpisodes:medications.dosage')}
                isDisabled={props.isDisabled} />
            </Cell>

            <Cell>
              <TextFieldController
                formControl={props.form.control}
                fieldKey={`${props.fieldKey}.${index}.instructions`}
                label={t('adminEpisodes:medications.instructions')}
                isDisabled={props.isDisabled} />
            </Cell>

            <IconButton
              onClick={() => handleClickDelete(index)}
              size="small">
              <Delete />
            </IconButton>
          </Row>
        ))}

        <Box mt={3}>
          <Divider />
        </Box>
      </Box>
    </>
  );
};
