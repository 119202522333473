import React from 'react';

import {
  Box,
  Grid,
  Typography,
  Divider
} from '@mui/material';

import { genTextFieldControllerComponent } from 'modules';
import * as types from '../types';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();



export interface IGeneralNotesProps {
  formControl: Control<types.IFormState>;
  isDisabled?: boolean;
}

export const GeneralNotes: React.FC<IGeneralNotesProps> = props => {
  const {t} = useTranslation();



  return (
    <>
      <Box mb={3}>
        <Typography variant="h2">
          {t('adminEpisodes:generalNotes')}
        </Typography>
      </Box>

      <Box mb={3}>
        <Grid container>

          <Grid
            item
            md={6}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey="diet"
              label={t('adminEpisodes:diet')}
              numLines={5}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={6}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey="exerciseActivity"
              label={t('adminEpisodes:exerciseActivity')}
              numLines={5}
              isDisabled={props.isDisabled} />
          </Grid>

        </Grid>

        <Box mt={3}>
          <Divider />
        </Box>
      </Box>
    </>
  );
};
