import React from 'react';
import _ from 'lodash';

import {
  useProviderSelectFieldQuery as useBaseQuery
} from 'generated/graphql';



export interface IProvider {
  id: string;
  formattedName: string;
}

export interface IData {
  providers: IProvider[];
  providersById: Record<string, IProvider>;
}

export function useProviderSelectFieldQuery() {
  const tuple = useBaseQuery();

  return React.useMemo(
    () => {
      const gqlProviders = tuple.data?.providers ?? [];

      const providers = _.map(gqlProviders, (gqlProvider): IProvider => ({
        id: gqlProvider.id,
        formattedName: `${gqlProvider.lastName}, ${gqlProvider.firstName}`.trim()
      }));

      const sortedProviders: IProvider[] = [
        {
          id: '',
          formattedName: ""
        },
        ..._.sortBy(providers, provider =>
          provider.formattedName.toLowerCase())
      ];

      return {
        ...tuple,
        data: {
          providers: sortedProviders,
          providersById: _.keyBy(providers, provider => provider.id)
        } as IData
      };
    },
    [tuple]
  );
}
