import React from 'react';

import {
  useViewTimesheetActivityApproveTimesheetVisitModalQuery,
  useApproveTimesheetVisitMutation
} from 'generated/graphql';



export interface IFormState {
  id: string;
  numOverrideBilledHours: string;
  approvalComment: string;
}

export interface IData {
  formState: IFormState;
}

export function useQuery(timesheetId: string, timesheetVisitId: string) {
  const tuple = useViewTimesheetActivityApproveTimesheetVisitModalQuery({
    variables: {
      timesheetId,
      timesheetVisitId
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlVisit = tuple.data?.timesheet?.visitAssignedEmployees[0];

      const data: IData = {
        formState: {
          id: timesheetVisitId,
          numOverrideBilledHours: gqlVisit?.numOverrideBilledHours ?? "",
          approvalComment: gqlVisit?.approvalComment ?? ""
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useApproveMutation() {
  const [fnMutate, tuple] = useApproveTimesheetVisitMutation();

  const fnWrappedMutate = React.useCallback(
    async (args: IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id: args.id,
            numOverrideBilledHours: args.numOverrideBilledHours.trim() || null,
            approvalComment: args.approvalComment
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
