import React from 'react';
import _ from 'lodash';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  Check,
  RadioButtonUnchecked,
  RadioButtonChecked,
  Clear
} from '@mui/icons-material';

import { SectionHeaderBox } from 'modules/layer1';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { ValueWithLabel } from 'modules';



export interface IQuizQuestionAnswerOption {
  id: string;
  description: string;
  isCorrect: boolean;
  isSelected: boolean;
}

export interface IQuizQuestion {
  id: string;
  description: string;
  answerOptions: IQuizQuestionAnswerOption[];
}

export interface IQuiz {
  id: string;
  score: number | null;
  questions: IQuizQuestion[];
}



interface ILiProps {
  hideBullets: boolean;
}

const Li = styled('li', {
  shouldForwardProp: prop =>
    prop !== 'hideBullets'
})<ILiProps>(({hideBullets}) => ({
  listStyle: hideBullets ? 'none' : undefined
}));

const LiContent = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const RowBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));



export interface IProps {
  quiz: IQuiz;
  showSelections?: boolean;
  showScore?: boolean;
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={3}>
      <SectionHeaderBox>
        <Typography variant="h2">
          {t('requirements:quiz.content')}
        </Typography>
      </SectionHeaderBox>

      {props.showScore && props.quiz.score !== null && (
        <Grid container>
          <Grid
            item
            md={6}>
            <Grid container>
              <ValueWithLabel
                label={t('requirements:quiz.score')}
                value={props.quiz.score.toString() + "%"} />
            </Grid>
          </Grid>
        </Grid>
      )}

      <ul>
        {_.map(props.quiz.questions, question => {
          const correctAnswerOption = _.find(question.answerOptions, option => option.isCorrect);
          const selectedAnswerOption = _.find(question.answerOptions, option => option.isSelected);

          return (
            <Li
              key={question.id}
              hideBullets={!!props.showSelections}>
              <RowBox>
                {props.showSelections && (
                  <Box mr={1}>
                    {correctAnswerOption?.id === selectedAnswerOption?.id ? (
                      <Check
                        fontSize="small"
                        color="success" />

                    ) : (
                      <Clear
                        fontSize="small"
                        color="error" />
                    )}
                  </Box>
                )}

                <strong>
                  {question.description}
                </strong>
              </RowBox>

              <ul>
                {_.map(question.answerOptions, answerOption => (
                  <Li
                    key={answerOption.id}
                    hideBullets={!!props.showSelections}>
                    <LiContent>
                      {props.showSelections && (
                        <Box mr={1}>
                          {answerOption.isSelected ? (
                            <RadioButtonChecked
                              fontSize="small" />

                          ) : (
                            <RadioButtonUnchecked
                              fontSize="small" />
                          )}
                        </Box>
                      )}

                      {answerOption.isCorrect ? (
                        <strong>
                          {answerOption.description}
                        </strong>

                      ) : answerOption.description}

                      {!props.showSelections && answerOption.isCorrect && (
                        <Box ml={1}>
                          <Check
                            fontSize="small"
                            color="success" />
                        </Box>
                      )}
                    </LiContent>
                  </Li>
                ))}
              </ul>
            </Li>
          );
        })}
      </ul>
    </Box>
  );
};
