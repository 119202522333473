import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  fields,
  SectionHeaderBox
} from 'modules';

import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from '../types';



const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();



export interface IEmergencyContactSectionProps {
  formControl: Control<types.IFormState>;
  isDisabled: boolean;
}

export const EmergencyContactSection: React.FC<IEmergencyContactSectionProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <SectionHeaderBox>
        <Typography variant="h2">
          {t('patients:emergencyContact')}
        </Typography>
      </SectionHeaderBox>

      <Grid container>

        <Grid
          item
          md={3}>
          <TextFieldController
            formControl={props.formControl}
            fieldKey="emergencyName"
            label={t('patients:emergencyName')}
            isDisabled={props.isDisabled} />
        </Grid>

        <Grid
          item
          md={3}>
          <TextFieldController
            formControl={props.formControl}
            fieldKey="emergencyRelationship"
            label={t('patients:emergencyRelationship')}
            isDisabled={props.isDisabled} />
        </Grid>

        <Grid
          item
          md={3}>
          <TextFieldController
            formControl={props.formControl}
            fieldKey="emergencyPhone"
            label={t('patients:emergencyPhone')}
            isDisabled={props.isDisabled} />
        </Grid>

        <Grid
          item
          md={3}>
          <TextFieldController
            formControl={props.formControl}
            fieldKey="emergencyAddress"
            label={t('patients:emergencyAddress')}
            isDisabled={props.isDisabled} />
        </Grid>

      </Grid>

    </Box>
  );
};
