import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  chips,
  HeaderLabelWithValue,
  paletteColors,
  ValueWithLabel,
  VisitEmployeeVisitInfoSection,
  VisitAfterVisitDataSection,
  VisitAssignedEmployeesSection
} from 'modules';
import {
  Box,
  Grid,
  Typography,
  Button
} from '@mui/material';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { Create } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



interface IParams {
  visitId: string;
}

export const ViewVisitActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.visitId ?? '');

  const isLoading =
    queryTuple.loading;



  const showEmployeeVisitInfo = React.useMemo(
    () => (
      queryTuple.data.visitStatusName === 'clockedIn' ||
      queryTuple.data.visitStatusName === 'clockedOut' ||
      queryTuple.data.visitStatusName === 'completed'
    ),
    [queryTuple.data.visitStatusName]
  );



  const showAfterVisitData = React.useMemo(
    () => (
      queryTuple.data.visitStatusName === 'clockedOut' ||
      queryTuple.data.visitStatusName === 'completed'
    ),
    [queryTuple.data.visitStatusName]
  );



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.visits,
          title: queryTuple.data.displayName,
          hasBackButton: true,
          elRight: !queryTuple.loading ? (
            <HeaderLabelWithValue label={t('status')}>
              <chips.VisitStatusChip
                valueId={queryTuple.data.visitStatusName} />
            </HeaderLabelWithValue>
          ) : undefined,
          elRightButtons: _.compact([
            (!queryTuple.loading && queryTuple.data.canEditVisits) ? (
              <Button
                key="editData"
                variant="contained"
                onClick={() => navigate(`/admin/visits/edit/${params.visitId}`)}
                color="primary"
                startIcon={(
                  <Create />
                )}>
                {t('editData')}
              </Button>
            ) : undefined
          ])
        }}>

        {!isLoading && (
          <>
            <Box mb={3}>
              <Box>
                <Box mb={3}>
                  <Typography variant="h2">
                    {t('details')}
                  </Typography>
                </Box>

                <Grid container>
                  <Grid
                    item
                    md={6}>
                    <Grid container>

                      <ValueWithLabel
                        label={t('visits:visitCategory')}
                        value={t(`visits:visitCategories.${queryTuple.data.visitCategoryName}`)} />

                      <ValueWithLabel
                        label={t('visits:date')}
                        value={queryTuple.data.formattedDate} />

                      <ValueWithLabel
                        label={t('visits:shift')}
                        value={t(`visits:shifts.${queryTuple.data.shiftName}`)} />

                      <ValueWithLabel
                        label={t('visits:note')}
                        value={queryTuple.data.note} />

                      <ValueWithLabel
                        label={t('visits:location')}
                        value={queryTuple.data.location} />

                      <ValueWithLabel
                        label={t('visits:patient')}
                        value={queryTuple.data.patient.name}
                        toRoute={queryTuple.data.patient.route} />

                      <ValueWithLabel
                        label={t('visits:episode')}
                        value={t('episodeDisplayName', {
                          ranking: queryTuple.data.episode.ranking
                        })}
                        toRoute={queryTuple.data.episode.route} />

                    </Grid>
                  </Grid>

                  <Grid
                    item
                    md={6}>
                    <Grid container>

                      <ValueWithLabel
                        label={t('visits:visitTemplate')}
                        value={queryTuple.data.template.displayName}
                        toRoute={queryTuple.data.template.route} />

                      <ValueWithLabel
                        label={t('visits:clockInTime')}
                        value={queryTuple.data.clockInTimestamp} />

                      <ValueWithLabel
                        label={t('visits:clockOutTime')}
                        value={queryTuple.data.clockOutTimestamp} />

                      <ValueWithLabel
                        label={t('visits:completedTime')}
                        value={queryTuple.data.completedTimestamp} />

                      <ValueWithLabel
                        label={t('visits:missedTime')}
                        value={queryTuple.data.missedTimestamp} />

                      <ValueWithLabel
                        label={t('visits:missedVisitReason')}
                        value={queryTuple.data.missedVisitReason} />

                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={3}>
                <Grid container>

                  {!showEmployeeVisitInfo && (
                    <Grid
                      item
                      md={6}>

                      <Box mb={3}>
                        <Typography variant="h2">
                          {t('visits:tasks')}
                        </Typography>
                      </Box>

                      {queryTuple.data.tasks.length === 0 ? (
                        <Box>
                          {t('visits:noTasks')}
                        </Box>

                      ) : (
                        <ul>
                          {_.map(queryTuple.data.tasks, task => (
                            <li key={task.id}>
                              {task.title}

                              {task.description && (
                                <ul>
                                  <li>
                                    {task.description}
                                  </li>
                                </ul>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}

                    </Grid>
                  )}

                  <Grid
                    item
                    md={6}>

                    <VisitAssignedEmployeesSection
                      visitId={params.visitId ?? ''}
                      userType="administrator" />

                  </Grid>

                </Grid>
              </Box>
            </Box>

            {showEmployeeVisitInfo && (
              <>
                <Box mb={3}>
                  <VisitEmployeeVisitInfoSection
                    visitId={params.visitId ?? ''} />
                </Box>

                {showAfterVisitData && (
                  <Box mb={3}>
                    <VisitAfterVisitDataSection
                      afterVisitDataId={queryTuple.data.afterVisitDataId} />
                  </Box>
                )}
              </>
            )}
          </>
        )}

      </BaseActivityTemplate>
    </>
  );
};
