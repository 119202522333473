import React from 'react';

import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import {
  Typography,
  Box,
  IconButton
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';



const RowBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const IconButtonContainerBox = styled(Box)(({theme}) => ({
  marginLeft: theme.spacing(3)
}));

const LabelTypography = styled(Typography)(({theme}) => ({
  flex: `0 0 ${theme.spacing(12)}`
}));



type IHiddenStatus =
  'hideAll' |
  'hidePartial' |
  'show';

export interface ISsnValueProps {
  value: string;
}

export const SsnValue: React.FC<ISsnValueProps> = props => {
  const {t} = useTranslation();

  const [hiddenStatus, setHiddenStatus] = React.useState<IHiddenStatus>('hideAll');



  function handleClickVisibilityButton() {
    setHiddenStatus(previous => {
      if (previous === 'hideAll') {
        return 'hidePartial';
      } else if (previous === 'hidePartial') {
        return 'show';
      } else if (previous === 'show') {
        return 'hideAll';
      } else {
        return previous;
      }
    });
  }



  return (
    <RowBox>
      <LabelTypography variant="body1">
        {!props.value ? (
          <>
            {t('noValue')}
          </>

        ) : (
          <>
            {hiddenStatus === 'hideAll' &&
              "***-**-****"}

            {hiddenStatus === 'hidePartial' &&
              `***-**-${props.value.slice(-4)}`}

            {hiddenStatus === 'show' &&
              `${props.value.slice(0, 3)}-${props.value.slice(3, 5)}-${props.value.slice(5)}`}
          </>
        )}
      </LabelTypography>

      {props.value && (
        <IconButtonContainerBox>
          <IconButton
            size="small"
            onClick={handleClickVisibilityButton}>

            {(hiddenStatus === 'hideAll' || hiddenStatus === 'hidePartial') && (
              <Visibility />
            )}

            {hiddenStatus === 'show' && (
              <VisibilityOff />
            )}

          </IconButton>
        </IconButtonContainerBox>
      )}
    </RowBox>
  );
};
