import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  genTextFieldControllerComponent,
  genDateFieldControllerComponent,
  TextField,
  genEnumSelectFieldControllerComponent,
  DisciplineSelectField,
  IDisciplineName,
  HireStatusSelectField,
  IHireStatusName
} from 'modules';

import { Control } from 'react-hook-form';
import * as types from '../types';
import { useTranslation } from 'react-i18next';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();
const DateFieldController = genDateFieldControllerComponent<types.IFormState>();
const DisciplineSelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, IDisciplineName>(DisciplineSelectField);
const HireStatusSelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, IHireStatusName>(HireStatusSelectField);



export interface IGeneralInformationSectionProps {
  formControl: Control<types.IFormState>;
  email: string;
  isDisabled: boolean;
}

export const GeneralInformationSection: React.FC<IGeneralInformationSectionProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('adminEmployees:generalInformation')}
      </Typography>

      <Box mt={4}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey="firstName"
              label={t('firstName')}
              isRequired
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey="lastName"
              label={t('lastName')}
              isRequired
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <DateFieldController
              formControl={props.formControl}
              fieldKey="birthdate"
              label={t('adminEmployees:birthdate')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey="employeeId"
              label={t('adminEmployees:employeeId')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextField
              label={t('adminEmployees:email')}
              value={props.email}
              onChange={() => {}}
              isDisabled />
          </Grid>

          <Grid
            item
            md={3}>
            <DisciplineSelectFieldController
              formControl={props.formControl}
              fieldKey="discipline"
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <HireStatusSelectFieldController
              formControl={props.formControl}
              fieldKey="hireStatus"
              isRequired
              isDisabled={props.isDisabled} />
          </Grid>

        </Grid>
      </Box>

    </Box>
  );
};
