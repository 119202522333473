import React from 'react';

import {
  BaseActivityTemplate,
  errorUtil,
  Form,
  formUtil,
  paletteColors
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from './types';
import * as dataService from './dataService';

import { DetailsSection } from './DetailsSection/DetailsSection';
import { ContactDetailsSection } from './ContactDetailsSection/ContactDetailsSection';
import { IntakeSection } from './IntakeSection/IntakeSection';
import { PharmacySection } from './PharmacySection/PharmacySection';
import { EmergencyContactSection } from './EmergencyContactSection/EmergencyContactSection';
import { EditSsnModal } from './EditSsnModal/EditSsnModal';



interface IParams {
  patientId?: string;
}

export const EditPatientActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const form = useForm<types.IFormState>({
    defaultValues: {
      id: '',
      firstName: "",
      lastName: "",
      patientStatus: '',
      birthdate: null,
      gender: '',
      medicareNumber: "",
      medicaidNumber: "",
      emrNumber: "",
      address: "",
      locationNote: "",
      primaryPhone: "",
      secondaryPhone: "",
      socDate: null,
      referralDate: null,
      primaryProviderId: '',
      secondaryProviderId: '',
      referringProviderId: '',
      pharmacyId: '',
      ssn: "",
      note: "",
      emergencyName: "",
      emergencyRelationship: "",
      emergencyPhone: "",
      emergencyAddress: ""
    }
  });

  const [fnQuery, queryTuple] = dataService.useEditPatientActivityLazyQuery();
  const [fnSave, saveMutationTuple] = dataService.useSavePatientMutation();
  const [fnArchive, archiveMutationTuple] = dataService.useArchivePatientMutation();

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading ||
    archiveMutationTuple.loading;

  const [isEditSsnModalOpen, setIsEditSsnModalOpen] = React.useState(false);



  React.useEffect(
    () => {
      if (params.patientId && !queryTuple.called) {
        fnQuery(params.patientId);
      }
    },
    [
      params.patientId,
      queryTuple.called
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        formUtil.setFieldsFromQuery(queryTuple.data.formState, form.setValue, [
          'id',
          'firstName',
          'lastName',
          'patientStatus',
          'birthdate',
          'gender',
          'medicareNumber',
          'medicaidNumber',
          'emrNumber',
          'address',
          'locationNote',
          'primaryPhone',
          'secondaryPhone',
          'socDate',
          'referralDate',
          'primaryProviderId',
          'secondaryProviderId',
          'referringProviderId',
          'pharmacyId',
          'ssn',
          'note',
          'emergencyName',
          'emergencyRelationship',
          'emergencyPhone',
          'emergencyAddress'
        ]);
      }
    },
    [
      queryTuple.data,
      queryTuple.loading
    ]
  );



  function handleAcceptEditSsnModal(ssn: string) {
    form.setValue('ssn', ssn);
    setIsEditSsnModalOpen(false);
  }



  async function handleSubmit(formData: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnSave(queryTuple.data.showSsn, formData);
      navigate(-1);

    }, {
      CANNOT_EDIT_SSN: t('patients:errors.CANNOT_EDIT_SSN')
    });
  }



  async function handleConfirmDelete() {
    await errorUtil.errorWrap(async () => {
      await fnArchive(form.watch('id'));
      navigate('/admin/patients');

    }, {
      CANNOT_ARCHIVE_PATIENT_WITH_EPISODES: t('patients:errors.CANNOT_ARCHIVE_PATIENT_WITH_EPISODES')
    });
  }



  return (
    <>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <BaseActivityTemplate
          mode="admin"
          navigationBarSelectedLinkId="patients"
          isLoading={isLoading}
          requireOneOfPermissionCategories={[
            'fullAdmin',
            'supervisor'
          ]}
          headerOptions={{
            backgroundColor: paletteColors.patients,
            title: !params.patientId ?
              t('patients:newPatient') :
              `${queryTuple.data.formState.firstName} ${queryTuple.data.formState.lastName}`,
            hasBackButton: true
          }}
          footerOptions={{
            hasDeleteButton: !!params.patientId,
            deleteButtonLabel: t('patients:deletePatient'),
            onConfirmDelete: handleConfirmDelete,
            onClickCancel: () => navigate(-1)
          }}>

          <DetailsSection
            form={form}
            isDisabled={isLoading}
            showSsn={queryTuple.data.showSsn}
            onClickEditSsn={() => setIsEditSsnModalOpen(true)} />

          <ContactDetailsSection
            formControl={form.control}
            isDisabled={isLoading} />

          <IntakeSection
            formControl={form.control}
            isDisabled={isLoading} />

          <PharmacySection
            formControl={form.control}
            isDisabled={isLoading} />

          <EmergencyContactSection
            formControl={form.control}
            isDisabled={isLoading} />

        </BaseActivityTemplate>
      </Form>

      <EditSsnModal
        initSsn={form.watch('ssn')}
        isOpen={isEditSsnModalOpen}
        onAccept={handleAcceptEditSsnModal}
        onClose={() => setIsEditSsnModalOpen(false)} />
    </>
  );
};
