import React from 'react';

import { BaseNavigationBar } from '../shared/BaseNavigationBar/BaseNavigationBar';
import * as types from '../types';



export interface IEmployeeNavigationBarProps {
  selectedLinkId?: string;
  setIsLoading: (value: boolean) => void;
}

export const EmployeeNavigationBar: React.FC<IEmployeeNavigationBarProps> = props => {
  const leftLinks: types.ILink[] = [
    {
      id: 'myVisits',
      props: {
        iconUrl: '',
        selectedIconUrl: '',
        label: 'navigation.myVisits',
        toRoute: '/employee/myVisits',
        isSelected: false
      }
    },
    {
      id: 'ecTasks',
      props: {
        iconUrl: '',
        selectedIconUrl: '',
        label: 'navigation.ecTasks',
        toRoute: '/employee/requirements',
        isSelected: false
      }
    },
    {
      id: 'myPatients',
      props: {
        iconUrl: '',
        selectedIconUrl: '',
        label: 'navigation.myPatients',
        toRoute: '/employee/myPatients',
        isSelected: false
      }
    }
  ];



  return (
    <BaseNavigationBar
      leftLinks={leftLinks}
      selectedLinkId={props.selectedLinkId}
      setIsLoading={props.setIsLoading} />
  );
};
