import React from 'react';
import _ from 'lodash';

import {
  useEmployeeEditRequirementActivityQuery,
  useEmployeeSubmitAssignedRequirementMutation
} from 'generated/graphql';
import {
  enumCache,
  fields
} from 'modules';

import * as types from './types';



export interface IData {
  displayName: string;
  typeName: enumCache.IRequirementTypeName;
  statusName: enumCache.IRequirementStatusName;
  description: string;
}

export function useQuery(id: string) {
  const tuple = useEmployeeEditRequirementActivityQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlRequirement = tuple.data?.assignedRequirement;
      const data: IData = {
        displayName: gqlRequirement?.preset.displayName ?? "",
        typeName: gqlRequirement?.preset.type.name ?? 'requirement',
        statusName: gqlRequirement?.status.name ?? 'pending',
        description: gqlRequirement?.preset.description ?? ""
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useSubmit(id: string) {
  const [fnMutate, tuple] = useEmployeeSubmitAssignedRequirementMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id,
            files: _.map(formState.files, file =>
              fields.fileData2EncodedString(file.data))
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
