import React from 'react';

import {
  Controller,
  Control,
  FieldPath,
  FieldValues
} from 'react-hook-form';

import { validationUtil } from 'modules/layer0';
import { fields } from 'modules/layer2';



export interface IDateFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label: string;
  isDisabled?: boolean;
}

export function genDateFieldControllerComponent<TFormState extends FieldValues>() {
  const DateFieldController: React.FC<IDateFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        name={props.fieldKey}
        control={props.formControl}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined,
          validate: validationUtil.rules.validDate
        }}
        render={({field, fieldState}) => (
          <fields.DateField
            label={props.label}
            value={field.value}
            onChange={value => field.onChange(value)}
            errorMessage={(fieldState.error as any)?.message || undefined}
            isDisabled={props.isDisabled} />
        )} />
    );
  };

  return DateFieldController;
}
