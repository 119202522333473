import React from 'react';

import {
  BaseActivityTemplate,
  Form,
  paletteColors,
  genTextFieldControllerComponent,
  validationUtil,
  errorUtil,
  formUtil
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from './types';
import { Grid } from '@mui/material';
import * as dataService from './dataService';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();



interface IParams {
  pharmacyId?: string;
}

export const EditPharmacyActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const form = useForm<types.IFormState>({
    defaultValues: {
      id: '',
      name: "",
      primaryPhone: "",
      secondaryPhone: "",
      faxNumber: "",
      address: ""
    }
  });

  const [fnQuery, queryTuple] = dataService.useLazyQuery();
  const [fnSave, saveMutationTuple] = dataService.useSaveMutation();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveMutation();

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading ||
    archiveMutationTuple.loading;



  React.useEffect(
    () => {
      if (params.pharmacyId && !queryTuple.called) {
        fnQuery(params.pharmacyId);
      }
    },
    [
      params.pharmacyId,
      queryTuple.called
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        formUtil.setFieldsFromQuery(queryTuple.data.formState, form.setValue, [
          'id',
          'name',
          'primaryPhone',
          'secondaryPhone',
          'faxNumber',
          'address'
        ]);
      }
    },
    [
      queryTuple.data,
      queryTuple.loading
    ]
  );



  async function handleSubmit(formData: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnSave(formData);
      navigate(-1);
    });
  }



  async function handleConfirmDelete() {
    await errorUtil.errorWrap(async () => {
      await fnArchive(form.watch('id'));
      navigate('/admin/pharmacies');
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.pharmacies,
          title: !params.pharmacyId ?
            t('pharmacies:newPharmacy') :
            queryTuple.data.name,
          hasBackButton: true
        }}
        footerOptions={{
          hasDeleteButton: !!params.pharmacyId,
          deleteButtonLabel: t('pharmacies:deletePharmacy'),
          onConfirmDelete: handleConfirmDelete,
          onClickCancel: () => navigate(-1)
        }}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="name"
              label={t('name')}
              isRequired
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={9} />

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="primaryPhone"
              label={t('primaryPhone')}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="secondaryPhone"
              label={t('secondaryPhone')}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="faxNumber"
              label={t('pharmacies:faxNumber')}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={3} />

          <Grid
            item
            md={6}>
            <TextFieldController
              formControl={form.control}
              fieldKey="address"
              label={t('address')}
              isDisabled={isLoading} />
          </Grid>

        </Grid>

      </BaseActivityTemplate>
    </Form>
  );
};
