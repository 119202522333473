import React from 'react';
import _ from 'lodash';

import { ValueWithLabel } from 'modules';
import * as types from '../types';
import { useTranslation } from 'react-i18next';



export interface IMedicationListProps {
  medications: types.IMedication[];
}

export const MedicationList: React.FC<IMedicationListProps> = props => {
  const {t} = useTranslation();



  return (
    <ValueWithLabel
      label={t('adminEpisodes:medications.medications')}>
      {props.medications.length === 0 ? (
        <div>
          {t('noValue')}
        </div>

      ) : (
        <ul>
          {_.map(props.medications, medication => (
            <li key={medication.id}>
              {medication.name}
              <ul>
                <li>
                  <strong>{t('adminEpisodes:medications.dosage')}:</strong> {medication.dosage}
                </li>

                <li>
                  <strong>{t('adminEpisodes:medications.instructions')}:</strong> {medication.instructions}
                </li>
              </ul>
            </li>
          ))}
        </ul>
      )}
    </ValueWithLabel>
  );
};
