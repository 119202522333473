import React from 'react';
import _ from 'lodash';

import {
  useVisitAfterVisitDataQuery,
  AfterVisitDataValueFieldsFragment
} from 'generated/graphql';

import { DataValueList } from 'modules/layer4';



export interface IData {
  medications: DataValueList.IDataValue[];
  allMedsTaken: DataValueList.IDataValue[];
  justificationOfServices: DataValueList.IDataValue[];
}

export function useQuery(afterVisitDataId: string) {
  const tuple = useVisitAfterVisitDataQuery({
    variables: {
      afterVisitDataId
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlData = tuple.data?.afterVisitData;
      const data: IData = {
        medications: __gqlDataValues2Client(gqlData?.medications ?? []),
        allMedsTaken: __gqlDataValues2Client(_.compact([gqlData?.allMedsTaken])),
        justificationOfServices: __gqlDataValues2Client(gqlData?.justificationOfServices ?? [])
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



function __gqlDataValue2Client(gqlDataValue: AfterVisitDataValueFieldsFragment): DataValueList.IDataValue {
  return {
    value: gqlDataValue.value,
    description: gqlDataValue.description ?? undefined
  };
}



function __gqlDataValues2Client(gqlDataValues: AfterVisitDataValueFieldsFragment[]) {
  return _.map(gqlDataValues, gqlValue => __gqlDataValue2Client(gqlValue));
}
