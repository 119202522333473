import React from 'react';
import moment from 'moment';

import { dateUtil } from 'modules';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';



const RowBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
}));

const LabelBox = styled(Box)(({theme}) => ({
  backgroundColor: '#F4F9FD',
  borderRadius: '66px',
  marginRight: theme.spacing(2),
  paddingTop: '2px',
  paddingRight: '8px',
  paddingBottom: '2px',
  paddingLeft: '8px'
}));

const LineBox = styled(Box)(() => ({
  flex: '1 0',
  borderBottom: '1px solid #262A38'
}));



export interface IDateLabelProps {
  date: moment.Moment;
}

export const DateLabel: React.FC<IDateLabelProps> = props => {
  return (
    <RowBox>
      <LabelBox>
        {props.date.format(dateUtil.displayDateFormat)}
      </LabelBox>

      <LineBox />
    </RowBox>
  );
};
