import React from 'react';

import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';



export type IUserStatus =
  'inviteRequired' |
  'invitePending' |
  'inviteExpired' |
  'active' |
  'disabled';

interface IStatusData {
  color: string;
  contrastColor: string;
}

const __status2Data: Record<IUserStatus, IStatusData> = {
  inviteRequired: {
    color: '#F9B100',
    contrastColor: '#fff'
  },
  invitePending: {
    color: '#317FF4',
    contrastColor: '#fff'
  },
  inviteExpired: {
    color: '#E74C3C',
    contrastColor: '#fff'
  },
  active: {
    color: '#2ECC71',
    contrastColor: '#fff'
  },
  disabled: {
    color: '#374253',
    contrastColor: '#fff'
  }
};

export interface IUserStatusChipProps {
  status: IUserStatus;
}

export const UserStatusChip: React.FC<IUserStatusChipProps> = props => {
  const {t} = useTranslation();



  const data = React.useMemo(
    () => __status2Data[props.status],
    [props.status]
  );



  return (
    <Chip
      label={t(`enums:userStatus.${props.status}`)}
      style={{
        backgroundColor: data.color,
        color: data.contrastColor
      }}
      size="small" />
  );
};
