import i18next from 'i18next';
import { genEnumSelectFieldComponent } from 'modules/layer2';
import * as dataService from './dataService';

export type IHireStatusName = dataService.IHireStatusName;
export type IHireStatus = dataService.IHireStatus;



export const HireStatusSelectField = genEnumSelectFieldComponent<IHireStatusName, IHireStatus>(dataService.useHireStatusSelectFieldQuery, () => i18next.t('hireStatus'));
