import React from 'react';

import { AppBarWrapper } from './AppBarWrapper/AppBarWrapper';
import { AdminNavigationBar } from './AdminNavigationBar/AdminNavigationBar';
import { LanguageBar } from './LanguageBar/LanguageBar';
import { TestModeWarningBar } from './TestModeWarningBar/TestModeWarningBar';
import { EmployeeNavigationBar } from './EmployeeNavigationBar/EmployeeNavigationBar';
import * as types from './types';

export type { IMode } from './types';



export interface IAppBarProps {
  mode: types.IMode;
  navigationBarSelectedLinkId?: string;
  setIsLoading: (value: boolean) => void;
}

export const AppBar: React.FC<IAppBarProps> = props => {
  return (
    <AppBarWrapper>

      {process.env.REACT_APP_ENV_NAME !== 'prod' && (
        <TestModeWarningBar />
      )}

      {props.mode === 'admin' && (
        <AdminNavigationBar
          selectedLinkId={props.navigationBarSelectedLinkId}
          setIsLoading={props.setIsLoading} />
      )}

      {props.mode === 'employee' && (
        <EmployeeNavigationBar
          selectedLinkId={props.navigationBarSelectedLinkId}
          setIsLoading={props.setIsLoading} />
      )}

      {props.mode === 'auth' && (
        <LanguageBar />
      )}

    </AppBarWrapper>
  );
};
