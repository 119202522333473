import React from 'react';

import {
  AuthCard,
  AuthTitle,
  Form,
  LoadingOverlay,
  ResponsiveImage,
  assetUtil,
  fields,
  authService,
  apolloService
} from 'modules';
import {
  Box,
  Button
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import * as dataService from './dataService';
import * as types from '../types';



const ForgotPasswordContainerBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
}));



interface IFormData {
  email: string;
  password: string;
}

const TextFieldController = fields.genTextFieldControllerComponent<IFormData>();



export interface IProps {
  onChangeState: (nextState: types.IState, totpStatus?: authService.ITotpStatus) => void;
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();

  const [forgotPasswordIsLoading, setForgotPasswordIsLoading] = React.useState(false);

  const [fnLogIn, logInTuple] = dataService.useLogIn();

  const form = useForm<IFormData>({
    defaultValues: {
      email: "",
      password: ""
    }
  });

  const isLoading =
    logInTuple.isLoading ||
    forgotPasswordIsLoading;



  async function handleClickForgotYourPassword() {
    const formData = form.getValues();
    const trEmail = formData.email;
    if (!trEmail || !trEmail.match(/.+@.+/)) {
      alert(t('auth:errors.emailRequired'));
      return;
    }

    try {
      setForgotPasswordIsLoading(true);
      await authService.forgotPassword(trEmail);
      props.onChangeState('changePassword');

    } catch (err: any) {
      alert(err?.message ?? t('errors.unknown'));

    } finally {
      setForgotPasswordIsLoading(false);
    }
  }



  async function handleSubmit(formData: IFormData) {
    const trEmail = formData.email.trim();
    try {
      (await apolloService.getClient())?.clearStore();
      const totpStatus = await fnLogIn(trEmail, formData.password);
      props.onChangeState('confirmLoginCode', totpStatus);

    } catch (err: any) {
      if (err.code === 'PasswordResetRequiredException') {
        await authService.forgotPassword(trEmail);
        props.onChangeState('changePassword');

      } else {
        console.error(err);
        alert(err?.message ?? t('errors.unknown'));
      }
    }
  }



  return (
    <>
      <LoadingOverlay
        active={isLoading} />

      <AuthCard.Comp>
        <Form onSubmit={form.handleSubmit(handleSubmit)}>

          <Box mb={1}>
            <AuthTitle.Comp>
              {t('auth:signIn')}
            </AuthTitle.Comp>
          </Box>

          <Box mb={4}>
            <ResponsiveImage urlSet={assetUtil.imgsLogo} />
          </Box>

          <Box mb={2}>
            <TextFieldController
              label={t('auth:email')}
              type="email"
              formControl={form.control}
              fieldKey="email"
              isRequired />
          </Box>

          <Box mb={2}>
            <TextFieldController
              label={t('auth:password')}
              type="password"
              formControl={form.control}
              fieldKey="password"
              isRequired />
          </Box>

          <ForgotPasswordContainerBox>
            <Button
              color="primary"
              variant="text"
              onClick={handleClickForgotYourPassword}>
              {t('auth:forgotYourPassword')}
            </Button>
          </ForgotPasswordContainerBox>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth>
            {t('auth:logIn')}
          </Button>

        </Form>
      </AuthCard.Comp>
    </>
  );
};
