import React from 'react';

import {
  validationUtil,
  authService,
  errorUtil,
  LoadingOverlay
} from '../../layer0';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseModal } from '../../layer1';
import { fields } from '../../layer2';
import { Form } from '../../layer3';



interface IFormState {
  phoneNumber: string;
}

const TextFieldController = fields.genTextFieldControllerComponent<IFormState>();



export interface ILoginCodePhoneNumberModalProps {
  onAccept: () => void;
  onClose: () => void;
}

export const LoginCodePhoneNumberModal: React.FC<ILoginCodePhoneNumberModalProps> = props => {
  const {t} = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);

  const form = useForm<IFormState>({
    defaultValues: {
      phoneNumber: ""
    }
  });



  async function handleSubmit(formState: IFormState) {
    setIsLoading(true);
    await errorUtil.errorWrap(async () => {
      await authService.updatePhoneNumber(formState.phoneNumber.trim());
      props.onAccept();
    });
    setIsLoading(false);
  }



  return (
    <BaseModal
      isOpen
      title={t('changeLoginCodePhoneNumber')}
      showButtons
      onClose={props.onClose}>

      {buttons => (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>

          <LoadingOverlay
            active={isLoading} />

          <TextFieldController
            formControl={form.control}
            fieldKey="phoneNumber"
            label={t('phoneNumber')}
            extraValidationRules={{
              pattern: validationUtil.rules.phoneNumber
            }}
            isRequired
            isDisabled={isLoading} />

          {buttons}

        </Form>
      )}

    </BaseModal>
  );
};
