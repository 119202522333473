import React from 'react';
import _ from 'lodash';

import {
  useDiagnosisSelectFieldLazyQuery
} from 'generated/graphql';



export interface IDiagnosis {
  id: string;
  displayName: string;
  icd10Code: string;
}

export interface IData {
  diagnoses: IDiagnosis[];
  diagnosesById: Record<string, IDiagnosis>;
}

const __debounceIntervalMs = 1000;

export function useLazyQuery() {
  const [fnQuery, tuple] = useDiagnosisSelectFieldLazyQuery({fetchPolicy: 'no-cache'});
  const [isLoading, setIsLoading] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState<number | null>(null);



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  const fnWrappedQuery = React.useCallback(
    (terms: string) => {
      setIsLoading(true);
      if (timeoutId !== null) {
        window.clearTimeout(timeoutId);
        setTimeoutId(null);
      }

      setTimeoutId(window.setTimeout(
        () => {
          fnQuery({
            variables: {
              terms
            }
          });
          setIsLoading(false);
        },
        __debounceIntervalMs
      ));
    },
    [
      fnQuery,
      timeoutId
    ]
  );



  const wrappedTuple = React.useMemo(
    () => {
      const gqlDiagnoses = tuple.data?.searchDiagnoses ?? [];

      const diagnoses = _.map(gqlDiagnoses, (gqlDiagnosis): IDiagnosis => ({
        id: gqlDiagnosis.id,
        displayName: gqlDiagnosis.displayName,
        icd10Code: gqlDiagnosis.icd10Code
      }));

      const sortedDiagnoses: IDiagnosis[] = [
        {
          id: '',
          displayName: "",
          icd10Code: ""
        },
        ..._.sortBy(diagnoses, diagnosis =>
          diagnosis.displayName.toLowerCase())
      ];

      const finalIsLoading = tuple.loading || isLoading;

      return {
        ...tuple,
        loading: finalIsLoading,
        data: {
          diagnoses: !finalIsLoading ? sortedDiagnoses : [],
          diagnosesById: _.keyBy(diagnoses, diagnosis => diagnosis.id)
        } as IData
      };
    },
    [
      tuple,
      isLoading
    ]
  );



  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}
