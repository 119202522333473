import React from 'react';
import _ from 'lodash';

import {
  useEditVisitTemplateActivityNewTemplateLazyQuery as useBaseCreateLazyQuery,
  useEditVisitTemplateActivityLazyQuery as useBaseEditLazyQuery,
  useSaveVisitTemplateMutation as useBaseSaveVisitTemplateMutation,
  useArchiveVisitTemplateMutation as useBaseArchiveVisitTemplateMutation,
  VisitTemplateTaskInput
} from 'generated/graphql';

import * as types from './types';



export interface IId {
  type: 'parentEpisode' | 'visitTemplate';
  id: string;
}



export interface ICreateData {
  formState: types.IFormState;
}

function __useCreateLazyQuery() {
  const [fnQuery, tuple] = useBaseCreateLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const fnWrappedQuery = React.useCallback(
    (episodeId: string) => {
      fnQuery({
        variables: {
          episodeId
        }
      });
    },
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const data: ICreateData = {
        formState: {
          displayName: "",
          visitCategoryName: 'form485',
          shiftName: 'am',
          frequencyAmount: "1",
          frequencyUnitName: 'month',
          note: "",
          location: tuple.data?.episode?.patient.address ?? "",
          tasks: []
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



interface IEditData {
  canEdit: boolean;
  episodeId: string;
  formState: types.IFormState;
}

function __useEditLazyQuery() {
  const [fnQuery, tuple] = useBaseEditLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const fnWrappedQuery = React.useCallback(
    (id: string) => {
      fnQuery({
        variables: {
          id
        }
      });
    },
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const gqlTemplate = tuple.data?.visitTemplate;
      const data: IEditData = {
        canEdit: gqlTemplate?.episode.status.name === 'pending',
        episodeId: gqlTemplate?.episode.id ?? '',
        formState: {
          displayName: gqlTemplate?.displayName ?? "",
          visitCategoryName: gqlTemplate?.visitCategory.name ?? 'form485',
          shiftName: gqlTemplate?.shift.name ?? 'am',
          frequencyAmount: (gqlTemplate?.frequencyAmount ?? 1).toString(),
          frequencyUnitName: gqlTemplate?.frequencyUnit.name ?? 'month',
          note: gqlTemplate?.note ?? "",
          location: gqlTemplate?.location ?? "",
          tasks: _.map(gqlTemplate?.tasks, (gqlTask): types.ITask => ({
            _key: gqlTask.id,
            id: gqlTask.id,
            title: gqlTask.title,
            description: gqlTask.description
          }))
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



export type IQueryType =
  'create' |
  'edit';

export interface IData {
  type: IQueryType;
  canEdit: boolean;
  episodeId: string;
  formState: types.IFormState;
}

export function useLazyQuery() {
  const [fnCreateQuery, createQueryTuple] = __useCreateLazyQuery();
  const [fnEditQuery, editQueryTuple] = __useEditLazyQuery();

  const [id, setId] = React.useState<IId>({
    type: 'parentEpisode',
    id: ''
  });

  const fnWrappedQuery = React.useCallback(
    (id: IId) => {
      setId(id);
      if (id.type === 'parentEpisode') {
        fnCreateQuery(id.id);
      } else if (id.type === 'visitTemplate') {
        fnEditQuery(id.id);
      }
    },
    [
      fnCreateQuery,
      fnEditQuery
    ]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const data: IData = {
        type: id.type === 'parentEpisode' ? 'create' : 'edit',
        canEdit: id.type === 'visitTemplate' ?
          editQueryTuple.data.canEdit :
          true,
        episodeId: id.type === 'visitTemplate' ?
          editQueryTuple.data.episodeId :
          id.id,
        formState: id.type === 'visitTemplate' ?
          editQueryTuple.data.formState :
          createQueryTuple.data.formState
      };

      return {
        ...(id.type === 'visitTemplate' ?
          editQueryTuple :
          createQueryTuple),
        data
      };
    },
    [
      id,
      createQueryTuple,
      editQueryTuple
    ]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



export function useSaveVisitTemplateMutation() {
  const [fnMutate, tuple] = useBaseSaveVisitTemplateMutation();

  const fnWrappedMutate = React.useCallback(
    async (id: IId, formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            episodeId: id.type === 'parentEpisode' ? id.id : undefined,
            id: id.type === 'visitTemplate' ? id.id : undefined,
            displayName: formState.displayName.trim(),
            visitCategoryName: formState.visitCategoryName,
            shiftName: formState.shiftName,
            frequencyAmount: +(formState.frequencyAmount.trim()) || 0,
            frequencyUnitName: formState.frequencyUnitName,
            note: formState.note.trim(),
            location: formState.location.trim(),
            tasks: _.map(formState.tasks, (task): VisitTemplateTaskInput => ({
              id: !task.id.startsWith('*') ? task.id : undefined,
              title: task.title,
              description: task.description
            }))
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useArchiveVisitTemplateMutation() {
  const [fnMutate, tuple] = useBaseArchiveVisitTemplateMutation();

  const fnWrappedMutate = React.useCallback(
    async (id: string) => {
      await fnMutate({
        variables: {
          id
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
