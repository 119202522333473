import React from 'react';
import _ from 'lodash';

import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';



export interface IRadioControllerOption {
  id: string;
  label: string;
}

export interface IRadioControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isDisabled?: boolean;
  label?: string;
  options: IRadioControllerOption[];
}

export function genRadioControllerComponent<TFormState extends FieldValues>() {
  const RadioController: React.FC<IRadioControllerProps<TFormState>> = props => {
    return (
      <Controller
        control={props.formControl}
        name={props.fieldKey}
        render={({field}) => (
          <>
            {props.label && (
              <FormLabel>
                {props.label}
              </FormLabel>
            )}

            <RadioGroup
              {...field}
              value={field.value ?? ''}>
              {_.map(props.options, option => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  label={option.label}
                  disabled={props.isDisabled}
                  control={(
                    <Radio />
                  )} />
              ))}
            </RadioGroup>
          </>
        )} />
    );
  };

  return RadioController;
}
