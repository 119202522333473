import _ from 'lodash';

import {
  IDisciplineName,
  IHireStatusName
} from 'modules';

import { TFunction } from 'react-i18next';



export const daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
] as const;
export type IDayOfWeek = typeof daysOfWeek[number];

function __genDayOfWeek2DisplayName(t: TFunction): Record<IDayOfWeek, string> {
  return {
    sunday: t('sunday'),
    monday: t('monday'),
    tuesday: t('tuesday'),
    wednesday: t('wednesday'),
    thursday: t('thursday'),
    friday: t('friday'),
    saturday: t('saturday')
  };
}

export let dayOfWeek2DisplayName: Record<IDayOfWeek, string> = {
  sunday: "",
  monday: "",
  tuesday: "",
  wednesday: "",
  thursday: "",
  friday: "",
  saturday: ""
};



export interface IWorkingHoursEntry {
  isEnabled: boolean;
  startTime: Date | null;
  endTime: Date | null;
  tillEndOfDay: boolean;
}

export interface ISkill {
  _key: string;
  id: string;
  name: string;
  description: string;
}

export interface IFormState {
  id: string;
  firstName: string;
  lastName: string;
  birthdate: Date | null;
  employeeId: string;
  discipline: IDisciplineName | '';
  hireStatus: IHireStatusName | '';
  address: string;
  primaryPhone: string;
  secondaryPhone: string;
  supervisorUserId: string;
  altSupervisorUserId: string;
  payRate: string;
  licenseCheck: string;
  fraudCheck: string;
  dayOfWeek2WorkingHoursEntry: Record<IDayOfWeek, IWorkingHoursEntry>;
  skills: ISkill[];
}



export function genEmptyDayOfWeek2WorkingHoursEntry() {
  return _.zipObject(
    daysOfWeek,
    _.times(daysOfWeek.length, (): IWorkingHoursEntry => ({
      isEnabled: false,
      startTime: null,
      endTime: null,
      tillEndOfDay: false
    }))
  ) as Record<IDayOfWeek, IWorkingHoursEntry>;
}



export function init(t: TFunction) {
  dayOfWeek2DisplayName = __genDayOfWeek2DisplayName(t);
}
