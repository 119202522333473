import React from 'react';
import _ from 'lodash';

import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  BaseActivityTemplate,
  Form,
  fields,
  paletteColors,
  formUtil,
  errorUtil,
  selectFields,
  genEnumSelectFieldControllerComponent,
  enumCache,
  FileInputField,
  IFileData,
  validationUtil
} from 'modules';
import {
  Button,
  Grid,
  Typography,
  Box
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from './types';
import * as dataService from './dataService';
import * as QuizPresetSection from './QuizPresetSection/QuizPresetSection';



const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();
const RequirementTypeSelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, enumCache.IRequirementTypeName>(selectFields.RequirementTypeSelectField);



interface IParams {
  presetId?: string;
}

export const EditRequirementPresetActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const form = useForm<types.IFormState>({
    defaultValues: {
      id: '',
      requirementTypeName: 'requirement',
      displayName: "",
      description: "",
      fileData: null,
      url: "",
      quizPreset: {
        questions: []
      }
    }
  });

  const requirementTypeName = form.watch('requirementTypeName');

  const [fnQuery, queryTuple] = dataService.useLazyQuery();
  const [fnSave, saveMutationTuple] = dataService.useSaveMutation(params.presetId);
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveMutation(params.presetId);

  const fileData = form.watch('fileData');
  const currentFileName = fileData?.name ?? queryTuple.data.fileName;

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading ||
    archiveMutationTuple.loading;



  React.useEffect(
    () => {
      if (params.presetId && !queryTuple.called) {
        fnQuery(params.presetId);
      }
    },
    [
      params.presetId,
      queryTuple.called
    ]
  );



  React.useEffect(
    () => {
      if (queryTuple.called && !queryTuple.loading) {
        formUtil.setFieldsFromQuery(queryTuple.data.formState, form.setValue, [
          'id',
          'requirementTypeName',
          'displayName',
          'description',
          'url',
          'quizPreset'
        ]);
      }
    },
    [
      form,
      queryTuple.called,
      queryTuple.loading,
      queryTuple.data.formState
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading && queryTuple.data.isLocked) {
        navigate(-1);
      }
    },
    [
      queryTuple.loading,
      queryTuple.data.isLocked
    ]
  );



  function handleSelectFile(fileData: IFileData) {
    form.setValue('fileData', fileData);
  }



  async function handleConfirmDelete() {
    await errorUtil.errorWrap(async () => {
      await fnArchive();
      navigate('/admin/requirements');
    });
  }



  async function handleSubmit(formState: types.IFormState) {
    if (formState.requirementTypeName === 'quiz') {
      if (formState.quizPreset.questions.length === 0) {
        alert(t('requirements:quiz.errors.emptyQuestionSet'));
        return;
      }

      let hasError = false;
      _.forEach(formState.quizPreset.questions, question => {
        if (question.answerOptions.length < 2) {
          alert(t('requirements:quiz.errors.notEnoughAnswers'));
          hasError = true;
          return false;
        }

        const correctAnswers = _.filter(question.answerOptions, option => option.isCorrect);
        if (correctAnswers.length !== 1) {
          alert(t('requirements:quiz.errors.mustHaveExactlyOneCorrectAnswer'));
          hasError = true;
          return false;
        }
      });
      if (hasError) {
        return;
      }
    }

    await errorUtil.errorWrap(async () => {
      await fnSave(formState);
      navigate(-1);
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.requirements,
          title: !params.presetId ?
            t('requirements:newRequirementPreset') :
            queryTuple.data.formState.displayName,
          hasBackButton: true
        }}
        footerOptions={{
          hasDeleteButton: !!params.presetId && !queryTuple.data.isLocked,
          onConfirmDelete: handleConfirmDelete,
          onClickCancel: () => navigate(-1)
        }}>

        <Box mb={3}>
          <Grid container>

            <Grid
              item
              md={3}>
              <RequirementTypeSelectFieldController
                formControl={form.control}
                fieldKey="requirementTypeName"
                isRequired
                isDisabled={!!params.presetId || isLoading} />
            </Grid>

            <Grid
              item
              md={3}>
              <TextFieldController
                formControl={form.control}
                fieldKey="displayName"
                label={t('requirements:name')}
                isRequired
                isDisabled={isLoading} />
            </Grid>

            <Grid
              item
              md={3}>
              <TextFieldController
                formControl={form.control}
                fieldKey="description"
                label={t('requirements:description')}
                isRequired
                isDisabled={isLoading}
                numLines={5} />
            </Grid>

            <Grid
              item
              md={3} />

            <Grid
              item
              md={3}>
              {currentFileName && (
                <Box mb={3}>
                  <Typography variant="body1">
                    <strong>{t('requirements:attachedFile')}: </strong>
                    {currentFileName}
                  </Typography>
                </Box>
              )}

              <FileInputField
                onSelectFile={handleSelectFile}>
                {handleClick => (
                  <Button
                    variant="contained"
                    onClick={handleClick}>
                    {t('uploadFile')}
                  </Button>
                )}
              </FileInputField>
            </Grid>

            <Grid
              item
              md={6}>
              <TextFieldController
                formControl={form.control}
                fieldKey="url"
                label={t('requirements:url')}
                extraValidationRules={{
                  pattern: validationUtil.rules.url
                }}
                isDisabled={isLoading} />
            </Grid>

          </Grid>
        </Box>

        {requirementTypeName === 'quiz' && (
          <QuizPresetSection.Comp
            form={form} />
        )}

      </BaseActivityTemplate>
    </Form>
  );
};
