import React from 'react';

import {
  enumCache,
  fields,
  genEnumSelectFieldComponent
} from 'modules/layer2';

import i18next from 'i18next';



function useQuery(): fields.IUseGetEnumValuesTuple<enumCache.IRequirementRecurrenceTypeName, enumCache.IRequirementRecurrenceType> {
  const [values, setValues] = React.useState<enumCache.IRequirementRecurrenceType[]>([]);

  React.useEffect(
    () => {
      const sub = enumCache.subjAllEnums.subscribe(data =>
        setValues(data.requirementRecurrenceTypes));
      return () => sub.unsubscribe();
    },
    []
  );

  return {
    loading: values.length === 0,
    data: {
      enumValues: values
    }
  };
}



export const RequirementRecurrenceTypeSelectField = genEnumSelectFieldComponent<enumCache.IRequirementRecurrenceTypeName, enumCache.IRequirementRecurrenceType>(
  useQuery,
  () => i18next.t('requirements:requirementRecurrenceType')
);
