import React from 'react';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';

import {
  IUserSelectFieldProps,
  validationUtil,
  UserSelectField
} from 'modules';



export interface IUserSelectFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label: string;
  typeName?: IUserSelectFieldProps['typeName'];
  isDisabled?: boolean;
  removeUserIds?: string[];
}

export function genUserSelectFieldControllerComponent<TFormState extends FieldValues>() {
  const UserSelectFieldController: React.FC<IUserSelectFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        name={props.fieldKey}
        control={props.formControl}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <UserSelectField
            typeName={props.typeName}
            label={props.label}
            value={field.value}
            onChange={field.onChange}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled}
            removeUserIds={props.removeUserIds} />
        )} />
    );
  };

  return UserSelectFieldController;
}
