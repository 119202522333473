import React from 'react';

import {
  useAdminEditMyProfileActivityQuery,
  useAdministratorSaveMyProfileMutation
} from 'generated/graphql';

import * as types from './types';



export interface IData {
  administratorId: string;
  initProfilePictureFileKey: string;
  formState: types.IFormState;
}

export function useQuery() {
  const tuple = useAdminEditMyProfileActivityQuery({fetchPolicy: 'no-cache'});



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlUser = tuple.data?.loggedInUser;
      const gqlAdmin = gqlUser?.typeSpecificUser.__typename === 'Administrator' ?
        gqlUser.typeSpecificUser :
        null;

      const data: IData = {
        administratorId: gqlAdmin?.id ?? '',
        initProfilePictureFileKey: gqlAdmin?.profilePictureFile?.key ?? '',
        formState: {
          profilePicture: undefined
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useSave() {
  const [fnMutate, tuple] = useAdministratorSaveMyProfileMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            profilePictureFile: formState.profilePicture ?
              `${formState.profilePicture.name}/${formState.profilePicture.dataUrl}` :
              formState.profilePicture
          }
        }
      });
    },
    [fnMutate]
  );

  return [
    fnWrappedMutate,
    tuple
  ] as const;
}
