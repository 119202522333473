import React from 'react';
import _ from 'lodash';

import {
  Box,
  Button
} from '@mui/material';
import {
  FileInputField,
  IFileData
} from 'modules/layer2';

import { Avatar } from 'modules/layer0';
import { ProtectedImage } from 'modules/layer2';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';



const ProfilePictureCellDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const ImageContainerDiv = styled('div')(() => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));



export interface IProps {
  /**
   * Key to the file on the API. Used to show the existing saved image
   * before the user enters data into the form.
   */
  apiFileKey: string;

  /**
   * `undefined` means the user hasn't entered a value into the form yet.
   * `null` means the user clicked delete.
   */
  formFile: IFileData | null | undefined;

  onChange: (formFile: IFileData | null) => void;
}

export const FC: React.FC<IProps> = props => {
  const {t} = useTranslation();



  return (
    <ProfilePictureCellDiv>

      <FileInputField
        acceptTypes={[
          'image/png',
          'image/jpeg'
        ]}
        onSelectFile={props.onChange}>

        {handleClick => (
          <ImageContainerDiv onClick={handleClick}>
            {(() => {
              if (props.formFile?.dataUrl) {
                return (
                  <Avatar.FC
                    src={props.formFile.dataUrl} />
                );

              } else if (_.isUndefined(props.formFile) && props.apiFileKey) {
                return (
                  <ProtectedImage
                    fileKey={props.apiFileKey}
                    asAvatar
                    disableLightBox />
                );

              } else {
                return (
                  <Avatar.FC />
                );
              }
            })()}

            {t('clickToEdit')}
          </ImageContainerDiv>
        )}

      </FileInputField>

      {props.formFile !== null && (
        <Box ml={3}>
          <Button
            onClick={() => props.onChange(null)}>
            {t('delete')}
          </Button>
        </Box>
      )}

    </ProfilePictureCellDiv>
  );
};
