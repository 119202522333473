import React from 'react';
import _ from 'lodash';

import {
  AccountCircle,
  MoreHoriz,
  ChatBubble
} from '@mui/icons-material';
import {
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover
} from '@mui/material';
import {
  ResponsiveImage,
  SwitchLanguageButton,
  messengerService
} from 'modules/layer2';

import { assetUtil } from 'modules/layer0';
import { ProtectedImage } from 'modules/layer2';

import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import * as types from '../../types';

import { LinkButton } from './LinkButton/LinkButton';

export { LinkButton } from './LinkButton/LinkButton';



const NavigationToolbarBox = styled(Box)(({theme}) => ({
  backgroundColor: '#fff',
  height: theme.spacing(8),
  paddingLeft: theme.spacing(9),
  paddingRight: theme.spacing(9),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch'
}));

const SectionBox = styled(Box)(() => ({
  display: 'flex'
}));

const LogoContainerBox = styled(Box)(({theme}) => ({
  marginRight: theme.spacing(4),
  alignSelf: 'center'
}));

const DropdownMenuContainerBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));



export interface IBaseNavigationBarProps {
  leftLinks: types.ILink[];
  dropdownMenuLinks?: types.IDropdownMenuLink[];
  selectedLinkId?: string;
  setIsLoading: (value: boolean) => void;
}

export const BaseNavigationBar: React.FC<IBaseNavigationBarProps> = props => {
  const navigate = useNavigate();
  const {
    t,
    i18n
  } = useTranslation();

  const queryTuple = dataService.useBaseNavigationBarQuery();

  const refMoreButton = React.useRef<HTMLButtonElement | null>(null);

  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = React.useState(false);
  const [numUnreadMessages, setNumUnreadMessages] = React.useState(0);



  React.useEffect(
    () => {
      const sub = messengerService.getObservable()
        .subscribe(data => {
          setNumUnreadMessages(
            _.sum(
              _.map(
                _.values(data.threadsById),
                thread => thread.numUnreadMessages)));
        });

      return () => sub.unsubscribe();
    },
    [setNumUnreadMessages]
  );



  const rightLinks = React.useMemo(
    (): types.ILink[] => [
      {
        id: 'messenger',
        props: {
          elIcon: (
            <Badge
              badgeContent={numUnreadMessages}
              color="error"
              max={9}>
              <ChatBubble
                color={numUnreadMessages > 0 ?
                  'primary' :
                  'disabled'} />
            </Badge>
          ),
          toRoute: queryTuple.data.typeName === 'administrator' ?
            '/admin/messenger' :
            '/employee/messenger',
          isSelected: false
        }
      },
      {
        id: 'myProfile',
        props: {
          elIcon: queryTuple.data.profilePictureFileKey ? (
            <ProtectedImage
              fileKey={queryTuple.data.profilePictureFileKey}
              asAvatar
              size="24px"
              disableLightBox
              disableSpinner />

          ) : (
            <AccountCircle />
          ),
          label: queryTuple.data.fullName,
          toRoute: queryTuple.data.typeName === 'administrator' ?
            '/admin/myProfile' :
            '/employee/myProfile',
          hasSolidLabel: true,
          isSelected: false
        }
      }
    ],
    [
      queryTuple.data.fullName,
      queryTuple.data.typeName,
      queryTuple.data.profilePictureFileKey,
      numUnreadMessages
    ]
  );



  const elLeftButtons = React.useMemo(
    () => _.map(props.leftLinks, link => (
      <LinkButton
        key={link.id}
        {...link.props}
        label={t(link.props.label ?? '')}
        isSelected={props.selectedLinkId === link.id} />
    )),
    [
      props.leftLinks,
      props.selectedLinkId,
      i18n.language
    ]
  );



  const elRightButtons = React.useMemo(
    () => _.map(rightLinks, link => (
      <LinkButton
        key={link.id}
        {...link.props}
        label={t(link.props.label ?? '')}
        isSelected={props.selectedLinkId === link.id} />
    )),
    [
      props.selectedLinkId,
      rightLinks,
      i18n.language
    ]
  );



  function handleClickDropdownMenuLink(toRoute: string) {
    navigate(toRoute);
  }



  return (
    <NavigationToolbarBox>
      <SectionBox>
        <LogoContainerBox>
          <ResponsiveImage
            urlSet={assetUtil.imgsHeaderLogo} />
        </LogoContainerBox>

        {elLeftButtons}

        {props.dropdownMenuLinks && (
          <>
            <DropdownMenuContainerBox>
              <IconButton
                ref={refMoreButton}
                onClick={() => setIsDropdownMenuOpen(true)}>
                <MoreHoriz />
              </IconButton>
            </DropdownMenuContainerBox>

            <Popover
              open={isDropdownMenuOpen}
              anchorEl={refMoreButton.current}
              onClose={() => setIsDropdownMenuOpen(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}>

              <List>
                {_.map(props.dropdownMenuLinks, link => (
                  <ListItem
                    key={link.id}
                    disablePadding>
                    <ListItemButton
                      onClick={() => handleClickDropdownMenuLink(link.toRoute)}>
                      <ListItemText
                        primary={link.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>

            </Popover>
          </>
        )}
      </SectionBox>

      <SectionBox>
        {elRightButtons}

        <SwitchLanguageButton />
      </SectionBox>
    </NavigationToolbarBox>
  );
};
