import React from 'react';
import _ from 'lodash';

import {
  usePatientsActivityQuery as useBaseQuery,
  useImportPatientsMutation as useBaseImportPatientsMutation,
  ImportPatientInput,
  PatientStatusName,
  GenderName
} from 'generated/graphql';

import { selectFields } from 'modules';



export interface IPatient {
  id: string;
  firstName: string;
  lastName: string;
  emrNumber: string;
  patientStatus: selectFields.IPatientStatus;
  socDate: string;
  branch: string;
}

export interface IData {
  patients: IPatient[];
}

export function usePatientsActivityQuery() {
  const tuple = useBaseQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  return React.useMemo(
    () => {
      const gqlPatients = tuple.data?.patients ?? [];
      const data: IData = {
        patients: _.map(gqlPatients, (gqlPatient): IPatient => ({
          id: gqlPatient.id,
          firstName: gqlPatient.firstName,
          lastName: gqlPatient.lastName,
          emrNumber: gqlPatient.emrNumber,
          patientStatus: {
            id: gqlPatient.patientStatus.id,
            name: gqlPatient.patientStatus.name,
            displayName: gqlPatient.patientStatus.displayName
          },
          socDate: gqlPatient.socDate ?? "",
          branch: ""
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export interface IImportPatient {
  firstName: string;
  lastName: string;
  patientStatusName: string;
  birthdate?: string;
  genderName?: string;
  medicareNumber?: string;
  medicaidNumber?: string;
  emrNumber?: string;
  address?: string;
  locationNote?: string;
  primaryPhone?: string;
  secondaryPhone?: string;
  socDate?: string;
  referralDate?: string;
  ssn?: string;
  emergencyName?: string;
  emergencyRelationship?: string;
  emergencyPhone?: string;
  emergencyAddress?: string;
}

export function useImportPatientsMutation() {
  const [fnMutate, tuple] = useBaseImportPatientsMutation();

  const fnWrappedMutate = React.useCallback(
    async (patients: IImportPatient[]) => {
      await fnMutate({
        variables: {
          input: {
            patients: _.map(patients, (patient): ImportPatientInput => ({
              firstName: patient.firstName,
              lastName: patient.lastName,
              patientStatusName: patient.patientStatusName as PatientStatusName,
              birthdate: patient.birthdate || undefined,
              genderName: (patient.genderName || undefined) as GenderName | undefined,
              medicareNumber: patient.medicareNumber,
              medicaidNumber: patient.medicaidNumber,
              emrNumber: patient.emrNumber,
              address: patient.address,
              locationNote: patient.locationNote,
              primaryPhone: patient.primaryPhone,
              secondaryPhone: patient.secondaryPhone,
              socDate: patient.socDate || undefined,
              referralDate: patient.referralDate || undefined,
              ssn: patient.ssn,
              emergencyName: patient.emergencyName,
              emergencyRelationship: patient.emergencyRelationship,
              emergencyPhone: patient.emergencyPhone,
              emergencyAddress: patient.emergencyAddress
            }))
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
