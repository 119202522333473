import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useTimesheetsActivityPayPeriodQuery,
  useTimesheetsActivityLazyQuery
} from 'generated/graphql';

import { dateUtil } from 'modules';
import Big from 'big.js';



const __numPayPeriodDays = 14;



export interface IPayPeriodData {
  currentPayPeriod: IPayPeriod | null;
}

export function useQuery() {
  const tuple = useTimesheetsActivityPayPeriodQuery({
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const currentPayPeriodStart = tuple.data?.currentPayPeriodStart ?
        dateUtil.dateWithTimeZone2Moment(tuple.data.currentPayPeriodStart) :
        null;

      const currentPayPeriod: IPayPeriod | null = currentPayPeriodStart ? {
        start: currentPayPeriodStart,
        end: currentPayPeriodStart.clone().add(__numPayPeriodDays - 1, 'days').endOf('day')
      } : null;

      const data: IPayPeriodData = {
        currentPayPeriod
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export interface IEmployee {
  fullName: string;
  route: string;
}

export interface ITimesheet {
  id: string;
  employee: IEmployee;
  numTotalHours: Big;
  numApprovedHours: Big;
}

export interface IPayPeriod {
  start: moment.Moment;
  end: moment.Moment;
}

export interface IData {
  timesheets: ITimesheet[];
}

export function useLazyQuery() {
  const [fnQuery, tuple] = useTimesheetsActivityLazyQuery({
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  const fnWrappedQuery = React.useCallback(
    (payPeriodStart?: moment.Moment) => {
      fnQuery({
        variables: {
          filter: {
            payPeriodStart: payPeriodStart
          }
        }
      });
    },
    [fnQuery]
  );



  const wrappedTuple = React.useMemo(
    () => {
      const data: IData = {
        timesheets: _.map(tuple.data?.timesheets ?? [], (gqlTimesheet): ITimesheet => ({
          id: gqlTimesheet.id,
          employee: {
            fullName: `${gqlTimesheet.employee.lastName}, ${gqlTimesheet.employee.firstName}`,
            route: `/admin/employees/profile/${gqlTimesheet.employee.id}`
          },
          numTotalHours: Big(gqlTimesheet.numTotalHours),
          numApprovedHours: Big(gqlTimesheet.numApprovedHours)
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );



  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}
