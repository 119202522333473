import React from 'react';

import {
  Box,
  Button,
  Grid,
  Typography
} from '@mui/material';
import {
  genDateFieldControllerComponent,
  genEnumSelectFieldControllerComponent,
  genTextFieldControllerComponent,
  selectFields,
  ValueWithLabel,
  Disclaimer
} from 'modules';

import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from '../types';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();
const DateFieldController = genDateFieldControllerComponent<types.IFormState>();
const GenderSelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, selectFields.IGenderName>(selectFields.GenderSelectField);
const PatientStatusSelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, selectFields.IPatientStatusName>(selectFields.PatientStatusSelectField);



export interface IDetailsSectionProps {
  form: UseFormReturn<types.IFormState>;
  isDisabled: boolean;
  showSsn: boolean;
  onClickEditSsn: () => void;
}

export const DetailsSection: React.FC<IDetailsSectionProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('patients:details')}
      </Typography>

      <Box mt={4}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.form.control}
              fieldKey="firstName"
              label={t('firstName')}
              isRequired
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.form.control}
              fieldKey="lastName"
              label={t('lastName')}
              isRequired
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <PatientStatusSelectFieldController
              formControl={props.form.control}
              fieldKey="patientStatus"
              isRequired
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <DateFieldController
              formControl={props.form.control}
              fieldKey="birthdate"
              label={t('birthdate')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <GenderSelectFieldController
              formControl={props.form.control}
              fieldKey="gender"
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.form.control}
              fieldKey="emrNumber"
              label={t('patients:emrNumber')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={6} />

          <Grid
            item
            md={3}>
            <Disclaimer.Comp>
              {t('medicareMedicaidDisclaimer')}
            </Disclaimer.Comp>
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.form.control}
              fieldKey="medicareNumber"
              label={t('patients:medicareNumber')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.form.control}
              fieldKey="medicaidNumber"
              label={t('patients:medicaidNumber')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3} />

          {props.showSsn && (
            <Grid
              item
              md={3}>
              <ValueWithLabel
                label={t('ssn')}>
                <Button
                  onClick={props.onClickEditSsn}>
                  {t('edit')}
                </Button>
              </ValueWithLabel>
            </Grid>
          )}

          <Grid
            item
            md={6}>
            <TextFieldController
              formControl={props.form.control}
              fieldKey="note"
              label={t('note')}
              numLines={6}
              isDisabled={props.isDisabled} />
          </Grid>

        </Grid>

      </Box>

    </Box>
  );
};
