import React from 'react';
import _ from 'lodash';

import {
  authService,
  enums,
  i18n,
  inactivityTracker,
  validationUtil,
  enumCache,
  apolloService,
  markerIoUtil,
  messengerService,
  protectedFileUtil
} from 'modules';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';

import './index.css';



async function __init() {
  await authService.init();

  const envName = process.env.REACT_APP_ENV_NAME;
  if (_.includes(['edge', 'dev', 'prod'], envName)) {
    await inactivityTracker.init();
  }

  if (envName === 'dev') {
    markerIoUtil.init();
  }

  i18n.init();
  enums.init();
  validationUtil.init();
  messengerService.init();
  protectedFileUtil.init();

  apolloService.getClientObservable()
    .subscribe(client => {
      if (client) {
        enumCache.init(client);
      }
    });

  ReactDOM.render(
    (
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    ),
    document.getElementById('root')
  );
}
__init();
