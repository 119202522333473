import React from 'react';

import {
  BaseActivityTemplate,
  paletteColors
} from 'modules';
import {
  AssignmentsTab,
  genElRightButtons as genAssignmentsTabButtons
} from './AssignmentsTab/AssignmentsTab';
import {
  PresetsTab,
  genElRightButtons as genPresetsTabButtons
} from './PresetsTab/PresetsTab';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';




export const RequirementsActivity: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [selectedTabId, setSelectedTabId] = React.useState('assignments');



  const elRightButtons = React.useMemo(
    () => {
      if (selectedTabId === 'assignments') {
        return genAssignmentsTabButtons(t, navigate);
      } else if (selectedTabId === 'presets') {
        return genPresetsTabButtons(t, navigate);
      } else {
        return [];
      }
    },
    [
      selectedTabId,
      t,
      history
    ]
  );



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        headerOptions={{
          backgroundColor: paletteColors.requirements,
          title: t('requirements'),
          elRightButtons,
          tabOptions: {
            tabs: [
              {
                id: 'assignments',
                label: t('requirements:assignments')
              },
              {
                id: 'presets',
                label: t('requirements:presets')
              }
            ],
            selectedTabId,
            onSelectTab: setSelectedTabId
          }
        }}>

        {selectedTabId === 'assignments' && (
          <AssignmentsTab />
        )}

        {selectedTabId === 'presets' && (
          <PresetsTab />
        )}

      </BaseActivityTemplate>
    </>
  );
};
