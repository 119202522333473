import markerSdk from '@marker.io/browser';



export async function init() {
  const destinationId = process.env.REACT_APP_MARKER_IO_DESTINATION_ID;
  if (destinationId && destinationId.trim() !== 'null') {
    await markerSdk.loadWidget({
      destination: destinationId
    });
  }
}
