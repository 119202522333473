import React from 'react';
import _ from 'lodash';

import {
  AuthRedirectorDocument as Document,
  AuthRedirectorQueryResult as Result
} from 'generated/graphql';

import { authService } from 'modules/layer0';
import { apolloService } from 'modules/layer1';
import { enumCache } from 'modules/layer2';

import update from 'immutability-helper';



export interface IData {
  authStatus: authService.IStatus;
  hasUser: boolean;
  hasTotp: boolean;
  permissionCategories: enumCache.IPermissionCategoryName[];
}

export interface ITuple {
  loading: boolean;
  data: IData;
}

export function useQuery() {
  const [client, setClient] = React.useState<apolloService.IApolloClient | null>(null);
  const [hasApiRequest, setHasApiRequest] = React.useState(false);
  const [apiRequestDone, setApiRequestDone] = React.useState(false);
  const [tuple, setTuple] = React.useState<ITuple>({
    loading: true,
    data: {
      authStatus: 'noAttemptMade',
      hasUser: false,
      hasTotp: false,
      permissionCategories: []
    }
  });



  React.useEffect(
    () => {
      const sub = apolloService.getClientObservable()
        .subscribe(eventClient => setClient(eventClient));

      return () => sub.unsubscribe();
    },
    []
  );



  React.useEffect(
    () => {
      if (client && hasApiRequest && !apiRequestDone) {
        (async () => {
          setApiRequestDone(true);
          const gqlResults = await __graphqlQuery(client);
          let hasTotp = false;

          if (gqlResults.hasUser) {
            hasTotp = await authService.hasTotp();
          }

          setTuple(previous => update(previous, {
            loading: {$set: false},
            data: {
              hasUser: {$set: gqlResults.hasUser},
              hasTotp: {$set: hasTotp},
              permissionCategories: {$set: gqlResults.permissionCategories}
            }
          }));
        })();
      }
    },
    [
      client,
      hasApiRequest,
      apiRequestDone
    ]
  );



  React.useEffect(
    () => {
      const sub = authService.getAuthStateObservable()
        .subscribe(authState => {
          const authStatus = authState.status;
          let stillLoading = false;

          if (authStatus === 'loggedIn') {
            stillLoading = true;
            setHasApiRequest(true);
          }

          setTuple(previous => update(previous, {
            loading: {
              $set: stillLoading
            },
            data: {
              authStatus: {
                $set: authStatus
              }
            }
          }));
        });

      return () => sub.unsubscribe();
    },
    []
  );



  return tuple;
}



interface IGraphqlQueryData {
  hasUser: boolean;
  permissionCategories: enumCache.IPermissionCategoryName[];
}

async function __graphqlQuery(client: apolloService.IApolloClient): Promise<IGraphqlQueryData> {
  const queryResult = await client.query<Result['data']>({
    query: Document,
    fetchPolicy: 'no-cache'
  });

  return {
    hasUser: !!queryResult.data?.loggedInUser?.id,
    permissionCategories: _.map(queryResult.data?.loggedInUser?.typeSpecificUser.permissionCategories ?? [], gqlCategory => gqlCategory.name)
  };
}
