import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useMyPatientsActivityQuery
} from 'generated/graphql';
import {
  dateUtil,
  enumCache
} from 'modules';



export interface IVisit {
  id: string;
  date: moment.Moment | null;
  shiftName: enumCache.IShiftName;
}

export interface IPatient {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  locationNote: string;
  nextVisit: IVisit | null;
}

export interface IData {
  patients: IPatient[];
}

export function useQuery() {
  const tuple = useMyPatientsActivityQuery();



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlEmployee = tuple.data?.loggedInUser?.typeSpecificUser.__typename === 'Employee' ?
        tuple.data.loggedInUser.typeSpecificUser :
        null;

      const data: IData = {
        patients: _.map(gqlEmployee?.patients ?? [], (gqlPatient): IPatient => ({
          id: gqlPatient.id,
          firstName: gqlPatient.firstName,
          lastName: gqlPatient.lastName,
          address: gqlPatient.address,
          locationNote: gqlPatient.locationNote,
          nextVisit: gqlPatient.nextVisit ? {
            id: gqlPatient.nextVisit.id,
            date: dateUtil.dateWithTimeZone2Moment(gqlPatient.nextVisit.date),
            shiftName: gqlPatient.nextVisit.shift.name
          } : null
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
