import React from 'react';
import moment from 'moment-timezone';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import {
  Autocomplete,
  TextField
} from '@mui/material';

import { validationUtil } from 'modules/layer0';
import { FieldContainer } from 'modules/layer1';



export interface ITimeZoneSelectFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const TimeZoneSelectField: React.FC<ITimeZoneSelectFieldProps> = props => {
  return (
    <FieldContainer.Comp>
      <Autocomplete
        options={moment.tz.names()}
        value={props.value}
        onChange={(_event, value) => props.onChange(value ?? "")}
        isOptionEqualToValue={(option, value) => option === value}
        disabled={props.isDisabled}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            error={!!props.errorMessage}
            helperText={props.errorMessage} />
        )} />
    </FieldContainer.Comp>
  );
};



export interface ITimeZoneSelectFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  label: string;
  isRequired?: boolean;
  isDisabled?: boolean;
}

export function genTimeZoneSelectFieldControllerComponent<TFormState extends FieldValues>() {
  const TextFieldController: React.FC<ITimeZoneSelectFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        name={props.fieldKey}
        control={props.formControl}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <TimeZoneSelectField
            label={props.label}
            value={field.value}
            onChange={field.onChange}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled} />
        )} />
    );
  };

  return TextFieldController;
}
