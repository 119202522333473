import React from 'react';
import _ from 'lodash';

import { useUserSelectFieldQuery as useBaseQuery } from 'generated/graphql';



export type ISiteUserTypeName =
  'administrator' |
  'employee';

export interface ISiteUser {
  id: string;
  fullName: string;
}

export interface IData {
  users: ISiteUser[];
  usersById: Record<string, ISiteUser>;
}

export function useUserSelectFieldQuery(typeName?: ISiteUserTypeName, removeUserIds?: string[]) {
  const tuple = useBaseQuery({
    variables: {
      typeNames: typeName ?
        [typeName] :
        [
          'administrator',
          'employee'
        ]
    }
  });

  return React.useMemo(
    () => {
      const removeUserIdsSet = new Set(removeUserIds ?? []);

      const users = _.chain(tuple.data?.siteUsersLite ?? [])
        .map((gqlUser): ISiteUser => ({
          id: gqlUser.id,
          fullName: gqlUser.fullName
        }))
        .filter(user => user.fullName.trim().length > 0)
        .filter(user => !removeUserIdsSet.has(user.id))
        .sortBy(user => user.fullName.toLowerCase())
        .value();

      const usersPlusEmpty: ISiteUser[] = [
        ...users,
        {
          id: '',
          fullName: ""
        }
      ];

      const data: IData = {
        users: usersPlusEmpty,
        usersById: _.keyBy(usersPlusEmpty, user => user.id)
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
