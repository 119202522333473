import React from 'react';
import _ from 'lodash';

import {
  Box,
  Grid,
  Link
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';



const LabelBox = styled(Box)(() => ({
  fontSize: '16px',
  fontWeight: 700,
  color: '#263238'
}));

const ValueBox = styled(Box)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  color: '#7e8589'
}));



export interface IValueWithLabelProps {
  label: string;
  value?: string;
  toRoute?: string;
  toExternalUrl?: string;
}

export const ValueWithLabel: React.FC<IValueWithLabelProps> = props => {
  const {t} = useTranslation();



  return (
    <>
      <Grid
        item
        md={5}>

        <LabelBox>
          {props.label}
        </LabelBox>

      </Grid>

      <Grid
        item
        md={7}>

        {!_.isNil(props.value) ? (
          <>
            {props.toRoute && (
              <ValueBox>
                <Link
                  component={RouterLink}
                  to={props.toRoute}>
                  {props.value || t('noValue')}
                </Link>
              </ValueBox>
            )}

            {!props.toRoute && props.toExternalUrl && (
              <ValueBox>
                <Link
                  href={props.toExternalUrl}
                  target="_blank">
                  {props.value || t('noValue')}
                </Link>
              </ValueBox>
            )}

            {!props.toRoute && !props.toExternalUrl && (
              <ValueBox>
                {props.value || t('noValue')}
              </ValueBox>
            )}
          </>
        ) : props.children}

      </Grid>
    </>
  );
};
