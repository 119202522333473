import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  errorUtil,
  Form,
  formUtil,
  paletteColors
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from './types';
import * as dataService from './dataService';

import { GeneralInformationSection } from './GeneralInformationSection/GeneralInformationSection';
import { ContactInformationSection } from './ContactInformationSection/ContactInformationSection';
import { SupervisorsAndPayRateSection } from './SupervisorsAndPayRateSection/SupervisorsAndPayRateSection';
import * as PermissionCategoriesSection from './PermissionCategoriesSection/PermissionCategoriesSection';



interface IParams {
  administratorId?: string;
}

export const EditAdministratorActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const form = useForm<types.IFormState>({
    defaultValues: {
      firstName: "",
      lastName: "",
      birthdate: null,
      employeeId: "",
      address: "",
      primaryPhone: "",
      secondaryPhone: "",
      supervisorUserId: "",
      altSupervisorUserId: "",
      payRate: "",
      permissionCategories: []
    }
  });

  const [fnQuery, queryTuple] = dataService.useEditAdministratorActivityLazyQuery();
  const [fnSave, saveMutationTuple] = dataService.useSaveAdministratorMutation();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveAdministratorMutation();

  const isLoading = React.useMemo(
    () =>
      queryTuple.loading ||
      saveMutationTuple.loading ||
      archiveMutationTuple.loading,
    [
      queryTuple.loading,
      saveMutationTuple.loading,
      archiveMutationTuple.loading
    ]
  );



  React.useEffect(
    () => {
      if (params.administratorId && !queryTuple.called) {
        fnQuery(params.administratorId);
      }
    },
    [
      params.administratorId,
      queryTuple.called
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        formUtil.setFieldsFromQuery(queryTuple.data.formState, form.setValue, [
          'id',
          'firstName',
          'lastName',
          'birthdate',
          'employeeId',
          'address',
          'primaryPhone',
          'secondaryPhone',
          'supervisorUserId',
          'altSupervisorUserId',
          'payRate',
          'permissionCategories'
        ]);
      }
    },
    [
      queryTuple.data.formState,
      queryTuple.loading
    ]
  );



  async function handleSubmit(formData: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnSave(formData);
      navigate(-1);
    });
  }



  async function handleConfirmDelete() {
    await errorUtil.errorWrap(async () => {
      await fnArchive(form.watch('id'));
      navigate(-1);
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        navigationBarSelectedLinkId="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.administrators,
          title: !params.administratorId ?
            t('adminAdministrators:createAdministrator') :
            (queryTuple.data.fullName || t('adminAdministrators:editAdministrator')),
          hasBackButton: true
        }}
        footerOptions={{
          hasDeleteButton: !!params.administratorId,
          deleteButtonLabel: t('adminAdministrators:deleteAdministrator'),
          onConfirmDelete: handleConfirmDelete,
          onClickCancel: () => navigate(-1)
        }}>

        <GeneralInformationSection
          formControl={form.control}
          email={queryTuple.data.email}
          isDisabled={isLoading} />

        <ContactInformationSection
          formControl={form.control}
          isDisabled={isLoading} />

        <SupervisorsAndPayRateSection
          formControl={form.control}
          isDisabled={isLoading} />

        {(!params.administratorId || !queryTuple.data.isSelf) && !queryTuple.loading && (
          <PermissionCategoriesSection.Comp
            formControl={form.control}
            isDisabled={isLoading} />
        )}

      </BaseActivityTemplate>
    </Form>
  );
};
