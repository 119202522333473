import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  HeaderLabelWithValue,
  paletteColors,
  chips,
  SectionHeaderBox,
  ValueWithLabel,
  ConfirmationModal,
  errorUtil,
  VisitEmployeeVisitInfoSection,
  VisitAfterVisitDataSection,
  VisitAssignedEmployeesSection
} from 'modules';
import {
  Box,
  Button,
  Grid,
  Typography
} from '@mui/material';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';
import { Create } from '@mui/icons-material';

import { PatientAbsentModal } from './PatientAbsentModal/PatientAbsentModal';



interface IParams {
  visitId: string;
}

export const ViewVisitActivity: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams<Partial<IParams>>();

  const queryTuple = dataService.useQuery(params.visitId ?? '');
  const [fnClockIn, clockInMutationTuple] = dataService.useEmployeeClockInToVisitMutation(params.visitId ?? '');
  const [fnClockOut, clockOutMutationTuple] = dataService.useEmployeeClockOutOfVisitMutation(params.visitId ?? '');
  const [fnComplete, completeMutationTuple] = dataService.useEmployeeCompleteVisitMutation(params.visitId ?? '');

  const [isPatientAbsentModalOpen, setIsPatientAbsentModalOpen] = React.useState(false);
  const [isClockInModalOpen, setIsClockInModalOpen] = React.useState(false);
  const [isClockOutModalOpen, setIsClockOutModalOpen] = React.useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = React.useState(false);

  const isLoading =
    queryTuple.loading ||
    clockInMutationTuple.loading ||
    clockOutMutationTuple.loading ||
    completeMutationTuple.loading;

  const showClockInButton = queryTuple.data.visitStatusName === 'open' || (
    queryTuple.data.visitStatusName === 'clockedIn' &&
    queryTuple.data.selfClockedStatus === 'none'
  );

  const showClockOutButton =
    queryTuple.data.visitStatusName === 'clockedIn' &&
    queryTuple.data.selfClockedStatus === 'clockedIn';



  function handleAcceptPatientAbsentModal() {
    setIsPatientAbsentModalOpen(false);
    queryTuple.refetch();
  }



  async function handleAcceptClockInModal() {
    setIsClockInModalOpen(false);
    await errorUtil.errorWrap(async () => {
      await fnClockIn();
      queryTuple.refetch();
    }, {
      INVALID_TIME_TO_CLOCK_IN: t('visits:errors.INVALID_TIME_TO_CLOCK_IN'),
      EMPLOYEE_ALREADY_CLOCKED_IN: t('visits:errors.EMPLOYEE_ALREADY_CLOCKED_IN'),
      EMPLOYEE_ALREADY_CLOCKED_OUT: t('visits:errors.EMPLOYEE_ALREADY_CLOCKED_OUT')
    });
  }



  async function handleAcceptClockOutModal() {
    setIsClockOutModalOpen(false);
    await errorUtil.errorWrap(async () => {
      await fnClockOut();
      queryTuple.refetch();
    });
  }



  async function handleAcceptCompleteModal() {
    setIsCompleteModalOpen(false);
    await errorUtil.errorWrap(async () => {
      await fnComplete();
      queryTuple.refetch();
    });
  }



  return (
    <>
      <BaseActivityTemplate
        mode="employee"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.visits,
          title: queryTuple.data.patientName,
          hasBackButton: true,

          elRight: !queryTuple.loading ? (
            <>
              {queryTuple.data.visitStatusName && (
                <HeaderLabelWithValue label={t('status')}>
                  <chips.VisitStatusChip
                    valueId={queryTuple.data.visitStatusName} />
                </HeaderLabelWithValue>
              )}

              {queryTuple.data.missedVisitReasonName && (
                <Box ml={3}>
                  <HeaderLabelWithValue label={t('visits:missedVisitReason')}>
                    {t(`visits:missedVisitReasonValues.${queryTuple.data.missedVisitReasonName}`)}
                  </HeaderLabelWithValue>
                </Box>
              )}
            </>
          ) : undefined,

          elRightButtons: _.compact([
            ...(queryTuple.data.visitStatusName === 'open' ? [
              (
                <Button
                  key="patientAbsent"
                  color="error"
                  variant="contained"
                  onClick={() => setIsPatientAbsentModalOpen(true)}>
                  {t('visits:patientAbsent')}
                </Button>
              )
            ] : []),

            ...((queryTuple.data.visitStatusName === 'clockedIn' && queryTuple.data.selfClockedStatus === 'clockedIn') ? [
              (
                <Button
                  key="editData"
                  variant="contained"
                  onClick={() => navigate(`/employee/visit/edit/${params.visitId}`)}
                  color="primary"
                  startIcon={(
                    <Create />
                  )}>
                  {t('editData')}
                </Button>
              )
            ] : []),

            ...(queryTuple.data.visitStatusName === 'clockedOut' ? [
              (
                <Button
                  key="editAfterVisitData"
                  variant="contained"
                  onClick={() => navigate(`/employee/visit/editAfterVisitData/${queryTuple.data.afterVisitDataId}`)}>
                  {t('visits:editAfterVisitData')}
                </Button>
              ),
              (
                <Button
                  key="complete"
                  variant="contained"
                  onClick={() => setIsCompleteModalOpen(true)}>
                  {t('visits:complete')}
                </Button>
              )
            ] : []),

            ...(showClockInButton ? [
              (
                <Button
                  key="clockIn"
                  variant="contained"
                  onClick={() => setIsClockInModalOpen(true)}>
                  {t('visits:clockIn')}
                </Button>
              )
            ] : []),

            ...(showClockOutButton ? [
              (
                <Button
                  key="clockOut"
                  variant="contained"
                  onClick={() => setIsClockOutModalOpen(true)}>
                  {t('visits:clockOut')}
                </Button>
              )
            ] : [])
          ])
        }}>

        {!isLoading && (
          <Box>
            <Box>
              <SectionHeaderBox>
                <Typography variant="h2">
                  {t('details')}
                </Typography>
              </SectionHeaderBox>
            </Box>

            <Grid container>
              <Grid
                item
                md={6}>
                <Grid container>

                  <ValueWithLabel
                    label={t('visits:visitName')}
                    value={queryTuple.data.displayName} />

                  <ValueWithLabel
                    label={t('visits:visitCategory')}
                    value={t(`visits:visitCategories.${queryTuple.data.visitCategoryName}`)} />

                  <ValueWithLabel
                    label={t('visits:date')}
                    value={queryTuple.data.formattedDate} />

                  <ValueWithLabel
                    label={t('visits:shift')}
                    value={t(`visits:shifts.${queryTuple.data.shiftName}`)} />

                  <ValueWithLabel
                    label={t('visits:note')}
                    value={queryTuple.data.note} />

                </Grid>
              </Grid>

              <Grid
                item
                md={6}>
                <Grid container>

                  <ValueWithLabel
                    label={t('visits:location')}
                    value={queryTuple.data.location} />

                  <ValueWithLabel
                    label={t('visits:clockInTime')}
                    value={queryTuple.data.clockInTimestamp} />

                  <ValueWithLabel
                    label={t('visits:clockOutTime')}
                    value={queryTuple.data.clockOutTimestamp} />

                  <ValueWithLabel
                    label={t('visits:completedTime')}
                    value={queryTuple.data.completedTimestamp} />

                  <ValueWithLabel
                    label={t('visits:missedTime')}
                    value={queryTuple.data.missedTimestamp} />

                  <ValueWithLabel
                    label={t('visits:missedVisitReason')}
                    value={queryTuple.data.missedVisitReason} />

                </Grid>
              </Grid>
            </Grid>

            <Box mt={3}>
              <Grid container>

                <Grid
                  item
                  md={6}>

                  <VisitAssignedEmployeesSection
                    visitId={params.visitId ?? ''}
                    userType="employee" />

                </Grid>

                <Grid
                  item
                  md={12}>

                  <VisitEmployeeVisitInfoSection
                    visitId={params.visitId ?? ''} />

                </Grid>

              </Grid>
            </Box>

            {(queryTuple.data.visitStatusName === 'clockedOut' || queryTuple.data.visitStatusName === 'completed') && (
              <Box mt={3}>
                <VisitAfterVisitDataSection
                  afterVisitDataId={queryTuple.data.afterVisitDataId} />
              </Box>
            )}
          </Box>
        )}

      </BaseActivityTemplate>

      {isPatientAbsentModalOpen && (
        <PatientAbsentModal
          visitId={params.visitId ?? ''}
          onAccept={handleAcceptPatientAbsentModal}
          onCancel={() => setIsPatientAbsentModalOpen(false)} />
      )}

      {isClockInModalOpen && (
        <ConfirmationModal
          isOpen
          title={t('areYouSure')}
          message={t('visits:clockInConfirmation')}
          onAccept={handleAcceptClockInModal}
          onCancel={() => setIsClockInModalOpen(false)} />
      )}

      {isClockOutModalOpen && (
        <ConfirmationModal
          isOpen
          title={t('areYouSure')}
          message={t('visits:clockOutConfirmation')}
          onAccept={handleAcceptClockOutModal}
          onCancel={() => setIsClockOutModalOpen(false)} />
      )}

      {isCompleteModalOpen && (
        <ConfirmationModal
          isOpen
          title={t('areYouSure')}
          message={t('visits:completeConfirmation')}
          onAccept={handleAcceptCompleteModal}
          onCancel={() => setIsCompleteModalOpen(false)} />
      )}
    </>
  );
};
