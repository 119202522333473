import React from 'react';
import moment from 'moment';

import {
  Avatar as BaseAvatar,
  Box,
  CircularProgress
} from '@mui/material';
import {
  dateUtil,
  ProtectedImage
} from 'modules';

import { styled } from '@mui/material/styles';



const ContainerBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(4),
  display: 'flex'
}));

const FillerBox = styled(Box)(() => ({
  flex: '1 0'
}));

const Avatar = styled(BaseAvatar)(({theme}) => ({
  width: theme.spacing(6),
  height: theme.spacing(6)
}));

interface IMessageContainerBoxProps {
  isOtherUser: boolean;
}

const MessageContainerBox = styled(Box)<IMessageContainerBoxProps>(({theme, isOtherUser}) => ({
  flex: !isOtherUser ?
    `0 0 min(100%, ${theme.spacing(67)})` :
    `0 0 min(100%, ${theme.spacing(67 + (6 + 2))})`,
  display: 'flex'
}));

const MessageInnerContainerBox = styled(Box)(() => ({
  flex: '1 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));

const MessageBox = styled(Box)(({theme}) => ({
  flex: '1 0',
  padding: theme.spacing(2),
  borderRadius: '6px',
  backgroundColor: '#F4F9FD',
  marginBottom: theme.spacing(1)
}));

const TimestampDiv = styled('div')(() => ({
  fontWeight: 500,
  fontSize: '10px',
  letterSpacing: '1.5px'
}));



export interface IAuthor {
  id: string;
  fullName: string;
  profilePictureFileKey: string;
}

export interface IMessageProps {
  authorType: 'self' | 'other';
  author: IAuthor;
  message: string;
  timestamp: moment.Moment;
  isInFlight: boolean;
}

export const Message: React.FC<IMessageProps> = props => {
  return (
    <ContainerBox>

      {props.authorType === 'self' && (
        <FillerBox />
      )}

      <MessageContainerBox
        isOtherUser={props.authorType === 'other'}>

        {props.authorType === 'other' && (
          <Box mr={2}>
            <ProtectedImage
              fileKey={props.author.profilePictureFileKey}
              asAvatar
              size="48px" />
          </Box>
        )}

        <MessageInnerContainerBox>
          <MessageBox>
            <strong>{props.author.fullName}:</strong> {props.message}
          </MessageBox>

          {!props.isInFlight ? (
            <TimestampDiv>
              {props.timestamp.format(dateUtil.timeFormat)}
            </TimestampDiv>

          ) : (
            <CircularProgress
              size={10} />
          )}
        </MessageInnerContainerBox>

      </MessageContainerBox>

      {props.authorType === 'other' && (
        <FillerBox />
      )}

    </ContainerBox>
  );
};
