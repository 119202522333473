import React from 'react';
import _ from 'lodash';

import {
  Box,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import {
  useFieldArray,
  UseFormReturn
} from 'react-hook-form';
import {
  SectionHeaderBox,
  fields,
  RowBox,
  CellBox,
  CheckboxField
} from 'modules';

import { Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import * as types from '../../types';
import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';



const ContainerBox = styled(Box)(({theme}) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(9)
}));



const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();



export interface IProps {
  form: UseFormReturn<types.IFormState>;
  questionIndex: number;
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();

  const answerOptionFields = useFieldArray({
    control: props.form.control,
    name: `quizPreset.questions.${props.questionIndex}.answerOptions`,
    keyName: '_key'
  });

  const answerOptions = props.form.watch(`quizPreset.questions.${props.questionIndex}.answerOptions`);



  function handleClickAddAnswerOption() {
    answerOptionFields.append({
      _key: `*${uuid.v4()}`,
      description: "",
      isCorrect: false
    });
  }



  function handleClickDeleteAnswerOption(index: number) {
    answerOptionFields.remove(index);
  }



  function handleClickIsCorrect(index: number, value: boolean) {
    const newOptions = _.map(answerOptions, (option, iteratorIndex) => ({
      ...option,
      isCorrect: iteratorIndex === index ?
        value :
        false
    }));

    props.form.setValue(`quizPreset.questions.${props.questionIndex}.answerOptions`, newOptions);
  }



  return (
    <ContainerBox>

      <SectionHeaderBox>
        <Typography variant="h3">
          {t('requirements:quiz.answerOptions')}
        </Typography>

        <Button onClick={handleClickAddAnswerOption}>
          {t('requirements:quiz.addAnswerOption')}
        </Button>
      </SectionHeaderBox>


      {answerOptionFields.fields.length === 0 && (
        <Typography>
          {t('requirements:quiz.noAnswerOptions')}
        </Typography>
      )}

      {_.map(answerOptionFields.fields, (field, index) => (
        <RowBox
          key={field._key}
          mb={1}>

          <CellBox>
            <TextFieldController
              label={t('requirements:quiz.answerOptionDescription')}
              formControl={props.form.control}
              fieldKey={`quizPreset.questions.${props.questionIndex}.answerOptions.${index}.description`}
              isRequired />
          </CellBox>

          <CheckboxField
            label={t('requirements:quiz.isCorrect')}
            value={field.isCorrect}
            onChange={() => handleClickIsCorrect(index, !field.isCorrect)}
            displayType="radio" />

          <IconButton
            onClick={() => handleClickDeleteAnswerOption(index)}>
            <Delete
              color="error" />
          </IconButton>

        </RowBox>
      ))}

    </ContainerBox>
  );
};

