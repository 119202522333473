import React from 'react';

import { AppBar } from '@mui/material';



export const AppBarWrapper: React.FC = props => {
  const refAppBar = React.useRef<HTMLDivElement>(null);
  const refAppBarOffset = React.useRef<HTMLDivElement>(null);



  React.useEffect(
    () => {
      if (refAppBar.current) {
        const resizeObserver = new ResizeObserver(() => {
          if (refAppBarOffset.current) {
            refAppBarOffset.current.style.height = `${refAppBar.current?.clientHeight ?? 0}px`;
          }
        });
        resizeObserver.observe(refAppBar.current);
      }
    },
    []
  );



  return (
    <>
      <AppBar position="fixed">
        <div ref={refAppBar}>
          {props.children}
        </div>
      </AppBar>

      <div ref={refAppBarOffset} />
    </>
  );
};
