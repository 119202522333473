import React from 'react';
import _ from 'lodash';

import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  BaseActivityTemplate,
  chips,
  HeaderLabelWithValue,
  paletteColors,
  ProtectedFileDownloadButton,
  ValueWithLabel,
  QuizContentSection
} from 'modules';
import {
  Button,
  Grid,
  Box
} from '@mui/material';

import { Create } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';

import { CreateRecurrenceModal } from './CreateRecurrenceModal/CreateRecurrenceModal';



export interface IParams {
  presetId: string;
}

export const ViewRequirementPresetActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.presetId ?? '');

  const [isCreateRecurrenceModalOpen, setIsCreateRecurrenceModalOpen] = React.useState(false);

  const isLoading =
    queryTuple.loading;



  function handleClickEditData() {
    navigate(`/admin/requirementPresets/edit/${params.presetId}`);
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.requirements,
          title: queryTuple.data.displayName,
          hasBackButton: true,

          elRight: !queryTuple.loading ? (
            <HeaderLabelWithValue
              label={t('requirements:requirementType')}>
              <chips.RequirementTypeChip
                valueId={queryTuple.data.type} />
            </HeaderLabelWithValue>
          ) : undefined,

          elRightButtons: !queryTuple.loading ?
            _.compact([
              !queryTuple.data.isLocked ? (
                <Button
                  key="editData"
                  variant="outlined"
                  onClick={handleClickEditData}
                  startIcon={(
                    <Create />
                  )}>
                  {t('editData')}
                </Button>
              ) : null,

              (
                <Button
                  key="assign"
                  variant="contained"
                  onClick={() => setIsCreateRecurrenceModalOpen(true)}>
                  {t('requirements:assign')}
                </Button>
              )
            ]) :
            []
        }}>

        <Box mb={3}>
          <Grid container>

            <ValueWithLabel
              label={t('requirements:description')}
              value={queryTuple.data.description} />

            <ValueWithLabel
              label={t('requirements:attachedFile')}
              value={!queryTuple.data.file ? "" : undefined}>
              {queryTuple.data.file && (
                <ProtectedFileDownloadButton
                  fileKey={queryTuple.data.file.key}
                  fileName={queryTuple.data.file.name} />
              )}
            </ValueWithLabel>

            <ValueWithLabel
              label={t('requirements:url')}
              value={queryTuple.data.url}
              toExternalUrl={queryTuple.data.url} />

          </Grid>
        </Box>

        {queryTuple.data.quizPreset && (
          <QuizContentSection.Comp
            quiz={queryTuple.data.quizPreset} />
        )}

      </BaseActivityTemplate>

      {isCreateRecurrenceModalOpen && (
        <CreateRecurrenceModal
          presetId={params.presetId ?? ''}
          onAccept={() => setIsCreateRecurrenceModalOpen(false)}
          onCancel={() => setIsCreateRecurrenceModalOpen(false)} />
      )}
    </>
  );
};
