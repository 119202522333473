import React from 'react';
import _ from 'lodash';

import {
  Grid,
  IconButton,
  Box,
  Typography,
  Button
} from '@mui/material';

import { HeaderBox } from 'modules';
import { Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';



const HeaderRightBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));



export interface IEditableListFormFragmentProps<T extends {_key: string;}> {
  title: string;
  addLabel: string;
  noItemsLabel: string;
  items: T[];
  onClickAdd: () => void;
  onClickDelete: (index: number) => void;
  fnGenElItem: (item: T, index: number) => JSX.Element;
  elHeaderRight?: JSX.Element;
}

export function genEditableListFormFragment<T extends {_key: string;}>() {
  const EditableListFormFragment: React.FC<IEditableListFormFragmentProps<T>> = props => {
    return (
      <Box>
        <HeaderBox>
          <Typography variant="h2">
            {props.title}
          </Typography>

          <HeaderRightBox>
            {props.elHeaderRight && (
              <Box mr={3}>
                {props.elHeaderRight}
              </Box>
            )}

            <Button
              onClick={props.onClickAdd}
              size="small">
              {props.addLabel}
            </Button>
          </HeaderRightBox>
        </HeaderBox>

        {props.items.length === 0 && (
          <div>
            {props.noItemsLabel}
          </div>
        )}

        <Grid container>
          {_.map(props.items, (item, index) => (
            <React.Fragment key={item._key}>
              {props.fnGenElItem(item, index)}

              <Grid
                item
                md={1}>
                <IconButton
                  onClick={() => props.onClickDelete(index)}
                  size="small">
                  <Delete />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    );
  };

  return EditableListFormFragment;
}
