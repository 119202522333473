import React from 'react';

import { styled } from '@mui/material/styles';
import { SwitchLanguageButton } from 'modules/layer2';



const BarDiv = styled('div')(() => ({
  flex: '1 0',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));



export const LanguageBar: React.FC = () => {
  return (
    <BarDiv>
      <SwitchLanguageButton styleType="large" />
    </BarDiv>
  );
};
