import React from 'react';

import {
  Box,
  Button,
  Typography
} from '@mui/material';
import {
  Control,
  useFieldArray
} from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import * as types from '../types';
import { Skill } from './Skill/Skill';
import * as uuid from 'uuid';



export interface ISkillsSectionProps {
  formControl: Control<types.IFormState>;
  isDisabled: boolean;
}

export const SkillsSection: React.FC<ISkillsSectionProps> = props => {
  const {t} = useTranslation();
  const skillsFieldArray = useFieldArray({
    control: props.formControl,
    name: 'skills',
    keyName: '_key'
  });



  function handleClickAddNewSkill() {
    const id = `*${uuid.v4()}`;
    skillsFieldArray.append({
      _key: id,
      id,
      name: "",
      description: ""
    });
  }



  function handleClickDeleteSkill(index: number) {
    skillsFieldArray.remove(index);
  }



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('adminEmployees:skills')}
      </Typography>

      {skillsFieldArray.fields.map((field, index) => (
        <Skill
          key={field.id}
          formControl={props.formControl}
          index={index}
          onClickDelete={() => handleClickDeleteSkill(index)}
          isDisabled={props.isDisabled} />
      ))}

      <Box mt={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickAddNewSkill}
          fullWidth>
          {t('adminEmployees:addNewSkill')}
        </Button>
      </Box>

    </Box>
  );
};
