import React from 'react';
import _ from 'lodash';

import {
  useEmployeeEditVisitActivityQuery,
  useEmployeeSaveVisitMutation as useBaseEmployeeSaveVisitMutation,
  VisitDataSchemaFieldsFragment,
  VisitDataValueFieldsFragment,
  VisitDataValueInput,
  VisitImageInput
} from 'generated/graphql';

import * as types from './types';
import * as uuid from 'uuid';



export type ISchema = Record<string, types.ISchemaValue>;

export interface IData {
  displayName: string;
  patientHasSignature: boolean;
  locationTypeSchema: ISchema;
  formState: types.IFormState;
}

export function useQuery(id: string) {
  const tuple = useEmployeeEditVisitActivityQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  return React.useMemo(
    () => {
      const locationTypeSchema: ISchema = __gqlSchemaValues2Client(tuple.data?.locationTypeSchema?.values ?? []);
      const gqlVisit = tuple.data?.visit;
      const data: IData = {
        displayName: gqlVisit?.displayName ?? "",
        patientHasSignature: !!gqlVisit?.episode.patient.latestSignature?.id,
        locationTypeSchema,
        formState: {
          tasks: _.map(gqlVisit?.tasks ?? [], (gqlTask): types.ITask => ({
            _key: gqlTask.id,
            id: gqlTask.id,
            title: gqlTask.title,
            description: gqlTask.description,
            isCompleted: !!gqlTask.completionTimestamp
          })),
          hasPatientConditionChanged: gqlVisit?.hasPatientConditionChanged ?? false,
          patientConditionChangedDescription: gqlVisit?.patientConditionChangeDescription ?? "",
          additionalComments: gqlVisit?.additionalComments ?? "",
          temperature: gqlVisit?.temperature ?? "",
          bloodPressure: gqlVisit?.bloodPressure ?? "",
          heartRate: gqlVisit?.heartRate ?? "",
          satRa: gqlVisit?.satRa ?? "",
          satO2: gqlVisit?.satO2 ?? "",
          o2l: gqlVisit?.o2l ?? "",
          medicationHeld: gqlVisit?.medicationHeld ?? "",
          patientAppearances: gqlVisit?.patientAppearances ?? "",
          locationTypeValues: __schema2FormValues(locationTypeSchema, gqlVisit?.locationType ? [gqlVisit.locationType] : []),
          signature: gqlVisit?.signature ? {
            dataUrl: '',
            fileKey: gqlVisit.signature.file.key
          } : null,
          useLatestPatientSignature: false,
          images: _.map(gqlVisit?.images ?? [], (gqlImage): types.IImage => ({
            id: gqlImage.id,
            title: gqlImage.title,
            key: gqlImage.file.key,
            file: null,
            name: '',
            url: ''
          }))
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



function __gqlSchemaValues2Client(gqlSchemaValues: VisitDataSchemaFieldsFragment['values']) {
  return _.keyBy(
    _.map(gqlSchemaValues, (gqlValue): types.ISchemaValue => ({
      value: gqlValue.value,
      hasDescription: gqlValue.hasDescription,
      descriptionLabel: gqlValue.descriptionLabel ?? undefined
    })),
    value => value.value
  );
}



function __schema2FormValues(schema: Record<string, types.ISchemaValue>, gqlVisitDataValues: VisitDataValueFieldsFragment[]) {
  const visitDataValuesByValue = _.keyBy(gqlVisitDataValues, value => value.value);
  return _.map(_.values(schema), (schemaValue): types.IValue => {
    const visitDataValue = visitDataValuesByValue[schemaValue.value];

    return {
      _key: uuid.v4(),
      name: schemaValue.value,
      isChecked: !!visitDataValue,
      description: visitDataValue?.description ?? ""
    };
  });
}



export function useEmployeeSaveVisitMutation(id: string) {
  const [fnMutate, tuple] = useBaseEmployeeSaveVisitMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id,
            completedTaskIds: _.map(
              _.filter(formState.tasks, task => task.isCompleted),
              task => task.id),
            hasPatientConditionChanged: formState.hasPatientConditionChanged,
            patientConditionChangeDescription: formState.hasPatientConditionChanged ?
              formState.patientConditionChangedDescription :
              "",
            additionalComments: formState.additionalComments,
            temperature: formState.temperature.trim(),
            bloodPressure: formState.bloodPressure.trim(),
            heartRate: formState.heartRate.trim(),
            satRa: formState.satRa.trim(),
            satO2: formState.satO2.trim(),
            o2l: formState.o2l.trim(),
            medicationHeld: formState.medicationHeld.trim(),
            patientAppearances: formState.patientAppearances.trim(),
            locationTypeValue: __values2GqlValueInputSingle(formState.locationTypeValues),
            images: _.map(formState.images, (image): VisitImageInput => ({
              id: !image.id.startsWith('*') ? image.id : undefined,
              title: image.title,
              imageFile: image.file ?
                `${image.name}/${image.url}` :
                undefined
            })),
            useLatestPatientSignature: formState.useLatestPatientSignature,
            signature: (!formState.useLatestPatientSignature && formState.signature?.dataUrl) ? {
              file: `signature.png/${formState.signature.dataUrl}`
            } : undefined
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



function __values2GqlValueInputSingle(values: types.IValue[]): VisitDataValueInput | null {
  const checkedValue = _.find(values, value => value.isChecked);
  return checkedValue ? {
    value: checkedValue.name,
    description: checkedValue.description
  } : null;
}
