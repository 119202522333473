import React from 'react';

import {
  enumCache,
  fields,
  genEnumSelectFieldComponent
} from 'modules/layer2';

import i18next from 'i18next';



function useQuery(): fields.IUseGetEnumValuesTuple<enumCache.IShiftName, enumCache.IShift> {
  const [values, setValues] = React.useState<enumCache.IShift[]>([]);

  React.useEffect(
    () => {
      const sub = enumCache.subjAllEnums.subscribe(data =>
        setValues(data.shifts));
      return () => sub.unsubscribe();
    },
    []
  );

  return {
    loading: values.length === 0,
    data: {
      enumValues: values
    }
  };
}



export const ShiftSelectField = genEnumSelectFieldComponent<enumCache.IShiftName, enumCache.IShift>(
  useQuery,
  () => i18next.t('visits:shift')
);
