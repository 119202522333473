import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  genDateFieldControllerComponent,
  selectFields
} from 'modules';

import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from '../types';



const DateFieldController = genDateFieldControllerComponent<types.IFormState>();
const ProviderSelectFieldController = selectFields.genProviderSelectFieldControllerComponent<types.IFormState>();



export interface IIntakeSectionProps {
  formControl: Control<types.IFormState>;
  isDisabled: boolean;
}

export const IntakeSection: React.FC<IIntakeSectionProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('patients:intake')}
      </Typography>

      <Box mt={4}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={3}>
            <DateFieldController
              formControl={props.formControl}
              fieldKey="socDate"
              label={t('patients:socDate')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <DateFieldController
              formControl={props.formControl}
              fieldKey="referralDate"
              label={t('patients:referralDate')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={6} />

          <Grid
            item
            md={3}>
            <ProviderSelectFieldController
              formControl={props.formControl}
              fieldKey="primaryProviderId"
              label={t('patients:primaryProvider')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <ProviderSelectFieldController
              formControl={props.formControl}
              fieldKey="secondaryProviderId"
              label={t('patients:secondaryProvider')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <ProviderSelectFieldController
              formControl={props.formControl}
              fieldKey="referringProviderId"
              label={t('patients:referringProvider')}
              isDisabled={props.isDisabled} />
          </Grid>

        </Grid>

      </Box>

    </Box>
  );
};
