import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  SkeletonRows,
  ValueWithLabel
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



export interface IAddressSectionProps {
  patientId: string;
}

export const AddressSection: React.FC<IAddressSectionProps> = props => {
  const {t} = useTranslation();
  const queryTuple = dataService.useQuery(props.patientId);



  return (
    <Box>
      <Box mb={3}>
        <Typography variant="h3">
          {t('patients:address')}
        </Typography>
      </Box>

      {queryTuple.loading ? (
        <SkeletonRows numRows={2} />

      ) : (
        <Grid container>
          <ValueWithLabel
            label={t('address')}
            value={queryTuple.data.address} />

          <ValueWithLabel
            label={t('locationNote')}
            value={queryTuple.data.locationNote} />
        </Grid>
      )}
    </Box>
  );
};
