import React from 'react';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import {
  Checkbox,
  FormControlLabel,
  Radio
} from '@mui/material';



export interface ICheckboxControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isDisabled?: boolean;
  label?: string;
  displayType?: 'check' | 'radio';
}

export function genCheckboxControllerComponent<TFormState extends FieldValues>() {
  const CheckboxController: React.FC<ICheckboxControllerProps<TFormState>> = props => {
    const displayType = props.displayType ?? 'check';

    return (
      <Controller
        name={props.fieldKey}
        control={props.formControl}
        render={({field}) => {
          const elField = displayType === 'check' ? (
            <Checkbox
              checked={field.value}
              value={field.value}
              onChange={field.onChange}
              color="primary"
              disabled={props.isDisabled} />

          ) : (
            <Radio
              checked={field.value}
              value={field.value}
              onChange={field.onChange}
              color="primary"
              disabled={props.isDisabled} />
          );

          return props.label ? (
            <FormControlLabel
              control={elField}
              label={props.label} />
          ) : elField;
        }} />
    );
  };

  return CheckboxController;
}
