import { IColumnDescription } from 'modules';



// TODO incorporate useTranslation()
export const columnDescriptions: IColumnDescription[] = [
  {
    id: 'firstName',
    label: "First Name",
    isRequired: true
  },
  {
    id: 'lastName',
    label: "Last Name",
    isRequired: true
  },
  {
    id: 'patientStatusName',
    label: "Patient Status",
    enumValues: [
      'pending',
      'newProcessing',
      'admitReady',
      'admitted',
      'notAdmitted'
    ],
    isRequired: true
  },
  {
    id: 'birthdate',
    label: "Birthdate",
    formatType: 'date'
  },
  {
    id: 'genderName',
    label: "Gender",
    enumValues: [
      'male',
      'female',
      'nonBinary'
    ]
  },
  {
    id: 'medicareNumber',
    label: "Medicare #"
  },
  {
    id: 'medicaidNumber',
    label: "Medicaid #"
  },
  {
    id: 'emrNumber',
    label: "EMR #"
  },
  {
    id: 'ssn',
    label: "SSN",
    formatType: 'ssn'
  },
  {
    id: 'address',
    label: "Address"
  },
  {
    id: 'locationNote',
    label: "Location Note"
  },
  {
    id: 'primaryPhone',
    label: "Primary Phone",
    formatType: 'phone'
  },
  {
    id: 'secondaryPhone',
    label: "Secondary Phone",
    formatType: 'phone'
  },
  {
    id: 'socDate',
    label: "SOC Date",
    formatType: 'date'
  },
  {
    id: 'referralDate',
    label: "Referral Date",
    formatType: 'date'
  },
  {
    id: 'emergencyName',
    label: "Emergency Contact Full Name"
  },
  {
    id: 'emergencyRelationship',
    label: "Emergency Contact Relationship"
  },
  {
    id: 'emergencyPhone',
    label: "Emergency Contact Phone",
    formatType: 'phone'
  },
  {
    id: 'emergencyAddress',
    label: "Emergency Contact Address"
  }
];
