import React from 'react';
import _ from 'lodash';

import {
  Box,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import {
  useFieldArray,
  UseFormReturn
} from 'react-hook-form';
import {
  SectionHeaderBox,
  fields,
  RowBox,
  CellBox
} from 'modules';

import { Delete } from '@mui/icons-material';
import * as types from '../types';
import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';
import * as AnswerOptionList from './AnswerOptionList/AnswerOptionList';



const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();



export interface IProps {
  form: UseFormReturn<types.IFormState>;
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();

  const questionFields = useFieldArray({
    control: props.form.control,
    name: 'quizPreset.questions',
    keyName: '_key'
  });



  function handleClickAddQuestion() {
    questionFields.append({
      _key: `*${uuid.v4()}`,
      description: "",
      answerOptions: []
    });
  }



  function handleClickDeleteQuestion(index: number) {
    questionFields.remove(index);
  }



  return (
    <Box mb={3}>
      <SectionHeaderBox>
        <Typography variant="h2">
          {t('requirements:quiz.content')}
        </Typography>

        <Button
          onClick={handleClickAddQuestion}>
          {t('requirements:quiz.addQuestion')}
        </Button>
      </SectionHeaderBox>

      {questionFields.fields.length === 0 && (
        <Typography>
          {t('requirements:quiz.noQuestions')}
        </Typography>
      )}

      {_.map(questionFields.fields, (field, index) => (
        <Box
          key={field._key}
          mb={1}>

          <RowBox>
            <CellBox>
              <TextFieldController
                label={t('requirements:quiz.questionDescription')}
                formControl={props.form.control}
                fieldKey={`quizPreset.questions.${index}.description`}
                isRequired />
            </CellBox>

            <IconButton
              onClick={() => handleClickDeleteQuestion(index)}>
              <Delete
                color="error" />
            </IconButton>
          </RowBox>

          <AnswerOptionList.Comp
            form={props.form}
            questionIndex={index} />

        </Box>
      ))}
    </Box>
  );
};
