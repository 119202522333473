import React from 'react';

import { authService } from 'modules';



export interface IData {
  setupUri: string;
}

export interface ITuple {
  isLoading: boolean;
  data: IData;
}

export function useGetSetupCode() {
  const [tuple, setTuple] = React.useState<ITuple>({
    isLoading: true,
    data: {
      setupUri: ''
    }
  });



  React.useEffect(
    () => {
      (async () => {
        const email = await authService.getEmail();
        const setupCode = await authService.setUpTotp();
        const appName = `PCS-${process.env.REACT_APP_ENV_NAME}`;
        const setupUri = `otpauth://totp/${appName}:${email}?secret=${setupCode}&issuer=${appName}`;
        setTuple({
          isLoading: false,
          data: {
            setupUri
          }
        });
      })();
    },
    []
  );



  return tuple;
}



export interface IVerifyTotpTokenTuple {
  isLoading: boolean;
}

export function useVerifyTotpToken() {
  const [tuple, setTuple] = React.useState<IVerifyTotpTokenTuple>({
    isLoading: false
  });



  async function fnRun(code: string) {
    setTuple({isLoading: true});
    await authService.verifyTotpToken(code);
    setTuple({isLoading: false});
  }



  return [
    fnRun,
    tuple
  ] as const;
}
