import React from 'react';

import {
  SiteUserTypeName,
  useBaseNavigationBarQuery as useBaseQuery
} from 'generated/graphql';



export type IUserTypeName =
  'administrator' |
  'employee';

export interface IData {
  fullName: string;
  typeName: IUserTypeName | '';
  profilePictureFileKey: string;
}

export function useBaseNavigationBarQuery() {
  const tuple = useBaseQuery();

  return React.useMemo(
    () => {
      const gqlTypeName = tuple.data?.loggedInUser?.typeName;
      const gqlTypeSpecificUser = tuple.data?.loggedInUser?.typeSpecificUser;
      const profilePictureFileKey = (
        gqlTypeSpecificUser?.__typename === 'Administrator' ||
        gqlTypeSpecificUser?.__typename === 'Employee'
      ) ?
        (gqlTypeSpecificUser.profilePictureFile?.key ?? '') :
        '';

      const data: IData = {
        fullName: tuple.data?.loggedInUser?.fullName ?? "",
        typeName: ({
          'administrator': 'administrator',
          'employee': 'employee',
          'system': '',
          '': ''
        } as Record<SiteUserTypeName | '', IUserTypeName | ''>)[gqlTypeName ?? ''],
        profilePictureFileKey
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
