import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';



const ContainerBox = styled(Box)(() => ({
  width: 100,
  height: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const ImageImg = styled('img')(() => ({
  display: 'block',
  width: '100%'
}));



export interface IImageProps {
  url: string;
}

export const Image: React.FC<IImageProps> = props => {
  return (
    <ContainerBox>
      <ImageImg src={props.url} />
    </ContainerBox>
  );
};
