import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  paletteColors,
  genTableComponent,
  IRow,
  ConfirmationModal,
  errorUtil,
  RowOptionsButton,
  UserStatusChip
} from 'modules';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { useNavigate } from 'react-router-dom';



const __columnIdList = [
  'firstName',
  'lastName',
  'email',
  'employeeId',
  'status',
  'phone',
  'options'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();



export const AdministratorsActivity: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const queryTuple = dataService.useAdministratorsActivityQuery();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveAdministratorMutation();

  const isLoading = React.useMemo(
    () =>
      queryTuple.loading ||
      archiveMutationTuple.loading,
    [
      queryTuple.loading,
      archiveMutationTuple.loading
    ]
  );

  const [administratorIdToDelete, setAdministratorIdToDelete] = React.useState('');



  React.useEffect(
    () => {
      if (queryTuple.error) {
        alert(queryTuple.error?.message ?? t('errors.unknown'));
      }
    },
    [queryTuple.error]
  );



  async function handleConfirmDeleteAdministrator() {
    setAdministratorIdToDelete('');
    await errorUtil.errorWrap(async () => {
      await fnArchive(administratorIdToDelete);
    });
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        navigationBarSelectedLinkId="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.administrators,
          title: t('adminAdministrators:administrators'),
          elRightButtons: [
            (
              <Button
                key="create"
                color="primary"
                variant="contained"
                onClick={() => navigate('/admin/administrators/create')}>
                {t('adminAdministrators:addNewAdministrator')}
              </Button>
            )
          ]
        }}>

        <Table
          columns={[
            {
              id: 'firstName',
              label: t('firstName')
            },
            {
              id: 'lastName',
              label: t('lastName')
            },
            {
              id: 'email',
              label: t('adminAdministrators:email')
            },
            {
              id: 'employeeId',
              label: t('adminAdministrators:employeeId')
            },
            {
              id: 'status',
              label: t('adminAdministrators:status')
            },
            {
              id: 'phone',
              label: t('adminAdministrators:phone')
            },
            {
              id: 'options',
              label: ""
            }
          ]}
          rows={_.map(queryTuple.data.administrators, (administrator): IRow<IColumnId> => ({
            id: administrator.id,
            columnId2Value: {
              firstName: {
                type: 'string',
                label: administrator.firstName,
                toRoute: `/admin/administrators/profile/${administrator.id}`
              },
              lastName: {
                type: 'string',
                label: administrator.lastName,
                toRoute: `/admin/administrators/profile/${administrator.id}`
              },
              email: {
                type: 'string',
                label: administrator.email
              },
              employeeId: {
                type: 'string',
                label: administrator.employeeId
              },
              status: {
                type: 'component',
                label: (
                  <UserStatusChip
                    status={administrator.status} />
                ),
                sortableValue: administrator.status
              },
              phone: {
                type: 'string',
                label: administrator.phone
              },
              options: {
                type: 'component',
                label: (
                  <RowOptionsButton
                    onClickDelete={() => setAdministratorIdToDelete(administrator.id)} />
                ),
                sortableValue: ''
              }
            }
          }))}
          initSortOptions={{
            sortByColumnId: 'lastName',
            sortDirection: 'ascending'
          }} />

      </BaseActivityTemplate>

      <ConfirmationModal
        isOpen={!!administratorIdToDelete}
        title={t('adminAdministrators:deleteAdministrator')}
        message={t('adminAdministrators:deleteAdministratorConfirmationModal')}
        acceptButtonLabel={t('delete')}
        onAccept={handleConfirmDeleteAdministrator}
        onCancel={() => setAdministratorIdToDelete('')} />
    </>
  );
};
