import { IColumnDescription } from 'modules';



// TODO incorporate useTranslation()
export const columnDescriptions: IColumnDescription[] = [
  {
    id: 'firstName',
    label: "First Name",
    isRequired: true
  },
  {
    id: 'lastName',
    label: "Last Name",
    isRequired: true
  },
  {
    id: 'hireStatus',
    label: "Hire Status",
    enumValues: [
      'inactive',
      'hired',
      'suspended'
    ],
    isRequired: true
  },
  {
    id: 'employeeId',
    label: "Employee ID",
    comment: "Must be unique across all other administrators and employees in the system."
  },
  {
    id: 'birthdate',
    label: "Birthdate",
    formatType: 'date',
    comment: "Cannot be in the future."
  },
  {
    id: 'discipline',
    label: "Discipline",
    enumValues: [
      'skilledNursing',
      'physicalTherapy',
      'occupationalTherapy',
      'speechTherapy',
      'socialWorker',
      'homeHealthAide'
    ]
  },
  {
    id: 'address',
    label: "Address"
  },
  {
    id: 'primaryPhone',
    label: "Primary Phone",
    formatType: 'phone'
  },
  {
    id: 'secondaryPhone',
    label: "Secondary Phone",
    formatType: 'phone'
  },
  // {
  //   id: 'supervisorEmployeeId',
  //   label: "Supervisor",
  //   formatType: 'employeeId'
  // },
  // {
  //   id: 'altSupervisorEmployeeId',
  //   label: "Alt. Supervisor",
  //   formatType: 'employeeId'
  // },
  {
    id: 'payRate',
    label: "Pay Rate",
    formatType: 'usdAmount'
  },
  {
    id: 'licenseCheck',
    label: "License Check"
  },
  {
    id: 'fraudCheck',
    label: "Fraud Check"
  }
];
