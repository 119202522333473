import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useViewVisitActivityQuery as useBaseQuery
} from 'generated/graphql';
import {
  dateUtil,
  enumCache
} from 'modules';

import { useTranslation } from 'react-i18next';



export interface ITask {
  id: string;
  title: string;
  description: string;
}

export interface ITemplate {
  id: string;
  displayName: string;
  route: string;
}

export interface IEpisode {
  id: string;
  ranking: number;
  route: string;
}

export interface IPatient {
  id: string;
  name: string;
  route: string;
}

export interface IData {
  canEditVisits: boolean;
  patient: IPatient;
  episode: IEpisode;
  template: ITemplate;
  visitStatusName: enumCache.IVisitStatusName;
  displayName: string;
  visitCategoryName: enumCache.IVisitCategoryName;
  formattedDate: string;
  shiftName: enumCache.IShiftName;
  note: string;
  location: string;
  tasks: ITask[];
  clockInTimestamp: string;
  clockOutTimestamp: string;
  completedTimestamp: string;
  missedTimestamp: string;
  missedVisitReason: string;
  afterVisitDataId: string;
}

export function useQuery(id: string) {
  const {t} = useTranslation();

  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlVisit = tuple.data?.visit;
      const visitStatusName = gqlVisit?.visitStatus.name ?? 'missed';

      const gqlTemplate = gqlVisit?.template;
      const templateId = gqlTemplate?.id ?? '';

      const gqlEpisode = gqlVisit?.episode;
      const episodeId = gqlEpisode?.id ?? '';
      const episodeStatusName = gqlEpisode?.status.name ?? 'pending';

      const gqlPatient = gqlEpisode?.patient;
      const patientId = gqlPatient?.id ?? '';

      const data: IData = {
        canEditVisits: episodeStatusName === 'active' && visitStatusName === 'open' && gqlEpisode?.endTimestamp && moment().isBefore(moment(gqlEpisode.endTimestamp, moment.ISO_8601)),
        patient: {
          id: patientId,
          name: `${gqlPatient?.lastName}, ${gqlPatient?.firstName}`,
          route: `/admin/patients/profile/${patientId}`
        },
        episode: {
          id: episodeId,
          ranking: gqlEpisode?.ranking ?? 0,
          route: `/admin/episodes/profile/${episodeId}`
        },
        template: {
          id: templateId,
          displayName: gqlTemplate?.displayName ?? "",
          route: `/admin/visitTemplates/view/${templateId}`
        },
        visitStatusName,
        displayName: gqlVisit?.displayName ?? "",
        visitCategoryName: gqlVisit?.visitCategory.name ?? 'form485',
        formattedDate: gqlVisit?.date ?
          dateUtil.formatDateWithTimeZone(gqlVisit.date) :
          "",
        shiftName: gqlVisit?.shift.name ?? 'am',
        note: gqlVisit?.note ?? "",
        location: gqlVisit?.location ?? "",
        tasks: _.map(gqlVisit?.tasks ?? [], (gqlTask): ITask => ({
          id: gqlTask.id,
          title: gqlTask.title,
          description: gqlTask.description
        })),
        clockInTimestamp: gqlVisit?.clockInTimestamp ?
          moment(gqlVisit.clockInTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat) :
          t('noValue'),
        clockOutTimestamp: gqlVisit?.clockOutTimestamp ?
          `${moment(gqlVisit.clockOutTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat)} ${gqlVisit?.wasAutoClockedOut ? t('visits:automatic') : ''}` :
          t('noValue'),
        completedTimestamp: gqlVisit?.completedTimestamp ?
          moment(gqlVisit.completedTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat) :
          t('noValue'),
        missedTimestamp: gqlVisit?.missedTimestamp ?
          moment(gqlVisit.missedTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat) :
          t('noValue'),
        missedVisitReason: gqlVisit?.missedVisitReason ?
          t(`visits:missedVisitReasonValues.${gqlVisit.missedVisitReason.name}`) :
          t('noValue'),
        afterVisitDataId: gqlVisit?.afterVisitData?.id ?? ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
