import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
  EpisodeDataValueFieldsFragment,
  useEpisodeProfileActivityDetailsTabQuery as useBaseQuery
} from 'generated/graphql';

import * as types from './types';
import { enumCache } from 'modules';



const __timestampFormat = 'YYYY-MM-DD HH:mm:ss';

export interface IProvider {
  id: string;
  fullName: string;
}

export interface IDiagnosis {
  id: string;
  displayName: string;
  icd10Code: string;
}

export interface ISurgicalProcedure {
  id: string;
  code: string;
  displayName: string;
  procedureDateWithTz: string;
}

export interface IEnumValue {
  name: string;
  displayName: string;
}

export interface IData {
  statusName: enumCache.IEpisodeStatusName;
  inactiveNote: string;
  startTimestamp: string;
  endTimestamp: string;
  timeZone: string;
  transferTimestamp: string;
  primaryProvider: IProvider | null;
  primaryDiagnosis: IDiagnosis | null;
  otherDiagnoses: IDiagnosis[];
  surgicalProcedures: ISurgicalProcedure[];
  prognosis: string;
  diet: string;
  exerciseActivity: string;
  riskAssessment: types.IEpisodeDataValue[];
  mentalStatus: types.IEpisodeDataValue[];
  neurological: types.IEpisodeDataValue[];
  psychosocial: types.IEpisodeDataValue[];
  dme: types.IEpisodeDataValue[];
  supplies: types.IEpisodeDataValue[];
  safetyMeasures: types.IEpisodeDataValue[];
  nutritionRequirements: types.IEpisodeDataValue[];
  functionalLimitations: types.IEpisodeDataValue[];
  activitiesPermitted: types.IEpisodeDataValue[];
  medications: types.IMedication[];
}

export function useQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );

  return React.useMemo(
    () => {
      const gqlEpisode = tuple.data?.episode;
      const gqlData = gqlEpisode?.episodeData;
      const timeZone = gqlEpisode?.timeZone ?? "";

      const data: IData = {
        statusName: gqlEpisode?.status.name ?? 'pending',
        inactiveNote: gqlEpisode?.inactiveNote ?? "",
        startTimestamp: (gqlEpisode?.startTimestamp && timeZone) ?
          moment(gqlEpisode.startTimestamp, moment.ISO_8601).tz(timeZone).format(__timestampFormat) :
          "",
        endTimestamp: (gqlEpisode?.endTimestamp && timeZone) ?
          moment(gqlEpisode.endTimestamp, moment.ISO_8601).tz(timeZone).format(__timestampFormat) :
          "",
        transferTimestamp: (gqlEpisode?.transferTimestamp && timeZone) ?
          moment(gqlEpisode.transferTimestamp, moment.ISO_8601).tz(timeZone).format(__timestampFormat) :
          "",
        timeZone,
        primaryProvider: gqlData?.primaryProvider ? {
          id: gqlData.primaryProvider.id,
          fullName: `${gqlData.primaryProvider.lastName}, ${gqlData.primaryProvider.firstName}`
        } : null,
        primaryDiagnosis: gqlData?.primaryDiagnosis ? {
          id: gqlData.primaryDiagnosis.id,
          displayName: gqlData.primaryDiagnosis.displayName,
          icd10Code: gqlData.primaryDiagnosis.icd10Code
        } : null,
        otherDiagnoses: _.map(gqlData?.otherDiagnoses ?? [], (gqlDx): IDiagnosis => ({
          id: gqlDx.id,
          displayName: gqlDx.displayName,
          icd10Code: gqlDx.icd10Code
        })),
        surgicalProcedures: _.map(gqlData?.surgicalProcedures ?? [], (gqlProcedure): ISurgicalProcedure => ({
          id: gqlProcedure.id,
          code: gqlProcedure.code,
          displayName: gqlProcedure.displayName,
          procedureDateWithTz: gqlProcedure.procedureDate ?? `,${moment.tz.guess()}`
        })),
        prognosis: gqlData?.prognosis?.value ?? '',
        diet: gqlData?.diet ?? "",
        exerciseActivity: gqlData?.exerciseActivity ?? "",
        riskAssessment: __gqlEpisodeDataValues2Client(gqlData?.riskAssessment ?? []),
        mentalStatus: __gqlEpisodeDataValues2Client(gqlData?.mentalStatus ?? []),
        neurological: __gqlEpisodeDataValues2Client(gqlData?.neurological ?? []),
        psychosocial: __gqlEpisodeDataValues2Client(gqlData?.psychosocial ?? []),
        dme: __gqlEpisodeDataValues2Client(gqlData?.dme ?? []),
        supplies: __gqlEpisodeDataValues2Client(gqlData?.supplies ?? []),
        safetyMeasures: __gqlEpisodeDataValues2Client(gqlData?.safetyMeasures ?? []),
        nutritionRequirements: __gqlEpisodeDataValues2Client(gqlData?.nutritionRequirements ?? []),
        functionalLimitations: __gqlEpisodeDataValues2Client(gqlData?.functionalLimitations ?? []),
        activitiesPermitted: __gqlEpisodeDataValues2Client(gqlData?.activitiesPermitted ?? []),
        medications: _.map(gqlData?.medications ?? [], (gqlMedication): types.IMedication => ({
          id: gqlMedication.id,
          name: gqlMedication.name,
          dosage: gqlMedication.dosage,
          instructions: gqlMedication.instructions
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



interface IGqlEnumValue extends Record<string, unknown> {
  name: string;
  displayName: string;
}

function __genEnumValue(gqlValue: IGqlEnumValue | null | undefined): IEnumValue | null {
  return gqlValue ? {
    name: gqlValue.name,
    displayName: gqlValue?.displayName
  } : null;
}



function __gqlEpisodeDataValues2Client(gqlEpisodeDataValues: EpisodeDataValueFieldsFragment[]) {
  return _.map(gqlEpisodeDataValues, (gqlValue): types.IEpisodeDataValue => ({
    value: gqlValue.value,
    description: gqlValue.description ?? undefined,
    childValues: gqlValue.childValues ?? []
  }));
}
