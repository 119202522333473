import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useVisitAssignedEmployeesSectionQuery
} from 'generated/graphql';

import { dateUtil } from 'modules/layer0';



export interface IAssignedEmployee {
  id: string;
  fullName: string;
  clockInTimestamp: string;
  clockOutTimestamp: string;
  wasAutoClockedOut: boolean;
  route: string;
}

export interface IData {
  assignedEmployees: IAssignedEmployee[];
}

export function useQuery(visitId: string) {
  const tuple = useVisitAssignedEmployeesSectionQuery({
    variables: {
      visitId
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlVisit = tuple.data?.visit;
      const data: IData = {
        assignedEmployees: _.map(gqlVisit?.assignedEmployees ?? [], (gqlAssignedEmployee): IAssignedEmployee => ({
          id: gqlAssignedEmployee.id,
          fullName: `${gqlAssignedEmployee.employee.lastName}, ${gqlAssignedEmployee.employee.firstName}`,
          clockInTimestamp: gqlAssignedEmployee.clockInTimestamp ?
            moment(gqlAssignedEmployee.clockInTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat) :
            "",
          clockOutTimestamp: gqlAssignedEmployee.clockOutTimestamp ?
            moment(gqlAssignedEmployee.clockOutTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat) :
            "",
          wasAutoClockedOut: gqlAssignedEmployee.wasAutoClockedOut,
          route: `/admin/employees/profile/${gqlAssignedEmployee.employee.id}`
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
