import React from 'react';

import {
  useProviderProfileActivityQuery as useBaseQuery
} from 'generated/graphql';

import { useTranslation } from 'react-i18next';



export interface IData {
  firstName: string;
  lastName: string;
  fullName: string;
  primaryPhone: string;
  secondaryPhone: string;
  faxNumber: string;
}

export function useProviderProfileActivityQuery(providerId: string) {
  const {t} = useTranslation();
  const tuple = useBaseQuery({
    variables: {
      providerId
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlProvider = tuple.data?.provider;
      const data: IData = {
        firstName: gqlProvider?.firstName || t('noValue'),
        lastName: gqlProvider?.lastName || t('noValue'),
        fullName: `${gqlProvider?.firstName ?? ""} ${gqlProvider?.lastName ?? ""}`.trim(),
        primaryPhone: gqlProvider?.primaryPhone || t('noValue'),
        secondaryPhone: gqlProvider?.secondaryPhone || t('noValue'),
        faxNumber: gqlProvider?.faxNumber || t('noValue')
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
