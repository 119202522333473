import React from 'react';

import { styled } from '@mui/material/styles';
import { Observable } from 'rxjs';



const Canvas = styled('canvas')(() => ({
  border: '1px solid #000',
  backgroundColor: '#ccc'
}));



export interface ISignatureCapturePadProps {
  obsGetImage: Observable<void>;
  onCaptureImage: (dataUrl: string) => void;
}

export const SignatureCapturePad: React.FC<ISignatureCapturePadProps> = props => {
  const refCanvas = React.useRef<HTMLCanvasElement>(null);
  const refContext = React.useRef<CanvasRenderingContext2D | null>(null);
  const refIsPointerDown = React.useRef(false);



  React.useEffect(
    () => {
      const elCanvas = refCanvas.current;
      if (elCanvas) {
        refContext.current = elCanvas.getContext('2d');

        elCanvas.addEventListener('pointerdown', handlePointerDown);
        elCanvas.addEventListener('pointermove', handlePointerMove);
        elCanvas.addEventListener('pointerup', handlePointerUp);
      }

      return () => {
        const elCanvas = refCanvas.current;
        if (elCanvas) {
          elCanvas.removeEventListener('pointerdown', handlePointerDown);
          elCanvas.removeEventListener('pointermove', handlePointerMove);
          elCanvas.removeEventListener('pointerup', handlePointerUp);
        }
      };
    },
    []
  );



  React.useEffect(
    () => {
      const sub = props.obsGetImage.subscribe(() => {
        const elCanvas = refCanvas.current;
        if (elCanvas) {
          props.onCaptureImage(elCanvas.toDataURL());
        }
      });

      return () => sub.unsubscribe();
    },
    [
      props.obsGetImage,
      props.onCaptureImage
    ]
  );



  function handlePointerDown(event: PointerEvent) {
    (event.target as HTMLCanvasElement).setPointerCapture(event.pointerId);
    refIsPointerDown.current = true;

    const context = refContext.current;
    if (context) {
      context.beginPath();
      context.lineWidth = 1;
      context.moveTo(event.offsetX, event.offsetY);
    }
  }



  function handlePointerMove(event: PointerEvent) {
    if (refIsPointerDown.current) {
      const context = refContext.current;
      if (context) {
        context.lineTo(event.offsetX, event.offsetY);
        context.stroke();
      }
    }
  }



  function handlePointerUp(event: PointerEvent) {
    (event.target as HTMLCanvasElement).releasePointerCapture(event.pointerId);
    refIsPointerDown.current = false;

    const context = refContext.current;
    if (context) {
      context.stroke();
    }
  }



  return (
    <Canvas
      ref={refCanvas}
      width={700}
      height={300}>
    </Canvas>
  );
};
