import React from 'react';

import {
  useUserActivityQuery as useBaseQuery
} from 'generated/graphql';



export interface IData {
  redirectRoute: string;
}

export function useUserActivityQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    }
  });

  return React.useMemo(
    () => {
      let routeRoot = '';
      const typeName = tuple.data?.siteUserLite?.typeName;
      if (typeName === 'administrator') {
        routeRoot = '/admin/administrators/profile/';
      } else if (typeName === 'employee') {
        routeRoot = '/admin/employees/profile/';
      }

      const data: IData = {
        redirectRoute: routeRoot ?
          `${routeRoot}${id}` :
          ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
