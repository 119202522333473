import React from 'react';
import _ from 'lodash';

import {
  useAdminNavigationBarQuery as useBaseQuery
} from 'generated/graphql';

import { enumCache } from 'modules';



export interface IData {
  permissionCategories: Set<enumCache.IPermissionCategoryName>;
}

export function useQuery() {
  const tuple = useBaseQuery();

  return React.useMemo(
    () => {
      const gqlCategories = tuple.data?.loggedInUser?.typeSpecificUser.permissionCategories ?? [];

      const data: IData = {
        permissionCategories: new Set(_.map(gqlCategories, gqlCategory => gqlCategory.name))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
