import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';

import { Control } from 'react-hook-form';
import * as types from '../types';
import { useTranslation } from 'react-i18next';
import { genTextFieldControllerComponent } from 'modules';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();



export interface IStatusCheckSectionProps {
  formControl: Control<types.IFormState>;
  isDisabled: boolean;
}

export const StatusCheckSection: React.FC<IStatusCheckSectionProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('adminEmployees:statusCheck')}
      </Typography>

      <Box mt={4}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={3}>

            <TextFieldController
              formControl={props.formControl}
              fieldKey="licenseCheck"
              label={t('adminEmployees:licenseCheck')}
              isDisabled={props.isDisabled} />

          </Grid>

          <Grid
            item
            md={3}>

            <TextFieldController
              formControl={props.formControl}
              fieldKey="fraudCheck"
              label={t('adminEmployees:fraudCheck')}
              isDisabled={props.isDisabled} />

          </Grid>

        </Grid>

      </Box>

    </Box>
  );
};
