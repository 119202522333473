import React from 'react';

import {
  useViewPharmacyActivityQuery
} from 'generated/graphql';

import { useTranslation } from 'react-i18next';



export interface IData {
  name: string;
  primaryPhone: string;
  secondaryPhone: string;
  faxNumber: string;
  address: string;
}

export function useQuery(id: string) {
  const {t} = useTranslation();
  const tuple = useViewPharmacyActivityQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlPharmacy = tuple.data?.pharmacy;
      const data: IData = {
        name: gqlPharmacy?.name ?? "",
        primaryPhone: gqlPharmacy?.primaryPhone ?? "",
        secondaryPhone: gqlPharmacy?.secondaryPhone ?? "",
        faxNumber: gqlPharmacy?.faxNumber ?? "",
        address: gqlPharmacy?.address ?? ""
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
