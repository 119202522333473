import React from 'react';
import _ from 'lodash';

import {
  useViewTaskSetTemplateActivityQuery
} from 'generated/graphql';



export interface ITask {
  id: string;
  title: string;
  description: string;
}

export interface IData {
  displayName: string;
  tasks: ITask[];
}

export function useQuery(id: string) {
  const tuple = useViewTaskSetTemplateActivityQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () =>{
      const gqlTemplate = tuple.data?.taskSetTemplate;
      const data: IData = {
        displayName: gqlTemplate?.displayName ?? "",
        tasks: _.map(gqlTemplate?.tasks ?? [], (gqlTask): ITask => ({
          id: gqlTask.id,
          title: gqlTask.title,
          description: gqlTask.description
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
