import React from 'react';

import styles from './ResponsiveImage.module.css';



export interface IResponsiveImageProps {
  urlSet?: string[];
  svgUrl?: string;
  alt?: string;
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  onClick?: () => void;
}

export const ResponsiveImage: React.FC<IResponsiveImageProps> = props => {
  const [url, url2x, url3x] = props.urlSet ?? [];



  return (
    <img
      className={styles.image}
      src={props.svgUrl ?? url}
      srcSet={props.urlSet ?
        `${url} 1x, ${url2x} 2x, ${url3x} 3x` :
        undefined}
      alt={props.alt}
      style={{
        width: props.width,
        height: props.height,
        maxWidth: props.maxWidth,
        maxHeight: props.maxHeight
      }}
      onClick={() =>{
        if (props.onClick) {
          props.onClick();
        }
      }} />
  );
};
