import React from 'react';

import {
  BaseModal,
  fields,
  Form,
  LoadingOverlay
} from 'modules';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';



interface IFormData {
  displayName: string;
}

const TextFieldController = fields.genTextFieldControllerComponent<IFormData>();



export interface IDuplicateModalProps {
  isLoading: boolean;
  onAccept: (displayName: string) => void;
  onCancel: () => void;
}

export const DuplicateModal: React.FC<IDuplicateModalProps> = props => {
  const {t} = useTranslation();

  const form = useForm<IFormData>({
    defaultValues: {
      displayName: ""
    }
  });



  function handleSubmit(formData: IFormData) {
    props.onAccept(formData.displayName);
  }



  return (
    <BaseModal
      title={t('visits:duplicateVisitTemplate')}
      isOpen
      showButtons
      onClose={props.onCancel}
      isLoading={props.isLoading}>

      {buttons => (
        <Form
          onSubmit={form.handleSubmit(handleSubmit)}>

          <LoadingOverlay
            active={props.isLoading} />

          <TextFieldController
            formControl={form.control}
            fieldKey="displayName"
            label={t('visits:newTemplateName')}
            isRequired
            isDisabled={props.isLoading} />

          {buttons}

        </Form>
      )}

    </BaseModal>
  );
};
