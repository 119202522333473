import React from 'react';
import _ from 'lodash';

import {
  useAdministratorsActivityQuery as useBaseQuery,
  useArchiveAdministratorMutation as useBaseArchiveAdministratorMutation
} from 'generated/graphql';

import { IUserStatus } from 'modules';



export interface IAdministrator {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  employeeId: string;
  status: IUserStatus;
  phone: string;
}

export interface IData {
  administrators: IAdministrator[];
}

export function useAdministratorsActivityQuery() {
  const tuple = useBaseQuery({fetchPolicy: 'no-cache'});

  return React.useMemo(
    () => {
      const data: IData = {
        administrators: _.map(tuple.data?.administrators ?? [], (gqlUser): IAdministrator => {
          let status: IUserStatus = 'inviteRequired';
          if (!gqlUser.siteUserFull && gqlUser.invite && !gqlUser.invite.isExpired) {
            status = 'invitePending';
          } else if (!gqlUser.siteUserFull && gqlUser.invite?.isExpired) {
            status = 'inviteExpired';
          } else if (gqlUser.siteUserFull && !gqlUser.siteUserFull.isDisabled) {
            status = 'active';
          } else if (gqlUser.siteUserFull && gqlUser.siteUserFull.isDisabled) {
            status = 'disabled';
          }

          return {
            id: gqlUser.id,
            firstName: gqlUser.firstName,
            lastName: gqlUser.lastName,
            email: gqlUser.siteUserFull?.email ?? "",
            employeeId: gqlUser.employeeId,
            status,
            phone: gqlUser.primaryPhone
          };
        })
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useArchiveAdministratorMutation() {
  const [fnMutate, tuple] = useBaseArchiveAdministratorMutation();

  const fnWrappedMutate = React.useCallback(
    async (administratorId: string) => {
      await fnMutate({
        variables: {
          id: administratorId
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
