import React from 'react';
import _ from 'lodash';

import {
  useTaskSetTemplatesActivityQuery
} from 'generated/graphql';



export interface ITask {
  id: string;
  title: string;
}

export interface ITaskSetTemplate {
  id: string;
  route: string;
  displayName: string;
  tasks: ITask[];
}

export interface IData {
  taskSetTemplates: ITaskSetTemplate[];
}

export function useQuery() {
  const tuple = useTaskSetTemplatesActivityQuery({
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const data: IData = {
        taskSetTemplates: _.map(tuple.data?.taskSetTemplates ?? [], (gqlTemplate): ITaskSetTemplate => ({
          id: gqlTemplate.id,
          route: `/admin/taskSetTemplates/view/${gqlTemplate.id}`,
          displayName: gqlTemplate.displayName,
          tasks: _.map(gqlTemplate.tasks, (gqlTask): ITask => ({
            id: gqlTask.id,
            title: gqlTask.title
          }))
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
