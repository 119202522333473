import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  SkeletonRows,
  SsnValue,
  Disclaimer,
  ValueWithLabel
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



export interface IDetailsSectionProps {
  patientId: string;
  showSsn?: boolean;
}

export const DetailsSection: React.FC<IDetailsSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.patientId);



  return (
    <Box>
      <Box mb={3}>
        <Typography variant="h3">
          {t('patients:details')}
        </Typography>
      </Box>

      {queryTuple.loading ? (
        <SkeletonRows numRows={props.showSsn ? 10 : 9} />

      ) : (
        <Grid container>
          <ValueWithLabel
            label={t('firstName')}
            value={queryTuple.data.firstName} />

          <ValueWithLabel
            label={t('lastName')}
            value={queryTuple.data.lastName} />

          <ValueWithLabel
            label={t('gender')}
            value={queryTuple.data.gender} />

          <ValueWithLabel
            label={t('birthdate')}
            value={queryTuple.data.birthdate} />

          <Grid
            item
            md={12}>
            <Disclaimer.Comp>
              {t('medicareMedicaidDisclaimer')}
            </Disclaimer.Comp>
          </Grid>

          <ValueWithLabel
            label={t('patients:medicareNumber')}
            value={queryTuple.data.medicareNumber} />

          <ValueWithLabel
            label={t('patients:medicaidNumber')}
            value={queryTuple.data.medicaidNumber} />

          <ValueWithLabel
            label={t('patients:emrNumber')}
            value={queryTuple.data.emrNumber} />

          {props.showSsn && (
            <ValueWithLabel
              label={t('ssn')}
              value={!queryTuple.data.ssn ? "" : undefined}>
              {queryTuple.data.ssn && (
                <SsnValue value={queryTuple.data.ssn} />
              )}
            </ValueWithLabel>
          )}

          <ValueWithLabel
            label={t('note')}
            value={queryTuple.data.note} />
        </Grid>
      )}
    </Box>
  );
};
