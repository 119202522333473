import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useEmployeeProfileProfileTabAuthorizedWorkingHoursSectionQuery as useBaseQuery
} from 'generated/graphql';

import { enums } from 'modules';



export interface ITimeRange {
  startTime: string;
  endTime: string;
}

export interface IWorkingHoursEntry {
  dayOfWeek: enums.IDayOfWeek;
  timeRange: ITimeRange | null;
}

export interface IData {
  workingHoursEntries: IWorkingHoursEntry[];
}

export function useAuthorizedWorkingHoursSectionQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlEntries = tuple.data?.employee?.workingHoursEntries ?? [];

      const workingHoursEntries = _.map(gqlEntries, (gqlEntry): IWorkingHoursEntry => ({
        dayOfWeek: gqlEntry.dayOfWeek.name,
        timeRange: {
          startTime: __gqlTime2FormattedTime(gqlEntry.startTime),
          endTime: __gqlTime2FormattedTime(gqlEntry.endTime)
        }
      }));
      const entriesByDay = _.keyBy(workingHoursEntries, entry => entry.dayOfWeek);

      const data: IData = {
        workingHoursEntries: _.map(enums.daysOfWeek, (dayOfWeek): IWorkingHoursEntry =>
          entriesByDay[dayOfWeek] ?? {
            dayOfWeek,
            timeRange: null
          })
      };

      return ({
        ...tuple,
        data
      });
    },
    [tuple]
  );
}



function __gqlTime2FormattedTime(gqlTime: string) {
  const mTime = moment(`1970-01-01T${gqlTime}`, moment.ISO_8601);
  return mTime.date() === 2 ?
    "24:00" :
    mTime.format('HH:mm');
}
