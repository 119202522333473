import i18n from 'i18next';



export const daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
] as const;
export type IDayOfWeek = typeof daysOfWeek[number];

function __genDayOfWeek2DisplayName(): Record<IDayOfWeek, string> {
  return {
    sunday: i18n.t('sunday'),
    monday: i18n.t('monday'),
    tuesday: i18n.t('tuesday'),
    wednesday: i18n.t('wednesday'),
    thursday: i18n.t('thursday'),
    friday: i18n.t('friday'),
    saturday: i18n.t('saturday')
  };
}

export let dayOfWeek2DisplayName = __genDayOfWeek2DisplayName();



export function init() {
  dayOfWeek2DisplayName = __genDayOfWeek2DisplayName();
}
