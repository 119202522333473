import React from 'react';
import _ from 'lodash';

import {
  useRedirectActivityLazyQuery
} from 'generated/graphql';

import { enumCache } from 'modules';



export interface IData {
  typeName: 'administrator' | 'employee' | 'system' | '';
  permissionCategories: Set<enumCache.IPermissionCategoryName>;
  hasTotp: boolean;
}

export function useLazyQuery() {
  const [fnQuery, tuple] = useRedirectActivityLazyQuery({
    fetchPolicy: 'no-cache'
  });



  const fnWrappedQuery = React.useCallback(
    () => fnQuery(),
    [fnQuery]
  );



  const wrappedTuple = React.useMemo(
    () => {
      const gqlCategories = tuple.data?.loggedInUser?.typeSpecificUser.permissionCategories ?? [];

      const data: IData = {
        typeName: tuple.data?.loggedInUser?.typeName ?? '',
        permissionCategories: new Set(_.map(gqlCategories, gqlCategory => gqlCategory.name)),
        hasTotp: true
      };

      return {
        ...tuple,
        loading: tuple.loading,
        data
      };
    },
    [tuple]
  );



  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}
