import React from 'react';

import {
  useAdminMyProfileActivityQuery
} from 'generated/graphql';



export interface IData {
  fullName: string;
  profilePictureFileKey: string;
}

export function useQuery() {
  const tuple = useAdminMyProfileActivityQuery({fetchPolicy: 'no-cache'});



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlLoggedInUser = tuple.data?.loggedInUser;
      const gqlAdmin = gqlLoggedInUser?.typeSpecificUser.__typename === 'Administrator' ?
        gqlLoggedInUser.typeSpecificUser :
        null;

      const data: IData = {
        fullName: gqlLoggedInUser?.fullName ?? "",
        profilePictureFileKey: gqlAdmin?.profilePictureFile?.key ?? ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
