import React from 'react';
import _ from 'lodash';

import {
  Routes,
  BrowserRouter,
  Route,
  RouteProps,
  useRoutes,
  RouteObject
} from 'react-router-dom';
import {
  ThemeProvider,
  createTheme
} from '@mui/material/styles';
import {
  AdminMessengerActivity,
  EmployeeMessengerActivity
} from 'components/activities/MessengerActivity/MessengerActivity';

import { CssBaseline } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import { apolloService } from 'modules';

import { RedirectActivity } from 'components/activities/RedirectActivity/RedirectActivity';
import { LoginActivity } from 'components/activities/auth/LoginActivity/LoginActivity';
import { RegisterActivity } from 'components/activities/auth/RegisterActivity/RegisterActivity';
import { ChangePasswordActivity } from 'components/activities/auth/ChangePasswordActivity/ChangePasswordActivity';
import { LogoutActivity } from 'components/activities/auth/LogoutActivity/LogoutActivity';
import { AdministratorsActivity } from 'components/activities/admin/administrators/AdministratorsActivity/AdministratorsActivity';
import { EditAdministratorActivity } from 'components/activities/admin/administrators/EditAdministratorActivity/EditAdministratorActivity';
import { AdministratorProfileActivity } from 'components/activities/admin/administrators/AdministratorProfileActivity/AdministratorProfileActivity';
import { EditEmployeeActivity } from 'components/activities/admin/employees/EditEmployeeActivity/EditEmployeeActivity';
import { EmployeesActivity } from 'components/activities/admin/employees/EmployeesActivity/EmployeesActivity';
import { EmployeeProfileActivity } from 'components/activities/admin/employees/EmployeeProfileActivity/EmployeeProfileActivity';
import { UserActivity } from 'components/activities/admin/UserActivity/UserActivity';
import { EditPatientActivity } from 'components/activities/admin/patients/EditPatientActivity/EditPatientActivity';
import { PatientsActivity } from 'components/activities/admin/patients/PatientsActivity/PatientsActivity';
import { PatientProfileActivity } from 'components/activities/admin/patients/PatientProfileActivity/PatientProfileActivity';
import { ProvidersActivity } from 'components/activities/admin/providers/ProvidersActivity/ProvidersActivity';
import { ProviderProfileActivity } from 'components/activities/admin/providers/ProviderProfileActivity/ProviderProfileActivity';
import { EditProviderActivity } from 'components/activities/admin/providers/EditProviderActivity/EditProviderActivity';
import { EpisodeProfileActivity } from 'components/activities/admin/episodes/EpisodeProfileActivity/EpisodeProfileActivity';
import { RequirementsActivity } from 'components/activities/admin/requirements/RequirementsActivity/RequirementsActivity';
import { ViewRequirementActivity } from 'components/activities/admin/requirements/ViewRequirementActivity/ViewRequirementActivity';
import { EmployeeHomeActivity } from 'components/activities/employee/EmployeeHomeActivity/EmployeeHomeActivity';
import { EditEpisodeActivity } from 'components/activities/admin/episodes/EditEpisodeActivity/EditEpisodeActivity';
import { ViewVisitTemplateActivity } from 'components/activities/admin/visitTemplates/ViewVisitTemplateActivity/ViewVisitTemplateActivity';
import { EditVisitTemplateActivity } from 'components/activities/admin/visitTemplates/EditVisitTemplateActivity/EditVisitTemplateActivity';
import { ViewVisitActivity } from 'components/activities/admin/visits/ViewVisitActivity/ViewVisitActivity';
import { EditVisitActivity } from 'components/activities/admin/visits/EditVisitActivity/EditVisitActivity';
import { MyVisitsActivity } from 'components/activities/employee/visits/MyVisitsActivity/MyVisitsActivity';
import { EditRequirementPresetActivity } from 'components/activities/admin/requirementPresets/EditRequirementPresetActivity/EditRequirementPresetActivity';
import { ViewRequirementPresetActivity } from 'components/activities/admin/requirementPresets/ViewRequirementPresetActivity/ViewRequirementPresetActivity';
import { PharmaciesActivity } from 'components/activities/admin/pharmacies/PharmaciesActivity/PharmaciesActivity';
import { ViewPharmacyActivity } from 'components/activities/admin/pharmacies/ViewPharmacyActivity/ViewPharmacyActivity';
import { EditPharmacyActivity } from 'components/activities/admin/pharmacies/EditPharmacyActivity/EditPharmacyActivity';
import { TimesheetsActivity } from 'components/activities/admin/timesheets/TimesheetsActivity/TimesheetsActivity';
import { AdminMyProfileActivity } from 'components/activities/admin/myProfile/AdminMyProfileActivity/AdminMyProfileActivity';
import { ViewTimesheetActivity } from 'components/activities/admin/timesheets/ViewTimesheetActivity/ViewTimesheetActivity';
import { TaskSetTemplatesActivity } from 'components/activities/admin/taskSetTemplates/TaskSetTemplatesActivity/TaskSetTemplatesActivity';
import { ViewTaskSetTemplateActivity } from 'components/activities/admin/taskSetTemplates/ViewTaskSetTemplateActivity/ViewTaskSetTemplateActivity';
import { EditTaskSetTemplateActivity } from 'components/activities/admin/taskSetTemplates/EditTaskSetTemplateActivity/EditTaskSetTemplateActivity';
import { SetUpTotpActivity } from 'components/activities/auth/SetUpTotpActivity/SetUpTotpActivity';
import { PostRegisterMakeApiUserActivity } from 'components/activities/auth/PostRegisterMakeApiUserActivity/PostRegisterMakeApiUserActivity';
import { AdminEditMyProfileActivity } from 'components/activities/admin/myProfile/AdminEditMyProfileActivity/AdminEditMyProfileActivity';
import { ExportTimesheetsActivity } from 'components/activities/admin/timesheets/ExportTimesheetsActivity/ExportTimesheetsActivity';

import { ViewVisitActivity as EmployeeViewVisitActivity } from 'components/activities/employee/visits/ViewVisitActivity/ViewVisitActivity';
import { EditVisitActivity as EmployeeEditVisitActivity } from 'components/activities/employee/visits/EditVisitActivity/EditVisitActivity';
import { ViewRequirementActivity as EmployeeViewRequirementActivity } from 'components/activities/employee/requirements/ViewRequirementActivity/ViewRequirementActivity';
import { EmployeeCenterTasksActivity } from 'components/activities/employee/requirements/EmployeeCenterTasksActivity/EmployeeCenterTasksActivity';
import { EditRequirementActivity as EmployeeEditRequirementActivity } from 'components/activities/employee/requirements/EditRequirementActivity/EditRequirementActivity';
import { EditAfterVisitDataActivity } from 'components/activities/employee/visits/EditAfterVisitDataActivity/EditAfterVisitDataActivity';
import { EmployeeMyProfileActivity } from 'components/activities/employee/myProfile/EmployeeMyProfileActivity/EmployeeMyProfileActivity';
import { EmployeeEditMyProfileActivity } from 'components/activities/employee/myProfile/EmployeeEditMyProfileActivity/EmployeeEditMyProfileActivity';
import { MyPatientsActivity } from 'components/activities/employee/patients/MyPatientsActivity/MyPatientsActivity';
import { PatientProfileActivity as EmployeePatientProfileActivity } from 'components/activities/employee/patients/PatientProfileActivity/PatientProfileActivity';
import { TakeQuizActivity } from 'components/activities/employee/requirements/TakeQuizActivity/TakeQuizActivity';



const theme = createTheme({
  palette: {
    primary: {
      main: '#317FF4'
    },
    secondary: {
      main: '#9AB0D9'
    }
  },
  typography: {
    h1: {
      fontSize: 34,
      fontWeight: 400,
      letterSpacing: '0.25px'
    },
    h2: {
      fontSize: 24,
      fontWeight: 400
    },
    h3: {
      fontSize: 20,
      fontWeight: 400
    },
    body1: {
      fontSize: '14px'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiGrid: {
      defaultProps: {
        spacing: 2
      }
    },
    MuiButton: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: '4px',
          backgroundColor: '#317FF4'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          '&$selected': {
            color: '#317FF4'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&$disabled': {
            backgroundColor: '#ddd'
          }
        }
      }
    }
  }
});



const __routes: RouteObject[] = [
  {
    path: '/auth/login',
    element: (<LoginActivity />)
  },
  {
    path: '/auth/register/:inviteCode',
    element: (<RegisterActivity />)
  },
  {
    path: '/auth/changePassword',
    element: (<ChangePasswordActivity />)
  },
  {
    path: '/auth/confirmNewUser',
    element: (<PostRegisterMakeApiUserActivity />)
  },
  {
    path: '/auth/confirmNewUser/:inviteCode',
    element: (<PostRegisterMakeApiUserActivity />)
  },
  {
    path: '/auth/setUpTotp',
    element: (<SetUpTotpActivity />)
  },
  {
    path: '/auth/logout',
    element: (<LogoutActivity />)
  },



  {
    path: '/admin/administrators',
    element: (<AdministratorsActivity />)
  },
  {
    path: '/admin/administrators/create',
    element: (<EditAdministratorActivity />)
  },
  {
    path: '/admin/administrators/profile/:administratorId',
    element: (<AdministratorProfileActivity />)
  },
  {
    path: '/admin/administrators/edit/:administratorId',
    element: (<EditAdministratorActivity />)
  },



  {
    path: '/admin/employees',
    element: (<EmployeesActivity />)
  },
  {
    path: '/admin/employees/create',
    element: (<EditEmployeeActivity />)
  },
  {
    path: '/admin/employees/profile/:employeeId',
    element: (<EmployeeProfileActivity />)
  },
  {
    path: '/admin/employees/edit/:employeeId',
    element: (<EditEmployeeActivity />)
  },



  {
    path: '/admin/patients',
    element: (<PatientsActivity />)
  },
  {
    path: '/admin/patients/create',
    element: (<EditPatientActivity />)
  },
  {
    path: '/admin/patients/profile/:patientId',
    element: (<PatientProfileActivity />)
  },
  {
    path: '/admin/patients/edit/:patientId',
    element: (<EditPatientActivity />)
  },



  {
    path: '/admin/episodes/profile/:episodeId',
    element: (<EpisodeProfileActivity />)
  },
  {
    path: '/admin/episodes/edit/:episodeId',
    element: (<EditEpisodeActivity />)
  },



  {
    path: '/admin/visitTemplates/view/:visitTemplateId',
    element: (<ViewVisitTemplateActivity />)
  },
  {
    path: '/admin/visitTemplates/create/:parentEpisodeId',
    element: (<EditVisitTemplateActivity />)
  },
  {
    path: '/admin/visitTemplates/edit/:visitTemplateId',
    element: (<EditVisitTemplateActivity />)
  },



  {
    path: '/admin/visits/view/:visitId',
    element: (<ViewVisitActivity />)
  },
  {
    path: '/admin/visits/edit/:visitId',
    element: (<EditVisitActivity />)
  },



  {
    path: '/admin/providers',
    element: (<ProvidersActivity />)
  },
  {
    path: '/admin/providers/profile/:providerId',
    element: (<ProviderProfileActivity />)
  },
  {
    path: '/admin/providers/create',
    element: (<EditProviderActivity />)
  },
  {
    path: '/admin/providers/edit/:providerId',
    element: (<EditProviderActivity />)
  },



  {
    path: '/admin/requirements',
    element: (<RequirementsActivity />)
  },
  {
    path: '/admin/requirements/view/:requirementId',
    element: (<ViewRequirementActivity />)
  },
  {
    path: '/admin/requirementPresets/view/:presetId',
    element: (<ViewRequirementPresetActivity />)
  },
  {
    path: '/admin/requirementPresets/create',
    element: (<EditRequirementPresetActivity />)
  },
  {
    path: '/admin/requirementPresets/edit/:presetId',
    element: (<EditRequirementPresetActivity />)
  },



  {
    path: '/admin/pharmacies',
    element: (<PharmaciesActivity />)
  },
  {
    path: '/admin/pharmacies/view/:pharmacyId',
    element: (<ViewPharmacyActivity />)
  },
  {
    path: '/admin/pharmacies/create',
    element: (<EditPharmacyActivity />)
  },
  {
    path: '/admin/pharmacies/edit/:pharmacyId',
    element: (<EditPharmacyActivity />)
  },



  {
    path: '/admin/taskSetTemplates',
    element: (<TaskSetTemplatesActivity />)
  },
  {
    path: '/admin/taskSetTemplates/view/:taskSetTemplateId',
    element: (<ViewTaskSetTemplateActivity />)
  },
  {
    path: '/admin/taskSetTemplates/create',
    element: (<EditTaskSetTemplateActivity />)
  },
  {
    path: '/admin/taskSetTemplates/edit/:taskSetTemplateId',
    element: (<EditTaskSetTemplateActivity />)
  },



  {
    path: '/admin/timesheets',
    element: (<TimesheetsActivity />)
  },
  {
    path: '/admin/timesheets/view/:timesheetId',
    element: (<ViewTimesheetActivity />)
  },
  {
    path: '/admin/timesheets/export',
    element: (<ExportTimesheetsActivity />)
  },



  {
    path: '/admin/user/:userId',
    element: (<UserActivity />)
  },



  {
    path: '/admin/myProfile',
    element: (<AdminMyProfileActivity />)
  },
  {
    path: '/admin/myProfile/edit',
    element: (<AdminEditMyProfileActivity />)
  },



  {
    path: '/employee',
    element: (<EmployeeHomeActivity />)
  },
  {
    path: '/employee/myVisits',
    element: (<MyVisitsActivity />)
  },
  {
    path: '/employee/visit/:visitId',
    element: (<EmployeeViewVisitActivity />)
  },
  {
    path: '/employee/visit/edit/:visitId',
    element: (<EmployeeEditVisitActivity />)
  },
  {
    path: '/employee/visit/editAfterVisitData/:afterVisitDataId',
    element: (<EditAfterVisitDataActivity />)
  },



  {
    path: '/employee/requirements',
    element: (<EmployeeCenterTasksActivity />)
  },
  {
    path: '/employee/requirements/view/:requirementId',
    element: (<EmployeeViewRequirementActivity />)
  },
  {
    path: '/employee/requirements/edit/:requirementId',
    element: (<EmployeeEditRequirementActivity />)
  },
  {
    path: '/employee/requirements/takeQuiz/:assignedRequirementId',
    element: (<TakeQuizActivity />)
  },



  {
    path: '/employee/myProfile',
    element: (<EmployeeMyProfileActivity />)
  },
  {
    path: '/employee/myProfile/edit',
    element: (<EmployeeEditMyProfileActivity />)
  },



  {
    path: '/employee/myPatients',
    element: (<MyPatientsActivity />)
  },
  {
    path: '/employee/myPatients/profile/:patientId',
    element: (<EmployeePatientProfileActivity />)
  },



  {
    path: '/admin/messenger',
    element: (<AdminMessengerActivity />)
  },
  {
    path: '/employee/messenger',
    element: (<EmployeeMessengerActivity />)
  },



  {
    path: '/',
    element: (<RedirectActivity />)
  }
];



export const App: React.FC = () => {
  const [client, setClient] = React.useState<apolloService.IApolloClient | null>(null);

  const elRoutes = useRoutes(_.map(__routes, (route): RouteObject => ({
    ...route,
    caseSensitive: true
  })));



  React.useEffect(
    () => {
      (async () => {
        await apolloService.refreshClient();
        setClient(await apolloService.getClient());
      })();
    },
    []
  );



  return client ? (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {elRoutes}
      </ThemeProvider>
    </ApolloProvider>
  ) : null;
};
