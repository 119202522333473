import React from 'react';
import _ from 'lodash';

import {
  Form,
  LoadingOverlay,
  fields,
  errorUtil
} from 'modules';
import {
  Box,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NetworkStatus } from '@apollo/client';

import * as dataService from './dataService';
import { Message } from './Message/Message';
import { DateLabel } from './DateLabel/DateLabel';



const ContainerDiv = styled('div')(({theme}) => ({
  flex: '1 0',
  paddingTop: theme.spacing(5),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(3),
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));

const HeaderBox = styled(Box)(({theme}) => ({
  paddingBottom: theme.spacing(2),
  borderBottom: '1px solid #262A38',
  marginBottom: theme.spacing(3)
}));

const BodyDiv = styled('div')(() => ({
  flex: '1 0',
  overflowY: 'auto'
}));

const FooterBox = styled(Box)(({theme}) => ({
  marginTop: theme.spacing(3)
}));



interface IFormState {
  message: string;
}

const TextFieldController = fields.genTextFieldControllerComponent<IFormState>();



export interface IThreadContentProps {
  threadId: string;
}

export const ThreadContent: React.FC<IThreadContentProps> = props => {
  const {t} = useTranslation();

  const {
    queryTuple,
    lastMessageId,
    fnPost
  } = dataService.useDataService(props.threadId);

  const refContainer = React.useRef<HTMLDivElement>(null);

  const form = useForm<IFormState>({
    defaultValues: {
      message: ""
    }
  });



  React.useEffect(
    () => {
      const elContainerBox = refContainer.current;
      if (elContainerBox) {
        elContainerBox.scrollTo({
          top: elContainerBox.scrollHeight
        });
      }
    },
    [lastMessageId]
  );



  async function handleSubmit(formState: IFormState) {
    const trMessage = formState.message.trim();
    if (trMessage) {
      form.setValue('message', "");
      await errorUtil.errorWrap(async () => {
        await fnPost(trMessage);
        await queryTuple.refetch();
      });
    }
  }



  return (
    <>
      <LoadingOverlay
        active={queryTuple.loading && queryTuple.networkStatus !== NetworkStatus.refetch} />

      <ContainerDiv>

        <HeaderBox>
          <Typography variant="h2">
            {_.map(queryTuple.data.otherUsers, (user, index) => (
              <span key={index.toString()}>
                {user.fullName}{index < queryTuple.data.otherUsers.length - 1 ? ", " : ""}
              </span>
            ))}
          </Typography>
        </HeaderBox>

        <BodyDiv
          ref={refContainer}>

          {_.flatten(
            _.map(queryTuple.data.messages, (message, index, messages) => {
              const previousMessage = index > 0 ?
                messages[index - 1] :
                null;
              const isPreviousMessageMoreThanADayBehindCurrent = previousMessage ?
                previousMessage.timestamp.isBefore(message.timestamp.clone().startOf('day')) :
                null;
              const showDate = index === 0 || isPreviousMessageMoreThanADayBehindCurrent;

              return _.compact([
                showDate ? (
                  <DateLabel
                    key={`${message.id}-${message.timestamp.unix()}`}
                    date={message.timestamp} />
                ) : null,

                (
                  <Message
                    key={message.id}
                    authorType={message.authorUser.isSelf ? 'self' : 'other'}
                    author={{
                      id: message.authorUser.id,
                      fullName: message.authorUser.fullName,
                      profilePictureFileKey: message.authorUser.profilePictureFileKey
                    }}
                    message={message.message}
                    timestamp={message.timestamp}
                    isInFlight={message.isInFlight} />
                )
              ]);
            }))}

        </BodyDiv>

        <FooterBox>
          <Form onSubmit={form.handleSubmit(handleSubmit)}>
            <TextFieldController
              formControl={form.control}
              fieldKey="message"
              label={t('message')} />
          </Form>
        </FooterBox>

      </ContainerDiv>
    </>
  );
};

