import React from 'react';
import _ from 'lodash';

import {
  Avatar as BaseAvatar,
  Box,
  Grid,
  Skeleton,
  Typography
} from '@mui/material';
import {
  ProtectedImage,
  SkeletonRows,
  UserLink,
  ValueWithLabel
} from 'modules';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



const Avatar = styled(BaseAvatar)(() => ({
  width: 100,
  height: 100
}));



interface IGeneralInformationSectionProps {
  administratorId: string;
}

export const GeneralInformationSection: React.FC<IGeneralInformationSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useAdministratorProfileGeneralInformationSectionQuery(props.administratorId);



  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h2">
          {t('adminEmployees:generalInformation')}
        </Typography>
      </Box>

      {queryTuple.loading ? (
        <>
          <Box display="flex">
            <Box flex="1">
              <Skeleton
                variant="text"/>
            </Box>

            <Skeleton
              variant="circular"
              width={100}
              height={100} />
          </Box>

          <SkeletonRows numRows={10} />
        </>

      ) : (
        <Grid container>
          <ValueWithLabel label={t('profilePicture')}>
            {queryTuple.data.profilePictureFileKey ? (
              <ProtectedImage
                fileKey={queryTuple.data.profilePictureFileKey}
                asAvatar />

            ) : (
              <Avatar />
            )}
          </ValueWithLabel>

          <ValueWithLabel
            label={t('firstName')}
            value={queryTuple.data.firstName} />

          <ValueWithLabel
            label={t('lastName')}
            value={queryTuple.data.lastName} />

          <ValueWithLabel
            label={t('birthdate')}
            value={queryTuple.data.birthdate} />

          <ValueWithLabel
            label={t('employeeId')}
            value={queryTuple.data.employeeId} />

          <ValueWithLabel
            label={t('address')}
            value={queryTuple.data.address} />

          <ValueWithLabel
            label={t('primaryPhone')}
            value={queryTuple.data.primaryPhone} />

          <ValueWithLabel
            label={t('secondaryPhone')}
            value={queryTuple.data.secondaryPhone} />

          <ValueWithLabel label={t('supervisor')}>
            <UserLink userId={queryTuple.data.supervisorId} />
          </ValueWithLabel>

          <ValueWithLabel label={t('altSupervisor')}>
            <UserLink userId={queryTuple.data.altSupervisorId} />
          </ValueWithLabel>

          <ValueWithLabel
            label={t('payRate')}
            value={queryTuple.data.payRate} />

          <ValueWithLabel
            label={t('permissionCategories')}>
            <ul>
              {_.map(queryTuple.data.permissionCategories, (category, index) => (
                <li key={index}>
                  {t(`enums:permissionCategories.${category}`)}
                </li>
              ))}
            </ul>
          </ValueWithLabel>
        </Grid>
      )}
    </Box>
  );
};
