import React from 'react';

import { authService } from 'modules';



export interface ITuple {
  isLoading: boolean;
}

export function useConfirmCode() {
  const [tuple, setTuple] = React.useState<ITuple>({
    isLoading: false
  });



  async function fnConfirmCode(inviteCode: string, mfaCode: string) {
    try {
      setTuple({isLoading: true});
      await authService.confirmRegisterCode(mfaCode);

    } finally {
      setTuple({isLoading: false});
    }
  }



  return [
    fnConfirmCode,
    tuple
  ] as const;
}
