import React from 'react';
import moment from 'moment-timezone';

import {
  BaseModal,
  Form,
  fields
} from 'modules';

import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';



const DateWithTimeZoneFieldController = fields.genDateWithTimeZoneFieldControllerComponent<IFormState>();



interface IFormState {
  startDate: string;
}

export interface IStartModalProps {
  onAccept: (startDate: string) => void;
  onCancel: () => void;
}

export const StartModal: React.FC<IStartModalProps> = props => {
  const {t} = useTranslation();

  const form = useForm<IFormState>({
    defaultValues: {
      startDate: `${moment().startOf('day').format('YYYY-MM-DD')},${moment.tz.guess()}`
    }
  });



  function handleSubmit(formState: IFormState) {
    props.onAccept(formState.startDate);
  }



  return (
    <BaseModal
      isOpen
      title={t('adminEpisodes:startEpisode')}
      onClose={props.onCancel}
      showButtons>

      {buttons => (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>

          <DateWithTimeZoneFieldController
            formControl={form.control}
            fieldKey="startDate"
            label={t('startDate')}
            isRequired />

          {buttons}

        </Form>
      )}

    </BaseModal>
  );
};
