import _ from 'lodash';

import { enumCache } from 'modules/layer2';
import * as chipGenerator from '../shared/chipGenerator';
import { map } from 'rxjs';



export const EpisodeInactiveTypeChip = chipGenerator.genChipComponent<enumCache.IEpisodeInactiveTypeName>({
  obsValueId2DisplayData: enumCache.subjAllEnums.asObservable()
    .pipe(map((value): Record<enumCache.IEpisodeInactiveTypeName, chipGenerator.IValueDisplayData> => {
      const enumValues = value.episodeInactiveTypes;
      const enumValuesByName = _.keyBy(enumValues, enumValue => enumValue.name) as Record<enumCache.IEpisodeInactiveTypeName, enumCache.IEpisodeInactiveType>;

      return {
        death: {
          label: enumValuesByName.death?.displayName ?? "",
          backgroundColor: '#000'
        },
        discharge: {
          label: enumValuesByName.discharge?.displayName ?? "",
          backgroundColor: '#000'
        }
      };
    }))
});
