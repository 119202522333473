import React from 'react';
import _ from 'lodash';

import {
  useNavigate,
  useLocation
} from 'react-router-dom';

import { LoadingOverlay } from 'modules/layer0';
import * as dataService from './dataService';
import { enumCache } from 'modules/layer2';



export interface IAuthRedirectorProps {
  type:
    'onlyAllowLoggedOutUsers' |
    'onlyAllowLoggedInUsers';
  redirectLoggedInUserToRoute?: string;
  requireOneOfPermissionCategories?: enumCache.IPermissionCategoryName[];
}

export const AuthRedirector: React.FC<IAuthRedirectorProps> = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryTuple = dataService.useQuery();

  const [showContent, setShowContent] = React.useState(false);



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        if (queryTuple.data.authStatus === 'notLoggedIn' && props.type === 'onlyAllowLoggedInUsers') {
          navigate('/auth/login');

        } else if (queryTuple.data.authStatus === 'loggedIn' && !queryTuple.data.hasUser) {
          if (!location.pathname.startsWith('/auth/confirmNewUser')) {
            navigate(props.redirectLoggedInUserToRoute ?? '/auth/confirmNewUser');
          } else {
            setShowContent(true);
          }

        } else if (queryTuple.data.authStatus === 'loggedIn' && props.type === 'onlyAllowLoggedOutUsers') {
          navigate(props.redirectLoggedInUserToRoute ?? '/');

        } else if (queryTuple.data.authStatus === 'loggedIn' && !queryTuple.data.hasTotp) {
          if (location.pathname !== '/auth/setUpTotp') {
            navigate('/auth/setUpTotp');
          } else {
            setShowContent(true);
          }

        } else if (props.requireOneOfPermissionCategories && _.intersection(props.requireOneOfPermissionCategories, queryTuple.data.permissionCategories).length === 0) {
          navigate('/');

        } else {
          setShowContent(true);
        }
      }
    },
    [
      queryTuple,
      history,
      location,
      props.type,
      props.redirectLoggedInUserToRoute
    ]
  );



  return showContent ? (
    <>
      {props.children}
    </>

  ) : (
    <LoadingOverlay active />
  );
};
