import {
  InMemoryCache,
  NormalizedCacheObject,
  ApolloClient,
  ApolloLink,
  HttpLink,
  split
} from '@apollo/client';

import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { BehaviorSubject } from 'rxjs';
import { authService } from '../layer0';



export type IApolloClient = ApolloClient<NormalizedCacheObject>;

const __subjClient = new BehaviorSubject<IApolloClient | null>(null);



async function __refreshClient() {
  const authToken = (await authService.getAuthState()).accessToken;

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_API_WS_URL ?? '',
    options: {
      reconnect: true,
      connectionParams: {
        Authorization: `Bearer ${authToken}`
      }
    }
  });

  const authLink = setContext(async (operation, prevContext) => {
    const authToken = (await authService.getAuthState()).accessToken;

    const headers: any = {
      ...prevContext.headers
    };
    if (authToken) {
      headers.authorization = `Bearer ${authToken}`;
    }

    return {
      headers
    };
  });

  const httpLink = new HttpLink({uri: process.env.REACT_APP_API_URL});

  const authHttpLink = ApolloLink.from([
    authLink,
    httpLink
  ]);

  const splitLink = split(
    ({query}) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authHttpLink
  );

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: splitLink
  });
}



export async function refreshClient() {
  __subjClient.next(await __refreshClient());
}



export async function getClient() {
  if (!__subjClient.value) {
    await refreshClient();
  }
  return __subjClient.value;
}



export function getClientObservable() {
  return __subjClient.asObservable();
}
