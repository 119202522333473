import React from 'react';
import _ from 'lodash';

import {
  usePharmacySelectFieldQuery
} from 'generated/graphql';



export interface IPharmacy {
  id: string;
  name: string;
}

export interface IData {
  pharmacies: IPharmacy[];
  pharmaciesById: Record<string, IPharmacy>;
}

export function useQuery() {
  const tuple = usePharmacySelectFieldQuery();

  return React.useMemo(
    () => {
      const gqlPharmacies = tuple.data?.pharmacies ?? [];

      const pharmacies = _.map(gqlPharmacies, (gqlPharmacy): IPharmacy => ({
        id: gqlPharmacy.id,
        name: gqlPharmacy.name
      }));

      const sortedPharmacies: IPharmacy[] = [
        {
          id: '',
          name: ""
        },
        ..._.sortBy(pharmacies, pharmacy =>
          pharmacy.name.toLowerCase())
      ];

      return {
        ...tuple,
        data: {
          pharmacies: sortedPharmacies,
          pharmaciesById: _.keyBy(pharmacies, pharmacy => pharmacy.id)
        } as IData
      };
    },
    [tuple]
  );
}
