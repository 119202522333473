import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';



export interface ISwitchLanguageButtonProps {
  styleType?: 'compact' | 'large';
}

export const SwitchLanguageButton: React.FC<ISwitchLanguageButtonProps> = props => {
  const {i18n} = useTranslation();

  const styleType = React.useMemo(
    () => props.styleType ?? 'compact',
    [props.styleType]
  );

  const buttonLabel = React.useMemo(
    () => {
      if (i18n.language === 'en') {
        return styleType === 'compact' ?
          "ES" :
          "Español";

      } else if (i18n.language === 'es') {
        return styleType === 'compact' ?
          "EN" :
          "English";
      }
    },
    [
      i18n.language,
      styleType
    ]
  );



  function handleClick() {
    if (i18n.language === 'en') {
      i18n.changeLanguage('es');
    } else if (i18n.language === 'es') {
      i18n.changeLanguage('en');
    }
  }



  return (
    <Button
      variant="text"
      color={styleType === 'compact' ? 'primary' : 'inherit'}
      onClick={handleClick}>
      {buttonLabel}
    </Button>
  );
};
