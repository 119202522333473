import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useEmployeeViewVisitActivityQuery,
  useEmployeeClockInToVisitMutation as useBaseEmployeeClockInToVisitMutation,
  useEmployeeClockOutOfVisitMutation as useBaseEmployeeClockOutOfVisitMutation,
  useEmployeeCompleteVisitMutation as useBaseEmployeeCompleteVisitMutation
} from 'generated/graphql';
import {
  dateUtil,
  enumCache
} from 'modules';

import { useTranslation } from 'react-i18next';



export interface ITask {
  id: string;
  title: string;
  description: string;
}

export type IClockedStatus =
  'none' |
  'clockedIn' |
  'clockedOut';

export interface IData {
  displayName: string;
  visitStatusName: enumCache.IVisitStatusName | '';
  selfClockedStatus: IClockedStatus;
  missedVisitReasonName: enumCache.IMissedVisitReasonName | '';
  afterVisitDataId: string;

  patientName: string;
  visitCategoryName: enumCache.IVisitCategoryName;
  formattedDate: string;
  shiftName: enumCache.IShiftName;
  note: string;
  location: string;
  tasks: ITask[];
  clockInTimestamp: string;
  clockOutTimestamp: string;
  completedTimestamp: string;
  missedTimestamp: string;
  missedVisitReason: string;
}

export function useQuery(id: string) {
  const {t} = useTranslation();

  const tuple = useEmployeeViewVisitActivityQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );

  return React.useMemo(
    () => {
      const gqlVisit = tuple.data?.visit;
      const gqlPatient = gqlVisit?.episode.patient;

      const gqlSelfAssignedEmployee = gqlVisit?.assignedEmployees[0];
      let selfClockedStatus: IClockedStatus = 'none';
      if (gqlSelfAssignedEmployee?.clockOutTimestamp) {
        selfClockedStatus = 'clockedOut';
      } else if (gqlSelfAssignedEmployee?.clockInTimestamp) {
        selfClockedStatus = 'clockedIn';
      }

      const data: IData = {
        displayName: gqlVisit?.displayName ?? "",
        visitStatusName: gqlVisit?.visitStatus.name ?? '',
        selfClockedStatus,
        missedVisitReasonName: gqlVisit?.missedVisitReason?.name ?? '',
        afterVisitDataId: gqlVisit?.afterVisitData?.id ?? '',

        patientName: `${gqlPatient?.firstName ?? ""} ${gqlPatient?.lastName ?? ""}`,
        visitCategoryName: gqlVisit?.visitCategory.name ?? 'form485',
        formattedDate: gqlVisit?.date ?
          dateUtil.formatDateWithTimeZone(gqlVisit.date) :
          "",
        shiftName: gqlVisit?.shift.name ?? 'am',
        note: gqlVisit?.note ?? "",
        location: gqlVisit?.location ?? "",
        tasks: _.map(gqlVisit?.tasks ?? [], (gqlTask): ITask => ({
          id: gqlTask.id,
          title: gqlTask.title,
          description: gqlTask.description
        })),
        clockInTimestamp: gqlVisit?.clockInTimestamp ?
          moment(gqlVisit.clockInTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat) :
          t('noValue'),
        clockOutTimestamp: gqlVisit?.clockOutTimestamp ?
          `${moment(gqlVisit.clockOutTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat)} ${gqlVisit?.wasAutoClockedOut ? t('visits:automatic') : ''}` :
          t('noValue'),
        completedTimestamp: gqlVisit?.completedTimestamp ?
          moment(gqlVisit.completedTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat) :
          t('noValue'),
        missedTimestamp: gqlVisit?.missedTimestamp ?
          moment(gqlVisit.missedTimestamp, moment.ISO_8601).format(dateUtil.timestampFormat) :
          t('noValue'),
        missedVisitReason: gqlVisit?.missedVisitReason ?
          t(`visits:missedVisitReasonValues.${gqlVisit.missedVisitReason.name}`) :
          t('noValue')
      };

      return {
        ...tuple,
        data,
        refetch: async () => {
          await tuple.refetch();
        }
      };
    },
    [tuple]
  );
}



export function useEmployeeClockInToVisitMutation(id: string) {
  const [fnMutate, tuple] = useBaseEmployeeClockInToVisitMutation({
    variables: {
      id
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      await fnMutate();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useEmployeeClockOutOfVisitMutation(id: string) {
  const [fnMutate, tuple] = useBaseEmployeeClockOutOfVisitMutation({
    variables: {
      id
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      await fnMutate();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useEmployeeCompleteVisitMutation(id: string) {
  const [fnMutate, tuple] = useBaseEmployeeCompleteVisitMutation({
    variables: {
      id
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      await fnMutate();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
