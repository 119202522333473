import React from 'react';

import {
  Box,
  Button
} from '@mui/material';
import {
  LoadingOverlay,
  errorUtil
} from 'modules/layer0';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import { BaseModal } from 'modules/layer1';
import { genTextFieldControllerComponent } from 'modules/layer2';
import { Form } from 'modules/layer3';



const ButtonRowBox = styled(Box)(({theme}) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
}));

const ButtonContainerBox = styled(Box)(({theme}) => ({
  flex: '1 0',
  '&:not(:first-child)': {
    marginLeft: theme.spacing(2)
  }
}));



interface IFormData {
  email: string;
}

const TextFieldController = genTextFieldControllerComponent<IFormData>();

export interface IInviteModalProps {
  isLoading: boolean;
  fnInvite: (email: string) => Promise<void>;
  isOpen: boolean;
  onAccept: () => void;
  onCancel: () => void;
}

export const InviteModal: React.FC<IInviteModalProps> = props => {
  const {t} = useTranslation();

  const form = useForm<IFormData>({
    defaultValues: {
      email: ""
    }
  });



  async function handleSubmit(formData: IFormData) {
    await errorUtil.errorWrap(async () => {
      await props.fnInvite(formData.email.trim());
      props.onAccept();
    });
  }



  return (
    <BaseModal
      title={t('inviteUser')}
      isOpen={props.isOpen}>

      {() => (
        <>
          <LoadingOverlay
            active={props.isLoading} />

          <Form
            onSubmit={form.handleSubmit(handleSubmit)}>

            <TextFieldController
              fieldKey="email"
              formControl={form.control}
              label={t('email')}
              isRequired
              isDisabled={props.isLoading}
              type="email" />

            <ButtonRowBox>
              <ButtonContainerBox>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled={props.isLoading}
                  onClick={props.onCancel}>
                  {t('cancel')}
                </Button>
              </ButtonContainerBox>

              <ButtonContainerBox>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={props.isLoading}>
                  {t('sendInvite')}
                </Button>
              </ButtonContainerBox>
            </ButtonRowBox>

          </Form>
        </>
      )}

    </BaseModal>
  );
};
