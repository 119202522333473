import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
  useEditVisitActivityQuery as useBaseQuery,
  useArchiveVisitMutation as useBaseArchiveVisitMutation,
  useSaveVisitMutation as useBaseSaveVisitMutation
} from 'generated/graphql';

import * as types from './types';



export interface IData {
  displayName: string;
  episodeId: string;
  formState: types.IFormState;
}

export function useQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlVisit = tuple.data?.visit;
      const data: IData = {
        displayName: gqlVisit?.displayName ?? "",
        episodeId: gqlVisit?.episode.id ?? '',
        formState: {
          dateWithTz: gqlVisit?.date ?? `,${moment.tz.guess()}`,
          location: gqlVisit?.location ?? "",
          assignedEmployees: _.map(gqlVisit?.assignedEmployees ?? [], (gqlAssignedEmployee): types.IEmployee => ({
            _key: gqlAssignedEmployee.employee.id,
            id: gqlAssignedEmployee.employee.id
          }))
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useArchiveVisitMutation(id: string) {
  const [fnMutate, tuple] = useBaseArchiveVisitMutation({
    variables: {
      id
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      await fnMutate();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useSaveVisitMutation(id: string) {
  const [fnMutate, tuple] = useBaseSaveVisitMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id,
            date: formState.dateWithTz.split(',')[0],
            location: formState.location.trim(),
            assignedEmployeeIds: _.map(formState.assignedEmployees, employee => employee.id)
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
