import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';



const TitleTypography = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '10px',
  letterSpacing: '1.5px',
  textTransform: 'uppercase',
  color: '#8C9DB6'
}));



export const Comp: React.FC = props => {
  return (
    <TitleTypography>
      {props.children}
    </TitleTypography>
  );
};
