import React from 'react';

import {
  BaseActivityTemplate,
  paletteColors,
  fields,
  Form,
  formUtil,
  errorUtil,
  genEditableListFormFragment
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  useFieldArray,
  useForm
} from 'react-hook-form';

import { Grid } from '@mui/material';
import * as dataService from './dataService';
import * as types from './types';
import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';



const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();
const EditableListFormFragment = genEditableListFormFragment<types.ITask>();



interface IParams {
  taskSetTemplateId?: string;
}

export const EditTaskSetTemplateActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [fnQuery, queryTuple] = dataService.useLazyQuery();
  const [fnSave, saveMutationTuple] = dataService.useSaveMutation();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveMutation();

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading ||
    archiveMutationTuple.loading;

  const form = useForm<types.IFormState>({
    defaultValues: {
      displayName: "",
      tasks: []
    }
  });

  const taskFields = useFieldArray({
    control: form.control,
    name: 'tasks',
    keyName: '_key'
  });



  React.useEffect(
    () => {
      if (params.taskSetTemplateId && !queryTuple.called) {
        fnQuery(params.taskSetTemplateId);
      }
    },
    [
      queryTuple.called,
      params.taskSetTemplateId
    ]
  );



  React.useEffect(
    () => {
      formUtil.setFieldsFromQuery(queryTuple.data.formState, form.setValue, [
        'id',
        'displayName',
        'tasks'
      ]);
    },
    [
      queryTuple.loading,
      queryTuple.data.formState,
      form.setValue
    ]
  );



  async function handleSubmit(formState: types.IFormState) {
    if (formState.tasks.length === 0) {
      alert(t('visits:errors.mustHaveAtLeastOneTask'));
      return;
    }

    await errorUtil.errorWrap(async () => {
      await fnSave(formState);
      navigate(-1);
    });
  }



  async function handleDelete() {
    await errorUtil.errorWrap(async () => {
      if (params.taskSetTemplateId) {
        await fnArchive(params.taskSetTemplateId);
        navigate('/admin/taskSetTemplates');
      }
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.taskSetTemplates,
          title: params.taskSetTemplateId ?
            queryTuple.data.displayName :
            t('visits:createNewTaskSetTemplate'),
          hasBackButton: true
        }}
        footerOptions={{
          onClickCancel: () => navigate(-1),
          hasDeleteButton: !!params.taskSetTemplateId,
          onConfirmDelete: handleDelete
        }}>

        <Grid container>

          <Grid
            item
            md={6}>
            <TextFieldController
              formControl={form.control}
              fieldKey="displayName"
              label={t('name')}
              isRequired
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={6} />

          <Grid
            item
            md={6}>

            <EditableListFormFragment
              title={t('visits:tasks')}
              noItemsLabel={t('visits:noTasks')}
              addLabel={t('visits:addTask')}
              items={taskFields.fields}
              onClickAdd={() => taskFields.append({
                _key: `*${uuid.v4()}`,
                title: "",
                description: ""
              })}
              onClickDelete={index => taskFields.remove(index)}
              fnGenElItem={(item, index) => (
                <>
                  <Grid
                    item
                    md={4}>
                    <TextFieldController
                      formControl={form.control}
                      fieldKey={`tasks.${index}.title`}
                      label={t('visits:title')}
                      isRequired
                      isDisabled={isLoading} />
                  </Grid>

                  <Grid
                    item
                    md={7}>
                    <TextFieldController
                      formControl={form.control}
                      fieldKey={`tasks.${index}.description`}
                      label={t('visits:description')}
                      isDisabled={isLoading} />
                  </Grid>
                </>
              )} />

          </Grid>

        </Grid>

      </BaseActivityTemplate>
    </Form>
  );
};
