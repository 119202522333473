import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  usePatientVisitsTabQuery
} from 'generated/graphql';
import {
  dateUtil,
  enumCache
} from 'modules';




export interface IVisit {
  id: string;
  displayName: string;
  visitStatusName: enumCache.IVisitStatusName;
  date: moment.Moment | null;
  timeZone: string;
  shiftName: enumCache.IShiftName;
  location: string;
}

export interface IData {
  visits: IVisit[];
}

export function useQuery(patientId: string) {
  const tuple = usePatientVisitsTabQuery({
    variables: {
      patientId
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const data: IData = {
        visits: _.map(tuple.data?.patient?.visits ?? [], (gqlVisit): IVisit => ({
          id: gqlVisit.id,
          displayName: gqlVisit.displayName,
          visitStatusName: gqlVisit.visitStatus.name,
          date: dateUtil.dateWithTimeZone2Moment(gqlVisit.date),
          timeZone: dateUtil.dateWithTimeZone2TimeZone(gqlVisit.date),
          shiftName: gqlVisit.shift.name,
          location: gqlVisit.location
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
