import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  errorUtil,
  paletteColors,
  ValueWithLabel
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  Button
} from '@mui/material';

import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';
import { Create } from '@mui/icons-material';
import { DuplicateModal } from './DuplicateModal/DuplicateModal';



interface IParams {
  visitTemplateId: string;
}

export const ViewVisitTemplateActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.visitTemplateId ?? '');
  const [fnDuplicate, duplicateMutationTuple] = dataService.useDuplicateVisitTemplateMutation(params.visitTemplateId ?? '');

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = React.useState(false);

  const isLoading =
    queryTuple.loading ||
    duplicateMutationTuple.loading;



  async function handleConfirmDuplicate(displayName: string) {
    await errorUtil.errorWrap(async () => {
      const newTemplateId = await fnDuplicate(displayName);
      setIsDuplicateModalOpen(false);
      navigate(`/admin/visitTemplates/view/${newTemplateId}`);
    }, {
      'EPISODE_CANNOT_BE_EDITED': t('visits:errors.EPISODE_CANNOT_BE_EDITED')
    });
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.visitTemplates,
          title: queryTuple.data.displayName,
          hasBackButton: true,
          elRightButtons: _.compact([
            queryTuple.data.canEdit ? (
              <Button
                key="editData"
                variant="outlined"
                onClick={() => navigate(`/admin/visitTemplates/edit/${params.visitTemplateId}`)}
                color="primary"
                startIcon={(
                  <Create />
                )}>
                {t('editData')}
              </Button>
            ) : null,

            queryTuple.data.canEdit ? (
              <Button
                key="duplicate"
                variant="outlined"
                onClick={() => setIsDuplicateModalOpen(true)}>
                {t('duplicate')}
              </Button>
            ) : null
          ])
        }}>

        {!isLoading && (
          <Box>
            <Box>
              <Grid container>
                <Grid
                  item
                  md={12}>
                  <Grid container>

                    <ValueWithLabel
                      label={t('visits:visitCategory')}
                      value={t(`visits:visitCategories.${queryTuple.data.visitCategoryName}`)} />

                    <ValueWithLabel
                      label={t('visits:shift')}
                      value={t(`visits:shifts.${queryTuple.data.shiftName}`)} />

                    <ValueWithLabel
                      label={t('visits:frequency')}
                      value={t('visits:frequencyValue', {
                        amount: queryTuple.data.frequencyAmount,
                        unit: t(`visits:frequencyUnits.${queryTuple.data.frequencyUnitName}`)
                      })} />

                    <ValueWithLabel
                      label={t('visits:location')}
                      value={queryTuple.data.location} />

                    <ValueWithLabel
                      label={t('visits:note')}
                      value={queryTuple.data.note} />

                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box mt={3}>
              <Box mb={3}>
                <Typography variant="h2">
                  {t('visits:tasks')}
                </Typography>
              </Box>

              {queryTuple.data.tasks.length === 0 ? (
                <Box>
                  {t('visits:noTasks')}
                </Box>

              ) : (
                <ul>
                  {_.map(queryTuple.data.tasks, task => (
                    <li key={task.id}>
                      {task.title}

                      {task.description && (
                        <ul>
                          <li>
                            {task.description}
                          </li>
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </Box>
          </Box>
        )}

      </BaseActivityTemplate>

      {isDuplicateModalOpen && (
        <DuplicateModal
          isLoading={isLoading}
          onAccept={handleConfirmDuplicate}
          onCancel={() => setIsDuplicateModalOpen(false)} />
      )}
    </>
  );
};
