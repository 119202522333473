import React from 'react';

import {
  Autocomplete,
  TextField
} from '@mui/material';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';

import { validationUtil } from 'modules/layer0';
import { FieldContainer } from 'modules/layer1';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';




export interface IProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const Comp: React.FC<IProps> = props => {
  const [fnQuery, queryTuple] = dataService.useLazyQuery();
  const [inputValue, setInputValue] = React.useState("");
  const {t} = useTranslation();



  function handleChangeInputValue(value: string, reason: string) {
    setInputValue(value);
    props.onChange(value);
    if (reason === 'input' && value.length > 0) {
      fnQuery(value);
    }
  }



  return (
    <FieldContainer.Comp>
      <Autocomplete
        options={queryTuple.data.medications}
        value={props.value}
        filterOptions={options => options}
        freeSolo
        inputValue={inputValue}
        onInputChange={(_event, value, reason) => handleChangeInputValue(value, reason)}
        onChange={(_event, value) => props.onChange(value ?? "")}
        isOptionEqualToValue={(option, value) => option === value}
        disabled={props.isDisabled}
        loading={queryTuple.loading}
        loadingText={t('loading')}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            error={!!props.errorMessage}
            helperText={props.errorMessage} />
        )} />
    </FieldContainer.Comp>
  );
};



export interface IMedicationSelectFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label: string;
  isDisabled?: boolean;
}

export function genMedicationSelectFieldControllerComponent<TFormState extends FieldValues>() {
  const MedicationSelectFieldController: React.FC<IMedicationSelectFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        control={props.formControl}
        name={props.fieldKey}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <Comp
            label={props.label}
            value={field.value}
            onChange={field.onChange}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled} />
        )} />
    );
  };

  return MedicationSelectFieldController;
}
