import React from 'react';

import {
  BaseModal,
  Form,
  fields,
  validationUtil,
  errorUtil,
  formUtil
} from 'modules';
import {
  Box,
  Typography
} from '@mui/material';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



const TextFieldController = fields.genTextFieldControllerComponent<dataService.IFormState>();



export interface IApproveVisitModalProps {
  timesheetId: string
  timesheetVisitId: string;
  onAccept: () => void;
  onClose: () => void;
}

export const ApproveVisitModal: React.FC<IApproveVisitModalProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.timesheetId, props.timesheetVisitId);
  const [fnApprove, approveMutationTuple] = dataService.useApproveMutation();

  const isLoading =
    queryTuple.loading ||
    approveMutationTuple.loading;

  const form = useForm<dataService.IFormState>({
    defaultValues: {
      id: props.timesheetVisitId,
      numOverrideBilledHours: "",
      approvalComment: ""
    }
  });



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        formUtil.setFieldsFromQuery(queryTuple.data.formState, form.setValue, [
          'id',
          'numOverrideBilledHours',
          'approvalComment'
        ]);
      }
    },
    [
      queryTuple.loading,
      queryTuple.data,
      form.setValue
    ]
  );



  async function handleSubmit(formState: dataService.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnApprove(formState);
      props.onAccept();
    });
  }



  return (
    <BaseModal
      isOpen
      showButtons
      title={t('visits:confirmApproveTimesheetVisitModal.title')}
      isLoading={isLoading}
      onClose={props.onClose}>

      {buttons => (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          <Box mb={3}>
            <Typography variant="body1">
              {t('visits:confirmApproveTimesheetVisitModal.message')}
            </Typography>
          </Box>

          <Box mb={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="numOverrideBilledHours"
              label={t('visits:numOverrideBilledHours')}
              extraValidationRules={{validate: validationUtil.rules.genNumberRule({
                min: 0,
                max: 24
              })}}
              isDisabled={isLoading} />
          </Box>

          <Box mb={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="approvalComment"
              label={t('visits:approvalComment')}
              isDisabled={isLoading} />
          </Box>

          {buttons}
        </Form>
      )}

    </BaseModal>
  );
};
