import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useViewRequirementActivityQuery as useBaseQuery,
  useApproveAssignedRequirementMutation,
  useRejectAssignedRequirementMutation
} from 'generated/graphql';
import {
  enumCache,
  QuizContentSection
} from 'modules';



export interface IFile {
  key: string;
  name: string;
}

export interface IUser {
  id: string;
  name: string;
}

export interface IData {
  typeName: enumCache.IRequirementTypeName;
  presetId: string;
  displayName: string;
  description: string;
  file: IFile | null;
  url: string;
  statusName: enumCache.IRequirementStatusName;
  assignedUser: IUser;
  dueTimestamp: moment.Moment | null;
  lastSubmittedTimestamp: moment.Moment | null;
  lastRejectedTimestamp: moment.Moment | null;
  approvedTimestamp: moment.Moment | null;
  employeeFiles: IFile[];
  quiz: QuizContentSection.IQuiz | null;
}

export function useViewRequirementActivityQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlRequirement = tuple.data?.assignedRequirement;

      const gqlQuizPreset = tuple.data?.assignedRequirement?.preset.typeSpecificData?.__typename === 'RequirementPresetQuiz' ?
        tuple.data.assignedRequirement.preset.typeSpecificData :
        null;
      const gqlAssignedQuiz = tuple.data?.assignedRequirement?.typeSpecificData?.__typename === 'AssignedRequirementQuiz' ?
        tuple.data.assignedRequirement.typeSpecificData :
        null;
      const submittedAnswerIds = new Set(_.map(gqlAssignedQuiz?.submittedAnswers ?? [], answer => answer.id));

      const data: IData = {
        typeName: gqlRequirement?.preset.type.name ?? 'requirement',
        presetId: gqlRequirement?.preset.id ?? '',
        displayName: gqlRequirement?.preset.displayName ?? "",
        description: gqlRequirement?.preset.description ?? "",
        file: gqlRequirement?.preset.file ? {
          key: gqlRequirement.preset.file.key ?? '',
          name: gqlRequirement.preset.file.fileName ?? ""
        } : null,
        url: gqlRequirement?.preset.url ?? "",
        statusName: gqlRequirement?.status.name ?? 'pending',
        assignedUser: {
          id: gqlRequirement?.assignedUser.id ?? '',
          name: gqlRequirement?.assignedUser.fullName ?? ""
        },
        dueTimestamp: gqlRequirement?.dueTimestamp ?
          moment(gqlRequirement.dueTimestamp, moment.ISO_8601) :
          null,
        lastSubmittedTimestamp: gqlRequirement?.lastSubmittedTimestamp ?
          moment(gqlRequirement.lastSubmittedTimestamp, moment.ISO_8601) :
          null,
        lastRejectedTimestamp: gqlRequirement?.lastRejectedTimestamp ?
          moment(gqlRequirement.lastRejectedTimestamp, moment.ISO_8601) :
          null,
        approvedTimestamp: gqlRequirement?.approvedTimestamp ?
          moment(gqlRequirement.approvedTimestamp, moment.ISO_8601) :
          null,
        employeeFiles: _.map(gqlRequirement?.employeeFiles, (gqlFile): IFile => ({
          key: gqlFile.key,
          name: gqlFile.fileName
        })),

        quiz: (gqlQuizPreset && gqlAssignedQuiz) ? {
          id: gqlAssignedQuiz.id,
          score: gqlAssignedQuiz.score ?? null,
          questions: _.map(gqlQuizPreset.questions, (gqlQuestion): QuizContentSection.IQuizQuestion => ({
            id: gqlQuestion.id,
            description: gqlQuestion.description,
            answerOptions: _.map(gqlQuestion.answerOptions, (gqlAnswerOption): QuizContentSection.IQuizQuestionAnswerOption => ({
              id: gqlAnswerOption.id,
              description: gqlAnswerOption.description,
              isCorrect: gqlAnswerOption.id === gqlQuestion.correctAnswerOption.id,
              isSelected: submittedAnswerIds.has(gqlAnswerOption.id)
            }))
          }))
        } : null
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useReject(id: string) {
  const [fnMutate, tuple] = useRejectAssignedRequirementMutation({
    variables: {
      id
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      await fnMutate();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useApprove(id: string) {
  const [fnMutate, tuple] = useApproveAssignedRequirementMutation({
    variables: {
      id
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      await fnMutate();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
