import React from 'react';

import {
  BaseActivityTemplate,
  HeaderLabelWithValue,
  paletteColors,
  chips,
  errorUtil,
  ConfirmationModal,
  enumCache
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Button,
  Link,
  Box
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { Create } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

import * as types from './types';
import * as dataService from './dataService';
import { DetailsTab } from './DetailsTab/DetailsTab';
import { CarePlanTab } from './CarePlanTab/CarePlanTab';
import { StartModal } from './StartModal/StartModal';
import { SetToInactiveModal } from './SetToInactiveModal/SetToInactiveModal';



type IDuplicateEpisodeType =
  'recertify' |
  'resume';

interface IParams {
  episodeId: string;
}

export const EpisodeProfileActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useEpisodeProfileActivityQuery(params.episodeId ?? '');
  const [fnSetStatus, setStatusMutationTuple] = dataService.useSetEpisodeStatusMutation(queryTuple.refetch, params.episodeId ?? '');
  const [fnRecertify, recertifyMutationTuple] = dataService.useRecertifyEpisodeMutation(params.episodeId ?? '');
  const [fnResume, resumeMutationTuple] = dataService.useResumeEpisodeMutation(params.episodeId ?? '');

  const [selectedTabId, setSelectedTabId] = React.useState('details');

  const [isStartModalOpen, setIsStartModalOpen] = React.useState(false);
  const [inactiveType, setInactiveType] = React.useState<types.IInactiveType | ''>('');
  const [statusToChangeTo, setStatusToChangeTo] = React.useState<enumCache.IEpisodeStatusName | ''>('');
  const [duplicateEpisodeType, setDuplicateEpisodeType] = React.useState<IDuplicateEpisodeType | ''>('');

  const isLoading =
    queryTuple.loading ||
    setStatusMutationTuple.loading ||
    recertifyMutationTuple.loading ||
    resumeMutationTuple.loading;



  async function setStatusToAccept(startDate: string) {
    await errorUtil.errorWrap(async () => {
      setIsStartModalOpen(false);
      await fnSetStatus({
        statusName: 'active',
        activeStartDate: startDate
      });
    }, {
      'EPISODE_DATA_MISSING_REQUIRED_FIELDS': t('adminEpisodes:errors.EPISODE_DATA_MISSING_REQUIRED_FIELDS')
    });
  }



  async function setStatusToInactive(note: string) {
    if (inactiveType) {
      await errorUtil.errorWrap(async () => {
        setInactiveType('');
        await fnSetStatus({
          statusName: 'inactive',
          inactiveType,
          inactiveNote: note
        });
      });
    }
  }



  async function handleAcceptStatusChange() {
    if (statusToChangeTo) {
      await errorUtil.errorWrap(async () => {
        setStatusToChangeTo('');
        await fnSetStatus({
          statusName: statusToChangeTo
        });
      });
    }
  }



  async function handleAcceptDuplicateEpisode() {
    if (duplicateEpisodeType) {
      await errorUtil.errorWrap(async () => {
        setDuplicateEpisodeType('');
        let newEpisodeId = '';
        if (duplicateEpisodeType === 'recertify') {
          newEpisodeId = await fnRecertify();
        } else if (duplicateEpisodeType === 'resume') {
          newEpisodeId = await fnResume();
        }

        if (newEpisodeId) {
          navigate(`/admin/episodes/profile/${newEpisodeId}`);
        }
      });
    }
  }



  const elRightButtons = React.useMemo(
    (): JSX.Element[] => {
      if (!queryTuple.data.isCurrent) {
        return [];
      }

      const status = queryTuple.data.statusName;
      if (status === 'pending') {
        return [
          (
            <Button
              key="start"
              onClick={() => setIsStartModalOpen(true)}
              variant="outlined">
              {t('adminEpisodes:start')}
            </Button>
          ),
          (
            <Button
              key="editData"
              variant="outlined"
              onClick={() => navigate(`/admin/episodes/edit/${params.episodeId}`)}
              color="primary"
              startIcon={(
                <Create />
              )}>
              {t('editData')}
            </Button>
          )
        ];

      } else if (status === 'active' && queryTuple.data.isWithinTimeRange) {
        return [
          (
            <Button
              key="transfer"
              onClick={() => setStatusToChangeTo('transferred')}
              variant="outlined">
              {t('adminEpisodes:transfer')}
            </Button>
          ),
          (
            <Button
              key="recertify"
              onClick={() => setDuplicateEpisodeType('recertify')}
              variant="outlined">
              {t('adminEpisodes:recertify')}
            </Button>
          ),
          (
            <Button
              key="death"
              onClick={() => setInactiveType('death')}
              variant="outlined">
              {t('adminEpisodes:death')}
            </Button>
          ),
          (
            <Button
              key="discharge"
              onClick={() => setInactiveType('discharge')}
              variant="outlined">
              {t('adminEpisodes:discharge')}
            </Button>
          )
        ];

      } else if (status === 'transferred') {
        return [
          (
            <Button
              key="resume"
              onClick={() => setDuplicateEpisodeType('resume')}
              variant="outlined">
              {t('adminEpisodes:resume')}
            </Button>
          )
        ];

      } else {
        return [];
      }
    },
    [
      queryTuple.data.isCurrent,
      queryTuple.data.statusName,
      queryTuple.data.isWithinTimeRange
    ]
  );



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.episodes,
          title: queryTuple.data.displayName,
          hasBackButton: true,
          tabOptions: {
            tabs: [
              {
                id: 'details',
                label: t('details')
              },
              {
                id: 'carePlan',
                label: t('adminEpisodes:carePlan')
              }
            ],
            selectedTabId,
            onSelectTab: setSelectedTabId
          },
          elRight: !queryTuple.loading ? (
            <>
              <HeaderLabelWithValue label={t('status')}>
                {(queryTuple.data.statusName === 'inactive' && queryTuple.data.inactiveType) ? (
                  <chips.EpisodeInactiveTypeChip
                    valueId={queryTuple.data.inactiveType} />

                ) : (
                  <chips.EpisodeStatusChip
                    valueId={queryTuple.data.statusName} />
                )}
              </HeaderLabelWithValue>

              {queryTuple.data.continuationType && (
                <Box ml={2}>
                  <HeaderLabelWithValue label={t('adminEpisodes:continuationType')}>
                    <chips.EpisodeContinuationTypeChip
                      valueId={queryTuple.data.continuationType} />

                    {queryTuple.data.parentEpisode && (
                      <Link
                        component={RouterLink}
                        to={`/admin/episodes/profile/${queryTuple.data.parentEpisode.id}`}>
                        (#{queryTuple.data.parentEpisode.ranking})
                      </Link>
                    )}
                  </HeaderLabelWithValue>
                </Box>
              )}
            </>
          ) : undefined,
          elRightButtons
        }}>

        {selectedTabId === 'details' && (
          <DetailsTab
            episodeId={params.episodeId ?? ''} />
        )}

        {selectedTabId === 'carePlan' && (
          <CarePlanTab
            episodeId={params.episodeId ?? ''} />
        )}

      </BaseActivityTemplate>

      {isStartModalOpen && (
        <StartModal
          onAccept={setStatusToAccept}
          onCancel={() => setIsStartModalOpen(false)} />
      )}

      {inactiveType && (
        <SetToInactiveModal
          type={inactiveType}
          onAccept={setStatusToInactive}
          onCancel={() => setInactiveType('')} />
      )}

      {statusToChangeTo && (
        <ConfirmationModal
          isOpen
          title={t(`adminEpisodes:changeStatusModal.title`)}
          message={t(`adminEpisodes:changeStatusModal.description`)}
          onAccept={handleAcceptStatusChange}
          onCancel={() => setStatusToChangeTo('')} />
      )}

      {duplicateEpisodeType && (
        <ConfirmationModal
          isOpen
          title={t('adminEpisodes:duplicateEpisodeModal.title')}
          message={t('adminEpisodes:duplicateEpisodeModal.description')}
          onAccept={handleAcceptDuplicateEpisode}
          onCancel={() => setDuplicateEpisodeType('')} />
      )}
    </>
  );
};
