import React from 'react';
import _ from 'lodash';

import {
  Box,
  Typography,
  Grid
} from '@mui/material';
import {
  SectionHeaderBox,
  RowBox,
  CellBox
} from 'modules/layer1';
import {
  SkeletonRows,
  ProtectedImage
} from 'modules/layer2';
import {
  ValueWithLabel,
  Signature
} from 'modules/layer3';

import { DataValueList } from 'modules/layer4';

import { Check } from '@mui/icons-material';
import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';



const TaskListItemBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));



export interface IVisitEmployeeVisitInfoSectionProps {
  visitId: string;
}

export const VisitEmployeeVisitInfoSection: React.FC<IVisitEmployeeVisitInfoSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.visitId);



  return queryTuple.loading ? (
    <SkeletonRows numRows={5} />

  ) : (
    <Box mb={3}>
      <Grid container>

        <Grid
          item
          md={6}>

          <Box mb={3}>
            <SectionHeaderBox>
              <Typography variant="h2">
                {t('visits:tasks')}
              </Typography>
            </SectionHeaderBox>

            {queryTuple.data.tasks.length === 0 ? (
              <>
                {t('visits:noTasks')}
              </>

            ) : (
              <ul>
                {_.map(queryTuple.data.tasks, task => (
                  <li key={task.id}>
                    <TaskListItemBox>
                      {task.title}

                      {task.isCompleted && (
                        <Box ml={3}>
                          <Check />
                        </Box>
                      )}
                    </TaskListItemBox>

                    {task.description && (
                      <ul>
                        <li>{task.description}</li>
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </Box>

          {queryTuple.data.status !== 'open' && (
            <>
              <Box mb={3}>
                <SectionHeaderBox>
                  <Typography variant="h2">
                    {t('visits:images')}
                  </Typography>
                </SectionHeaderBox>

                {queryTuple.data.images.length === 0 ? (
                  <Typography variant="body1">
                    {t('visits:noImages')}
                  </Typography>

                ) : (
                  <>
                    {_.map(queryTuple.data.images, image => (
                      <RowBox key={image.id}>
                        <ProtectedImage
                          fileKey={image.fileKey} />

                        <CellBox ml={3}>
                          {image.title}
                        </CellBox>
                      </RowBox>
                    ))}
                  </>
                )}
              </Box>

              <Box mb={3}>
                <SectionHeaderBox>
                  <Typography variant="h2">
                    {t('visits:signature')}
                  </Typography>
                </SectionHeaderBox>

                {queryTuple.data.signatureFileKey ? (
                  <Signature
                    source={{
                      type: 'fileKey',
                      value: queryTuple.data.signatureFileKey
                    }} />

                ) : (
                  <Typography variant="body1">
                    {t('visits:noSignature')}
                  </Typography>
                )}
              </Box>
            </>
          )}

        </Grid>

        <Grid
          item
          md={6}>

          {queryTuple.data.status !== 'open' && (
            <Box mb={3}>
              <SectionHeaderBox>
                <Typography variant="h2">
                  {t('visits:aboutVisit')}
                </Typography>
              </SectionHeaderBox>

              <Grid container>
                <ValueWithLabel
                  label={t('visits:hasPatientConditionChanged')}
                  value={queryTuple.data.hasPatientConditionChanged ?
                    t('yes') :
                    t('no')} />

                <ValueWithLabel
                  label={t('visits:patientConditionChangeDescription')}
                  value={queryTuple.data.patientConditionChangeDescription} />

                <ValueWithLabel
                  label={t('visits:additionalComments')}
                  value={queryTuple.data.additionalComments} />

                <ValueWithLabel
                  label={t('visits:temperatureF')}
                  value={queryTuple.data.temperature} />

                <ValueWithLabel
                  label={t('visits:bloodPressure')}
                  value={queryTuple.data.bloodPressure} />

                <ValueWithLabel
                  label={t('visits:heartRate')}
                  value={queryTuple.data.heartRate} />

                <ValueWithLabel
                  label={t('visits:satRa')}
                  value={queryTuple.data.satRa} />

                <ValueWithLabel
                  label={t('visits:satO2')}
                  value={queryTuple.data.satO2} />

                <ValueWithLabel
                  label={t('visits:o2l')}
                  value={queryTuple.data.o2l} />

                <ValueWithLabel
                  label={t('visits:medicationHeld')}
                  value={queryTuple.data.medicationHeld} />

                <ValueWithLabel
                  label={t('visits:patientAppearances')}
                  value={queryTuple.data.patientAppearances} />

                <DataValueList.Comp
                  label={t('visits:locationType')}
                  labelTranslationPrefix="visits:locationTypeValues"
                  values={queryTuple.data.locationType} />
              </Grid>
            </Box>
          )}

        </Grid>

      </Grid>
    </Box>
  );
};
