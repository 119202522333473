import React from 'react';
import _ from 'lodash';

import {
  useExportTimesheetLazyQuery
} from 'generated/graphql';
import {
  dateUtil,
  protectedFileUtil
} from 'modules';

import * as types from './types';



export function useLazyQuery() {
  const [isDownloading, setIsDownloading] = React.useState(false);

  const [fnQuery, tuple] = useExportTimesheetLazyQuery({
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  const fnWrappedQuery = React.useCallback(
    (formState: types.IFormState) => {
      if (formState.startTimestamp && formState.endTimestamp) {
        fnQuery({
          variables: {
            input: {
              type: formState.type,
              timeRange: {
                start: dateUtil.dateAndTimeZone2Moment(formState.startTimestamp, dateUtil.defaultTimeZone),
                end: dateUtil.dateAndTimeZone2Moment(formState.endTimestamp, dateUtil.defaultTimeZone)
              },
              patientId: formState.type === 'patient' ? formState.patientId : undefined,
              employeeUserIds: (formState.type === 'employees' || formState.type === 'visitsPerEmployee') ?
                _.map(formState.employees, employee => employee.id) :
                undefined
            }
          }
        });
      }
    },
    [fnQuery]
  );



  React.useEffect(
    () => {
      (async () => {
        if (tuple.called && !tuple.loading && tuple.data?.exportTimesheet) {
          setIsDownloading(true);
          await protectedFileUtil.forceDownloadFromFileKey(tuple.data.exportTimesheet, "report.xlsx");
          setIsDownloading(false);
        }
      })();
    },
    [tuple]
  );



  return [
    fnWrappedQuery,
    {
      ...tuple,
      loading: tuple.loading || isDownloading,
      data: null
    }
  ] as const;
}
