import React from 'react';

import {
  BaseActivityTemplate,
  errorUtil,
  paletteColors,
  InviteModal
} from 'modules';
import {
  Grid,
  Button
} from '@mui/material';

import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';
import { Create } from '@mui/icons-material';

import { GeneralInformationSection } from './GeneralInformationSection/GeneralInformationSection';



interface IParams {
  administratorId: string;
}

export const AdministratorProfileActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useAdministratorProfileActivityQuery(params.administratorId ?? '');
  const [fnInvite, inviteMutationTuple] = dataService.useInviteAdministratorMutation(params.administratorId ?? '');

  const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false);

  const isLoading =
    queryTuple.loading ||
    inviteMutationTuple.loading;



  async function handleClickResendInvite() {
    const email = queryTuple.data.invite?.recipientEmail ?? '';
    if (email) {
      await errorUtil.errorWrap(async () => {
        await fnInvite(email);
      });
    }
  }



  function handleClickInviteUser() {
    setIsInviteModalOpen(true);
  }



  function handleClickEditData() {
    navigate(`/admin/administrators/edit/${params.administratorId}`);
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        navigationBarSelectedLinkId="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.administrators,
          title: queryTuple.data.fullName,
          employeeId: queryTuple.data.employeeId,
          elRightButtons: [
            (!queryTuple.data.isLinkedToUser && queryTuple.data.invite) ? (
              <Button
                key="resendInvite"
                variant={queryTuple.data.invite.isInviteExpired ? 'contained' : 'outlined'}
                onClick={handleClickResendInvite}
                color="primary">
                {t('resendInvite')}
              </Button>
            ) : null,

            !queryTuple.data.isLinkedToUser ? (
              <Button
                key="inviteUser"
                variant="outlined"
                onClick={handleClickInviteUser}
                color="primary">
                {t('inviteUser')}
              </Button>
            ) : null,

            (
              <Button
                key="editData"
                variant="outlined"
                onClick={handleClickEditData}
                color="primary"
                startIcon={(
                  <Create />
                )}>
                {t('editData')}
              </Button>
            )
          ],
          hasBackButton: true
        }}>

        <Grid
          item
          md={3}>
          <GeneralInformationSection
            administratorId={params.administratorId ?? ''} />
        </Grid>

      </BaseActivityTemplate>

      {isInviteModalOpen && (
        <InviteModal
          isOpen
          isLoading={inviteMutationTuple.loading}
          fnInvite={fnInvite}
          onAccept={() => setIsInviteModalOpen(false)}
          onCancel={() => setIsInviteModalOpen(false)} />
      )}
    </>
  );
};
