import React from 'react';

import { Chip as MuiChip } from '@mui/material';
import { Observable } from 'rxjs';



export interface IValueDisplayData {
  label: string;
  backgroundColor: string;
  contrastColor?: string;
}

export interface IChipProps<T extends string> {
  valueId: T;
}

export function genChipComponent<T extends string>({
  obsValueId2DisplayData
}: {
  obsValueId2DisplayData: Observable<Record<string, IValueDisplayData>>;
}) {
  const Chip: React.FC<IChipProps<T>> = props => {
    const [valueId2DisplayData, setValueId2DisplayData] = React.useState<Record<string, IValueDisplayData>>({});

    const data = React.useMemo(
      () => valueId2DisplayData[props.valueId] ?? null,
      [
        props.valueId,
        valueId2DisplayData
      ]
    );

    React.useEffect(
      () => {
        const sub = obsValueId2DisplayData.subscribe(value =>
          setValueId2DisplayData(value));
        return () => sub.unsubscribe();
      },
      []
    );

    return (
      <div>
        <MuiChip
          label={data?.label ?? ""}
          style={{
            backgroundColor: data?.backgroundColor ?? '#000',
            color: data?.contrastColor ?? '#fff'
          }}
          size="small" />
      </div>
    );
  };

  return Chip;
}
