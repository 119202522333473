import React from 'react';
import _ from 'lodash';

import {
  Typography,
  Box,
  Grid,
  Button
} from '@mui/material';
import {
  BaseActivityTemplate,
  Form,
  paletteColors,
  SectionHeaderBox,
  fields,
  errorUtil,
  ConfirmationModal,
  SignatureCaptureModal,
  validationUtil,
  Signature
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  FieldPath,
  useFieldArray,
  useForm
} from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import { styled } from '@mui/material/styles';

import * as dataService from './dataService';
import * as types from './types';
import { RadioButtonGroup } from './RadioButtonGroup/RadioButtonGroup';
import { ImagesSection } from './ImagesSection/ImagesSection';
import { EditImageModal } from './EditImageModal/EditImageModal';
import { UseLatestSignatureModal } from './UseLatestPatientSignatureModal/UseLatestPatientSignatureModal';



const CheckboxController = fields.genCheckboxControllerComponent<types.IFormState>();
const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();



const ButtonRowBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));



interface IParams {
  visitId: string;
}

export const EditVisitActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.visitId ?? '');
  const [fnSave, saveMutationTuple] = dataService.useEmployeeSaveVisitMutation(params.visitId ?? '');

  const [editImageModalIndex, setEditImageModalIndex] = React.useState<number | null>(null);
  const [deleteImageModalIndex, setDeleteImageModalIndex] = React.useState<number | null>(null);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = React.useState(false);
  const [isUseLatestSignatureModalOpen, setIsUseLatestSignatureModalOpen] = React.useState(false);
  const [latestPatientSignatureFileKey, setLatestPatientSignatureFileKey] = React.useState('');

  const form = useForm<types.IFormState>({
    defaultValues: {
      tasks: [],
      hasPatientConditionChanged: false,
      patientConditionChangedDescription: "",
      additionalComments: "",
      temperature: "",
      bloodPressure: "",
      heartRate: "",
      satRa: "",
      satO2: "",
      o2l: "",
      medicationHeld: "",
      patientAppearances: "",
      locationTypeValues: [],
      images: [],
      signature: null
    }
  });

  const hasPatientConditionChanged = form.watch('hasPatientConditionChanged');
  const signature = form.watch('signature');
  const images = form.watch('images');

  const taskFields = useFieldArray({
    control: form.control,
    name: 'tasks',
    keyName: '_key'
  });

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading;



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        const fieldPaths: FieldPath<types.IFormState>[] = [
          'tasks',
          'hasPatientConditionChanged',
          'patientConditionChangedDescription',
          'additionalComments',
          'temperature',
          'bloodPressure',
          'heartRate',
          'satRa',
          'satO2',
          'o2l',
          'medicationHeld',
          'patientAppearances',
          'locationTypeValues',
          'images',
          'signature'
        ];

        const formState = queryTuple.data.formState;
        _.forEach(fieldPaths, path => {
          form.setValue(path, _.get(formState, path), {shouldDirty: false});
        });
      }
    },
    [
      queryTuple.loading,
      queryTuple.data.formState
    ]
  );



  function handleAcceptEditImageModal(index: number, image: types.IImage) {
    form.setValue('images', update(images, {
      [index]: {
        $set: image
      }
    }));
    setEditImageModalIndex(null);
  }



  function handleConfirmDeleteImage(index: number) {
    form.setValue('images', update(images, {
      $splice: [[index, 1]]
    }));
    setDeleteImageModalIndex(null);
  }



  function handleAcceptSignatureCaptureModal(dataUrl: string) {
    setIsSignatureModalOpen(false);
    setLatestPatientSignatureFileKey('');
    form.setValue('useLatestPatientSignature', false);
    form.setValue('signature', {
      dataUrl,
      fileKey: ''
    });
  }



  function handleAcceptUseLatestSignatureModal(fileKey: string) {
    setIsUseLatestSignatureModalOpen(false);
    setLatestPatientSignatureFileKey(fileKey);
    form.setValue('useLatestPatientSignature', true);
    form.setValue('signature', null);
  }



  async function handleSubmit(formState: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnSave(formState);
      navigate(-1);
    });
  }



  return (
    <>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <BaseActivityTemplate
          mode="employee"
          isLoading={isLoading}
          headerOptions={{
            backgroundColor: paletteColors.visits,
            title: queryTuple.data.displayName,
            hasBackButton: true
          }}
          footerOptions={{
            hasDeleteButton: false,
            onClickCancel: () => navigate(-1)
          }}>

          <Grid container>

            <Grid
              item
              md={6}>

              <Box mb={3}>
                <SectionHeaderBox>
                  <Typography variant="h2">
                    {t('visits:tasks')}
                  </Typography>
                </SectionHeaderBox>

                {taskFields.fields.length === 0 ? (
                  <>
                    {t('visits:noTasks')}
                  </>

                ) : (
                  <ul>
                    {_.map(taskFields.fields, (field, index) => (
                      <li key={field.id}>
                        <CheckboxController
                          formControl={form.control}
                          fieldKey={`tasks.${index}.isCompleted`}
                          label={field.title}
                          isDisabled={isLoading} />

                        {field.description && (
                          <ul>
                            <li>{field.description}</li>
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </Box>

            </Grid>

            <Grid
              item
              md={6}>

              <Box mb={3}>
                <ImagesSection
                  images={images}
                  onClickAdd={() => setEditImageModalIndex(images.length)}
                  onClickEdit={index => setEditImageModalIndex(index)}
                  onClickDelete={index => setDeleteImageModalIndex(index)} />
              </Box>

              <Box mb={3}>
                <SectionHeaderBox>
                  <Typography variant="h2">
                    {t('visits:signature')}
                  </Typography>
                </SectionHeaderBox>

                <Box mb={3}>
                  {!signature && !latestPatientSignatureFileKey && (
                    <Typography variant="body1">
                      {t('visits:noSignature')}
                    </Typography>
                  )}

                  {signature && (
                    <Signature
                      source={signature.dataUrl ? {
                        type: 'dataUrl',
                        value: signature.dataUrl
                      } : {
                        type: 'fileKey',
                        value: signature.fileKey
                      }} />
                  )}

                  {latestPatientSignatureFileKey && (
                    <Signature
                      source={{
                        type: 'fileKey',
                        value: latestPatientSignatureFileKey
                      }} />
                  )}
                </Box>

                <ButtonRowBox>
                  {queryTuple.data.patientHasSignature && (
                    <Box mr={1}>
                      <Button
                        variant="outlined"
                        onClick={() => setIsUseLatestSignatureModalOpen(true)}
                        disabled={!!latestPatientSignatureFileKey}>
                        {!latestPatientSignatureFileKey ?
                          t('visits:useLatestPatientSignature') :
                          t('visits:usingLatestPatientSignature')}
                      </Button>
                    </Box>
                  )}

                  <Button
                    variant="outlined"
                    onClick={() => setIsSignatureModalOpen(true)}>
                    {t('visits:enterNewSignature')}
                  </Button>
                </ButtonRowBox>
              </Box>

            </Grid>

          </Grid>

          <Box mb={3}>
            <SectionHeaderBox>
              <Typography variant="h2">
                {t('visits:aboutVisit')}
              </Typography>
            </SectionHeaderBox>

            <Box mb={3}>
              <CheckboxController
                formControl={form.control}
                fieldKey="hasPatientConditionChanged"
                label={t('visits:hasPatientConditionChanged')}
                isDisabled={isLoading} />

              {hasPatientConditionChanged && (
                <TextFieldController
                  formControl={form.control}
                  fieldKey="patientConditionChangedDescription"
                  label={t('visits:describeThePatientsConditionChange')}
                  numLines={3}
                  isRequired
                  isDisabled={isLoading} />
              )}
            </Box>

            <Box mb={3}>
              <TextFieldController
                formControl={form.control}
                fieldKey="additionalComments"
                label={t('visits:additionalComments')}
                numLines={3}
                isDisabled={isLoading} />
            </Box>

            <Box mb={3}>
              <Grid container>

                <Grid
                  item
                  md={3}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="temperature"
                    label={t('visits:temperatureF')}
                    isDisabled={isLoading}
                    extraValidationRules={{
                      pattern: validationUtil.rules.temperatureRule
                    }} />
                </Grid>

                <Grid
                  item
                  md={3}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="bloodPressure"
                    label={t('visits:bloodPressure')}
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={3}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="heartRate"
                    label={t('visits:heartRate')}
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={3}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="satRa"
                    label={t('visits:satRa')}
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={3}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="satO2"
                    label={t('visits:satO2')}
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={3}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="o2l"
                    label={t('visits:o2l')}
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={3}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="medicationHeld"
                    label={t('visits:medicationHeld')}
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={3}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="patientAppearances"
                    label={t('visits:patientAppearances')}
                    isDisabled={isLoading} />
                </Grid>

              </Grid>
            </Box>

            <RadioButtonGroup
              form={form}
              fieldKey="locationTypeValues"
              title={t('visits:locationType')}
              schemaValuesByValue={queryTuple.data.locationTypeSchema}
              labelTranslationPrefix="visits:locationTypeValues"
              isDisabled={isLoading} />
          </Box>

        </BaseActivityTemplate>
      </Form>

      {editImageModalIndex !== null && (
        <EditImageModal
          initImage={images[editImageModalIndex] ?? null}
          onCancel={() => setEditImageModalIndex(null)}
          onAccept={image => handleAcceptEditImageModal(editImageModalIndex, image)} />
      )}

      {deleteImageModalIndex !== null && (
        <ConfirmationModal
          isOpen
          title={t('areYouSure')}
          message={t('visits:confirmDeleteImage')}
          onCancel={() => setDeleteImageModalIndex(null)}
          onAccept={() => handleConfirmDeleteImage(deleteImageModalIndex)} />
      )}

      {isSignatureModalOpen && (
        <SignatureCaptureModal
          onAccept={handleAcceptSignatureCaptureModal}
          onCancel={() => setIsSignatureModalOpen(false)} />
      )}

      {isUseLatestSignatureModalOpen && (
        <UseLatestSignatureModal
          visitId={params.visitId ?? ''}
          onAccept={handleAcceptUseLatestSignatureModal}
          onCancel={() => setIsUseLatestSignatureModalOpen(false)} />
      )}
    </>
  );
};
