import React from 'react';

import {
  Box,
  Button
} from '@mui/material';
import {
  errorUtil,
  BaseActivityTemplate,
  LoadingOverlay,
  AuthCard,
  AuthTitle,
  AuthDescription,
  Form,
  fields
} from 'modules';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from '../types';
import * as dataService from './dataService';
import { styled } from '@mui/material/styles';
import { Email } from '@mui/icons-material';



const IconContainerBox = styled(Box)(({theme}) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));



interface IFormData {
  code: string;
}

const TextFieldController = fields.genTextFieldControllerComponent<IFormData>();



export interface IProps {
  inviteCode: string;
  onChangeState: (state: types.IState) => void;
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();
  const [fnConfirmCode, confirmCodeTuple] = dataService.useConfirmCode();

  const form = useForm<IFormData>({
    defaultValues: {
      code: ""
    }
  });



  async function processSubmit(formData: IFormData) {
    await errorUtil.errorWrap(async () => {
      await fnConfirmCode(props.inviteCode, formData.code);
      props.onChangeState('done');
    });
  }



  return (
    <BaseActivityTemplate
      mode="auth"
      useAuthBackground
      onlyAllowLoggedOutUsers>

      <LoadingOverlay
        active={confirmCodeTuple.isLoading} />

      <AuthCard.Comp>
        <Form onSubmit={form.handleSubmit(processSubmit)}>

          <Box mb={1}>
            <AuthTitle.Comp>
              {t('auth:enterVerificationCode')}
            </AuthTitle.Comp>
          </Box>

          <IconContainerBox>
            <Email
              sx={{fontSize: 60}}
              color="warning" />
          </IconContainerBox>

          <Box mb={1}>
            <AuthDescription.Comp>
              {t('auth:registerSentCode')}
            </AuthDescription.Comp>
          </Box>

          <Box mb={3}>
            <TextFieldController
              label={t('auth:code')}
              formControl={form.control}
              fieldKey="code"
              isRequired />
          </Box>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth>
            {t('auth:confirm')}
          </Button>

        </Form>
      </AuthCard.Comp>

    </BaseActivityTemplate>
  );
};
