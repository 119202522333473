import React from 'react';
import _ from 'lodash';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';

import { ChevronRight as BaseChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';



interface IContainerBoxProps {
  isLink: boolean;
}

const ContainerBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isLink'
})<IContainerBoxProps>(({isLink, theme}) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: '#F4F9FD',
  borderRadius: '4px',
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(3),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  ...(isLink && ({
    cursor: 'pointer'
  }))
}));

const TitleGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch'
}));

const TitleTypography = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '16px'
}));

const SubtitleTypography = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '14px',
  color: '#000'
}));

const AdditionalInfoTypography = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '16px'
}));

const ChevronRight = styled(BaseChevronRight)(() => ({
  display: 'block'
}));



export interface IDetailedListItemProps {
  title: string;
  subtitle?: string;
  elLeft?: JSX.Element;
  buryLeftClickEvent?: boolean;
  additionalInfo?: string | JSX.Element;
  toRoute?: string;
}

export const DetailedListItem: React.FC<IDetailedListItemProps> = props => {
  const navigate = useNavigate();



  const buryClickEvent: React.MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();
  };



  return (
    <ContainerBox
      isLink={!!props.toRoute}
      onClick={props.toRoute ?
        () => navigate(props.toRoute ?? '') :
        undefined}>

      <Grid
        container
        spacing={0}>

        <TitleGrid
          item
          md={3}>
          <TitleTypography>
            {props.title}
          </TitleTypography>

          <div onClick={props.buryLeftClickEvent ? event => buryClickEvent(event) : undefined}>
            {props.elLeft}
          </div>
        </TitleGrid>

        <Grid
          item
          md={9}>
          {_.isString(props.additionalInfo) ? (
            <AdditionalInfoTypography>
              {props.additionalInfo}
            </AdditionalInfoTypography>
          ) : props.additionalInfo}
        </Grid>

        <Grid
          item
          md={12}>
          <SubtitleTypography>
            {props.subtitle}
          </SubtitleTypography>
        </Grid>

      </Grid>

      <Box>
        <ChevronRight color="primary" />
      </Box>

    </ContainerBox>
  );
};
