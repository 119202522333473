import React from 'react';

import {
  BaseActivityTemplate,
  LoadingOverlay
} from 'modules';

import { useNavigate } from 'react-router-dom';

import * as dataService from './dataService';



export const RedirectActivity: React.FC = () => {
  const navigate = useNavigate();

  const [fnQuery, queryTuple] = dataService.useLazyQuery();



  React.useEffect(
    () => {
      fnQuery();
    },
    []
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        if (queryTuple.data.typeName === 'administrator') {
          if (
            (queryTuple.data.permissionCategories.has('fullAdmin')) ||
            (queryTuple.data.permissionCategories.has('supervisor'))
          ) {
            navigate('/admin/patients');
          } else {
            navigate('/admin/employees');
          }

        } else if (queryTuple.data.typeName === 'employee') {
          navigate('/employee/myVisits');
        }
      }
    },
    [
      queryTuple,
      navigate
    ]
  );



  return (
    <BaseActivityTemplate mode="auth">

      <LoadingOverlay
        active={queryTuple.loading} />

    </BaseActivityTemplate>
  );
};
