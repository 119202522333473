import React from 'react';
import _ from 'lodash';

import {
  useHireStatusSelectFieldQuery as useBaseQuery
} from 'generated/graphql';

import { IGenericEnum } from 'modules/layer2';



export const hireStatusNames = [
  'inactive',
  'hired',
  'suspended'
] as const;
export type IHireStatusName = typeof hireStatusNames[number];

export type IHireStatus = IGenericEnum<IHireStatusName>;

export interface IData {
  enumValues: IHireStatus[];
}

export function useHireStatusSelectFieldQuery() {
  const tuple = useBaseQuery();

  return React.useMemo(
    () => {
      const data: IData = {
        enumValues: _.map(tuple.data?.hireStatuses ?? [], (gqlHireStatus): IHireStatus => ({
          id: gqlHireStatus.id,
          name: gqlHireStatus.name,
          displayName: gqlHireStatus.displayName
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
