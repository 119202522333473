import _ from 'lodash';

import * as dataService from './dataService';
import { BehaviorSubject } from 'rxjs';
import { IApolloClient } from 'modules/layer1/apolloService';
import i18next from 'i18next';

export type {
  IDayOfWeek,
  IDayOfWeekName,
  IDiscipline,
  IDisciplineName,
  IEpisodeContinuationType,
  IEpisodeContinuationTypeName,
  IEpisodeInactiveType,
  IEpisodeInactiveTypeName,
  IEpisodeStatus,
  IEpisodeStatusName,
  IGender,
  IGenderName,
  IHireStatus,
  IHireStatusName,
  IMissedVisitReason,
  IMissedVisitReasonName,
  IPatientStatus,
  IPatientStatusName,
  IPermissionCategory,
  IPermissionCategoryName,
  IRequirementRecurrenceType,
  IRequirementRecurrenceTypeName,
  IRequirementStatus,
  IRequirementStatusName,
  IRequirementType,
  IRequirementTypeName,
  IShift,
  IShiftName,
  ITimesheetVisitApprovalStatus,
  ITimesheetVisitApprovalStatusName,
  IVisitCategory,
  IVisitCategoryName,
  IVisitStatus,
  IVisitStatusName,
  IVisitTemplateFrequencyUnit,
  IVisitTemplateFrequencyUnitName,
  IEnumNamesList,
  IGenericEnum
} from './dataService';



export type IAllEnums = dataService.IAllEnums;
export const subjAllEnums = new BehaviorSubject<IAllEnums>({
  daysOfWeek: [],
  disciplines: [],
  episodeContinuationTypes: [],
  episodeInactiveTypes: [],
  episodeStatuses: [],
  genders: [],
  hireStatuses: [],
  missedVisitReasons: [],
  patientStatuses: [],
  permissionCategories: [],
  requirementRecurrenceTypes: [],
  requirementStatuses: [],
  requirementTypes: [],
  shifts: [],
  timesheetVisitApprovalStatuses: [],
  visitCategories: [],
  visitStatuses: [],
  visitTemplateFrequencyUnits: []
});



export async function init(client: IApolloClient) {
  const result = await dataService.runQuery(client);
  if (result) {
    subjAllEnums.next(__populateDisplayNames(result) as IAllEnums);
  }

  i18next.on('languageChanged', () => {
    subjAllEnums.next(__populateDisplayNames() as IAllEnums);
  });
}



function __populateDisplayNames(initValue?: IAllEnums) {
  return _.mapValues(initValue ?? subjAllEnums.getValue(), (enumArray: dataService.IGenericEnum<string>[], enumName) =>
    _.map(enumArray, enumValue => ({
      ...enumValue,
      displayName: i18next.t(`enums:${enumName}.${enumValue.name}`)
    })));
}
