import {
  genEnumSelectFieldComponent,
  enumCache
} from 'modules/layer2';

import i18next from 'i18next';
import * as dataService from './dataService';

export type {
  IPatientStatus,
  IPatientStatusName
} from './dataService';



export const PatientStatusSelectField = genEnumSelectFieldComponent<dataService.IPatientStatusName, enumCache.IPatientStatus>(
  dataService.usePatientStatusSelectFieldQuery,
  () => i18next.t('patients:patientStatus')
);
