import React from 'react';
import _ from 'lodash';

import {
  paletteColors,
  genTableComponent,
  IRow,
  chips,
  ConfirmationModal,
  errorUtil,
  LoadingOverlay
} from 'modules';

import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { TFunction } from 'i18next';



const __columnIdList = [
  'type',
  'displayName',
  'assignedUser',
  'dueTimestamp',
  'status',
  'delete'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();



export function genElRightButtons(t: TFunction, fnHistoryPush: (route: string) => void): JSX.Element[] {
  return [];
}



export const AssignmentsTab: React.FC = () => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveRequirementMutation(queryTuple.refetch);

  const [idToDelete, setIdToDelete] = React.useState('');

  const isLoading =
    queryTuple.loading ||
    archiveMutationTuple.loading;



  async function handleConfirmDeleteRequirement() {
    setIdToDelete('');
    await errorUtil.errorWrap(async () => {
      await fnArchive(idToDelete);
    });
  }



  return (
    <>
      <LoadingOverlay active={isLoading} />

      <Table
        columns={[
          {
            id: 'type',
            label: t('type')
          },
          {
            id: 'displayName',
            label: t('name')
          },
          {
            id: 'assignedUser',
            label: t('requirements:assignedUser')
          },
          {
            id: 'dueTimestamp',
            label: t('requirements:due')
          },
          {
            id: 'status',
            label: t('status')
          },
          {
            id: 'delete',
            label: ""
          }
        ]}
        rows={_.map(queryTuple.data.requirements, (requirement): IRow<IColumnId> => ({
          id: requirement.id,
          columnId2Value: {
            type: {
              type: 'component',
              label: (
                <chips.RequirementTypeChip
                  valueId={requirement.type} />
              ),
              sortableValue: requirement.type
            },
            displayName: {
              type: 'string',
              label: requirement.displayName,
              toRoute: `/admin/requirements/view/${requirement.id}`
            },
            assignedUser: {
              type: 'string',
              label: requirement.assignedUser.fullName,
              toRoute: `/admin/user/profile/${requirement.assignedUser.id}`
            },
            dueTimestamp: {
              type: 'string',
              label: requirement.dueTimestamp.format('YYYY-MM-DD'),
              sortableValue: requirement.dueTimestamp.unix()
            },
            status: {
              type: 'component',
              label: (
                <chips.RequirementStatusChip
                  valueId={requirement.status} />
              ),
              sortableValue: requirement.status
            },
            delete: {
              type: 'component',
              label: (
                <IconButton
                  size="small"
                  onClick={() => setIdToDelete(requirement.id)}>
                  <Delete
                    htmlColor={paletteColors.danger} />
                </IconButton>
              ),
              sortableValue: ""
            }
          }
        }))}
        initSortOptions={{
          sortByColumnId: 'dueTimestamp',
          sortDirection: 'descending'
        }} />

      <ConfirmationModal
        isOpen={!!idToDelete}
        title={t('requirements:deleteRequirement')}
        message={t('requirements:deleteRequirementConfirmationModal')}
        acceptButtonLabel={t('delete')}
        onAccept={handleConfirmDeleteRequirement}
        onCancel={() => setIdToDelete('')} />
    </>
  );
};
