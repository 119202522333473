import React from 'react';

import {
  PatientProfileActivityPatientDetailsTabIntakeSectionProviderFieldsFragment,
  usePatientProfileActivityPatientDetailsTabIntakeSectionQuery as useBaseQuery
} from 'generated/graphql';

import { useTranslation } from 'react-i18next';



export interface IProvider {
  id: string;
  fullName: string;
  primaryPhone: string;
  faxNumber: string;
}

export interface IData {
  socDate: string;
  primaryProvider: IProvider | null;
  secondaryProvider: IProvider | null;
  referringProvider: IProvider | null;
}

export function useQuery(id: string) {
  const {t} = useTranslation();
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlPatient = tuple.data?.patient;
      const data: IData = {
        socDate: gqlPatient?.socDate || t('noValue'),
        primaryProvider: __gqlProvider2Client(gqlPatient?.primaryProvider ?? null),
        secondaryProvider: __gqlProvider2Client(gqlPatient?.secondaryProvider ?? null),
        referringProvider: __gqlProvider2Client(gqlPatient?.referringProvider ?? null)
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



function __gqlProvider2Client(gqlProvider: PatientProfileActivityPatientDetailsTabIntakeSectionProviderFieldsFragment | null): IProvider | null {
  return gqlProvider ? {
    id: gqlProvider.id,
    fullName: `${gqlProvider.firstName} ${gqlProvider.lastName}`.trim(),
    primaryPhone: gqlProvider.primaryPhone,
    faxNumber: gqlProvider.faxNumber
  } : null;
}
