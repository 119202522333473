import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';



const DisclaimerTypography = styled(Typography)(({theme}) => ({
  color: theme.palette.error.main
}));



export const Comp: React.FC = props => (
  <DisclaimerTypography>
    {props.children}
  </DisclaimerTypography>
);
