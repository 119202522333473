import React from 'react';
import _ from 'lodash';

import {
  genTableComponent,
  IRow,
  chips,
  LoadingOverlay
} from 'modules';

import { Lock } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { Button } from '@mui/material';
import { TFunction } from 'i18next';



const __columnIdList = [
  'type',
  'isLocked',
  'displayName',
  'created'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();



export function genElRightButtons(t: TFunction, fnHistoryPush: (route: string) => void): JSX.Element[] {
  return [
    (
      <Button
        key="createPreset"
        variant="contained"
        onClick={() => fnHistoryPush('/admin/requirementPresets/create')}>
        {t('requirements:addNewPreset')}
      </Button>
    )
  ];
}



export const PresetsTab: React.FC = () => {
  const {t} = useTranslation();

  const queryTuple = dataService.usePresetsTabQuery();

  const isLoading =
    queryTuple.loading;



  return (
    <>
      <LoadingOverlay active={isLoading} />

      <Table
        columns={[
          {
            id: 'type',
            label: t('type')
          },
          {
            id: 'isLocked',
            label: t('lockedFlag')
          },
          {
            id: 'displayName',
            label: t('displayName')
          },
          {
            id: 'created',
            label: t('created')
          }
        ]}
        rows={_.map(queryTuple.data.presets, (preset): IRow<IColumnId> => ({
          id: preset.id,
          columnId2Value: {
            type: {
              type: 'component',
              label: (
                <chips.RequirementTypeChip
                  valueId={preset.type} />
              ),
              sortableValue: preset.type
            },
            isLocked: {
              type: 'component',
              label: preset.isLocked ? (
                <Lock />

              ) : (
                <div />
              ),
              sortableValue: preset.isLocked ? 1 : 0
            },
            displayName: {
              type: 'string',
              label: preset.displayName,
              sortableValue: preset.displayName.toLowerCase(),
              toRoute: `/admin/requirementPresets/view/${preset.id}`
            },
            created: {
              type: 'string',
              label: preset.metaCreated.format('YYYY-MM-DD HH:mm'),
              sortableValue: preset.metaCreated.unix()
            }
          }
        }))}
        initSortOptions={{
          sortByColumnId: 'displayName',
          sortDirection: 'ascending'
        }} />
    </>
  );
};
