import React from 'react';
import _ from 'lodash';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  UseControllerProps
} from 'react-hook-form';

import { TextField as BaseTextField } from '@mui/material';

import { validationUtil } from 'modules/layer0';
import { FieldContainer } from 'modules/layer1';



export interface ITextFieldProps {
  label: string;
  type?: 'text' | 'email' | 'password' | 'tel';
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isDisabled?: boolean;
  numLines?: number;
}

export const TextField: React.FC<ITextFieldProps> = props => {
  return (
    <FieldContainer.Comp>
      <BaseTextField
        label={props.label}
        type={props.type}
        value={props.value ?? ""}
        onChange={event => props.onChange(event.target.value)}
        error={!!props.errorMessage}
        helperText={props.errorMessage}
        variant="outlined"
        disabled={props.isDisabled}
        multiline={!_.isUndefined(props.numLines) && props.numLines > 1}
        rows={props.numLines}
        fullWidth />
    </FieldContainer.Comp>
  );
};



export interface ITextFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label: string;
  type?: ITextFieldProps['type'];
  isDisabled?: boolean;
  numLines?: number;
  extraValidationRules?: UseControllerProps['rules'];
}

export function genTextFieldControllerComponent<TFormState extends FieldValues>() {
  const TextFieldController: React.FC<ITextFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        name={props.fieldKey}
        control={props.formControl}
        rules={{
          ...props.extraValidationRules,
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <TextField
            label={props.label}
            type={props.type}
            value={field.value}
            onChange={field.onChange}
            numLines={props.numLines}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled} />
        )} />
    );
  };

  return TextFieldController;
}
