import React from 'react';

import { BaseActivityTemplate } from 'modules';



export const EmployeeHomeActivity: React.FC = () => {
  return (
    <BaseActivityTemplate
      mode="employee">

      Employee home

    </BaseActivityTemplate>
  );
};
