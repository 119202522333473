import React from 'react';

import {
  genCheckboxControllerComponent,
  genTextFieldControllerComponent
} from 'modules';
import {
  FieldPath,
  useFieldArray,
  UseFormReturn
} from 'react-hook-form';
import {
  Typography,
  Box,
  Grid,
  Divider
} from '@mui/material';

import * as types from '../types';
import { useTranslation } from 'react-i18next';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();
const CheckboxController = genCheckboxControllerComponent<types.IFormState>();



export type IFieldKey =
  'medicationValues' |
  'justificationOfServicesValues';

export interface ICheckboxGroupProps {
  form: UseFormReturn<types.IFormState>;
  fieldKey: IFieldKey;
  title: string;
  schemaValuesByValue: Record<string, types.ISchemaValue>;
  labelTranslationPrefix: string;
  isDisabled?: boolean;
}

export const CheckboxGroup: React.FC<ICheckboxGroupProps> = props => {
  const {t} = useTranslation();

  const fields = useFieldArray({
    control: props.form.control,
    name: props.fieldKey,
    keyName: '_key'
  });
  const fieldValues = props.form.watch(props.fieldKey);



  return (
    <>
      <Box mb={3}>
        <Typography variant="h2">
          {props.title}
        </Typography>
      </Box>

      <Box mb={3}>
        <Grid container>
          {fields.fields.map((field, index) => {
            const schema = props.schemaValuesByValue[field.name];
            const descriptionLabel = schema.descriptionLabel;

            return (
              <Grid
                key={field.name}
                item
                md={4}>

                <CheckboxController
                  formControl={props.form.control}
                  fieldKey={`${props.fieldKey}.${index}.isChecked` as FieldPath<types.IFormState>}
                  label={t(`${props.labelTranslationPrefix}.${field.name}`)}
                  isDisabled={props.isDisabled} />

                {fieldValues[index].isChecked && (
                  <>
                    {schema.hasDescription && (
                      <Box mt={1}>
                        <TextFieldController
                          formControl={props.form.control}
                          fieldKey={`${props.fieldKey}.${index}.description` as FieldPath<types.IFormState>}
                          label={descriptionLabel ?
                            t(`${props.labelTranslationPrefix}.${descriptionLabel}`) :
                            t('description')}
                          isDisabled={props.isDisabled} />
                      </Box>
                    )}
                  </>
                )}

              </Grid>
            );
          })}
        </Grid>

        <Box mt={3}>
          <Divider />
        </Box>
      </Box>
    </>
  );
};
