import React from 'react';

import {
  Box,
  Grid,
  Divider,
  Typography,
  IconButton,
  Button
} from '@mui/material';
import {
  UseFormReturn,
  useFieldArray
} from 'react-hook-form';

import { Delete } from '@mui/icons-material';
import { selectFields } from 'modules';
import * as types from '../types';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import * as uuid from 'uuid';



const TitleRow = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const Row = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
}));

const Cell = styled(Box)(({theme}) => ({
  flex: '1 0',
  marginRight: theme.spacing(1)
}));



const ProviderSelectFieldController = selectFields.genProviderSelectFieldControllerComponent<types.IFormState>();
const DiagnosisSelectFieldController = selectFields.genDiagnosisSelectFieldControllerComponent<types.IFormState>();



export interface IProviderDiagnosisProcedureSectionProps {
  form: UseFormReturn<types.IFormState>;
  isDisabled: boolean;
}

export const ProviderDiagnosisProcedureSection: React.FC<IProviderDiagnosisProcedureSectionProps> = props => {
  const {t} = useTranslation();

  const otherDiagnosisFields = useFieldArray({
    control: props.form.control,
    name: 'otherDiagnosisValues',
    keyName: '_key'
  });



  function handleClickAddDiagnosis() {
    otherDiagnosisFields.append({
      _key: uuid.v4(),
      diagnosis: null
    } as types.IOtherDiagnosisValue);
  }



  function handleClickDelete(index: number) {
    otherDiagnosisFields.remove(index);
  }



  return (
    <Box mb={3}>
      <Grid container>

        <Grid
          item
          md={4}>
          <Box mb={3}>
            <Typography variant="h2">
              {t('adminEpisodes:primaryProvider')}
            </Typography>
          </Box>

          <Box mb={3}>
            <ProviderSelectFieldController
              formControl={props.form.control}
              fieldKey="primaryProviderId"
              label={t('adminEpisodes:primaryProvider')}
              isDisabled={props.isDisabled} />
          </Box>
        </Grid>

        <Grid
          item
          md={4}>
          <Box mb={3}>
            <Typography variant="h2">
              {t('adminEpisodes:primaryDiagnosis')}
            </Typography>
          </Box>

          <Box mb={3}>
            <DiagnosisSelectFieldController
              formControl={props.form.control}
              fieldKey="primaryDiagnosisValue"
              label={t('adminEpisodes:primaryDiagnosis')}
              isDisabled={props.isDisabled} />
          </Box>
        </Grid>

        <Grid
          item
          md={4}>
          <TitleRow>
            <Typography variant="h2">
              {t('adminEpisodes:otherDiagnoses')}
            </Typography>

            <Button onClick={handleClickAddDiagnosis}>
              {t('adminEpisodes:addDiagnosis')}
            </Button>
          </TitleRow>

          {otherDiagnosisFields.fields.map((field, index) => (
            <Row key={field._key}>
              <Cell>
                <DiagnosisSelectFieldController
                  formControl={props.form.control}
                  fieldKey={`otherDiagnosisValues[${index}].diagnosis`}
                  label={t('adminEpisodes:diagnosis')}
                  isDisabled={props.isDisabled} />
              </Cell>

              <IconButton
                onClick={() => handleClickDelete(index)}
                size="small">
                <Delete />
              </IconButton>
            </Row>
          ))}
        </Grid>

      </Grid>

      <Box mt={3}>
        <Divider />
      </Box>
    </Box>
  );
};
