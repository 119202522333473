import React from 'react';
import _ from 'lodash';

import {
  BaseModal,
  enumCache,
  selectFields,
  genEnumSelectFieldControllerComponent,
  Form,
  RowBox,
  CellBox,
  fields,
  errorUtil,
  LoadingOverlay
} from 'modules';
import {
  useFieldArray,
  useForm
} from 'react-hook-form';
import {
  Button,
  IconButton,
  Box,
  Typography
} from '@mui/material';

import { Clear } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';
import * as dataService from './dataService';



const DisciplineSelectFieldController = genEnumSelectFieldControllerComponent<dataService.IFormState, enumCache.IDisciplineName>(selectFields.DisciplineSelectField);
const TextFieldController = fields.genTextFieldControllerComponent<dataService.IFormState>();



export interface IProps {
  onAccept: (threadId: string) => void;
  onClose: () => void;
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();

  const [fnMutate, mutationTuple] = dataService.usePostMutation();

  const isLoading =
    mutationTuple.loading;

  const form = useForm<dataService.IFormState>({
    defaultValues: {
      disciplines: [
        {
          _key: uuid.v4(),
          name: ''
        }
      ],
      message: ""
    }
  });

  const disciplineFields = useFieldArray({
    control: form.control,
    name: 'disciplines',
    keyName: '_key'
  });



  function handleClickAddDiscipline() {
    disciplineFields.append({
      _key: uuid.v4(),
      name: ''
    });
  }



  function handleClickRemoveDiscipline(index: number) {
    if (disciplineFields.fields.length > 1) {
      disciplineFields.remove(index);
    }
  }



  async function handleSubmit(formState: dataService.IFormState) {
    const trMessage = formState.message.trim();
    if (!trMessage) {
      alert(t('errors.emptyMessage'));
      return;
    }

    await errorUtil.errorWrap(async () => {
      const threadId = await fnMutate(formState);
      props.onAccept(threadId);
    });
  }



  return (
    <BaseModal
      isOpen
      showButtons
      title={t('newDisciplineMessage')}
      onClose={props.onClose}>

      {buttons => (
        <>
          <LoadingOverlay active={isLoading} />

          <Form
            onSubmit={form.handleSubmit(handleSubmit)}>

            <Box mb={3}>
              <Typography>
                {t('disciplineMessageDescription')}
              </Typography>
            </Box>

            {_.map(disciplineFields.fields, (field, index) => (
              <RowBox key={field._key}>

                <CellBox>
                  <DisciplineSelectFieldController
                    formControl={form.control}
                    fieldKey={`disciplines.${index}.name`}
                    isDisabled={isLoading} />
                </CellBox>

                <IconButton
                  onClick={() => handleClickRemoveDiscipline(index)}>
                  <Clear />
                </IconButton>

              </RowBox>
            ))}

            <RowBox>
              <CellBox />

              <Button
                variant="text"
                onClick={handleClickAddDiscipline}>
                {t('addDiscipline')}
              </Button>
            </RowBox>

            <Box mb={3}>
              <TextFieldController
                label={t('message')}
                formControl={form.control}
                fieldKey="message"
                isRequired
                isDisabled={isLoading} />
            </Box>

            {buttons}

          </Form>
        </>
      )}

    </BaseModal>
  );
};
