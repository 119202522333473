import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  Form,
  paletteColors,
  genTextFieldControllerComponent,
  validationUtil,
  errorUtil
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  FieldPath,
  useForm
} from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import * as types from './types';
import { Grid } from '@mui/material';
import * as dataService from './dataService';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();



interface IParams {
  providerId?: string;
}

export const EditProviderActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const form = useForm<types.IFormState>({
    defaultValues: {
      id: '',
      firstName: "",
      lastName: "",
      primaryPhone: "",
      secondaryPhone: "",
      faxNumber: ""
    }
  });

  const [fnQuery, queryTuple] = dataService.useEditProviderActivityLazyQuery();
  const [fnSave, saveMutationTuple] = dataService.useSaveProviderMutation();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveProviderMutation();

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading ||
    archiveMutationTuple.loading;



  React.useEffect(
    () => {
      if (params.providerId && !queryTuple.called) {
        fnQuery(params.providerId);
      }
    },
    [
      params.providerId,
      queryTuple.called
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        const fieldPaths: FieldPath<types.IFormState>[] = [
          'id',
          'firstName',
          'lastName',
          'primaryPhone',
          'secondaryPhone',
          'faxNumber'
        ];

        const formState = queryTuple.data.formState;
        _.forEach(fieldPaths, path => {
          form.setValue(path, _.get(formState, path), {shouldDirty: false});
        });
      }
    },
    [
      queryTuple.data,
      queryTuple.loading
    ]
  );



  async function handleSubmit(formData: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnSave(formData);
      navigate(-1);
    });
  }



  async function handleConfirmDelete() {
    await errorUtil.errorWrap(async () => {
      await fnArchive(form.watch('id'));
      navigate('/admin/providers');
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.providers,
          title: !params.providerId ?
            t('adminProviders:newProvider') :
            queryTuple.data.fullName,
          hasBackButton: true
        }}
        footerOptions={{
          hasDeleteButton: !!params.providerId,
          deleteButtonLabel: t('adminProviders:deleteProvider'),
          onConfirmDelete: handleConfirmDelete,
          onClickCancel: () => navigate(-1)
        }}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="firstName"
              label={t('firstName')}
              isRequired
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="lastName"
              label={t('lastName')}
              isRequired
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={6} />

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="primaryPhone"
              label={t('primaryPhone')}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="secondaryPhone"
              label={t('secondaryPhone')}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={form.control}
              fieldKey="faxNumber"
              label={t('adminProviders:faxNumber')}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={isLoading} />
          </Grid>

        </Grid>

      </BaseActivityTemplate>
    </Form>
  );
};
