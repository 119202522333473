import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  genTextFieldControllerComponent,
  validationUtil
} from 'modules';

import { Control } from 'react-hook-form';
import * as types from '../types';
import { useTranslation } from 'react-i18next';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();



export interface IContactInformationSectionProps {
  formControl: Control<types.IFormState>;
  isDisabled: boolean;
}

export const ContactInformationSection: React.FC<IContactInformationSectionProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('contactInformation')}
      </Typography>

      <Box mt={4}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={6}>

            <TextFieldController
              fieldKey="address"
              label={t('address')}
              formControl={props.formControl}
              isDisabled={props.isDisabled} />

          </Grid>

          <Grid
            item
            md={3}>

            <TextFieldController
              fieldKey="primaryPhone"
              label={t('primaryPhone')}
              formControl={props.formControl}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={props.isDisabled} />

          </Grid>

          <Grid
            item
            md={3}>

            <TextFieldController
              fieldKey="secondaryPhone"
              label={t('secondaryPhone')}
              formControl={props.formControl}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={props.isDisabled} />

          </Grid>

        </Grid>

      </Box>

    </Box>
  );
};
