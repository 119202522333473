import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  SkeletonRows,
  ValueWithLabel
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { Provider } from './Provider/Provider';



export interface IIntakeSectionProps {
  patientId: string;
}

export const IntakeSection: React.FC<IIntakeSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.patientId);



  return queryTuple.loading ? (
    <SkeletonRows numRows={10} />

  ) : (
    <Box>

      <Grid container>
        <ValueWithLabel
          label={t('patients:socDate')}
          value={queryTuple.data.socDate} />
      </Grid>

      <Box mt={4}>
        {queryTuple.data.primaryProvider && (
          <Provider
            id={queryTuple.data.primaryProvider.id}
            title={t('patients:primaryProvider')}
            fullName={queryTuple.data.primaryProvider.fullName}
            primaryPhone={queryTuple.data.primaryProvider.primaryPhone}
            faxNumber={queryTuple.data.primaryProvider.faxNumber} />
        )}

        {queryTuple.data.secondaryProvider && (
          <Provider
            id={queryTuple.data.secondaryProvider.id}
            title={t('patients:secondaryProvider')}
            fullName={queryTuple.data.secondaryProvider.fullName}
            primaryPhone={queryTuple.data.secondaryProvider.primaryPhone}
            faxNumber={queryTuple.data.secondaryProvider.faxNumber} />
        )}

        {queryTuple.data.referringProvider && (
          <Provider
            id={queryTuple.data.referringProvider.id}
            title={t('patients:referringProvider')}
            fullName={queryTuple.data.referringProvider.fullName}
            primaryPhone={queryTuple.data.referringProvider.primaryPhone}
            faxNumber={queryTuple.data.referringProvider.faxNumber} />
        )}
      </Box>

    </Box>
  );
};
