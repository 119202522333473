import React from 'react';

import {
  usePatientProfileActivityPatientDetailsTabPharmacySectionQuery
} from 'generated/graphql';



export interface IData {
  name: string;
  route: string
}

export function useQuery(id: string) {
  const tuple = usePatientProfileActivityPatientDetailsTabPharmacySectionQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlPharmacy = tuple.data?.patient?.pharmacy;
      const data: IData = {
        name: gqlPharmacy?.name ?? "",
        route: gqlPharmacy?.id ?
          `/admin/pharmacies/view/${gqlPharmacy.id}` :
          ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
