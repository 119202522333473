import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useAdministratorProfileGeneralInformationSectionQuery as useBaseQuery
} from 'generated/graphql';
import {
  dateUtil,
  enumCache
} from 'modules';

import { useTranslation } from 'react-i18next';



export interface IData {
  profilePictureFileKey: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  employeeId: string;
  email: string;
  address: string;
  primaryPhone: string;
  secondaryPhone: string;
  supervisorId: string;
  altSupervisorId: string;
  payRate: string;
  permissionCategories: enumCache.IPermissionCategoryName[];
}

export function useAdministratorProfileGeneralInformationSectionQuery(id: string) {
  const {t} = useTranslation();
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(() => {
    const gqlAdmin = tuple.data?.administrator;
    const data: IData = {
      profilePictureFileKey: gqlAdmin?.profilePictureFile?.key ?? '',
      firstName: gqlAdmin?.firstName || t('noValue'),
      lastName: gqlAdmin?.lastName || t('noValue'),
      birthdate: gqlAdmin?.birthdate ?
        moment(gqlAdmin.birthdate, moment.ISO_8601).format(dateUtil.displayDateFormat) :
        t('noValue'),
      employeeId: gqlAdmin?.employeeId || t('noValue'),
      email: gqlAdmin?.siteUserFull?.email || t('noValue'),
      address: gqlAdmin?.address || t('noValue'),
      primaryPhone: gqlAdmin?.primaryPhone || t('noValue'),
      secondaryPhone: gqlAdmin?.secondaryPhone || t('noValue'),
      supervisorId: gqlAdmin?.supervisor?.id ?? '',
      altSupervisorId: gqlAdmin?.altSupervisor?.id ?? '',
      payRate: gqlAdmin?.payRate ?
        `$${gqlAdmin.payRate} / hour` :
        t('noValue'),
      permissionCategories: _.map(gqlAdmin?.permissionCategories ?? [], gqlCategory => gqlCategory.name)
    };

    return {
      ...tuple,
      data
    };
  }, [tuple]);
}
