import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  Form,
  paletteColors,
  fields,
  selectFields,
  SectionHeaderBox,
  genUserSelectFieldControllerComponent,
  RowBox,
  CellBox,
  dateUtil,
  errorUtil
} from 'modules';
import {
  useFieldArray,
  useForm
} from 'react-hook-form';
import {
  Button,
  Grid,
  IconButton,
  Typography
} from '@mui/material';

import * as uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import * as dataService from './dataService';
import * as types from './types';



const RadioController = fields.genRadioControllerComponent<types.IFormState>();
const DateFieldController = fields.genDateFieldControllerComponent<types.IFormState>();
const PatientSelectFieldController = selectFields.PatientSelectField.genControllerComponent<types.IFormState>();
const UserSelectFieldController = genUserSelectFieldControllerComponent<types.IFormState>();



export const ExportTimesheetsActivity: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [fnQueryExport, queryTuple] = dataService.useLazyQuery();

  const form = useForm<types.IFormState>({
    defaultValues: {
      type: 'patient',
      startTimestamp: null,
      endTimestamp: null,
      patientId: '',
      employees: []
    }
  });

  const employeeUserIdFields = useFieldArray({
    control: form.control,
    name: 'employees',
    keyName: '_key'
  });

  const watchType = form.watch('type');

  const isLoading =
    queryTuple.loading;



  function handleClickAddEmployee() {
    employeeUserIdFields.append({
      _key: uuid.v4(),
      id: ''
    });
  }



  function handleClickRemoveEmployee(index: number) {
    employeeUserIdFields.remove(index);
  }



  async function handleSubmit(formState: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      if (formState.startTimestamp && formState.endTimestamp) {
        const mStart = dateUtil.dateAndTimeZone2Moment(formState.startTimestamp, dateUtil.defaultTimeZone);
        const mEnd = dateUtil.dateAndTimeZone2Moment(formState.endTimestamp, dateUtil.defaultTimeZone);

        if (!mStart) {
          throw new Error(t('visits:errors.invalidStartDate'));
        }

        if (!mEnd) {
          throw new Error(t('visits:errors.invalidEndDate'));
        }

        if (mEnd.isSameOrBefore(mStart)) {
          throw new Error(t('visits:errors.endBeforeStart'));
        }

        fnQueryExport(formState);
      }
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'humanResources'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.timesheets,
          title: t('visits:exportTimesheets')
        }}
        footerOptions={{
          saveButtonLabel: t('visits:export'),
          onClickCancel: () => navigate(-1)
        }}>

        <Grid container>

          <Grid
            item
            md={3}>
            <RadioController
              label={t('visits:reportType')}
              formControl={form.control}
              fieldKey="type"
              isDisabled={isLoading}
              options={[
                {
                  id: 'patient',
                  label: t('visits:patient')
                },
                {
                  id: 'employees',
                  label: t('visits:employees')
                },
                {
                  id: 'visitsPerEmployee',
                  label: t('visits:visitsPerEmployee')
                }
              ]} />
          </Grid>

          <Grid
            item
            md={9} />

          <Grid
            item
            md={3}>
            <DateFieldController
              label={t('visits:rangeStart')}
              formControl={form.control}
              fieldKey="startTimestamp"
              isRequired
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={3}>
            <DateFieldController
              label={t('visits:rangeEnd')}
              formControl={form.control}
              fieldKey="endTimestamp"
              isRequired
              isDisabled={isLoading} />
          </Grid>

          <Grid
            item
            md={6} />

          {watchType === 'patient' && (
            <>
              <Grid
                item
                md={3}>
                <PatientSelectFieldController
                  formControl={form.control}
                  fieldKey="patientId"
                  isRequired
                  isDisabled={isLoading} />
              </Grid>

              <Grid
                item
                md={9} />
            </>
          )}

          {(watchType === 'employees' || watchType === 'visitsPerEmployee') && (
            <>
              <Grid
                item
                md={3}>

                <SectionHeaderBox>
                  <Typography variant="h2">
                    {t('visits:employees')}
                  </Typography>

                  <Button
                    onClick={handleClickAddEmployee}>
                    {t('visits:addEmployee')}
                  </Button>
                </SectionHeaderBox>

                {_.map(employeeUserIdFields.fields, (field, index) => (
                  <RowBox key={field._key}>
                    <CellBox>
                      <UserSelectFieldController
                        label={t('employee')}
                        formControl={form.control}
                        fieldKey={`employees.${index}.id`}
                        typeName="employee"
                        isRequired
                        isDisabled={isLoading} />
                    </CellBox>

                    <IconButton
                      onClick={() => handleClickRemoveEmployee(index)}>
                      <Close fontSize="small" />
                    </IconButton>
                  </RowBox>
                ))}

              </Grid>

              <Grid
                item
                md={9} />
            </>
          )}

        </Grid>

      </BaseActivityTemplate>
    </Form>
  );
};
