import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  errorUtil,
  Form,
  paletteColors,
  fields,
  genEnumSelectFieldControllerComponent,
  enumCache,
  selectFields,
  validationUtil,
  HeaderBox
} from 'modules';
import {
  FieldPath,
  useForm
} from 'react-hook-form';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Grid,
  Typography
} from '@mui/material';

import * as dataService from './dataService';
import * as types from './types';
import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';
import { TaskList } from './TaskList/TaskList';
import { AddTasksFromTemplateModal } from './AddTasksFromTemplateModal/AddTasksFromTemplateModal';



const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();
const ShiftSelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, enumCache.IShiftName>(selectFields.ShiftSelectField);
const VisitCategorySelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, enumCache.IVisitCategoryName>(selectFields.VisitCategorySelectField);
const VisitTemplateFrequencyUnitSelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, enumCache.IVisitTemplateFrequencyUnitName>(selectFields.VisitTemplateFrequencyUnitSelectField);



interface IParams {
  parentEpisodeId?: string;
  visitTemplateId?: string;
}

export const EditVisitTemplateActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const form = useForm<types.IFormState>({
    defaultValues: {
      displayName: "",
      visitCategoryName: 'form485',
      shiftName: 'am',
      frequencyAmount: "",
      frequencyUnitName: 'month',
      note: "",
      tasks: []
    }
  });

  const [fnQuery, queryTuple] = dataService.useLazyQuery();
  const [fnSave, saveMutationTuple] = dataService.useSaveVisitTemplateMutation();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveVisitTemplateMutation();

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading ||
    archiveMutationTuple.loading;

  const [isAddTasksFromTemplateModalOpen, setIsAddTasksFromTemplateModalOpen] = React.useState(false);



  React.useEffect(
    () => {
      if (queryTuple.called && !queryTuple.loading && !queryTuple.data.canEdit) {
        navigate(`/admin/visitTemplates/view/${params.visitTemplateId}`);
      }
    },
    [
      queryTuple.called,
      queryTuple.loading,
      queryTuple.data.canEdit
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.called) {
        if (params.parentEpisodeId) {
          fnQuery({
            type: 'parentEpisode',
            id: params.parentEpisodeId
          });

        } else if (params.visitTemplateId) {
          fnQuery({
            type: 'visitTemplate',
            id: params.visitTemplateId
          });
        }
      }
    },
    [
      params.parentEpisodeId,
      params.visitTemplateId,
      queryTuple.called
    ]
  );



  React.useEffect(
    () => {
      if (queryTuple.called && !queryTuple.loading) {
        const fieldPaths: FieldPath<types.IFormState>[] = [
          'displayName',
          'frequencyAmount',
          'frequencyUnitName',
          'note',
          'location',
          'shiftName',
          'tasks',
          'visitCategoryName'
        ];

        const formState = queryTuple.data.formState;
        _.forEach(fieldPaths, path => {
          form.setValue(path, _.get(formState, path), {shouldDirty: false});
        });
      }
    },
    [
      form,
      queryTuple.called,
      queryTuple.loading,
      queryTuple.data.formState
    ]
  );



  function handleAcceptAddTasksFromTemplateModal(template: selectFields.ITaskSetTemplate) {
    setIsAddTasksFromTemplateModalOpen(false);
    form.setValue('tasks', [
      ...form.watch('tasks'),
      ..._.map(template.tasks, (task): types.ITask => {
        const id = `*${uuid.v4()}`;
        return {
          _key: id,
          id,
          title: task.title,
          description: task.description
        };
      })
    ]);
  }



  async function handleSubmit(formState: types.IFormState) {
    if (formState.frequencyUnitName === 'week') {
      const iAmount = +(formState.frequencyAmount) || 0;
      if (iAmount < 1 || iAmount > 7) {
        alert(t('visits:errors.invalidWeeklyFrequencyAmount'));
        return;
      }
    }

    await errorUtil.errorWrap(async () => {
      await fnSave({
        type: params.parentEpisodeId ? 'parentEpisode' : 'visitTemplate',
        id: params.parentEpisodeId ?? params.visitTemplateId ?? ''
      }, formState);
      navigate(-1);
    });
  }



  async function handleConfirmDelete() {
    await errorUtil.errorWrap(async () => {
      if (params.visitTemplateId) {
        await fnArchive(params.visitTemplateId);
        navigate(`/admin/episodes/profile/${queryTuple.data.episodeId}`);
      }
    }, {
      'EPISODE_CANNOT_BE_EDITED': t('visits:errors.EPISODE_CANNOT_BE_EDITED')
    });
  }



  return (
    <>
      <Form onSubmit={form.handleSubmit(handleSubmit)}>
        <BaseActivityTemplate
          mode="admin"
          isLoading={isLoading}
          requireOneOfPermissionCategories={[
            'fullAdmin',
            'supervisor'
          ]}
          headerOptions={{
            backgroundColor: paletteColors.visitTemplates,
            title: params.parentEpisodeId ?
              t('visits:newVisitTemplate') :
              queryTuple.data.formState.displayName,
            hasBackButton: true
          }}
          footerOptions={{
            hasDeleteButton: !!params.visitTemplateId,
            onConfirmDelete: handleConfirmDelete,
            onClickCancel: () => navigate(-1)
          }}>

          <Grid container>

            <Grid
              item
              md={6}>

              <HeaderBox>
                <Typography variant="h2">
                  {t('visits:details')}
                </Typography>
              </HeaderBox>

              <Grid container>

                <Grid
                  item
                  md={12}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="displayName"
                    label={t('name')}
                    isRequired
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={6}>
                  <VisitCategorySelectFieldController
                    formControl={form.control}
                    fieldKey="visitCategoryName"
                    isRequired
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={6}>
                  <ShiftSelectFieldController
                    formControl={form.control}
                    fieldKey="shiftName"
                    isRequired
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={6}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="frequencyAmount"
                    label={t('visits:frequencyAmount')}
                    extraValidationRules={{
                      validate: validationUtil.rules.genIntegerRule({
                        min: 1,
                        max: 31
                      })
                    }}
                    isRequired
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={6}>
                  <VisitTemplateFrequencyUnitSelectFieldController
                    formControl={form.control}
                    fieldKey="frequencyUnitName"
                    isRequired
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={12}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="location"
                    label={t('visits:location')}
                    isRequired
                    isDisabled={isLoading} />
                </Grid>

                <Grid
                  item
                  md={12}>
                  <TextFieldController
                    formControl={form.control}
                    fieldKey="note"
                    label={t('visits:note')}
                    numLines={5}
                    isRequired
                    isDisabled={isLoading} />
                </Grid>

              </Grid>

            </Grid>

            <Grid
              item
              md={6}>
              <TaskList
                formControl={form.control}
                isDisabled={isLoading}
                onClickAddTaskSetTemplate={() => setIsAddTasksFromTemplateModalOpen(true)} />
            </Grid>

          </Grid>

        </BaseActivityTemplate>
      </Form>

      {isAddTasksFromTemplateModalOpen && (
        <AddTasksFromTemplateModal
          onAccept={handleAcceptAddTasksFromTemplateModal}
          onClose={() => setIsAddTasksFromTemplateModalOpen(false)} />
      )}
    </>
  );
};
