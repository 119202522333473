import React from 'react';

import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { LoadingOverlay } from 'modules';
import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';



interface IParams {
  userId: string;
}

export const UserActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useUserActivityQuery(params.userId ?? '');



  React.useEffect(
    () => {
      if (queryTuple.data.redirectRoute) {
        navigate(queryTuple.data.redirectRoute);
      }
    },
    [
      history,
      queryTuple.data.redirectRoute
    ]
  );



  if (queryTuple.loading) {
    return (
      <LoadingOverlay active />
    );

  } else if (!queryTuple.data.redirectRoute) {
    return (
      <div>
        {t('invalidUser')}
      </div>
    );

  } else {
    return null;
  }
};
