import React from 'react';

import {
  authService,
  apolloService
} from 'modules';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



export const LogoutActivity: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();



  React.useEffect(
    () => {
      (async () => {
        await authService.logOut();
        (await apolloService.getClient())?.clearStore();
        navigate('/');
      })();
    },
    []
  );



  return (
    <div>
      {t('loading')}
    </div>
  );
};
