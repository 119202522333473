import React from 'react';
import _ from 'lodash';

import {
  Typography,
  Box,
  Radio,
  Divider,
  FormControlLabel
} from '@mui/material';
import {
  FieldPath,
  useFieldArray,
  UseFormReturn,
  Controller
} from 'react-hook-form';

import { fields } from 'modules';
import * as types from '../types';
import { useTranslation } from 'react-i18next';



const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();



export type IFieldKey =
  'allMedsTakenValues';

export interface IRadioButtonGroupProps {
  form: UseFormReturn<types.IFormState>;
  fieldKey: IFieldKey;
  title: string;
  schemaValuesByValue: Record<string, types.ISchemaValue>;
  labelTranslationPrefix: string;
  isDisabled?: boolean;
}

export const RadioButtonGroup: React.FC<IRadioButtonGroupProps> = props => {
  const {t} = useTranslation();

  const fields = useFieldArray({
    control: props.form.control,
    name: props.fieldKey,
    keyName: '_key'
  });
  const fieldValues = props.form.watch(props.fieldKey);



  function handleChangeCheckbox(changeIndex: number, isChecked: boolean) {
    _.forEach(fieldValues, (fieldValue, index) => {
      props.form.setValue(
        `${props.fieldKey}.${index}.isChecked`,
        changeIndex === index ?
          isChecked :
          false
      );
    });
  }



  return (
    <>
      <Box mb={3}>
        <Typography variant="h2">
          {props.title}
        </Typography>
      </Box>

      <Box mb={3}>
        {_.map(fields.fields, (field, index) => {
          const schema = props.schemaValuesByValue[field.name];
          const descriptionLabel = schema.descriptionLabel;

          return (
            <Box
              key={field.name}
              mb={1}>
              <Controller
                name={`${props.fieldKey}.${index}.isChecked`}
                control={props.form.control}
                render={({field: controllerField}) => {
                  const label = t(`${props.labelTranslationPrefix}.${field.name}`);
                  return (
                    <FormControlLabel
                      control={(
                        <Radio
                          checked={controllerField.value}
                          value={controllerField.value}
                          color="primary"
                          disabled={props.isDisabled} />
                      )}
                      onChange={(_event, isChecked) => handleChangeCheckbox(index, isChecked)}
                      label={label} />
                  );
                }} />

              {fieldValues[index].isChecked && (
                <>
                  {schema.hasDescription && (
                    <Box mt={1}>
                      <TextFieldController
                        formControl={props.form.control}
                        fieldKey={`${props.fieldKey}.${index}.description` as FieldPath<types.IFormState>}
                        label={descriptionLabel ?
                          t(`${props.labelTranslationPrefix}.${descriptionLabel}`) :
                          t('description')}
                        isDisabled={props.isDisabled} />
                    </Box>
                  )}
                </>
              )}
            </Box>
          );
        })}

        <Box mt={3}>
          <Divider />
        </Box>
      </Box>
    </>
  );
};
