import React from 'react';

import {
  Box,
  Button,
  Typography
} from '@mui/material';
import {
  Controller,
  useForm
} from 'react-hook-form';
import {
  assetUtil,
  errorUtil,
  validationUtil,
  BaseActivityTemplate,
  ResponsiveImage,
  TextField,
  LoadingOverlay,
  AuthCard,
  AuthTitle
} from 'modules';

import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import * as types from '../types';



const FieldContainerBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));



interface IFormData {
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
}



interface IProps {
  inviteCode: string;
  onChangeState: (state: types.IState) => void;
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();

  const {control, handleSubmit} = useForm<IFormData>({
    defaultValues: {
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: ""
    }
  });

  const queryTuple = dataService.useQuery(props.inviteCode);
  const [fnRegister, registerTuple] = dataService.useRegister();
  const [fnResendConfirmationCode, resendConfirmationCodeMutationTuple] = dataService.useResendConfirmationCodeMutation();

  const isLoading =
    queryTuple.loading ||
    registerTuple.loading ||
    resendConfirmationCodeMutationTuple.loading;



  async function processSubmit(formData: IFormData) {
    if (formData.password !== formData.confirmPassword) {
      alert(t('auth:errors.passwordsDoNotMatch'));
      return;
    }

    await errorUtil.errorWrap(async () => {
      const trEmail = formData.email.trim();

      try {
        await fnRegister({
          email: trEmail,
          phoneNumber: formData.phoneNumber.trim(),
          password: formData.password
        });
        props.onChangeState('confirmRegisterCode');

      } catch (err: any) {
        if (err.message === 'EMAIL_EXISTS') {
          await fnResendConfirmationCode(trEmail);
          props.onChangeState('confirmRegisterCode');

        } else {
          throw err;
        }
      }
    }, {
      USER_ALREADY_CONFIRMED: t('auth:errors.USER_ALREADY_EXISTS')
    });
  }



  return (
    <BaseActivityTemplate
      mode="auth"
      useAuthBackground
      onlyAllowLoggedOutUsers
      redirectLoggedInUserToRoute={`/auth/confirmRegistration/${props.inviteCode}`}>

      <LoadingOverlay
        active={isLoading} />

      {!queryTuple.loading && !queryTuple.data.isValidInvite && (
        <AuthCard.Comp>
          {t('auth:errors.INVALID_INVITE_CODE')}
        </AuthCard.Comp>
      )}

      {!queryTuple.loading && queryTuple.data.isValidInvite && (
        <AuthCard.Comp>
          <form
            onSubmit={handleSubmit(processSubmit)}
            autoComplete="off">

            <Box mb={1}>
              <AuthTitle.Comp>
                {t('auth:registerAccount')}
              </AuthTitle.Comp>
            </Box>

            <Box mb={4}>
              <ResponsiveImage urlSet={assetUtil.imgsLogo} />
            </Box>

            <FieldContainerBox>
              <Controller
                name="email"
                control={control}
                rules={{required: validationUtil.rules.required}}
                render={({field, formState}) => (
                  <TextField
                    label={t('auth:email')}
                    type="email"
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={formState.errors.email?.message} />
                )} />
            </FieldContainerBox>

            <FieldContainerBox>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: validationUtil.rules.required,
                  pattern: validationUtil.rules.phoneNumber
                }}
                render={({field, formState}) => (
                  <TextField
                    label={t('auth:phoneNumber')}
                    type="tel"
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={formState.errors.phoneNumber?.message} />
                )} />

              <Typography variant="caption">
                {t('auth:phoneNumberUsageDescription')}
              </Typography>
            </FieldContainerBox>

            <FieldContainerBox>
              <Controller
                name="password"
                control={control}
                rules={{
                  required: validationUtil.rules.required,
                  validate: validationUtil.isValidPassword
                }}
                render={({field, formState}) => (
                  <TextField
                    label={t('auth:password')}
                    type="password"
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={formState.errors.password?.message} />
                )} />
            </FieldContainerBox>

            <FieldContainerBox>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{required: validationUtil.rules.required}}
                render={({field, formState}) => (
                  <TextField
                    label={t('auth:confirmPassword')}
                    type="password"
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={formState.errors.confirmPassword?.message} />
                )} />
            </FieldContainerBox>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth>
              {t('auth:register')}
            </Button>

          </form>
        </AuthCard.Comp>
      )}

    </BaseActivityTemplate>
  );
};
