import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enAdminAdministrators from 'assets/localization/en/adminAdministrators.json';
import enAdminEmployees from 'assets/localization/en/adminEmployees.json';
import enAdminEpisodes from 'assets/localization/en/adminEpisodes.json';
import enAdminProviders from 'assets/localization/en/adminProviders.json';
import enAuth from 'assets/localization/en/auth.json';
import enBreadcrumbs from 'assets/localization/en/breadcrumbs.json';
import enCommon from 'assets/localization/en/common.json';
import enEnums from 'assets/localization/en/enums.json';
import enPatients from 'assets/localization/en/patients.json';
import enPharmacies from 'assets/localization/en/pharmacies.json';
import enRequirements from 'assets/localization/en/requirements.json';
import enVisits from 'assets/localization/en/visits.json';

import esAdminAdministrators from 'assets/localization/es/adminAdministrators.json';
import esAdminEmployees from 'assets/localization/es/adminEmployees.json';
import esAdminEpisodes from 'assets/localization/es/adminEpisodes.json';
import esAdminProviders from 'assets/localization/es/adminProviders.json';
import esAuth from 'assets/localization/es/auth.json';
import esBreadcrumbs from 'assets/localization/es/breadcrumbs.json';
import esCommon from 'assets/localization/es/common.json';
import esEnums from 'assets/localization/es/enums.json';
import esPatients from 'assets/localization/es/patients.json';
import esPharmacies from 'assets/localization/es/pharmacies.json';
import esRequirements from 'assets/localization/es/requirements.json';
import esVisits from 'assets/localization/es/visits.json';



export function init() {
  i18n
    .use(initReactI18next)
    .init({
      ns: [
        'common',
        'auth'
      ],
      defaultNS: 'common',
      resources: {
        en: {
          adminAdministrators: enAdminAdministrators,
          adminEmployees: enAdminEmployees,
          adminEpisodes: enAdminEpisodes,
          adminProviders: enAdminProviders,
          auth: enAuth,
          breadcrumbs: enBreadcrumbs,
          common: enCommon,
          enums: enEnums,
          patients: enPatients,
          pharmacies: enPharmacies,
          requirements: enRequirements,
          visits: enVisits
        },
        es: {
          adminAdministrators: esAdminAdministrators,
          adminEmployees: esAdminEmployees,
          adminEpisodes: esAdminEpisodes,
          adminProviders: esAdminProviders,
          auth: esAuth,
          breadcrumbs: esBreadcrumbs,
          common: esCommon,
          enums: esEnums,
          patients: esPatients,
          pharmacies: esPharmacies,
          requirements: esRequirements,
          visits: esVisits
        }
      },
      lng: 'en',
      interpolation: {
        escapeValue: false
      }
    });
}
