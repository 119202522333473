import _ from 'lodash';

import {
  red,
  green,
  blue,
  teal,
  indigo
} from '@mui/material/colors';

import { enumCache } from 'modules/layer2';
import * as chipGenerator from '../shared/chipGenerator';
import { map } from 'rxjs';



export const VisitStatusChip = chipGenerator.genChipComponent<enumCache.IVisitStatusName>({
  obsValueId2DisplayData: enumCache.subjAllEnums.asObservable()
    .pipe(map((value): Record<enumCache.IVisitStatusName, chipGenerator.IValueDisplayData> => {
      const enumValues = value.visitStatuses;
      const enumValuesByName = _.keyBy(enumValues, enumValue => enumValue.name) as Record<enumCache.IVisitStatusName, enumCache.IVisitStatus>;

      return {
        open: {
          label: enumValuesByName.open?.displayName ?? "",
          backgroundColor: blue['A700']
        },
        missed: {
          label: enumValuesByName.missed?.displayName ?? "",
          backgroundColor: red['A700']
        },
        clockedIn: {
          label: enumValuesByName.clockedIn?.displayName ?? "",
          backgroundColor: teal['A700']
        },
        clockedOut: {
          label: enumValuesByName.clockedOut?.displayName ?? "",
          backgroundColor: indigo['A700']
        },
        completed: {
          label: enumValuesByName.completed?.displayName ?? "",
          backgroundColor: green['A700']
        }
      };
    }))
});
