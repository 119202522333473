import React from 'react';
import _ from 'lodash';

import {
  useMessengerNewMessageModalQuery,
  useMessengerPostMessageMutation
} from 'generated/graphql';



export interface IUser {
  _key: string;
  id: string;
}

export interface IFormState {
  users: IUser[];
  message: string;
}



export interface IData {
  selfUserId: string;
}

export function useQuery() {
  const tuple = useMessengerNewMessageModalQuery({
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const data: IData = {
        selfUserId: tuple.data?.loggedInUser?.id ?? ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function usePostMutation() {
  const [fnMutate, tuple] = useMessengerPostMessageMutation();



  const fnWrappedMutate = React.useCallback(
    async (formState: IFormState) => {
      const retVal = await fnMutate({
        variables: {
          input: {
            recipientUserIds: _.map(formState.users, user => user.id),
            message: formState.message.trim()
          }
        }
      });

      return retVal.data?.messengerPostMessage.thread.id ?? '';
    },
    [fnMutate]
  );



  return [
    fnWrappedMutate,
    {
      ...tuple,
      data: null
    }
  ] as const;
}
