import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  paletteColors,
  genTableComponent,
  IRow,
  RowOptionsButton,
  ConfirmationModal,
  errorUtil,
  ImportCsvModal
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { columnDescriptions } from './columnDescriptions';



const __columnIdList = [
  'name',
  'primaryPhone',
  'secondaryPhone',
  'faxNumber',
  'address',
  'options'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();



export const PharmaciesActivity: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const queryTuple = dataService.useQuery();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveMutation();
  const [fnImport, importMutationTuple] = dataService.useImportMutation();

  const [pharmacyIdToDelete, setPharmacyIdToDelete] = React.useState('');
  const [isImportCsvModalOpen, setIsImportCsvModalOpen] = React.useState(false);

  const isLoading =
    queryTuple.loading ||
    importMutationTuple.loading ||
    archiveMutationTuple.loading;



  async function handleConfirmDeletePharmacy() {
    setPharmacyIdToDelete('');
    await errorUtil.errorWrap(async () => {
      await fnArchive(pharmacyIdToDelete);
      await queryTuple.refetch();
    });
  }



  async function importPharmacies(rows: any[]) {
    await errorUtil.errorWrap(async () => {
      await fnImport(_.map(rows, (row): dataService.IImportPharmacy => ({
        name: row.name ?? "",
        primaryPhone: row.primaryPhone ?? "",
        secondaryPhone: row.secondaryPhone ?? "",
        faxNumber: row.faxNumber ?? "",
        address: row.address ?? ""
      })));
      await queryTuple.refetch();
    });
  }



  async function handleAcceptImportCsvModal() {
    await queryTuple.refetch();
    setIsImportCsvModalOpen(false);
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.pharmacies,
          title: t('pharmacies'),
          elRightButtons: [
            (
              <Button
                key="import"
                variant="outlined"
                onClick={() => setIsImportCsvModalOpen(true)}>
                {t('pharmacies:importPharmacies')}
              </Button>
            ),
            (
              <Button
                key="create"
                variant="contained"
                onClick={() => navigate('/admin/pharmacies/create')}>
                {t('pharmacies:addNewPharmacy')}
              </Button>
            )
          ]
        }}>

        <Table
          columns={[
            {
              id: 'name',
              label: t('name')
            },
            {
              id: 'primaryPhone',
              label: t('primaryPhone')
            },
            {
              id: 'secondaryPhone',
              label: t('secondaryPhone')
            },
            {
              id: 'faxNumber',
              label: t('adminProviders:faxNumber')
            },
            {
              id: 'address',
              label: t('address')
            },
            {
              id: 'options',
              label: ""
            }
          ]}
          rows={_.map(queryTuple.data.pharmacies, (pharmacy): IRow<IColumnId> => ({
            id: pharmacy.id,
            columnId2Value: {
              name: {
                type: 'string',
                label: pharmacy.name,
                toRoute: `/admin/pharmacies/view/${pharmacy.id}`
              },
              primaryPhone: {
                type: 'string',
                label: pharmacy.primaryPhone
              },
              secondaryPhone: {
                type: 'string',
                label: pharmacy.secondaryPhone
              },
              faxNumber: {
                type: 'string',
                label: pharmacy.faxNumber
              },
              address: {
                type: 'string',
                label: pharmacy.address
              },
              options: {
                type: 'component',
                label: (
                  <RowOptionsButton
                    onClickDelete={() => setPharmacyIdToDelete(pharmacy.id)} />
                ),
                sortableValue: ''
              }
            }
          }))}
          initSortOptions={{
            sortByColumnId: 'name',
            sortDirection: 'ascending'
          }} />

      </BaseActivityTemplate>

      <ConfirmationModal
        isOpen={!!pharmacyIdToDelete}
        title={t('pharmacies:deletePharmacy')}
        message={t('pharmacies:deletePharmacyConfirmationModal')}
        acceptButtonLabel={t('delete')}
        onAccept={handleConfirmDeletePharmacy}
        onCancel={() => setPharmacyIdToDelete('')} />

      {isImportCsvModalOpen && (
        <ImportCsvModal
          isOpen
          columnDescriptions={columnDescriptions}
          fnImport={importPharmacies}
          isLoading={importMutationTuple.loading}
          onAccept={handleAcceptImportCsvModal}
          onCancel={() => setIsImportCsvModalOpen(false)} />
      )}
    </>
  );
};
