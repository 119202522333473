import React from 'react';
import _ from 'lodash';

import { ValueWithLabel } from 'modules/layer3';
import { useTranslation } from 'react-i18next';



export interface IDataValue {
  value: string;
  description?: string;
}

export interface IProps {
  label: string;
  labelTranslationPrefix: string;
  values: IDataValue[];
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();



  return (
    <ValueWithLabel
      label={props.label}>
      {props.values.length === 0 ? (
        <div>
          {t('noValue')}
        </div>

      ) : (
        <ul>
          {_.map(props.values, value => (
            <li key={value.value}>
              {t(`${props.labelTranslationPrefix}.${value.value}`)}

              {value.description && (
                <ul>
                  {value.description && (
                    <li>"{value.description}"</li>
                  )}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </ValueWithLabel>
  );
};
