import React from 'react';
import _ from 'lodash';

import {
  usePatientSelectFieldQuery
} from 'generated/graphql';



export interface IPatient {
  id: string;
  label: string;
}

export interface IData {
  patients: IPatient[];
  patientsById: Record<string, IPatient>;
}

export function useQuery() {
  const tuple = usePatientSelectFieldQuery();

  return React.useMemo(
    () => {
      const patients = _.sortBy(
        _.map(tuple.data?.patients ?? [], (gqlPatient): IPatient => ({
          id: gqlPatient.id,
          label: `${gqlPatient.lastName}, ${gqlPatient.firstName}`
        })),
        patient => patient.label.toLowerCase());

      patients.push({
        id: '',
        label: ""
      });

      const data: IData = {
        patients,
        patientsById: _.keyBy(patients, patient => patient.id)
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
