import React from 'react';

import {
  BaseActivityTemplate,
  paletteColors,
  SkeletonRows,
  ValueWithLabel
} from 'modules';
import {
  Button,
  Grid
} from '@mui/material';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { Create } from '@mui/icons-material';



interface IParams {
  pharmacyId: string;
}

export const ViewPharmacyActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.pharmacyId ?? '');



  function handleClickEditData() {
    navigate(`/admin/pharmacies/edit/${params.pharmacyId ?? ''}`);
  }



  return (
    <BaseActivityTemplate
      mode="admin"
      isLoading={queryTuple.loading}
      requireOneOfPermissionCategories={[
        'fullAdmin',
        'supervisor'
      ]}
      headerOptions={{
        backgroundColor: paletteColors.pharmacies,
        title: queryTuple.data.name,
        hasBackButton: true,
        elRightButtons: [
          (
            <Button
              key="editData"
              variant="outlined"
              onClick={handleClickEditData}
              startIcon={(
                <Create />
              )}>
              {t('editData')}
            </Button>
          )
        ]
      }}>

      {queryTuple.loading ? (
        <SkeletonRows numRows={5} />

      ) : (
        <Grid container>
          <ValueWithLabel
            label={t('primaryPhone')}
            value={queryTuple.data.primaryPhone} />

          <ValueWithLabel
            label={t('secondaryPhone')}
            value={queryTuple.data.secondaryPhone} />

          <ValueWithLabel
            label={t('pharmacies:faxNumber')}
            value={queryTuple.data.faxNumber} />

          <ValueWithLabel
            label={t('address')}
            value={queryTuple.data.address} />
        </Grid>
      )}

    </BaseActivityTemplate>
  );
};
