import _ from 'lodash';

import {
  green,
  blue
} from '@mui/material/colors';

import { enumCache } from 'modules/layer2';
import * as chipGenerator from '../shared/chipGenerator';
import { map } from 'rxjs';



export const EpisodeStatusChip = chipGenerator.genChipComponent<enumCache.IEpisodeStatusName>({
  obsValueId2DisplayData: enumCache.subjAllEnums.asObservable()
    .pipe(map((value): Record<enumCache.IEpisodeStatusName, chipGenerator.IValueDisplayData> => {
      const enumValues = value.episodeStatuses;
      const enumValuesByName = _.keyBy(enumValues, enumValue => enumValue.name) as Record<enumCache.IEpisodeStatusName, enumCache.IEpisodeStatus>;

      return {
        pending: {
          label: enumValuesByName.pending?.displayName ?? "",
          backgroundColor: '#317FF4'
        },
        active: {
          label: enumValuesByName.active?.displayName ?? "",
          backgroundColor: green['A700']
        },
        transferred: {
          label: enumValuesByName.transferred?.displayName ?? "",
          backgroundColor: blue['A700']
        },
        inactive: {
          label: enumValuesByName.inactive?.displayName ?? "",
          backgroundColor: '#000'
        }
      };
    }))
});
