import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  BaseActivityTemplate,
  dateUtil,
  paletteColors,
  TimePeriodNavigator,
  genTableComponent,
  IRow
} from 'modules';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { useNavigate } from 'react-router-dom';



const __columnIds = [
  'id',
  'employee',
  'numTotalHours',
  'numApprovedHours'
] as const;
type IColumnId = typeof __columnIds[number];

const Table = genTableComponent<IColumnId>();

const __duration = moment.duration(14, 'days');



export const TimesheetsActivity: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const queryTuple = dataService.useQuery();
  const [fnTimesheetsQuery, timesheetsQueryTuple] = dataService.useLazyQuery();

  const [payPeriodStart, setPayPeriodStart] = React.useState<moment.Moment | null>(null);

  const isLoading =
    queryTuple.loading ||
    timesheetsQueryTuple.loading;



  React.useEffect(
    () => {
      if (queryTuple.data.currentPayPeriod && !payPeriodStart) {
        setPayPeriodStart(queryTuple.data.currentPayPeriod.start);
      }
    },
    [
      queryTuple.data.currentPayPeriod,
      payPeriodStart
    ]
  );



  React.useEffect(
    () => {
      if (payPeriodStart) {
        fnTimesheetsQuery(payPeriodStart);
      }
    },
    [payPeriodStart]
  );



  function formatTimePeriodLabel(start: moment.Moment, duration: moment.Duration) {
    return t('visits:payPeriodRange', {
      start: start.format(dateUtil.displayDateFormat),
      end: start.clone()
        .add(duration)
        .subtract(1, 'milliseconds')
        .format(dateUtil.displayDateFormat)
    });
  }



  return (
    <BaseActivityTemplate
      mode="admin"
      isLoading={isLoading}
      requireOneOfPermissionCategories={[
        'fullAdmin',
        'humanResources'
      ]}
      headerOptions={{
        backgroundColor: paletteColors.timesheets,
        title: t('timesheets'),
        elRight: payPeriodStart ? (
          <TimePeriodNavigator
            periodStart={payPeriodStart}
            duration={__duration}
            onChangePeriodStart={setPayPeriodStart}
            formatLabel={formatTimePeriodLabel} />
        ) : undefined,
        elRightButtons: [
          (
            <Button
              key="export"
              onClick={() => navigate('/admin/timesheets/export')}>
              {t('visits:exportTimesheets')}
            </Button>
          )
        ]
      }}>

      <Table
        columns={[
          {
            id: 'id',
            label: t('id')
          },
          {
            id: 'employee',
            label: t('employee')
          },
          {
            id: 'numTotalHours',
            label: t('visits:numTotalHours')
          },
          {
            id: 'numApprovedHours',
            label: t('visits:numApprovedHours')
          }
        ]}
        rows={_.map(timesheetsQueryTuple.data.timesheets, (timesheet): IRow<IColumnId> => ({
          id: timesheet.id,
          columnId2Value: {
            id: {
              type: 'string',
              label: timesheet.id.slice(-7),
              toRoute: `/admin/timesheets/view/${timesheet.id}`
            },
            employee: {
              type: 'string',
              label: timesheet.employee.fullName,
              toRoute: timesheet.employee.route,
              sortableValue: timesheet.employee.fullName
            },
            numTotalHours: {
              type: 'string',
              label: timesheet.numTotalHours.toFixed(2),
              sortableValue: timesheet.numTotalHours.toNumber()
            },
            numApprovedHours: {
              type: 'string',
              label: timesheet.numApprovedHours.toFixed(2),
              sortableValue: timesheet.numApprovedHours.toNumber()
            }
          }
        }))}
        initSortOptions={{
          sortByColumnId: 'employee',
          sortDirection: 'ascending'
        }} />

    </BaseActivityTemplate>
  );
};
