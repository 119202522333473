import React from 'react';
import _ from 'lodash';

import {
  Box,
  Skeleton
} from '@mui/material';



export interface ISkeletonRowsProps {
  numRows: number;
}

export const SkeletonRows: React.FC<ISkeletonRowsProps> = props => {
  return (
    <>
      {_.times(props.numRows, index => (
        <Box
          key={index}
          mb={2}>
          <Skeleton variant="text" />
        </Box>
      ))}
    </>
  );
};
