import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useEmployeesActivityQuery as useBaseQuery,
  useArchiveEmployeeMutation as useBaseArchiveEmployeeMutation,
  ImportEmployeeInput,
  HireStatusName,
  DisciplineName,
  useImportEmployeesMutation as useBaseImportEmployeesMutation
} from 'generated/graphql';
import {
  IUserStatus,
  enumCache
} from 'modules';

import { useTranslation } from 'react-i18next';



export interface IEmployee {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  birthdate: moment.Moment | null;
  employeeId: string;
  disciplineDisplayName: string;
  status: IUserStatus;
  hireStatus: enumCache.IHireStatusName;
}

export interface IData {
  employees: IEmployee[];
}

export function useEmployeesActivityQuery() {
  const {t} = useTranslation();
  const tuple = useBaseQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error.message ?? t('errors.unknown'));
      }
    },
    [tuple.error]
  );

  return React.useMemo(
    () => {
      const data: IData = {
        employees: _.map(tuple.data?.employees ?? [], (gqlEmployee): IEmployee => {
          let status: IUserStatus = 'inviteRequired';
          if (!gqlEmployee.siteUserFull && gqlEmployee.invite && !gqlEmployee.invite.isExpired) {
            status = 'invitePending';
          } else if (!gqlEmployee.siteUserFull && gqlEmployee.invite?.isExpired) {
            status = 'inviteExpired';
          } else if (gqlEmployee.siteUserFull && !gqlEmployee.siteUserFull.isDisabled) {
            status = 'active';
          } else if (gqlEmployee.siteUserFull && gqlEmployee.siteUserFull.isDisabled) {
            status = 'disabled';
          }

          const firstName = gqlEmployee.firstName;
          const lastName = gqlEmployee.lastName;

          return ({
            id: gqlEmployee.id,
            firstName,
            lastName,
            fullName: `${firstName} ${lastName}`.trim(),
            email: gqlEmployee.siteUserFull?.email ?? "",
            birthdate: gqlEmployee.birthdate ?
              moment(gqlEmployee.birthdate, moment.ISO_8601) :
              null,
            employeeId: gqlEmployee.employeeId,
            disciplineDisplayName: gqlEmployee.discipline?.displayName ?? "",
            status,
            hireStatus: gqlEmployee.hireStatus.name
          });
        })
      };

      return {
        ...tuple,
        data,
        refetch: async () => {
          await tuple.refetch();
        }
      };
    },
    [tuple]
  );
}



export function useArchiveEmployeeMutation(fnRefetch: () => Promise<void>) {
  const [fnMutate, tuple] = useBaseArchiveEmployeeMutation();

  const fnWrappedMutate = React.useCallback(
    async (employeeId: string) => {
      await fnMutate({
        variables: {
          id: employeeId
        }
      });
      await fnRefetch();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export interface IImportEmployee {
  firstName: string;
  lastName: string;
  hireStatus: string;
  employeeId?: string;
  birthdate?: string;
  discipline?: string;
  address?: string;
  primaryPhone?: string;
  secondaryPhone?: string;
  payRate?: string;
  licenseCheck?: string;
  fraudCheck?: string;
}

export function useImportEmployeesMutation() {
  const [fnMutate, tuple] = useBaseImportEmployeesMutation();

  const fnWrappedMutate = React.useCallback(
    async (employees: IImportEmployee[]) => {
      await fnMutate({
        variables: {
          input: {
            employees: _.map(employees, (employee): ImportEmployeeInput => ({
              firstName: employee.firstName,
              lastName: employee.lastName,
              hireStatusName: employee.hireStatus as HireStatusName,
              employeeId: employee.employeeId,
              birthdate: employee.birthdate,
              disciplineName: employee.discipline as DisciplineName | undefined,
              address: employee.address,
              primaryPhone: employee.primaryPhone,
              secondaryPhone: employee.secondaryPhone,
              payRate: employee.payRate,
              licenseCheck: employee.licenseCheck,
              fraudCheck: employee.fraudCheck
            }))
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
