import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  ConfirmationModal,
  dateUtil,
  errorUtil,
  genTableComponent,
  ImportCsvModal,
  IRow,
  paletteColors,
  RowOptionsButton,
  UserStatusChip,
  chips
} from 'modules';
import {
  Box,
  Button,
  Grid,
  TextField,
  Checkbox
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { columnDescriptions } from './columnDescriptions';



const FilterBarRightSectionBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));



const __columnIdList = [
  'firstName',
  'lastName',
  'email',
  'birthdate',
  'employeeId',
  'status',
  'hireStatus',
  'discipline',
  'options'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();



export const EmployeesActivity: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const queryTuple = dataService.useEmployeesActivityQuery();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveEmployeeMutation(queryTuple.refetch);
  const [fnImport, importMutationTuple] = dataService.useImportEmployeesMutation();

  const [employeeIdToDelete, setEmployeeIdToDelete] = React.useState('');
  const [filterText, setFilterText] = React.useState("");
  const [onlyShowHired, setOnlyShowHired] = React.useState(false);
  const [isImportCsvModalOpen, setIsImportCsvModalOpen] = React.useState(false);

  const isLoading =
    queryTuple.loading ||
    archiveMutationTuple.loading;



  const filteredEmployees = React.useMemo(
    () => {
      const trFilterText = filterText.trim().toLowerCase();
      const filteredByName = trFilterText ?
        _.filter(queryTuple.data.employees, employee =>
          employee.fullName.toLowerCase().indexOf(trFilterText) >= 0) :
        queryTuple.data.employees;

      return onlyShowHired ?
        _.filter(filteredByName, employee =>
          employee.hireStatus === 'hired') :
        filteredByName;
    },
    [
      filterText,
      onlyShowHired,
      queryTuple.data.employees
    ]
  );



  async function handleConfirmDeleteEmployee() {
    setEmployeeIdToDelete('');
    await errorUtil.errorWrap(async () => {
      await fnArchive(employeeIdToDelete);
    });
  }



  function handleAcceptImportCsvModal() {
    queryTuple.refetch();
    setIsImportCsvModalOpen(false);
  }



  async function importEmployees(rows: any[]) {
    await fnImport(_.map(rows, (row): dataService.IImportEmployee => ({
      firstName: row.firstName ?? "",
      lastName: row.lastName ?? "",
      hireStatus: row.hireStatus ?? '',
      employeeId: row.employeeId || undefined,
      birthdate: row.birthdate || undefined,
      discipline: row.discipline || undefined,
      address: row.address || undefined,
      primaryPhone: row.primaryPhone || undefined,
      secondaryPhone: row.secondaryPhone || undefined,
      payRate: row.payRate || undefined,
      licenseCheck: row.licenseCheck || undefined,
      fraudCheck: row.fraudCheck || undefined
    })));
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        navigationBarSelectedLinkId="employees"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.employees,
          title: t('adminEmployees:employees'),
          elRightButtons: [
            (
              <Button
                key="import"
                variant="outlined"
                onClick={() => setIsImportCsvModalOpen(true)}>
                {t('adminEmployees:importEmployees')}
              </Button>
            ),
            (
              <Button
                key="create"
                color="primary"
                variant="contained"
                onClick={() => navigate('/admin/employees/create')}>
                {t('adminEmployees:addNewEmployee')}
              </Button>
            )
          ]
        }}>

        <Box mb={2}>
          <Grid container>

            <Grid
              item
              md={3}>

              <TextField
                label={t('adminEmployees:findAnEmployee')}
                value={filterText}
                onChange={event => setFilterText(event.target.value)}
                size="small"
                InputProps={{
                  endAdornment: (
                    <Search color="inherit" />
                  )
                }} />

            </Grid>

            <Grid
              item
              md={9}>

              <FilterBarRightSectionBox>
                <Checkbox
                  checked={onlyShowHired}
                  onChange={event => setOnlyShowHired(event.target.checked)} />

                <span>
                  {t('adminEmployees:onlyShowHired')}
                </span>
              </FilterBarRightSectionBox>

            </Grid>

          </Grid>
        </Box>

        <Table
          columns={[
            {
              id: 'firstName',
              label: t('firstName')
            },
            {
              id: 'lastName',
              label: t('lastName')
            },
            {
              id: 'email',
              label: t('email')
            },
            {
              id: 'birthdate',
              label: t('birthdate')
            },
            {
              id: 'employeeId',
              label: t('employeeId')
            },
            {
              id: 'status',
              label: t('status')
            },
            {
              id: 'hireStatus',
              label: t('hireStatus')
            },
            {
              id: 'discipline',
              label: t('discipline')
            },
            {
              id: 'options',
              label: ""
            }
          ]}
          rows={_.map(filteredEmployees, (employee): IRow<IColumnId> => ({
            id: employee.id,
            columnId2Value: {
              firstName: {
                type: 'string',
                label: employee.firstName,
                toRoute: `/admin/employees/profile/${employee.id}`
              },
              lastName: {
                type: 'string',
                label: employee.lastName,
                toRoute: `/admin/employees/profile/${employee.id}`
              },
              email: {
                type: 'string',
                label: employee.email
              },
              birthdate: {
                type: 'string',
                label: employee.birthdate?.format(dateUtil.displayDateFormat) ?? ""
              },
              employeeId: {
                type: 'string',
                label: employee.employeeId
              },
              status: {
                type: 'component',
                label: (
                  <UserStatusChip
                    status={employee.status} />
                ),
                sortableValue: employee.status
              },
              hireStatus: {
                type: 'component',
                label: (
                  <chips.HireStatusChip
                    valueId={employee.hireStatus} />
                ),
                sortableValue: employee.hireStatus
              },
              discipline: {
                type: 'string',
                label: employee.disciplineDisplayName
              },
              options: {
                type: 'component',
                label: (
                  <RowOptionsButton
                    onClickDelete={() => setEmployeeIdToDelete(employee.id)} />
                ),
                sortableValue: ''
              }
            }
          }))}
          initSortOptions={{
            sortByColumnId: 'lastName',
            sortDirection: 'ascending'
          }} />

      </BaseActivityTemplate>

      <ConfirmationModal
        isOpen={!!employeeIdToDelete}
        title={t('adminEmployees:deleteEmployee')}
        message={t('adminEmployees:deleteEmployeeConfirmationModal')}
        acceptButtonLabel={t('delete')}
        onAccept={handleConfirmDeleteEmployee}
        onCancel={() => setEmployeeIdToDelete('')} />

      {isImportCsvModalOpen && (
        <ImportCsvModal
          isOpen
          columnDescriptions={columnDescriptions}
          fnImport={importEmployees}
          isLoading={importMutationTuple.loading}
          onAccept={handleAcceptImportCsvModal}
          onCancel={() => setIsImportCsvModalOpen(false)} />
      )}
    </>
  );
};
