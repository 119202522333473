import React from 'react';

import {
  Avatar as BaseAvatar,
  Box,
  Link,
  Skeleton
} from '@mui/material';

import * as dataService from './dataService';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';



const ContainerBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const Avatar = styled(BaseAvatar)(({theme}) => ({
  width: theme.spacing(4),
  height: theme.spacing(4)
}));

const NameBox = styled(Box)(({theme}) => ({
  flex: '1 0',
  marginLeft: theme.spacing(2)
}));



export interface IUserLinkProps {
  userId: string;
}

export const UserLink: React.FC<IUserLinkProps> = props => {
  const [fnQuery, queryTuple] = dataService.useUserLinkLazyQuery();



  React.useEffect(
    () => {
      if (props.userId && !queryTuple.called) {
        fnQuery(props.userId);
      }
    },
    [
      props.userId,
      queryTuple.called,
      fnQuery
    ]
  );



  if (!queryTuple.loading && !queryTuple.data.id) {
    return null;
  }



  return (
    <ContainerBox>
      {queryTuple.loading ? (
        <>
          <Skeleton
            variant="circular"
            width={32}
            height={32} />

          <NameBox>
            <Skeleton variant="text" />
          </NameBox>
        </>

      ) : (
        <>
          <Avatar
            src={queryTuple.data.profilePictureUrl} />

          <NameBox>
            <Link
              to={queryTuple.data.routeUrl}
              component={RouterLink}>
              {queryTuple.data.fullName}
            </Link>
          </NameBox>
        </>
      )}
    </ContainerBox>
  );
};
