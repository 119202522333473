import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  EmployeeRequirementsTabAssignedRequirementFragment,
  useEmployeeRequirementsTabLoggedInUserLazyQuery,
  useEmployeeRequirementsTabOtherUserLazyQuery
} from 'generated/graphql';

import { enumCache } from 'modules';



export interface IRequirement {
  id: string;
  name: string;
  typeName: enumCache.IRequirementTypeName;
  statusName: enumCache.IRequirementStatusName;
  dueDate: moment.Moment;
}

export interface IData {
  requirements: IRequirement[];
}



function __useLoggedInUserLazyQuery() {
  const [fnQuery, tuple] = useEmployeeRequirementsTabLoggedInUserLazyQuery({
    fetchPolicy: 'no-cache'
  });

  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );

  const wrappedFnQuery = React.useCallback(
    () => {
      fnQuery();
    },
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const gqlUser = tuple.data?.loggedInUser?.typeSpecificUser.__typename === 'Employee' ?
        tuple.data.loggedInUser.typeSpecificUser :
        null;

      const data: IData = {
        requirements: __gqlRequirements2Client(gqlUser?.assignedRequirements ?? [])
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    wrappedFnQuery,
    wrappedTuple
  ] as const;
}



function __useOtherUserLazyQuery() {
  const [fnQuery, tuple] = useEmployeeRequirementsTabOtherUserLazyQuery({
    fetchPolicy: 'no-cache'
  });

  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );

  const wrappedFnQuery = React.useCallback(
    (employeeId: string) => {
      fnQuery({
        variables: {
          employeeId
        }
      });
    },
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const data: IData = {
        requirements: __gqlRequirements2Client(tuple.data?.employee?.assignedRequirements ?? [])
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    wrappedFnQuery,
    wrappedTuple
  ] as const;
}



function __gqlRequirements2Client(gqlRequirements: EmployeeRequirementsTabAssignedRequirementFragment[]): IData['requirements'] {
  return _.map(gqlRequirements, (gqlRequirement): IRequirement => ({
    id: gqlRequirement.id,
    name: gqlRequirement.preset.displayName,
    typeName: gqlRequirement.preset.type.name,
    statusName: gqlRequirement.status.name,
    dueDate: moment(gqlRequirement.dueTimestamp, moment.ISO_8601)
  }));
}



export function useQuery(employeeId?: string) {
  const [fnLoggedInUserQuery, loggedInUserQueryTuple] = __useLoggedInUserLazyQuery();
  const [fnOtherUserQuery, otherUserQueryTuple] = __useOtherUserLazyQuery();

  React.useEffect(
    () => {
      if (!loggedInUserQueryTuple.called && !otherUserQueryTuple.called) {
        if (employeeId) {
          fnOtherUserQuery(employeeId);
        } else {
          fnLoggedInUserQuery();
        }
      }
    },
    [
      employeeId,
      fnLoggedInUserQuery,
      fnOtherUserQuery,
      loggedInUserQueryTuple.called,
      otherUserQueryTuple.called
    ]
  );

  return React.useMemo(
    () => {
      if (employeeId) {
        return otherUserQueryTuple;
      } else {
        return loggedInUserQueryTuple;
      }
    },
    [
      employeeId,
      loggedInUserQueryTuple,
      otherUserQueryTuple
    ]
  );
}
