import React from 'react';
import _ from 'lodash';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Thread } from './Thread/Thread';
import * as dataService from './dataService';



const ContainerBox = styled(Box)(({theme}) => ({
  flex: `0 0 ${theme.spacing(49)}`,
  backgroundColor: '#EFF4F8',
  overflowY: 'auto'
}));



export interface IThreadBarProps {
  selectedThreadId: string;
  onClickThread: (threadId: string) => void;
}

export const ThreadBar: React.FC<IThreadBarProps> = props => {
  const queryTuple = dataService.useQuery();

  return (
    <ContainerBox>
      {_.map(queryTuple.data.threads, thread => (
        <Thread
          key={thread.id}
          displayName={thread.displayName}
          users={thread.users}
          lastMessageTimestamp={thread.lastMessage?.timestamp ?? null}
          lastMessage={thread.lastMessage?.message ?? ""}
          numUnreadMessages={thread.numUnreadMessages}
          isSelected={thread.id === props.selectedThreadId}
          onClick={() => props.onClickThread(thread.id)} />
      ))}
    </ContainerBox>
  );
};
