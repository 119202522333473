import _ from 'lodash';

import {
  AllEnumsDocument as Document,
  AllEnumsQueryResult as Result,
  AllEnumsQueryVariables as Variables
} from 'generated/graphql';

import { IApolloClient } from 'modules/layer1/apolloService';



export interface IGenericEnum<TOptionName extends string> {
  id: string;
  name: TOptionName;
  displayName: string;
}



export const dayOfWeekNames = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
] as const;
export type IDayOfWeekName = typeof dayOfWeekNames[number];
export type IDayOfWeek = IGenericEnum<IDayOfWeekName>;



export const disciplineNames = [
  'clinicalManager',
  'educationNurse',
  'homeHealthAide',
  'occupationalTherapy',
  'officeStaff',
  'physicalTherapy',
  'skilledNursing',
  'socialWorker',
  'speechTherapy'
] as const;
export type IDisciplineName = typeof disciplineNames[number];
export type IDiscipline = IGenericEnum<IDisciplineName>;



export const episodeContinuationTypes = [
  'recertification',
  'resumption'
] as const;
export type IEpisodeContinuationTypeName = typeof episodeContinuationTypes[number];
export type IEpisodeContinuationType = IGenericEnum<IEpisodeContinuationTypeName>;



export const episodeInactiveTypes = [
  'death',
  'discharge'
] as const;
export type IEpisodeInactiveTypeName = typeof episodeInactiveTypes[number];
export type IEpisodeInactiveType = IGenericEnum<IEpisodeInactiveTypeName>;



export const episodeStatuses = [
  'active',
  'inactive',
  'pending',
  'transferred'
] as const;
export type IEpisodeStatusName = typeof episodeStatuses[number];
export type IEpisodeStatus = IGenericEnum<IEpisodeStatusName>;



export const genderNames = [
  'male',
  'female',
  'nonBinary'
] as const;
export type IGenderName = typeof genderNames[number];
export type IGender = IGenericEnum<IGenderName>;



export const hireStatusNames = [
  'inactive',
  'hired',
  'suspended'
] as const;
export type IHireStatusName = typeof hireStatusNames[number];
export type IHireStatus = IGenericEnum<IHireStatusName>;



export const missedVisitReason = [
  'automatic',
  'patientInHospital',
  'rehab',
  'mdVisits',
  'deceased'
] as const;
export type IMissedVisitReasonName = typeof missedVisitReason[number];
export interface IMissedVisitReason extends IGenericEnum<IMissedVisitReasonName> {
  isUserSelectable: boolean;
}



export const patientStatusNames = [
  'pending',
  'newProcessing',
  'admitReady',
  'admitted',
  'notAdmitted',
  'discharged'
] as const;
export type IPatientStatusName = typeof patientStatusNames[number];
export type IPatientStatus = IGenericEnum<IPatientStatusName>;



export const permissionCategoryNames = [
  'fullAdmin',
  'humanResources',
  'supervisor'
] as const;
export type IPermissionCategoryName = typeof permissionCategoryNames[number];
export type IPermissionCategory = IGenericEnum<IPermissionCategoryName>;



export const requirementRecurrenceTypes = [
  'once',
  'weekly',
  'monthly',
  'yearly'
] as const;
export type IRequirementRecurrenceTypeName = typeof requirementRecurrenceTypes[number];
export type IRequirementRecurrenceType = IGenericEnum<IRequirementRecurrenceTypeName>;



export const requirementStatuses = [
  'pending',
  'submitted',
  'rejected',
  'completed'
] as const;
export type IRequirementStatusName = typeof requirementStatuses[number];
export type IRequirementStatus = IGenericEnum<IRequirementStatusName>;



export const requirementTypes = [
  'requirement',
  'document',
  'inService',
  'training',
  'quiz'
] as const;
export type IRequirementTypeName = typeof requirementTypes[number];
export type IRequirementType = IGenericEnum<IRequirementTypeName>;



export const shifts = [
  'am',
  'pm',
  'night'
] as const;
export type IShiftName = typeof shifts[number];
export type IShift = IGenericEnum<IShiftName>;



export const timesheetVisitApprovalStatus = [
  'pending',
  'approved',
  'denied'
] as const;
export type ITimesheetVisitApprovalStatusName = typeof timesheetVisitApprovalStatus[number];
export type ITimesheetVisitApprovalStatus = IGenericEnum<ITimesheetVisitApprovalStatusName>;



export const visitCategories = [
  'form485',
  'hha'
] as const;
export type IVisitCategoryName = typeof visitCategories[number];
export type IVisitCategory = IGenericEnum<IVisitCategoryName>;



export const visitStatuses = [
  'open',
  'missed',
  'clockedIn',
  'clockedOut',
  'completed'
] as const;
export type IVisitStatusName = typeof visitStatuses[number];
export type IVisitStatus = IGenericEnum<IVisitStatusName>;



export const visitTemplateFrequencyUnits = [
  'week',
  'month'
] as const;
export type IVisitTemplateFrequencyUnitName = typeof visitTemplateFrequencyUnits[number];
export type IVisitTemplateFrequencyUnit = IGenericEnum<IVisitTemplateFrequencyUnitName>;



export type IEnumNamesList =
  typeof dayOfWeekNames |
  typeof disciplineNames |
  typeof episodeContinuationTypes |
  typeof episodeInactiveTypes |
  typeof episodeStatuses |
  typeof genderNames |
  typeof hireStatusNames |
  typeof missedVisitReason |
  typeof patientStatusNames |
  typeof permissionCategoryNames |
  typeof requirementRecurrenceTypes |
  typeof requirementStatuses |
  typeof requirementTypes |
  typeof shifts |
  typeof timesheetVisitApprovalStatus |
  typeof visitCategories |
  typeof visitStatuses |
  typeof visitTemplateFrequencyUnits;



export interface IEnumTuple<TEnumName extends string> {
  list: IGenericEnum<TEnumName>[];
}

export interface IAllEnums {
  daysOfWeek: IDayOfWeek[];
  disciplines: IDiscipline[];
  episodeContinuationTypes: IEpisodeContinuationType[];
  episodeInactiveTypes: IEpisodeInactiveType[];
  episodeStatuses: IEpisodeStatus[];
  genders: IGender[];
  hireStatuses: IHireStatus[];
  missedVisitReasons: IMissedVisitReason[];
  patientStatuses: IPatientStatus[];
  permissionCategories: IPermissionCategory[];
  requirementRecurrenceTypes: IRequirementRecurrenceType[];
  requirementStatuses: IRequirementStatus[];
  requirementTypes: IRequirementType[];
  shifts: IShift[];
  timesheetVisitApprovalStatuses: ITimesheetVisitApprovalStatus[];
  visitCategories: IVisitCategory[];
  visitStatuses: IVisitStatus[];
  visitTemplateFrequencyUnits: IVisitTemplateFrequencyUnit[];
}

export async function runQuery(client: IApolloClient): Promise<IAllEnums> {
  const queryResult = await client.query<Result['data'], Variables>({
    query: Document,
    variables: {}
  });

  return {
    daysOfWeek: __gqlObjects2Clients<IDayOfWeekName>(queryResult.data?.daysOfWeek ?? []),
    disciplines: __gqlObjects2Clients<IDisciplineName>(queryResult.data?.disciplines ?? []),
    episodeContinuationTypes: __gqlObjects2Clients<IEpisodeContinuationTypeName>(queryResult.data?.episodeContinuationTypes ?? []),
    episodeInactiveTypes: __gqlObjects2Clients<IEpisodeInactiveTypeName>(queryResult.data?.episodeInactiveTypes ?? []),
    episodeStatuses: __gqlObjects2Clients<IEpisodeStatusName>(queryResult.data?.episodeStatuses ?? []),
    genders: __gqlObjects2Clients<IGenderName>(queryResult.data?.genders ?? []),
    hireStatuses: __gqlObjects2Clients<IHireStatusName>(queryResult.data?.hireStatuses ?? []),
    missedVisitReasons: _.map(queryResult.data?.missedVisitReasons ?? [], (gqlValue): IMissedVisitReason => ({
      id: gqlValue.id,
      name: gqlValue.name,
      displayName: gqlValue.displayName,
      isUserSelectable: gqlValue.isUserSelectable
    })),
    patientStatuses: __gqlObjects2Clients<IPatientStatusName>(queryResult.data?.patientStatuses ?? []),
    permissionCategories: __gqlObjects2Clients<IPermissionCategoryName>(queryResult.data?.permissionCategories ?? []),
    requirementRecurrenceTypes: __gqlObjects2Clients<IRequirementRecurrenceTypeName>(queryResult.data?.requirementRecurrenceTypes ?? []),
    requirementStatuses: __gqlObjects2Clients<IRequirementStatusName>(queryResult.data?.requirementStatuses ?? []),
    requirementTypes: __gqlObjects2Clients<IRequirementTypeName>(queryResult.data?.requirementTypes ?? []),
    shifts: __gqlObjects2Clients<IShiftName>(queryResult.data?.shifts ?? []),
    timesheetVisitApprovalStatuses: __gqlObjects2Clients<ITimesheetVisitApprovalStatusName>(queryResult.data?.timesheetVisitApprovalStatuses ?? []),
    visitCategories: __gqlObjects2Clients<IVisitCategoryName>(queryResult.data?.visitCategories ?? []),
    visitStatuses: __gqlObjects2Clients<IVisitStatusName>(queryResult.data?.visitStatuses ?? []),
    visitTemplateFrequencyUnits: __gqlObjects2Clients<IVisitTemplateFrequencyUnitName>(queryResult.data?.visitTemplateFrequencyUnits ?? [])
  };
}



interface IGqlEnumObject<TEnumName extends string> {
  id: string;
  name: TEnumName;
  displayName: string;
}

function __gqlObject2Client<TEnumName extends string>(gqlObject: IGqlEnumObject<TEnumName>): IGenericEnum<TEnumName> {
  return {
    id: gqlObject.id,
    name: gqlObject.name,
    displayName: gqlObject.displayName
  };
}



function __gqlObjects2Clients<TEnumName extends string>(gqlObjects: IGqlEnumObject<TEnumName>[]) {
  return _.map(gqlObjects, gqlObject => __gqlObject2Client(gqlObject));
}
