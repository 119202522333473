import {
  genEnumSelectFieldComponent,
  enumCache
} from 'modules/layer2';

import i18next from 'i18next';
import * as dataService from './dataService';

export type IDisciplineName = dataService.IDisciplineName;



export const DisciplineSelectField = genEnumSelectFieldComponent<IDisciplineName, enumCache.IDiscipline>(dataService.useDisciplineSelectFieldQuery, () => i18next.t('discipline'));
