import React from 'react';

import {
  authService,
  BaseActivityTemplate,
  EmployeeProfileTab,
  paletteColors,
  LoginCodePhoneNumberModal
} from 'modules';

import { Button } from '@mui/material';
import { Create } from '@mui/icons-material';
import * as dataService from './dataService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



export const EmployeeMyProfileActivity: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery();

  const [isLoginCodePhoneNumberModalOpen, setIsLoginCodePhoneNumberModalOpen] = React.useState(false);



  React.useEffect(
    () => {
      if (!queryTuple.loading && !queryTuple.data.employeeId) {
        navigate('/');
      }
    },
    [
      queryTuple.loading,
      queryTuple.data.employeeId
    ]
  );



  async function handleClickLogOut() {
    await authService.logOut();
    navigate('/');
  }



  function handleClickEditData() {
    navigate('/employee/myProfile/edit');
  }



  return (
    <>
      <BaseActivityTemplate
        mode="employee"
        headerOptions={{
          backgroundColor: paletteColors.employees,
          title: queryTuple.data.fullName,
          elRightButtons: [
            (
              <Button
                key="logOut"
                variant="outlined"
                onClick={handleClickLogOut}>
                {t('auth:logOut')}
              </Button>
            ),
            (
              <Button
                key="changeLoginCodePhoneNumber"
                variant="outlined"
                onClick={() => setIsLoginCodePhoneNumberModalOpen(true)}>
                {t('changeLoginCodePhoneNumber')}
              </Button>
            ),
            (
              <Button
                key="editData"
                variant="outlined"
                onClick={handleClickEditData}
                startIcon={(
                  <Create />
                )}>
                {t('editData')}
              </Button>
            )
          ]
        }}
        isLoading={queryTuple.loading}>

        {!queryTuple.loading && queryTuple.data.employeeId && (
          <EmployeeProfileTab
            employeeId={queryTuple.data.employeeId} />
        )}

      </BaseActivityTemplate>

      {isLoginCodePhoneNumberModalOpen && (
        <LoginCodePhoneNumberModal
          onAccept={() => setIsLoginCodePhoneNumberModalOpen(false)}
          onClose={() => setIsLoginCodePhoneNumberModalOpen(false)} />
      )}
    </>
  );
};
