import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  Radio
} from '@mui/material';



export interface ICheckboxFieldProps {
  value: boolean;
  onChange: (value: boolean) => void;
  label?: string;
  isDisabled?: boolean;
  displayType?: 'check' | 'radio';
}

export const CheckboxField: React.FC<ICheckboxFieldProps> = props => {
  const displayType = props.displayType ?? 'check';

  const elField = displayType === 'check' ? (
    <Checkbox
      checked={props.value}
      value={props.value}
      onChange={() => props.onChange(!props.value)}
      color="primary"
      disabled={props.isDisabled} />

  ) : (
    <Radio
      checked={props.value}
      value={props.value}
      onChange={() => props.onChange(!props.value)}
      color="primary"
      disabled={props.isDisabled} />
  );

  return (
    <FormControlLabel
      label={props.label ?? ""}
      control={elField} />
  );
};
