import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  SkeletonRows,
  ValueWithLabel
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



export interface IStatusCheckSectionProps {
  employeeId: string;
}

export const StatusCheckSection: React.FC<IStatusCheckSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useStatusCheckSectionQuery(props.employeeId);



  return (
    <Box mt={6}>
      <Box mb={3}>
        <Typography variant="h3">
          {t('adminEmployees:statusCheck')}
        </Typography>
      </Box>

      {queryTuple.loading ? (
        <SkeletonRows numRows={7} />

      ) : (
        <Grid container>
          <ValueWithLabel
            label={t('adminEmployees:licenseCheck')}
            value={queryTuple.data.licenseCheck} />

          <ValueWithLabel
            label={t('adminEmployees:fraudCheck')}
            value={queryTuple.data.fraudCheck} />
        </Grid>
      )}
    </Box>
  );
};
