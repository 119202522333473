import React from 'react';

import {
  Box,
  Grid,
  InputLabel,
  Typography
} from '@mui/material';
import {
  Control,
  UseFormWatch
} from 'react-hook-form';
import {
  genCheckboxControllerComponent,
  genTimeFieldControllerComponent
} from 'modules';

import { styled } from '@mui/material/styles';
import * as types from '../../types';



const CheckboxController = genCheckboxControllerComponent<types.IFormState>();
const TimeFieldController = genTimeFieldControllerComponent<types.IFormState>();



const ContainerBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center'
}));

const CheckboxSectionBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const FieldsSectionBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start'
}));

const DividerDiv = styled('div')(({theme}) => ({
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  alignSelf: 'center',
  width: '12px',
  height: '3px',
  backgroundColor: '#979797'
}));

const VerticalFieldContainerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

const VerticalFieldValueBox = styled(Box)(({theme}) => ({
  marginTop: theme.spacing(1)
}));

const EodInputLabel = styled(InputLabel)(() => ({
  transformOrigin: 'center',
  '&$shrink': {
    transformOrigin: 'center'
  }
}));



export interface IWorkingHoursEntryProps {
  dayOfWeek: types.IDayOfWeek;
  formControl: Control<types.IFormState>;
  formWatch: UseFormWatch<types.IFormState>;
  isDisabled: boolean;
}

export const WorkingHoursEntry: React.FC<IWorkingHoursEntryProps> = props => {
  const isEnabled = props.formWatch(`dayOfWeek2WorkingHoursEntry.${props.dayOfWeek}.isEnabled`);
  const tillEndOfDay = props.formWatch(`dayOfWeek2WorkingHoursEntry.${props.dayOfWeek}.tillEndOfDay`);



  return (
    <ContainerBox>
      <Grid
        container
        spacing={2}
        alignItems="center">

        <Grid
          item
          md={4}>

          <CheckboxSectionBox>
            <CheckboxController
              fieldKey={`dayOfWeek2WorkingHoursEntry.${props.dayOfWeek}.isEnabled`}
              formControl={props.formControl} />

            <Typography variant="body1">
              <strong>
                {types.dayOfWeek2DisplayName[props.dayOfWeek]}
              </strong>
            </Typography>
          </CheckboxSectionBox>

        </Grid>

        <Grid
          item
          md={8}>

          <FieldsSectionBox
            style={{opacity: isEnabled ? 1 : 0}}>

            <Box>
              <TimeFieldController
                label="From"
                formControl={props.formControl}
                fieldKey={`dayOfWeek2WorkingHoursEntry.${props.dayOfWeek}.startTime`}
                isRequired={isEnabled}
                isDisabled={!isEnabled} />
            </Box>

            <DividerDiv />

            {!tillEndOfDay ? (
              <Box>
                <TimeFieldController
                  label="To"
                  formControl={props.formControl}
                  fieldKey={`dayOfWeek2WorkingHoursEntry.${props.dayOfWeek}.endTime`}
                  isRequired={isEnabled}
                  isDisabled={!isEnabled} />
              </Box>

            ) : (
              <VerticalFieldContainerBox>
                <InputLabel shrink>
                  To
                </InputLabel>

                <VerticalFieldValueBox>
                  24:00
                </VerticalFieldValueBox>
              </VerticalFieldContainerBox>
            )}

            <VerticalFieldContainerBox>
              <EodInputLabel>
                EOD?
              </EodInputLabel>

              <CheckboxController
                formControl={props.formControl}
                fieldKey={`dayOfWeek2WorkingHoursEntry.${props.dayOfWeek}.tillEndOfDay`} />
            </VerticalFieldContainerBox>

          </FieldsSectionBox>

        </Grid>

      </Grid>
    </ContainerBox>
  );
};
