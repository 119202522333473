import React from 'react';
import _ from 'lodash';

import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Link
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';



const BreadcrumbsTypography = styled(Typography)(() => ({
  fontSize: '12px'
}));

const BaseBreadcrumbs = styled(MuiBreadcrumbs)(() => ({
  '& .MuiBreadcrumbs-separator': {
    fontSize: '12px'
  }
}));



export const Breadcrumbs: React.FC = () => {
  const rawBreadcrumbs = useBreadcrumbs();
  const {t} = useTranslation();

  const breadcrumbs = React.useMemo(
    () => _.map(
      rawBreadcrumbs.slice(1, rawBreadcrumbs.length - 1),
      rawBreadcrumb => (
        <Link
          key={rawBreadcrumb.match.pathname}
          to={rawBreadcrumb.match.pathname}
          color="inherit"
          component={RouterLink}>

          <BreadcrumbsTypography>
            {t(`breadcrumbs:${_.last(rawBreadcrumb.match.pathname.split('/')) ?? ''}`)}
          </BreadcrumbsTypography>

        </Link>
      )
    ),
    [rawBreadcrumbs]
  );



  return (
    <BaseBreadcrumbs separator="›">
      {breadcrumbs}
    </BaseBreadcrumbs>
  );
};
