import React from 'react';
import _ from 'lodash';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import {
  Autocomplete,
  TextField
} from '@mui/material';

import { validationUtil } from 'modules/layer0';
import { FieldContainer } from 'modules/layer1';



export interface IOption {
  id: string;
  label: string;
}

export interface IBaseEnumSelectFieldProps {
  label?: string;
  options: IOption[];
  optionId: string;
  onChangeOptionId: (optionId: string) => void;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const BaseEnumSelectField: React.FC<IBaseEnumSelectFieldProps> = props => {
  const options = React.useMemo(
    (): IOption[] => [
      ...props.options,
      {
        id: '',
        label: ""
      }
    ],
    [props.options]
  );

  const optionsById = React.useMemo(
    () => _.keyBy(options, option => option.id),
    [options]
  );

  const selectedOption = React.useMemo(
    () => optionsById[props.optionId] ?? {
      id: '',
      label: ""
    },
    [
      props.optionId,
      optionsById
    ]
  );



  return (
    <FieldContainer.Comp>
      <Autocomplete
        options={options}
        value={selectedOption}
        onChange={(_event, value) => props.onChangeOptionId(value?.id ?? '')}
        getOptionLabel={option => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={props.isDisabled}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            error={!!props.errorMessage}
            helperText={props.errorMessage} />
        )} />
    </FieldContainer.Comp>
  );
};



export interface IEnumSelectFieldProps<TOptionName extends string> extends Pick<IBaseEnumSelectFieldProps, 'label' | 'errorMessage' | 'isDisabled'> {
  optionId: TOptionName | '';
  onChangeOptionId: (optionId: TOptionName | '') => void;
}

export interface IGenericEnum<TOptionName extends string> {
  id: string;
  name: TOptionName;
  displayName: string;
}

export interface IUseGetEnumValuesTuple<TEnumName extends string, TEnum extends IGenericEnum<TEnumName>> {
  loading: boolean;
  data: {
    enumValues: TEnum[];
  };
}

export function genEnumSelectFieldComponent<TEnumName extends string, TEnum extends IGenericEnum<TEnumName>>(
  useGetEnumValues: () => IUseGetEnumValuesTuple<TEnumName, TEnum>,
  getDefaultLabel?: () => string
) {
  const EnumSelectField: React.FC<IEnumSelectFieldProps<TEnumName>> = props => {
    const tuple = useGetEnumValues();

    const options = React.useMemo(
      () =>
        tuple.loading ?
          [] :
          _.map(tuple.data.enumValues, (enumValue): IOption => ({
            id: enumValue.name,
            label: enumValue.displayName
          })),
      [
        tuple.loading,
        tuple.data.enumValues
      ]
    );



    return (
      <BaseEnumSelectField
        {...props}
        label={getDefaultLabel ? getDefaultLabel() : undefined}
        options={options}
        optionId={props.optionId}
        onChangeOptionId={optionId => props.onChangeOptionId(optionId as TEnumName | '')}
        isDisabled={props.isDisabled || tuple.loading} />
    );
  };

  return EnumSelectField;
}



export interface IEnumFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label?: string;
  isDisabled?: boolean;
}

export function genEnumSelectFieldControllerComponent<TFormState extends FieldValues, TOptionName extends string>(
  Component: React.FC<IEnumSelectFieldProps<TOptionName>>
) {
  const EnumSelectFieldController: React.FC<IEnumFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        name={props.fieldKey}
        control={props.formControl}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <Component
            label={props.label}
            optionId={field.value}
            onChangeOptionId={field.onChange}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled} />
        )} />
    );
  };

  return EnumSelectFieldController;
}
