import React from 'react';
import _ from 'lodash';

import {
  useVisitEmployeeVisitInfoSectionQuery,
  VisitDataValueFieldsFragment
} from 'generated/graphql';

import { enumCache } from 'modules/layer2';
import { DataValueList } from 'modules/layer4';

import { useTranslation } from 'react-i18next';



export interface IVisitImage {
  id: string;
  title: string;
  fileKey: string;
}

export interface ITask {
  id: string;
  title: string;
  description: string;
  isCompleted: boolean;
}

export interface IData {
  status: enumCache.IVisitStatusName;
  tasks: ITask[];
  hasPatientConditionChanged: boolean;
  patientConditionChangeDescription: string;
  additionalComments: string;
  temperature: string;
  bloodPressure: string;
  heartRate: string;
  satRa: string;
  satO2: string;
  o2l: string;
  medicationHeld: string;
  patientAppearances: string;
  locationType: DataValueList.IDataValue[];
  signatureFileKey: string;
  images: IVisitImage[];
}

export function useQuery(id: string) {
  const {t} = useTranslation();

  const tuple = useVisitEmployeeVisitInfoSectionQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlVisit = tuple.data?.visit;
      const data: IData = {
        status: gqlVisit?.visitStatus.name ?? 'open',
        tasks: _.map(gqlVisit?.tasks ?? [], (gqlTask): ITask => ({
          id: gqlTask.id,
          title: gqlTask.title,
          description: gqlTask.description,
          isCompleted: !!gqlTask.completionTimestamp
        })),
        hasPatientConditionChanged: gqlVisit?.hasPatientConditionChanged ?? false,
        patientConditionChangeDescription: gqlVisit?.patientConditionChangeDescription || t('noValue'),
        additionalComments: gqlVisit?.additionalComments || t('noValue'),
        temperature: gqlVisit?.temperature ?? "",
        bloodPressure: gqlVisit?.bloodPressure ?? "",
        heartRate: gqlVisit?.heartRate ?? "",
        satRa: gqlVisit?.satRa ?? "",
        satO2: gqlVisit?.satO2 ?? "",
        o2l: gqlVisit?.o2l ?? "",
        medicationHeld: gqlVisit?.medicationHeld ?? "",
        patientAppearances: gqlVisit?.patientAppearances ?? "",
        locationType: __gqlDataValues2Client(_.compact([gqlVisit?.locationType])),
        signatureFileKey: gqlVisit?.signature?.file.key ?? '',
        images: _.map(gqlVisit?.images ?? [], (gqlImage): IVisitImage => ({
          id: gqlImage.id,
          title: gqlImage.title,
          fileKey: gqlImage.file.key
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



function __gqlDataValue2Client(gqlDataValue: VisitDataValueFieldsFragment): DataValueList.IDataValue {
  return {
    value: gqlDataValue.value,
    description: gqlDataValue.description ?? undefined
  };
}



function __gqlDataValues2Client(gqlDataValues: VisitDataValueFieldsFragment[]) {
  return _.map(gqlDataValues, gqlValue => __gqlDataValue2Client(gqlValue));
}
