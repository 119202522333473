import React from 'react';
import _ from 'lodash';

import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Box
} from '@mui/material';
import {
  ArrowUpward as BaseArrowUpward,
  ArrowDownward as BaseArrowDownward
} from '@mui/icons-material';

import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';



const HeaderCellBox = styled(Box)(() => ({
  fontSize: '14px',
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center'
}));

interface IHiddenBoxProps {
  isHidden: boolean;
}

const HiddenBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isHidden'
})<IHiddenBoxProps>(({isHidden}) => ({
  ...(isHidden && {
    opacity: 0
  })
}));

const ArrowUpward = styled(BaseArrowUpward)(() => ({
  display: 'block'
}));

const ArrowDownward = styled(BaseArrowDownward)(() => ({
  display: 'block'
}));

const CellTypography = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 400
}));



export interface IColumn<TColumnId extends string> {
  id: TColumnId;
  label: string;
  sortType?: 'string' | 'number';
  width?: string;
}

export interface IRowStringValue {
  type: 'string';
  sortableValue?: string | number;
  label: string;
  toRoute?: string;
}

export interface IRowComponentValue {
  type: 'component';
  sortableValue: string | number;
  label: JSX.Element;
}

export type IRowValue =
  IRowStringValue |
  IRowComponentValue;

export interface IRow<TColumnId extends string> {
  id: string;
  columnId2Value: Record<TColumnId, IRowValue>;
}

interface ISortOptions<TColumnId extends string> {
  sortByColumnId: TColumnId | '';
  sortDirection: 'ascending' | 'descending';
}

export interface ITableProps<TColumnId extends string> {
  columns: IColumn<TColumnId>[];
  rows: IRow<TColumnId>[];
  disableSorting?: boolean;
  initSortOptions?: ISortOptions<TColumnId>;
}

export function genTableComponent<TColumnId extends string>() {
  const Table: React.FC<ITableProps<TColumnId>> = props => {
    const [sortOptions, setSortOptions] = React.useState<ISortOptions<TColumnId>>(props.initSortOptions ?? {
      sortByColumnId: props.columns[0].id,
      sortDirection: 'ascending'
    });



    const sortedRows = React.useMemo(
      () => {
        const sortByColumnId = sortOptions?.sortByColumnId ?? '';
        if (props.disableSorting || !sortByColumnId) {
          return props.rows;
        }

        let retVal = _.sortBy(props.rows, row => {
          const rowValue = row.columnId2Value[sortByColumnId];
          const sortableValue = rowValue.type === 'string' ?
            (rowValue.sortableValue ?? rowValue.label) :
            rowValue.sortableValue;

          return sortableValue;
        });

        if (sortOptions?.sortDirection === 'descending') {
          retVal = _.reverse(retVal);
        }

        return retVal;
      },
      [
        props.rows,
        props.disableSorting,
        sortOptions.sortByColumnId,
        sortOptions.sortDirection
      ]
    );



    function rowValue2Component(rowValue?: IRowValue) {
      if (!rowValue) {
        return (
          <div />
        );

      } else if (rowValue.type === 'component') {
        return rowValue.label;

      } else if (rowValue.type === 'string') {
        const baseContent = (
          <CellTypography>
            {rowValue.label}
          </CellTypography>
        );

        if (rowValue.toRoute) {
          return (
            <Link
              to={rowValue.toRoute}
              component={RouterLink}>
              {baseContent}
            </Link>
          );

        } else {
          return baseContent;
        }

      } else {
        return (
          <div />
        );
      }
    }



    function handleClickColumnHeader(columnId: TColumnId) {
      if (sortOptions.sortByColumnId !== columnId) {
        setSortOptions({
          sortByColumnId: columnId,
          sortDirection: 'ascending'
        });

      } else if (sortOptions.sortDirection === 'ascending') {
        setSortOptions(previous => ({
          ...previous,
          sortDirection: 'descending'
        }));

      } else {
        setSortOptions(previous => ({
          ...previous,
          sortByColumnId: ''
        }));
      }
    }



    return (
      <MuiTable size="small">
        <TableHead>
          <TableRow>
            {_.map(props.columns, column => (
              <TableCell
                key={column.id}
                style={{
                  cursor: !props.disableSorting ? 'pointer' : undefined,
                  width: column.width
                }}
                onClick={() => !props.disableSorting ?
                  handleClickColumnHeader(column.id) :
                  undefined}>

                <HeaderCellBox>
                  {column.label}

                  <HiddenBox
                    isHidden={props.disableSorting || sortOptions.sortByColumnId !== column.id}>

                    {sortOptions.sortDirection === 'ascending' && (
                      <ArrowUpward />
                    )}

                    {sortOptions.sortDirection === 'descending' && (
                      <ArrowDownward />
                    )}

                  </HiddenBox>
                </HeaderCellBox>

              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {_.map(sortedRows, row => (
            <TableRow key={row.id}>
              {_.map(props.columns, column => (
                <TableCell key={column.id}>
                  {rowValue2Component(row.columnId2Value[column.id])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    );
  };

  return Table;
}
