import React from 'react';
import _ from 'lodash';

import {
  useProvidersActivityQuery as useBaseQuery,
  useArchiveProviderMutation as useBaseArchiveProviderMutation,
  useImportProvidersMutation as useBaseImportProvidersMutation,
  ImportProviderInput
} from 'generated/graphql';



export interface IProvider {
  id: string;
  firstName: string;
  lastName: string;
  primaryPhone: string;
  secondaryPhone: string;
  faxNumber: string;
}

export interface IData {
  providers: IProvider[];
}

export function useProvidersActivityQuery() {
  const tuple = useBaseQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  return React.useMemo(
    () => {
      const gqlProviders = tuple.data?.providers ?? [];
      const data: IData= {
        providers: _.map(gqlProviders, (gqlProvider): IProvider => ({
          id: gqlProvider.id,
          firstName: gqlProvider.firstName,
          lastName: gqlProvider.lastName,
          primaryPhone: gqlProvider.primaryPhone,
          secondaryPhone: gqlProvider.secondaryPhone,
          faxNumber: gqlProvider.faxNumber
        }))
      };

      return {
        ...tuple,
        data,
        refetch: async () => {
          await tuple.refetch();
        }
      };
    },
    [tuple]
  );
}



export function useArchiveProviderMutation(fnRefetch: () => Promise<void>) {
  const [fnMutate, tuple] = useBaseArchiveProviderMutation();

  const fnWrappedMutate = React.useCallback(
    async (providerId: string) => {
      await fnMutate({
        variables: {
          id: providerId
        }
      });
      await fnRefetch();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export interface IImportProvider {
  firstName: string;
  lastName: string;
  primaryPhone: string;
  secondaryPhone: string;
  faxNumber: string;
}

export function useImportProvidersMutation() {
  const [fnMutate, tuple] = useBaseImportProvidersMutation();

  const fnWrappedMutate = React.useCallback(
    async (providers: IImportProvider[]) => {
      await fnMutate({
        variables: {
          input: {
            providers: _.map(providers, (provider): ImportProviderInput => ({
              firstName: provider.firstName,
              lastName: provider.lastName,
              primaryPhone: provider.primaryPhone,
              secondaryPhone: provider.secondaryPhone,
              faxNumber: provider.faxNumber
            }))
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
