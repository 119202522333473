import React from 'react';
import _ from 'lodash';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import {
  Autocomplete,
  TextField
} from '@mui/material';

import * as dataService from './dataService';

import { validationUtil } from 'modules/layer0';
import { FieldContainer } from 'modules/layer1';



export interface IEmployeeSelectFieldProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isDisabled?: boolean;
  onlyEmployeesWithAttachedUsers?: boolean;
}

export const EmployeeSelectField: React.FC<IEmployeeSelectFieldProps> = props => {
  const queryTuple = dataService.useEmployeeSelectFieldQuery();



  const value = React.useMemo(
    () => queryTuple.data.employeesById[props.value] ?? {
      id: '',
      fullName: ""
    },
    [
      queryTuple.data.employeesById,
      props.value
    ]
  );



  const employees = React.useMemo(
    () => props.onlyEmployeesWithAttachedUsers ?
      _.filter(queryTuple.data.employees, employee => employee.hasAttachedUser) :
      queryTuple.data.employees,
    [
      queryTuple.data.employees,
      props.onlyEmployeesWithAttachedUsers
    ]
  );



  return (
    <FieldContainer.Comp>
      <Autocomplete
        options={employees}
        value={value}
        onChange={(_event, value) => props.onChange(value?.id ?? '')}
        getOptionLabel={employee => employee.fullName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={queryTuple.loading || props.isDisabled}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            error={!!props.errorMessage}
            helperText={props.errorMessage} />
        )} />
    </FieldContainer.Comp>
  );
};



export interface IEmployeeSelectFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label?: string;
  isDisabled?: boolean;
  onlyEmployeesWithAttachedUsers?: boolean;
}

export function genEmployeeSelectFieldControllerComponent<TFormState extends FieldValues>() {
  const EmployeeSelectFieldController: React.FC<IEmployeeSelectFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        control={props.formControl}
        name={props.fieldKey}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <EmployeeSelectField
            label={props.label}
            value={field.value}
            onChange={field.onChange}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled}
            onlyEmployeesWithAttachedUsers={props.onlyEmployeesWithAttachedUsers} />
        )} />
    );
  };

  return EmployeeSelectFieldController;
}
