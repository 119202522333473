import React from 'react';
import _ from 'lodash';

import {
  useViewVisitTemplateActivityQuery as useBaseQuery,
  useDuplicateVisitTemplateMutation as useBaseDuplicateVisitTemplateMutation
} from 'generated/graphql';

import { enumCache } from 'modules';



export interface ITask {
  id: string;
  title: string;
  description: string;
}

export interface IData {
  displayName: string;
  visitCategoryName: enumCache.IVisitCategoryName;
  shiftName: enumCache.IShiftName;
  frequencyAmount: number;
  frequencyUnitName: enumCache.IVisitTemplateFrequencyUnitName;
  note: string;
  location: string;
  tasks: ITask[];
  canEdit: boolean;
}

export function useQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlTemplate = tuple.data?.visitTemplate;
      const data: IData = {
        displayName: gqlTemplate?.displayName ?? "",
        visitCategoryName: gqlTemplate?.visitCategory.name ?? 'form485',
        shiftName: gqlTemplate?.shift.name ?? 'am',
        frequencyAmount: gqlTemplate?.frequencyAmount ?? 0,
        frequencyUnitName: gqlTemplate?.frequencyUnit.name ?? 'month',
        note: gqlTemplate?.note ?? "",
        location: gqlTemplate?.location ?? "",
        tasks: _.map(gqlTemplate?.tasks, (gqlTask): ITask => ({
          id: gqlTask.id,
          title: gqlTask.title,
          description: gqlTask.description
        })),
        canEdit: gqlTemplate?.episode.status.name === 'pending'
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useDuplicateVisitTemplateMutation(id: string) {
  const [fnMutate, tuple] = useBaseDuplicateVisitTemplateMutation();

  const fnWrappedMutate = React.useCallback(
    async (displayName: string) => {
      const retVal = await fnMutate({
        variables: {
          input: {
            id,
            displayName
          }
        }
      });

      return retVal.data?.duplicateVisitTemplate.id ?? '';
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
