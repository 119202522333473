import _ from 'lodash';

import * as chipGenerator from '../shared/chipGenerator';
import { map } from 'rxjs';

import { enumCache } from 'modules/layer2';



export const HireStatusChip = chipGenerator.genChipComponent<enumCache.IHireStatusName>({
  obsValueId2DisplayData: enumCache.subjAllEnums.asObservable()
    .pipe(map((value): Record<enumCache.IHireStatusName, chipGenerator.IValueDisplayData> => {
      const enumValues = value.hireStatuses;
      const enumValuesByName = _.keyBy(enumValues, enumValue =>
        enumValue.name) as Record<enumCache.IHireStatusName, enumCache.IHireStatus>;

      return {
        hired: {
          label: enumValuesByName.hired?.displayName ?? "",
          backgroundColor: '#00CE84',
          contrastColor: '#fff'
        },
        inactive: {
          label: enumValuesByName.inactive?.displayName ?? "",
          backgroundColor: '#E0E0E0',
          contrastColor: '#7C7E88'
        },
        suspended: {
          label: enumValuesByName.suspended?.displayName ?? "",
          backgroundColor: '#E74C3C',
          contrastColor: '#fff'
        }
      };
    }))
});
