import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useViewTimesheetActivityQuery,
  useDenyTimesheetVisitMutation
} from 'generated/graphql';
import {
  dateUtil,
  enumCache
} from 'modules';

import Big from 'big.js';



export interface IVisit {
  id: string;
  displayName: string;
  route: string;
  date: moment.Moment | null;
  clockInTimestamp: moment.Moment | null;
  clockOutTimestamp: moment.Moment | null;
  durationHours: Big | null;
  approvalStatusName: enumCache.ITimesheetVisitApprovalStatusName | '';
  numOverrideBilledHours: Big | null;
  approvalComment: string;
}

export interface IEmployee {
  fullName: string;
  route: string;
}

export interface IData {
  shortId: string;
  employee: IEmployee;
  numTotalHours: Big | null;
  numApprovedHours: Big | null;
  startTimestamp: moment.Moment | null;
  endTimestamp: moment.Moment | null;
  visits: IVisit[];
}

export function useQuery(timesheetId: string) {
  const tuple = useViewTimesheetActivityQuery({
    variables: {
      timesheetId
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlTimesheet = tuple.data?.timesheet;
      const gqlEmployee = gqlTimesheet?.employee;

      const data: IData = {
        shortId: gqlTimesheet?.id.slice(-7) ?? "",
        employee: {
          fullName: `${gqlEmployee?.lastName ?? ""}, ${gqlEmployee?.firstName ?? ""}`,
          route: `/admin/employee/profile/${gqlEmployee?.id ?? ''}`
        },
        numTotalHours: gqlTimesheet?.numTotalHours ?
          Big(gqlTimesheet.numTotalHours) :
          null,
        numApprovedHours: gqlTimesheet?.numApprovedHours ?
          Big(gqlTimesheet.numApprovedHours) :
          null,
        startTimestamp: gqlTimesheet?.startTimestamp ?
          moment(gqlTimesheet.startTimestamp, moment.ISO_8601) :
          null,
        endTimestamp: gqlTimesheet?.endTimestamp ?
          moment(gqlTimesheet.endTimestamp, moment.ISO_8601) :
          null,
        visits: _.map(gqlTimesheet?.visitAssignedEmployees ?? [], (gqlVisitAssignedEmployee): IVisit => ({
          id: gqlVisitAssignedEmployee.id,
          displayName: gqlVisitAssignedEmployee.visit.template.displayName,
          route: `/admin/visits/view/${gqlVisitAssignedEmployee.visit.id}`,
          date: dateUtil.dateWithTimeZone2Moment(gqlVisitAssignedEmployee.visit.date),
          clockInTimestamp: gqlVisitAssignedEmployee.clockInTimestamp ?
            moment(gqlVisitAssignedEmployee.clockInTimestamp, moment.ISO_8601) :
            null,
          clockOutTimestamp: gqlVisitAssignedEmployee.clockOutTimestamp ?
            moment(gqlVisitAssignedEmployee.clockOutTimestamp, moment.ISO_8601) :
            null,
          durationHours: gqlVisitAssignedEmployee.durationMs ?
            Big(gqlVisitAssignedEmployee.durationMs)
              .div(1000)
              .div(60)
              .div(60) :
            null,
          approvalStatusName: gqlVisitAssignedEmployee.approvalStatus?.name ?? '',
          numOverrideBilledHours: gqlVisitAssignedEmployee.numOverrideBilledHours ?
            Big(gqlVisitAssignedEmployee.numOverrideBilledHours) :
            null,
          approvalComment: gqlVisitAssignedEmployee.approvalComment
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useDenyMutation() {
  const [fnMutate, tuple] = useDenyTimesheetVisitMutation();



  const fnWrappedMutate = React.useCallback(
    async (id: string) => {
      await fnMutate({
        variables: {
          input: {
            id
          }
        }
      });
    },
    [fnMutate]
  );



  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );



  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
