import React from 'react';
import _ from 'lodash';

import {
  Box,
  IconButton,
  Tab,
  Tabs,
  Typography
} from '@mui/material';

import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { ResponsiveImage } from 'modules/layer2';
import { assetUtil } from 'modules/layer0';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';



const OuterContainerBox = styled(Box)(({theme}) => ({
  paddingTop: theme.spacing(5),
  paddingRight: theme.spacing(9),
  paddingLeft: theme.spacing(9),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));

const BodyContainerBox = styled(Box)(({theme}) => ({
  paddingBottom: theme.spacing(5),
  flex: `0 0 ${theme.spacing(13)}px`,
  height: theme.spacing(13),
  display: 'flex',
  justifyContent: 'space-between'
}));

const LeftSectionBox = styled(Box)(() => ({
  display: 'flex'
}));

const TitlesBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const RightSectionBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'stretch'
}));

const ButtonRowBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const ButtonContainerBox = styled(Box)(({theme}) => ({
  marginLeft: theme.spacing(2)
}));

const EmployeeIdBox = styled(Box)(({theme}) => ({
  marginLeft: theme.spacing(2)
}));

const TitleBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));



export interface ITab {
  id: string;
  label: string;
}

export interface ITabOptions {
  tabs: ITab[];
  selectedTabId: string;
  onSelectTab: (tabId: string) => void;
}

export interface IActivityHeaderProps {
  backgroundColor: string;
  title: string;
  employeeId?: string;
  hasBackButton?:boolean;
  onClickBackButton?: () => void;
  tabOptions?: ITabOptions;
  elRightButtons?: (JSX.Element | null)[];
  elRight?: JSX.Element;
}

export const ActivityHeader: React.FC<IActivityHeaderProps> = props => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const elRightButtons = React.useMemo(
    () => _.compact(props.elRightButtons),
    [props.elRightButtons]
  );



  function handleClickBack() {
    if (props.onClickBackButton) {
      props.onClickBackButton();
    } else {
      navigate(-1);
    }
  }



  return (
    <OuterContainerBox
      style={{backgroundColor: props.backgroundColor}}>

      <BodyContainerBox>

        <LeftSectionBox>
          {props.hasBackButton && (
            <Box
              mt={1}
              mr={3}>
              <IconButton
                size="small"
                onClick={handleClickBack}>
                <ResponsiveImage
                  urlSet={assetUtil.imgsBackArrow} />
              </IconButton>
            </Box>
          )}

          <TitlesBox>
            <Breadcrumbs />

            <TitleBox>
              <Typography variant="h1">
                {props.title}
              </Typography>

              {props.employeeId && (
                <EmployeeIdBox>
                  <Typography
                    color="secondary"
                    variant="inherit">
                    {t('adminEmployees:displayEmployeeId', {employeeId: props.employeeId})}
                  </Typography>
                </EmployeeIdBox>
              )}
            </TitleBox>
          </TitlesBox>
        </LeftSectionBox>

        <RightSectionBox>
          {props.elRight}

          {elRightButtons && (
            <ButtonRowBox>
              {_.map(elRightButtons, elRightButton => (
                <ButtonContainerBox
                  key={elRightButton.key}>
                  {elRightButton}
                </ButtonContainerBox>
              ))}
            </ButtonRowBox>
          )}
        </RightSectionBox>

      </BodyContainerBox>

      {props.tabOptions && (
        <Tabs
          value={props.tabOptions.selectedTabId}
          onChange={(_event, value) => props.tabOptions?.onSelectTab(value)}>
          {_.map(props.tabOptions.tabs, tab => (
            <Tab
              key={tab.id}
              value={tab.id}
              label={tab.label} />
          ))}
        </Tabs>
      )}

    </OuterContainerBox>
  );
};
