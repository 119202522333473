import React from 'react';

import {
  fields,
  IRadioControllerOption
} from 'modules';
import {
  Typography,
  Box,
  Divider
} from '@mui/material';

import * as types from '../types';
import { UseFormReturn } from 'react-hook-form';

export type { IRadioControllerOption } from 'modules';



const RadioController = fields.genRadioControllerComponent<types.IFormState>();



export type IFieldKey =
  'prognosisValue';

export interface IRadioButtonGroupProps {
  form: UseFormReturn<types.IFormState>;
  fieldKey: IFieldKey;
  title: string;
  options: IRadioControllerOption[];
  labelTranslationPrefix: string;
  isDisabled?: boolean;
}

export const RadioButtonGroup: React.FC<IRadioButtonGroupProps> = props => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h2">
          {props.title}
        </Typography>
      </Box>

      <Box mb={3}>
        <RadioController
          formControl={props.form.control}
          fieldKey={props.fieldKey}
          isDisabled={props.isDisabled}
          options={props.options} />

        <Box mt={3}>
          <Divider />
        </Box>
      </Box>
    </>
  );
};
