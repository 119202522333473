import React from 'react';

import {
  useEmployeePatientProfileActivityQuery
} from 'generated/graphql';

import { IPatientStatusName } from 'modules/layer3/selectFields';



export interface IData {
  fullName: string;
  patientStatusName: IPatientStatusName;
}

export function useQuery(id: string) {
  const tuple = useEmployeePatientProfileActivityQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlPatient = tuple.data?.patient;
      const data: IData = {
        fullName: `${gqlPatient?.firstName ?? ""} ${gqlPatient?.lastName ?? ""}`,
        patientStatusName: gqlPatient?.patientStatus.name ?? 'pending'
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
