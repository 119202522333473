import React from 'react';

import {
  Box,
  Modal,
  Typography,
  Button
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { LoadingOverlay } from 'modules/layer0';



interface IContainerBoxProps {
  isDoubleWide: boolean;
  expandWidthToFit: boolean;
}

const ContainerBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isDoubleWide' &&
    prop !== 'expandWidthToFit'
})<IContainerBoxProps>(({isDoubleWide, expandWidthToFit, theme}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  width: !expandWidthToFit ?
    theme.spacing(57 * (isDoubleWide ? 2 : 1)) :
    undefined,
  maxWidth: '90vw',
  maxHeight: '90vh',
  overflowY: 'auto',
  padding: theme.spacing(3)
}));

const TitleTypography = styled(Typography)(() => ({
  textAlign: 'center',
  fontWeight: 500,
  fontSize: '20px',
  color: '#263238'
}));

const ButtonRowBox = styled(Box)(({theme}) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
}));

const ButtonContainerBox = styled(Box)(() => ({
  flex: '1 0',
  display: 'flex'
}));

const AcceptButton = styled(Button)(() => ({
  backgroundColor: '#E74C3C',
  color: '#fff'
}));



export interface IBaseModalProps {
  title?: string;
  isOpen: boolean;
  isLoading?: boolean;
  onAccept?: () => void;
  onClose?: () => void;
  acceptButtonLabel?: string;
  cancelButtonLabel?: string;
  disableDefaultWidth?: boolean;
  showButtons?: boolean;
  isDoubleWide?: boolean;
  expandWidthToFit?: boolean;
  children: (buttons: JSX.Element | null) => JSX.Element;
}

export const BaseModal: React.FC<IBaseModalProps> = props => {
  return (
    <Modal
      open={props.isOpen}
      onClose={props.onClose ?? (() => {})}>

      <>
        <LoadingOverlay
          active={!!props.isLoading} />

        <ContainerBox
          isDoubleWide={!!props.isDoubleWide}
          expandWidthToFit={!!props.expandWidthToFit}
          style={{
            width: props.disableDefaultWidth ? 'unset' : undefined
          }}>

          {props.title && (
            <Box mb={2}>
              <TitleTypography>
                {props.title}
              </TitleTypography>
            </Box>
          )}

          <Box>
            {props.children(props.showButtons ? (
              <ButtonRowBox>
                <ButtonContainerBox mr={1}>
                  <Button
                    onClick={props.onClose}
                    fullWidth
                    variant="outlined"
                    disabled={props.isLoading}>
                    {props.cancelButtonLabel ?? "Cancel"}
                  </Button>
                </ButtonContainerBox>

                <ButtonContainerBox>
                  <AcceptButton
                    type="submit"
                    onClick={props.onAccept}
                    fullWidth
                    variant="contained"
                    disabled={props.isLoading}>
                    {props.acceptButtonLabel ?? "Accept"}
                  </AcceptButton>
                </ButtonContainerBox>
              </ButtonRowBox>
            ) : null)}
          </Box>

        </ContainerBox>
      </>

    </Modal>
  );
};
