import React from 'react';
import _ from 'lodash';

import {
  chips,
  dateUtil,
  genTableComponent,
  IRow,
  LoadingOverlay
} from 'modules';

import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';



const __columnIds = [
  'displayName',
  'visitStatus',
  'date',
  'timeZone',
  'shift',
  'location'
] as const;
type IColumnId = typeof __columnIds[number];

const Table = genTableComponent<IColumnId>();



export interface IPatientVisitsTabProps {
  patientId: string;
  userType: 'administrator' | 'employee';
}

export const PatientVisitsTab: React.FC<IPatientVisitsTabProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.patientId);



  const genRoute = React.useCallback(
    (visitId: string) => {
      if (props.userType === 'administrator') {
        return `/admin/visits/view/${visitId}`;
      } else if (props.userType === 'employee') {
        return `/employee/visit/${visitId}`;
      } else {
        return '';
      }
    },
    [props.userType]
  );



  return (
    <>
      <LoadingOverlay
        active={queryTuple.loading} />

      <Table
        columns={[
          {
            id: 'displayName',
            label: t('name')
          },
          {
            id: 'visitStatus',
            label: t('status')
          },
          {
            id: 'date',
            label: t('date')
          },
          {
            id: 'timeZone',
            label: t('timeZone')
          },
          {
            id: 'shift',
            label: t('shift')
          },
          {
            id: 'location',
            label: t('location')
          }
        ]}
        rows={_.map(queryTuple.data.visits, (visit): IRow<IColumnId> => ({
          id: visit.id,
          columnId2Value: {
            displayName: {
              type: 'string',
              label: visit.displayName,
              toRoute: genRoute(visit.id)
            },
            visitStatus: {
              type: 'component',
              label: (
                <chips.VisitStatusChip
                  valueId={visit.visitStatusName} />
              ),
              sortableValue: visit.visitStatusName
            },
            date: {
              type: 'string',
              label: visit.date?.format(dateUtil.displayDateFormat) ?? t('noValue'),
              sortableValue: visit.date?.unix() ?? 0
            },
            timeZone: {
              type: 'string',
              label: visit.timeZone
            },
            shift: {
              type: 'string',
              label: t(`shifts.${visit.shiftName}`),
              sortableValue: `${t(`shifts.${visit.shiftName}`)}`
            },
            location: {
              type: 'string',
              label: visit.location
            }
          }
        }))}
        initSortOptions={{
          sortByColumnId: 'date',
          sortDirection: 'descending'
        }} />
    </>
  );
};
