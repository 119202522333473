import React from 'react';
import _ from 'lodash';

import {
  Box,
  Typography,
  Link
} from '@mui/material';
import {
  baseTypes,
  SkeletonRows
} from 'modules';

import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import * as dataService from './dataService';



export interface IVisitAssignedEmployeesSectionProps {
  visitId: string;
  userType: baseTypes.IUserType;
}

export const VisitAssignedEmployeesSection: React.FC<IVisitAssignedEmployeesSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.visitId);

  const isLoading =
    queryTuple.loading;



  return isLoading ? (
    <SkeletonRows numRows={5} />

  ) : (
    <>
      <Box mb={3}>
        <Typography variant="h2">
          {t('visits:assignedEmployees')}
        </Typography>
      </Box>

      <ul>
        {_.map(queryTuple.data.assignedEmployees, employee => (
          <li key={employee.id}>
            {props.userType === 'administrator' && (
              <Link
                component={RouterLink}
                to={employee.route}>
                {employee.fullName}
              </Link>
            )}

            {props.userType === 'employee' && employee.fullName}

            {(employee.clockInTimestamp || employee.clockOutTimestamp) && (
              <ul>
                {employee.clockInTimestamp && (
                  <li>
                    <strong>{t('visits:clockInTime')}:</strong> {employee.clockInTimestamp}
                  </li>
                )}

                {employee.clockOutTimestamp && (
                  <li>
                    <strong>{t('visits:clockOutTime')}:</strong> {employee.clockOutTimestamp} {employee.wasAutoClockedOut && t('visits:automatic')}
                  </li>
                )}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};
