import React from 'react';

import {
  DatePicker,
  LocalizationProvider
} from '@mui/lab';
import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';

import { TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { validationUtil } from 'modules/layer0';
import { FieldContainer } from 'modules/layer1';



export type IDateErrorReason =
  'shouldDisableDate' |
  'disablePast' |
  'disableFuture' |
  'minDate' |
  'maxDate' |
  'invalidDate';

export interface IDateFieldProps {
  label: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
  errorMessage?: string;
  onError?: (reason: IDateErrorReason | '') => void;
  isDisabled?: boolean;
}

export const DateField: React.FC<IDateFieldProps> = props => {
  return (
    <FieldContainer.Comp>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          showToolbar={false}
          inputFormat="MM/dd/yyyy"
          label={props.label}
          value={props.value}
          onChange={value => props.onChange(value)}
          onError={reason => props.onError ?
            props.onError(reason ?? '') :
            undefined}
          disabled={props.isDisabled}
          mask="__/__/____"
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              error={!!props.errorMessage}
              helperText={props.errorMessage} />
          )} />
      </LocalizationProvider>
    </FieldContainer.Comp>
  );
};



export interface IDateFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label: string;
  isDisabled?: boolean;
}

export function genDateFieldControllerComponent<TFormState extends FieldValues>() {
  const DateFieldController: React.FC<IDateFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        name={props.fieldKey}
        control={props.formControl}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <DateField
            label={props.label}
            value={field.value}
            onChange={value => field.onChange(value)}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled} />
        )} />
    );
  };

  return DateFieldController;
}
