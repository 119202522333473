import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useEmployeeProfileActivityVisitsTabQuery
} from 'generated/graphql';
import {
  dateUtil,
  enumCache
} from 'modules';



export interface IPatient {
  fullName: string;
  route: string;
}

export interface IVisit {
  id: string;
  name: string;
  route: string;
  status: enumCache.IVisitStatusName;
  patient: IPatient;
  date: moment.Moment | null;
  timeZone: string;
  shift: enumCache.IShiftName;
  clockInTimestamp: moment.Moment | null;
  clockOutTimestamp: moment.Moment | null;
  clockedDurationHours: number;
}

export interface IData {
  visits: IVisit[];
}

export function useQuery(employeeId: string) {
  const tuple = useEmployeeProfileActivityVisitsTabQuery({
    variables: {
      employeeId
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const data: IData = {
        visits: _.map(tuple.data?.employee?.assignedVisits ?? [], (gqlVisit): IVisit => {
          const gqlPatient = gqlVisit.episode.patient;
          const gqlAssignedEmployee = gqlVisit.assignedEmployees[0] ?? {
            id: '',
            clockInTimestamp: null,
            clockOutTimestamp: null
          };
          const clockInTimestamp = gqlAssignedEmployee.clockInTimestamp ?
            moment(gqlAssignedEmployee.clockInTimestamp, moment.ISO_8601) :
            null;
          const clockOutTimestamp = gqlAssignedEmployee.clockOutTimestamp ?
            moment(gqlAssignedEmployee.clockOutTimestamp, moment.ISO_8601) :
            null;

          return ({
            id: gqlVisit.id,
            name: gqlVisit.template.displayName,
            route: `/admin/visits/view/${gqlVisit.id}`,
            status: gqlVisit.visitStatus.name,

            patient: {
              fullName: `${gqlPatient.lastName}, ${gqlPatient.firstName}`,
              route: `/admin/patients/profile/${gqlPatient.id}`
            },

            date: dateUtil.dateWithTimeZone2Moment(gqlVisit.date),
            timeZone: dateUtil.dateWithTimeZone2TimeZone(gqlVisit.date),
            shift: gqlVisit.shift.name,
            clockInTimestamp,
            clockOutTimestamp,

            clockedDurationHours: (clockInTimestamp && clockOutTimestamp) ?
              clockOutTimestamp.diff(clockInTimestamp, 'hours', true) :
              0
          });
        })
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
