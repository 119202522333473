import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  chips,
  ConfirmationModal,
  dateUtil,
  errorUtil,
  HeaderLabelWithValue,
  LoadingOverlay,
  paletteColors,
  ProtectedFileDownloadButton,
  RowBox,
  SectionHeaderBox,
  UserLink,
  ValueWithLabel,
  QuizContentSection
} from 'modules';
import {
  Button,
  Box,
  Grid,
  Typography
} from '@mui/material';

import { useParams } from 'react-router';
import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';



interface IParams {
  requirementId: string;
}

export const ViewRequirementActivity: React.FC = () => {
  const {t} = useTranslation();
  const params = useParams<Partial<IParams>>();

  const queryTuple = dataService.useViewRequirementActivityQuery(params.requirementId ?? '');
  const [fnReject, rejectMutationTuple] = dataService.useReject(params.requirementId ?? '');
  const [fnApprove, approveMutationTuple] = dataService.useApprove(params.requirementId ?? '');

  const [isRejectModalOpen, setIsRejectModalOpen] = React.useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = React.useState(false);

  const isLoading =
    queryTuple.loading ||
    rejectMutationTuple.loading ||
    approveMutationTuple.loading;



  const hasSubmittedFiles = (
    queryTuple.data.typeName === 'document' ||
    queryTuple.data.typeName === 'inService' ||
    queryTuple.data.typeName === 'requirement' ||
    queryTuple.data.typeName === 'training'
  );

  const isAutomaticallyEvaluated = queryTuple.data.typeName === 'quiz';



  async function reject() {
    await errorUtil.errorWrap(async () => {
      await fnReject();
      setIsRejectModalOpen(false);
      queryTuple.refetch();
    });
  }



  async function approve() {
    await errorUtil.errorWrap(async () => {
      await fnApprove();
      setIsApproveModalOpen(false);
      queryTuple.refetch();
    });
  }


  return (
    <>
      <LoadingOverlay
        active={isLoading} />

      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.requirements,
          title: queryTuple.data.displayName,
          hasBackButton: true,

          elRight: (
            <RowBox>
              {!queryTuple.loading && (
                <Box mr={3}>
                  <HeaderLabelWithValue
                    label={t('requirements:requirementType')}>
                    <chips.RequirementTypeChip
                      valueId={queryTuple.data.typeName} />
                  </HeaderLabelWithValue>
                </Box>
              )}

              <HeaderLabelWithValue
                label={t('status')}>
                <chips.RequirementStatusChip
                  valueId={queryTuple.data.statusName} />
              </HeaderLabelWithValue>
            </RowBox>
          ),

          elRightButtons: _.compact(
            (!isAutomaticallyEvaluated && queryTuple.data.statusName === 'submitted') ? [
              (
                <Button
                  key="reject"
                  variant="contained"
                  color="error"
                  onClick={() => setIsRejectModalOpen(true)}>
                  {t('requirements:reject')}
                </Button>
              ),
              (
                <Button
                  key="accept"
                  variant="contained"
                  onClick={() => setIsApproveModalOpen(true)}>
                  {t('requirements:approve')}
                </Button>
              )
            ] : []
          )
        }}>

        <Box mb={3}>
          <Grid container>

            <Grid
              item
              md={6}>

              <Grid container>

                <ValueWithLabel
                  label={t('requirements:assignedEmployee')}>
                  <UserLink userId={queryTuple.data.assignedUser.id} />
                </ValueWithLabel>

                <ValueWithLabel
                  label={t('requirements:preset')}
                  value={queryTuple.data.description}
                  toRoute={`/admin/requirementPresets/view/${queryTuple.data.presetId}`} />

                <ValueWithLabel
                  label={t('requirements:attachedFile')}
                  value={!queryTuple.data.file ? '' : undefined}>
                  {queryTuple.data.file && (
                    <ProtectedFileDownloadButton
                      fileKey={queryTuple.data.file.key}
                      fileName={queryTuple.data.file.name} />
                  )}
                </ValueWithLabel>

                <ValueWithLabel
                  label={t('requirements:url')}
                  value={queryTuple.data.url}
                  toExternalUrl={queryTuple.data.url} />

              </Grid>

            </Grid>

            <Grid
              item
              md={6}>

              <Grid container>

                <ValueWithLabel
                  label={t('requirements:dueDate')}
                  value={queryTuple.data.dueTimestamp?.format(dateUtil.displayTimestampFormat) ?? ""} />

                <ValueWithLabel
                  label={t('requirements:lastSubmittedDate')}
                  value={queryTuple.data.lastSubmittedTimestamp?.format(dateUtil.displayTimestampFormat) ?? ""} />

                <ValueWithLabel
                  label={t('requirements:lastRejectedDate')}
                  value={queryTuple.data.lastRejectedTimestamp?.format(dateUtil.displayTimestampFormat) ?? ""} />

                <ValueWithLabel
                  label={t('requirements:approvedDate')}
                  value={queryTuple.data.approvedTimestamp?.format(dateUtil.displayTimestampFormat) ?? ""} />
              </Grid>

            </Grid>

          </Grid>
        </Box>

        {queryTuple.data.statusName !== 'pending' && (
          <Box mb={3}>
            {queryTuple.data.typeName === 'quiz' && queryTuple.data.quiz && (
              <QuizContentSection.Comp
                quiz={queryTuple.data.quiz}
                showSelections
                showScore />
            )}

            {hasSubmittedFiles && (
              <>
                <SectionHeaderBox>
                  <Typography variant="h2">
                    {t('requirements:employeeUploadedFiles')}
                  </Typography>
                </SectionHeaderBox>

                {queryTuple.data.employeeFiles.length === 0 ? (
                  <Typography variant="body1">
                    {t('requirements:noFiles')}
                  </Typography>

                ) : (
                  <>
                    {_.map(queryTuple.data.employeeFiles, file => (
                      <Box
                        key={file.key}
                        mb={1}>
                        <ProtectedFileDownloadButton
                          fileKey={file.key}
                          fileName={file.name} />
                      </Box>
                    ))}
                  </>
                )}
              </>
            )}
          </Box>
        )}

      </BaseActivityTemplate>

      <ConfirmationModal
        isOpen={!isLoading && isRejectModalOpen}
        title={t('areYouSure')}
        message={t('requirements:confirmReject')}
        onAccept={reject}
        onCancel={() => setIsRejectModalOpen(false)} />

      <ConfirmationModal
        isOpen={!isLoading && isApproveModalOpen}
        title={t('areYouSure')}
        message={t('requirements:confirmApprove')}
        onAccept={approve}
        onCancel={() => setIsApproveModalOpen(false)} />
    </>
  );
};
