import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';

import { GeneralInformationSection } from './GeneralInformationSection/GeneralInformationSection';
import { AuthorizedWorkingHoursSection } from './AuthorizedWorkingHoursSection/AuthorizedWorkingHoursSection';
import { StatusCheckSection } from './StatusCheckSection/StatusCheckSection';
import { SkillsSection } from './SkillsSection/SkillsSection';
import { useTranslation } from 'react-i18next';



export interface IEmployeeProfileTab {
  employeeId: string;
}

export const EmployeeProfileTab: React.FC<IEmployeeProfileTab> = props => {
  const {t} = useTranslation();



  return (
    <Box>
      <Box mb={4}>
        <Typography variant="h2">
          {t('adminEmployees:profile')}
        </Typography>
      </Box>

      <Grid
        container
        spacing={10}>

        <Grid
          item
          md={4}>

          <GeneralInformationSection
            employeeId={props.employeeId} />

        </Grid>

        <Grid
          item
          md={4}>

          <AuthorizedWorkingHoursSection
            employeeId={props.employeeId} />

          <StatusCheckSection
            employeeId={props.employeeId} />

        </Grid>

        <Grid
          item
          md={4}>

          <SkillsSection
            employeeId={props.employeeId} />

        </Grid>

      </Grid>
    </Box>
  );
};
