import React from 'react';
import _ from 'lodash';

import {
  Box,
  Typography
} from '@mui/material';
import {
  Control,
  UseFormWatch
} from 'react-hook-form';

import { WorkingHoursEntry } from './WorkingHoursEntry/WorkingHoursEntry';
import * as types from '../types';
import { useTranslation } from 'react-i18next';



export type FnValidate = (formData: types.IFormState) => string | true;

export interface IAuthorizedWorkingHoursSection {
  formControl: Control<types.IFormState>;
  formWatch: UseFormWatch<types.IFormState>;
  isDisabled: boolean;
}

export const AuthorizedWorkingHoursSection: React.FC<IAuthorizedWorkingHoursSection> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('adminEmployees:authorizedWorkingHours')}
      </Typography>

      <Box mt={4}>

        {_.map(types.daysOfWeek, dayOfWeek => (
          <WorkingHoursEntry
            key={dayOfWeek}
            dayOfWeek={dayOfWeek}
            formControl={props.formControl}
            formWatch={props.formWatch}
            isDisabled={props.isDisabled} />
        ))}

      </Box>

    </Box>
  );
};
