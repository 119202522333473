import React from 'react';

import {
  usePatientProfileActivityPatientDetailsTabEmergencyContactSectionQuery
} from 'generated/graphql';



export interface IData {
  name: string;
  relationship: string;
  phone: string;
  address: string;
}

export function useQuery(id: string) {
  const tuple = usePatientProfileActivityPatientDetailsTabEmergencyContactSectionQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlPatient = tuple.data?.patient;
      const data: IData = {
        name: gqlPatient?.emergencyName ?? "",
        relationship: gqlPatient?.emergencyRelationship ?? "",
        phone: gqlPatient?.emergencyPhone ?? "",
        address: gqlPatient?.emergencyAddress ?? ""
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
