import React from 'react';

import {
  Autocomplete,
  TextField
} from '@mui/material';

import * as dataService from './dataService';

import { FieldContainer } from 'modules/layer1';

export type { ISiteUserTypeName } from './dataService';



export interface IUserSelectFieldProps {
  typeName?: dataService.ISiteUserTypeName;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isDisabled?: boolean;
  removeUserIds?: string[];
}

export const UserSelectField: React.FC<IUserSelectFieldProps> = props => {
  const queryTuple = dataService.useUserSelectFieldQuery(props.typeName, props.removeUserIds);

  const value = React.useMemo(
    () => queryTuple.data.usersById[props.value] ?? {
      id: '',
      fullName: ""
    },
    [
      queryTuple.data.usersById,
      props.value
    ]
  );



  return (
    <FieldContainer.Comp>
      <Autocomplete
        options={queryTuple.data.users}
        value={value}
        onChange={(_event, value) => props.onChange(value?.id ?? '')}
        getOptionLabel={user => user.fullName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={queryTuple.loading || props.isDisabled}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            error={!!props.errorMessage}
            helperText={props.errorMessage} />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.id}>
            {option.fullName}
          </li>
        )} />
    </FieldContainer.Comp>
  );
};
