import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';



const DescriptionTypography = styled(Typography)(() => ({
  fontSize: '0.75rem',
  fontFamily: 'Roboto, sans-serif',
  lineHeight: '1.66',
  fontWeight: 400
}));



export const Comp: React.FC = props => {
  return (
    <DescriptionTypography>
      {props.children}
    </DescriptionTypography>
  );
};
