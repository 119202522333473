import React from 'react';
import _ from 'lodash';

import {
  useDisciplineSelectFieldQuery as useBaseQuery
} from 'generated/graphql';

import { enumCache } from 'modules/layer2';



export type IDisciplineName = enumCache.IDisciplineName;
export type IDiscipline = enumCache.IDiscipline;

export interface IData {
  enumValues: IDiscipline[];
}

export function useDisciplineSelectFieldQuery() {
  const tuple = useBaseQuery();

  return React.useMemo(
    () => {
      const data: IData = {
        enumValues: _.map(tuple.data?.disciplines ?? [], (gqlDiscipline): IDiscipline => ({
          id: gqlDiscipline.id,
          name: gqlDiscipline.name,
          displayName: gqlDiscipline.displayName
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
