import React from 'react';
import _ from 'lodash';

import {
  useGenderSelectFieldQuery as useBaseQuery
} from 'generated/graphql';

import { IGenericEnum } from 'modules/layer2';



export const genderNames = [
  'male',
  'female',
  'nonBinary'
] as const;
export type IGenderName = typeof genderNames[number];

export type IGender = IGenericEnum<IGenderName>;

export interface IData {
  enumValues: IGender[];
}

export function useGenderSelectFieldQuery() {
  const tuple = useBaseQuery();

  return React.useMemo(
    () => {
      const data: IData = {
        enumValues: _.map(tuple.data?.genders ?? [], (gqlGender): IGender => ({
          id: gqlGender.id,
          name: gqlGender.name,
          displayName: gqlGender.displayName
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
