import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  DayOfWeekName,
  DisciplineName,
  EmployeeSkillInput,
  EmployeeWorkingHoursEntryInput,
  HireStatusName,
  useEditEmployeeActivityLazyQuery as useBaseLazyQuery,
  useSaveEmployeeMutation as useBaseSaveEmployeeMutation,
  useArchiveEmployeeMutation as useBaseArchiveEmployeeMutation
} from 'generated/graphql';
import {
  dateUtil,
  timeFormat
} from 'modules';

import * as types from './types';



export interface IData {
  formState: types.IFormState;
  fullName: string;
  email: string;
}

export function useEditAdministratorActivityLazyQuery() {
  const [fnQuery, tuple] = useBaseLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(
    () => {
      if (tuple.error?.message) {
        alert(tuple.error.message);
      }
    },
    [tuple.error?.message]
  );

  const fnWrappedQuery = React.useCallback(
    (employeeId: string) => fnQuery({
      variables: {
        employeeId
      }
    }),
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const gqlEmployee = tuple.data?.employee;
      const data: IData = {
        formState: {
          id: gqlEmployee?.id ?? '',
          firstName: gqlEmployee?.firstName ?? "",
          lastName: gqlEmployee?.lastName ?? "",
          birthdate: dateUtil.dateString2DateOrNull(gqlEmployee?.birthdate ?? ''),
          employeeId: gqlEmployee?.employeeId ?? "",
          discipline: gqlEmployee?.discipline?.name ?? '',
          hireStatus: gqlEmployee?.hireStatus.name ?? '',
          address: gqlEmployee?.address ?? "",
          primaryPhone: gqlEmployee?.primaryPhone ?? "",
          secondaryPhone: gqlEmployee?.secondaryPhone ?? "",
          supervisorUserId: gqlEmployee?.supervisor?.id ?? '',
          altSupervisorUserId: gqlEmployee?.altSupervisor?.id ?? '',
          payRate: gqlEmployee?.payRate ?? "",
          licenseCheck: gqlEmployee?.licenseCheck ?? "",
          fraudCheck: gqlEmployee?.fraudCheck ?? "",

          dayOfWeek2WorkingHoursEntry: _.reduce(gqlEmployee?.workingHoursEntries ?? [], (mapping, gqlEntry) => {
            mapping[gqlEntry.dayOfWeek.name] = {
              isEnabled: true,
              startTime: dateUtil.timeString2DateObject(gqlEntry.startTime),
              endTime: dateUtil.timeString2DateObject(
                gqlEntry.endTime === '24:00:00' ?
                  ('00:00:00') :
                  gqlEntry.endTime),
              tillEndOfDay: gqlEntry.endTime === '24:00:00'
            };
            return mapping;
          }, types.genEmptyDayOfWeek2WorkingHoursEntry()),

          skills: _.map(gqlEmployee?.skills ?? [], (gqlSkill): types.ISkill => ({
            _key: gqlSkill.id,
            id: gqlSkill.id,
            name: gqlSkill.name,
            description: gqlSkill.description
          }))
        },
        fullName: `${gqlEmployee?.firstName ?? ""} ${gqlEmployee?.lastName ?? ""}`.trim(),
        email: gqlEmployee?.siteUserFull?.email ?? ""
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



export function useSaveEmployeeMutation() {
  const [fnMutate, tuple] = useBaseSaveEmployeeMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id: formState.id || undefined,
            firstName: formState.firstName.trim(),
            lastName: formState.lastName.trim(),
            birthdate: formState.birthdate || null,
            employeeId: formState.employeeId.trim(),
            disciplineName: (formState.discipline || null) as DisciplineName | null,
            hireStatusName: (formState.hireStatus || null) as HireStatusName,
            address: formState.address.trim(),
            primaryPhone: formState.primaryPhone.trim(),
            secondaryPhone: formState.secondaryPhone.trim(),
            supervisorUserId: formState.supervisorUserId || null,
            altSupervisorUserId: formState.altSupervisorUserId || null,
            payRate: formState.payRate.trim() || null,
            licenseCheck: formState.licenseCheck.trim(),
            fraudCheck: formState.fraudCheck.trim(),

            workingHoursEntries: _.chain(formState.dayOfWeek2WorkingHoursEntry)
              .pickBy(entry => entry.isEnabled)
              .map((entry, dayOfWeek): EmployeeWorkingHoursEntryInput => ({
                dayOfWeekName: dayOfWeek as DayOfWeekName,
                startTime: moment(entry?.startTime ?? '', timeFormat).format('HH:mm:ss'),
                endTime: !entry?.tillEndOfDay ?
                  moment(entry?.endTime ?? '', timeFormat).format('HH:mm:ss') :
                  '24:00:00'
              }))
              .value(),

            skills: _.map(formState.skills, (skill): EmployeeSkillInput => ({
              id: !skill.id.startsWith('*') ? skill.id : undefined,
              name: skill.name,
              description: skill.description
            }))
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useArchiveEmployeeMutation() {
  const [fnMutate, tuple] = useBaseArchiveEmployeeMutation();

  const fnWrappedMutate = React.useCallback(
    (id: string) => fnMutate({
      variables: {
        id
      }
    }),
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
