import React from 'react';

import {
  Box,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';



const HeaderLabelWithValueBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const LabelTypography = styled(Typography)(({theme}) => ({
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 400,
  fontSize: '12px',
  marginBottom: theme.spacing(1)
}));



export interface IHeaderLabelWithValueProps {
  label: string;
}

export const HeaderLabelWithValue: React.FC<IHeaderLabelWithValueProps> = props => {
  return (
    <HeaderLabelWithValueBox>
      <LabelTypography>
        {props.label}
      </LabelTypography>

      {props.children}
    </HeaderLabelWithValueBox>
  );
};
