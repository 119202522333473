import React from 'react';

import { ProtectedImage } from 'modules/layer2';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';



const ContainerBox = styled(Box)(() => ({
  width: '352px',
  height: '152px',
  border: '1px solid #000'
}));

const ImageImg = styled('img')(() => ({
  display: 'block',
  width: '100%',
  height: '100%'
}));



export interface IFileKey {
  type: 'fileKey';
  value: string;
}

export interface IDataUrl {
  type: 'dataUrl';
  value: string;
}

export type ISource =
  IFileKey |
  IDataUrl

export interface ISignatureProps {
  source: ISource;
}

export const Signature: React.FC<ISignatureProps> = props => {
  return (
    <ContainerBox>
      {props.source.type === 'fileKey' && (
        <ProtectedImage
          fillContainer
          useBackgroundColor
          fileKey={props.source.value} />
      )}

      {props.source.type === 'dataUrl' && (
        <ImageImg
          src={props.source.value} />
      )}
    </ContainerBox>
  );
};
