import React from 'react';

import {
  useEditPharmacyActivityLazyQuery,
  useSavePharmacyMutation,
  useArchivePharmacyMutation
} from 'generated/graphql';

import * as types from './types';



export interface IData {
  name: string;
  formState: types.IFormState;
}

export function useLazyQuery() {
  const [fnQuery, tuple] = useEditPharmacyActivityLazyQuery({fetchPolicy: 'no-cache'});



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  const fnWrappedQuery = React.useCallback(
    (id: string) => fnQuery({
      variables: {
        id
      }
    }),
    [fnQuery]
  );



  const wrappedTuple = React.useMemo(
    () => {
      const gqlPharmacy = tuple.data?.pharmacy;
      const data: IData = {
        name: gqlPharmacy?.name ?? "",
        formState: {
          id: gqlPharmacy?.id ?? '',
          name: gqlPharmacy?.name ?? "",
          primaryPhone: gqlPharmacy?.primaryPhone ?? "",
          secondaryPhone: gqlPharmacy?.secondaryPhone ?? "",
          faxNumber: gqlPharmacy?.faxNumber ?? "",
          address: gqlPharmacy?.address ?? ""
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );



  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



export function useSaveMutation() {
  const [fnMutate, tuple] = useSavePharmacyMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id: formState.id || undefined,
            name: formState.name.trim(),
            primaryPhone: formState.primaryPhone.trim(),
            secondaryPhone: formState.secondaryPhone.trim(),
            faxNumber: formState.faxNumber.trim(),
            address: formState.address.trim()
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useArchiveMutation() {
  const [fnMutate, tuple] = useArchivePharmacyMutation();

  const fnWrappedMutate = React.useCallback(
    async (id: string) => {
      await fnMutate({
        variables: {
          id
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
