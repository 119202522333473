import React from 'react';
import _ from 'lodash';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  enums,
  SkeletonRows,
  ValueWithLabel
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



export interface IAuthorizedWorkingHoursSectionProps {
  employeeId: string;
}

export const AuthorizedWorkingHoursSection: React.FC<IAuthorizedWorkingHoursSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useAuthorizedWorkingHoursSectionQuery(props.employeeId);



  return (
    <Box>
      <Box mb={3}>
        <Typography variant="h3">
          {t('adminEmployees:authorizedWorkingHours')}
        </Typography>
      </Box>

      {queryTuple.loading ? (
        <SkeletonRows numRows={7} />

      ) : (
        <Grid container>
          {_.map(queryTuple.data.workingHoursEntries, entry => (
            <ValueWithLabel
              key={entry.dayOfWeek}
              label={enums.dayOfWeek2DisplayName[entry.dayOfWeek]}
              value={entry.timeRange ?
                `${entry.timeRange.startTime} - ${entry.timeRange.endTime}` :
                t('noValue')} />
          ))}
        </Grid>
      )}

    </Box>
  );
};
