import React from 'react';
import _ from 'lodash';

import {
  BaseModal,
  CellBox,
  Form,
  LoadingOverlay,
  RowBox,
  SectionHeaderBox,
  selectFields,
  fields
} from 'modules';
import {
  useFieldArray,
  useForm
} from 'react-hook-form';
import {
  Typography,
  IconButton,
  Grid,
  Box
} from '@mui/material';
import {
  AddCircle,
  Delete
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';
import { IDayOfWeek } from 'modules/layer0/enums';



const __daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
] as const;

interface IDayOfWeekOption {
  _key: string;
  id: string;
  label: string;
  isChecked: boolean;
}

interface IEmployee {
  _key: string;
  id: string;
}

interface IFormData {
  daysOfWeek: IDayOfWeekOption[];
  assignedEmployees: IEmployee[];
}

const EmployeeSelectFieldController = selectFields.genEmployeeSelectFieldControllerComponent<IFormData>();
const CheckboxController = fields.genCheckboxControllerComponent<IFormData>();



export interface IGenerateVisitsData {
  daysOfWeek: IDayOfWeek[];
  assignedEmployeeIds: string[];
}

export interface IGenerateVisitsModalProps {
  isLoading: boolean;
  onAccept: (data: IGenerateVisitsData) => void;
  onCancel: () => void;
}

export const GenerateVisitsModal: React.FC<IGenerateVisitsModalProps> = props => {
  const {t} = useTranslation();

  const form = useForm<IFormData>({
    defaultValues: {
      daysOfWeek: _.map(__daysOfWeek, (day): IDayOfWeekOption => ({
        _key: day,
        id: day,
        label: t(day),
        isChecked: false
      })),
      assignedEmployees: []
    }
  });

  const dayOfWeekFields = useFieldArray({
    control: form.control,
    name: 'daysOfWeek',
    keyName: '_key'
  });

  const employeeFields = useFieldArray({
    control: form.control,
    name: 'assignedEmployees',
    keyName: '_key'
  });



  function handleClickAddEmployee() {
    employeeFields.append({
      _key: uuid.v4(),
      id: ''
    });
  }



  function handleClickDeleteEmployee(index: number) {
    employeeFields.remove(index);
  }



  function handleSubmit(formData: IFormData) {
    const selectedDaysOfWeek = _.filter(formData.daysOfWeek, day => day.isChecked);
    if (selectedDaysOfWeek.length === 0) {
      alert(t('visits:errors.mustHaveAtLeastOneDayOfWeek'));
      return;
    }

    if (formData.assignedEmployees.length === 0) {
      alert(t('visits:errors.mustHaveAtLeastOneAssignedEmployee'));
      return;
    }

    props.onAccept({
      daysOfWeek: _.map(selectedDaysOfWeek, day => day.id as IDayOfWeek),
      assignedEmployeeIds: _.map(formData.assignedEmployees, employee => employee.id)
    });
  }



  return (
    <BaseModal
      title={t('visits:generateVisitsFromTemplate')}
      isOpen
      isDoubleWide
      showButtons
      onClose={props.onCancel}
      isLoading={props.isLoading}>

      {buttons => (
        <Form
          onSubmit={form.handleSubmit(handleSubmit)}>

          <LoadingOverlay
            active={props.isLoading} />

          <Box mb={3}>
            {t('visits:generateVisitsFromTemplateModalDescription')}
          </Box>

          <Grid container>

            <Grid
              item
              md={4}>

              <SectionHeaderBox>
                <Typography variant="h2">
                  {t('visits:daysOfWeek')}
                </Typography>
              </SectionHeaderBox>

              {dayOfWeekFields.fields.map((field, index) => (
                <Box key={field._key}>
                  <CheckboxController
                    key={field._key}
                    formControl={form.control}
                    fieldKey={`daysOfWeek.${index}.isChecked`}
                    label={field.label} />
                </Box>
              ))}

            </Grid>

            <Grid
              item
              md={8}>

              <SectionHeaderBox>
                <Typography variant="h2">
                  {t('visits:assignedEmployees')}
                </Typography>

                <IconButton
                  size="small"
                  onClick={handleClickAddEmployee}>
                  <AddCircle />
                </IconButton>
              </SectionHeaderBox>

              {employeeFields.fields.map((field, index) => (
                <RowBox key={field._key}>
                  <CellBox>
                    <EmployeeSelectFieldController
                      key={field._key}
                      formControl={form.control}
                      fieldKey={`assignedEmployees.${index}.id`}
                      isRequired
                      isDisabled={props.isLoading} />
                  </CellBox>

                  <IconButton
                    size="small"
                    onClick={() => handleClickDeleteEmployee(index)}>
                    <Delete />
                  </IconButton>
                </RowBox>
              ))}

            </Grid>

          </Grid>

          {buttons}

        </Form>
      )}

    </BaseModal>
  );
};
