import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  paletteColors,
  genTableComponent,
  IRow as _IRow,
  dateUtil,
  ValueWithLabel,
  SectionHeaderBox,
  chips,
  errorUtil,
  ConfirmationModal
} from 'modules';
import {
  Grid,
  Box,
  Typography,
  Button
} from '@mui/material';

import { styled } from '@mui/material/styles';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { ApproveVisitModal } from './ApproveVisitModal/ApproveVisitModal';



const RowBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));



const __columnIds = [
  'name',
  'visitDate',
  'clockInTimestamp',
  'clockOutTimestamp',
  'durationHours',
  'approvalStatus',
  'numOverrideBilledHours',
  'approvalComment',
  'buttons'
] as const;
type IColumnId = typeof __columnIds[number];
type IRow = _IRow<IColumnId>;

const Table = genTableComponent<IColumnId>();



export interface IParams {
  timesheetId: string;
}

export const ViewTimesheetActivity: React.FC = () => {
  const {t} = useTranslation();
  const params = useParams<Partial<IParams>>();

  const queryTuple = dataService.useQuery(params.timesheetId ?? '');
  const [fnDeny, denyMutationTuple] = dataService.useDenyMutation();

  const isLoading =
    queryTuple.loading ||
    denyMutationTuple.loading;

  const [confirmApproveVisitId, setConfirmApproveVisitId] = React.useState('');
  const [confirmDenyVisitId, setConfirmDenyVisitId] = React.useState('');



  async function handleConfirmDenyVisit() {
    await errorUtil.errorWrap(async () => {
      setConfirmDenyVisitId('');
      await fnDeny(confirmDenyVisitId);
      await queryTuple.refetch();
    });
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'humanResources'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.timesheets,
          title: t('visits:timesheetTitle', {
            id: queryTuple.data.shortId
          })
        }}>

        <Box mb={3}>
          <Grid container>
            <ValueWithLabel
              label={t('employee')}
              value={queryTuple.data.employee.fullName}
              toRoute={queryTuple.data.employee.route} />

            <ValueWithLabel
              label={t('visits:payPeriod')}
              value={(queryTuple.data.startTimestamp && queryTuple.data.endTimestamp) ?
                t('visits:payPeriodRange', {
                  start: queryTuple.data.startTimestamp.format(dateUtil.displayTimestampFormat),
                  end: queryTuple.data.endTimestamp.format(dateUtil.displayTimestampFormat)
                }) :
                ""} />

            <ValueWithLabel
              label={t('visits:numTotalHours')}
              value={queryTuple.data.numTotalHours?.toFixed(2) ?? ""} />

            <ValueWithLabel
              label={t('visits:numApprovedHours')}
              value={queryTuple.data.numApprovedHours?.toFixed(2) ?? ""} />
          </Grid>
        </Box>

        <SectionHeaderBox>
          <Typography variant="h2">
            {t('visits:visits')}
          </Typography>
        </SectionHeaderBox>

        <Table
          columns={[
            {
              id: 'name',
              label: t('name')
            },
            {
              id: 'visitDate',
              label: t('visits:visitDate')
            },
            {
              id: 'clockInTimestamp',
              label: t('visits:clockInTime')
            },
            {
              id: 'clockOutTimestamp',
              label: t('visits:clockOutTime')
            },
            {
              id: 'durationHours',
              label: t('visits:durationHours')
            },
            {
              id: 'approvalStatus',
              label: t('visits:approvalStatus')
            },
            {
              id: 'numOverrideBilledHours',
              label: t('visits:numOverrideBilledHours')
            },
            {
              id: 'approvalComment',
              label: t('visits:approvalComment'),
              width: '10%'
            },
            {
              id: 'buttons',
              label: t('visits:actions')
            }
          ]}
          rows={_.map(queryTuple.data.visits, (visit): IRow => ({
            id: visit.id,
            columnId2Value: {
              name: {
                type: 'string',
                label: visit.displayName,
                toRoute: visit.route
              },
              visitDate: {
                type: 'string',
                label: visit.date?.format(dateUtil.displayDateFormat) ?? t('noValue'),
                sortableValue: visit.date?.unix() ?? 0
              },
              clockInTimestamp: {
                type: 'string',
                label: visit.clockInTimestamp?.format(dateUtil.displayTimestampFormat) ?? t('noValue'),
                sortableValue: visit.clockInTimestamp?.unix() ?? 0
              },
              clockOutTimestamp: {
                type: 'string',
                label: visit.clockOutTimestamp?.format(dateUtil.displayTimestampFormat) ?? t('noValue'),
                sortableValue: visit.clockOutTimestamp?.unix() ?? 0
              },
              durationHours: {
                type: 'string',
                label: visit.durationHours?.toFixed(2) ?? t('noValue'),
                sortableValue: visit.durationHours?.toNumber() ?? 0
              },
              approvalStatus: {
                type: 'component',
                label: visit.approvalStatusName ? (
                  <chips.TimesheetVisitApprovalStatusChip
                    valueId={visit.approvalStatusName} />

                ) : (
                  <div>
                    {t('noValue')}
                  </div>
                ),
                sortableValue: visit.approvalStatusName.toLowerCase()
              },
              numOverrideBilledHours: {
                type: 'string',
                label: visit.numOverrideBilledHours?.toFixed(2) ?? t('noValue'),
                sortableValue: visit.numOverrideBilledHours?.toNumber() ?? 0
              },
              approvalComment: {
                type: 'string',
                label: visit.approvalComment ?? t('noValue'),
                sortableValue: visit.approvalComment.toLowerCase()
              },
              buttons: {
                type: 'component',
                label: (
                  <RowBox>
                    <Button
                      size="small"
                      onClick={() => setConfirmApproveVisitId(visit.id)}>
                      {t('visits:approve')}
                    </Button>

                    <Button
                      size="small"
                      onClick={() => setConfirmDenyVisitId(visit.id)}
                      disabled={visit.approvalStatusName === 'denied'}>
                      {t('visits:deny')}
                    </Button>
                  </RowBox>
                ),
                sortableValue: ''
              }
            }
          }))}
          initSortOptions={{
            sortByColumnId: 'visitDate',
            sortDirection: 'descending'
          }} />

      </BaseActivityTemplate>

      {confirmApproveVisitId && (
        <ApproveVisitModal
          timesheetId={params.timesheetId ?? ''}
          timesheetVisitId={confirmApproveVisitId}
          onAccept={() => {
            setConfirmApproveVisitId('');
            queryTuple.refetch();
          }}
          onClose={() => setConfirmApproveVisitId('')} />
      )}

      {confirmDenyVisitId && (
        <ConfirmationModal
          isOpen
          title={t('visits:confirmDenyTimesheetVisitModal.title')}
          message={t('visits:confirmDenyTimesheetVisitModal.message')}
          onAccept={handleConfirmDenyVisit}
          onCancel={() => setConfirmDenyVisitId('')} />
      )}
    </>
  );
};
