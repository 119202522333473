import React from 'react';
import _ from 'lodash';

import {
  genCheckboxControllerComponent,
  genTextFieldControllerComponent
} from 'modules';
import {
  FieldPath,
  useFieldArray,
  UseFormReturn
} from 'react-hook-form';
import {
  Typography,
  Box,
  Grid,
  Divider
} from '@mui/material';

import * as types from '../types';
import { useTranslation } from 'react-i18next';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();
const CheckboxController = genCheckboxControllerComponent<types.IFormState>();



export type IFieldKey =
  'riskAssessmentValues' |
  'mentalStatusValues' |
  'neurologicalValues' |
  'psychosocialValues' |
  'dmeValues' |
  'suppliesValues' |
  'safetyMeasuresValues' |
  'nutritionRequirementsValues' |
  'functionalLimitationsValues' |
  'activitiesPermittedValues';

export interface ICheckboxGroupProps {
  form: UseFormReturn<types.IFormState>;
  fieldKey: IFieldKey;
  title: string;
  schemaValuesByValue: Record<string, types.ISchemaValue>;
  labelTranslationPrefix: string;
  isDisabled?: boolean;
}

export const CheckboxGroup: React.FC<ICheckboxGroupProps> = props => {
  const {t} = useTranslation();

  const fields = useFieldArray({
    control: props.form.control,
    name: props.fieldKey,
    keyName: '_key'
  });
  const fieldValues = props.form.watch(props.fieldKey);



  return (
    <>
      <Box mb={3}>
        <Typography variant="h2">
          {props.title}
        </Typography>
      </Box>

      <Box mb={3}>
        <Grid container>
          {fields.fields.map((field, index) => {
            const schema = props.schemaValuesByValue[field.name];
            const descriptionLabel = schema.descriptionLabel;

            return (
              <Grid
                key={field.name}
                item
                md={4}>

                <CheckboxController
                  formControl={props.form.control}
                  fieldKey={`${props.fieldKey}.${index}.isChecked` as FieldPath<types.IFormState>}
                  label={t(`${props.labelTranslationPrefix}.${field.name}`)}
                  isDisabled={props.isDisabled} />

                {fieldValues[index].isChecked && (
                  <>
                    {schema.hasDescription && (
                      <Box mt={1}>
                        <TextFieldController
                          formControl={props.form.control}
                          fieldKey={`${props.fieldKey}.${index}.description` as FieldPath<types.IFormState>}
                          label={descriptionLabel ?
                            t(`${props.labelTranslationPrefix}.${descriptionLabel}`) :
                            t('description')}
                          isDisabled={props.isDisabled} />
                      </Box>
                    )}

                    {schema.childValues.length > 0 && (
                      <Box ml={3}>
                        <ChildCheckboxGroup
                          form={props.form}
                          fieldIndex={index}
                          labelTranslationPrefix={props.labelTranslationPrefix}
                          schemaValuesByValue={_.keyBy(schema.childValues)}
                          isDisabled={props.isDisabled} />
                      </Box>
                    )}
                  </>
                )}

              </Grid>
            );
          })}
        </Grid>

        <Box mt={3}>
          <Divider />
        </Box>
      </Box>
    </>
  );
};



export interface IChildCheckboxGroupProps {
  form: UseFormReturn<types.IFormState>;
  fieldIndex: number;
  schemaValuesByValue: Record<string, string>;
  labelTranslationPrefix: string;
  isDisabled?: boolean;
}

const ChildCheckboxGroup: React.FC<IChildCheckboxGroupProps> = props => {
  const {t} = useTranslation();

  const fields = useFieldArray({
    control: props.form.control,
    name: `nutritionRequirementsValues.${props.fieldIndex}.childValues`,
    keyName: '_key'
  });



  return (
    <Grid container>
      {fields.fields.map((field, index) => (
        <Grid
          key={field.name}
          item
          md={4}>

          <CheckboxController
            formControl={props.form.control}
            fieldKey={`nutritionRequirementsValues.${props.fieldIndex}.childValues.${index}.isChecked` as FieldPath<types.IFormState>}
            label={t(`${props.labelTranslationPrefix}.${field.name}`)}
            isDisabled={props.isDisabled} />

        </Grid>
      ))}
    </Grid>
  );
};
