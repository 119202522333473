import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  paletteColors,
  genTableComponent,
  IRow,
  dateUtil
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



const __columnIdList = [
  'firstName',
  'lastName',
  'address',
  'locationNote',
  'nextVisitDate',
  'nextVisitShift'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();



export const MyPatientsActivity: React.FC = () => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery();

  const isLoading =
    queryTuple.loading;



  return (
    <BaseActivityTemplate
      mode="employee"
      navigationBarSelectedLinkId="myPatients"
      isLoading={isLoading}
      headerOptions={{
        backgroundColor: paletteColors.patients,
        title: t('myPatients')
      }}>

      <Table
        columns={[
          {
            id: 'firstName',
            label: t('firstName')
          },
          {
            id: 'lastName',
            label: t('lastName')
          },
          {
            id: 'address',
            label: t('address')
          },
          {
            id: 'locationNote',
            label: t('locationNote')
          },
          {
            id: 'nextVisitDate',
            label: t('nextVisitDate')
          },
          {
            id: 'nextVisitShift',
            label: t('nextVisitShift')
          }
        ]}
        rows={_.map(queryTuple.data.patients, (patient): IRow<IColumnId> => ({
          id: patient.id,
          columnId2Value: {
            firstName: {
              type: 'string',
              label: patient.firstName,
              toRoute: `/employee/myPatients/profile/${patient.id}`
            },
            lastName: {
              type: 'string',
              label: patient.lastName,
              toRoute: `/employee/myPatients/profile/${patient.id}`
            },
            address: {
              type: 'string',
              label: patient.address
            },
            locationNote: {
              type: 'string',
              label: patient.locationNote
            },
            nextVisitDate: {
              type: 'string',
              label: patient.nextVisit?.date ?
                patient.nextVisit.date.format(dateUtil.displayDateFormat) :
                t('noValue'),
              sortableValue: patient.nextVisit?.date?.unix() ?? 0,
              toRoute: patient.nextVisit ?
                `/employee/visit/${patient.nextVisit.id}` :
                undefined
            },
            nextVisitShift: {
              type: 'string',
              label: patient.nextVisit?.shiftName ?
                t(`shifts.${patient.nextVisit.shiftName}`) :
                "",
              sortableValue: patient.nextVisit?.shiftName ?? ''
            }
          }
        }))}
        initSortOptions={{
          sortByColumnId: 'nextVisitDate',
          sortDirection: 'ascending'
        }} />

    </BaseActivityTemplate>
  );
};
