import _ from 'lodash';
import moment from 'moment-timezone';



export const dateFormat = 'YYYY-MM-DD';
export const displayDateFormat = 'MM/DD/YYYY';
export const timestampFormat = 'MM/DD/YYYY HH:mm:ss ZZ';
export const displayTimestampFormat = 'MM/DD/YYYY HH:mm';
export const timeFormat = 'HH:mm:ss';
export const defaultTimeZone = 'America/New_York';



export function dateString2Date(dateString: string) {
  return moment(dateString, dateFormat).toDate();
}



export function dateString2DateOrNull(dateString: string) {
  return dateString ?
    dateString2Date(dateString) :
    null;
}



export function dateWithTimeZone2Date(dateWithTimeZone: string | null) {
  if (!dateWithTimeZone) {
    return null;
  }

  const [dateString] = dateWithTimeZone.split(',');
  return dateString2Date(dateString);
}



export function dateWithTimeZone2TimeZone(dateWithTimeZone: string | null) {
  if (!dateWithTimeZone) {
    return '';
  }

  const [dateString, timeZone] = dateWithTimeZone.split(',');
  return timeZone;
}



export function dateWithTimeZone2Moment(dateWithTimeZone: string | null) {
  if (!dateWithTimeZone) {
    return null;
  }

  const [dateString, timeZone] = dateWithTimeZone.split(',');
  return moment.tz(dateString, dateFormat, timeZone);
}



export function dateAndTimeZone2Moment(date: Date, timeZone: string) {
  return moment.tz(__date2DateString(date), dateFormat, timeZone);
}



function __date2DateString(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const [
    paddedMonth,
    paddedDay
  ] = _.map([month, day], num =>
    _.padStart(num.toString(), 2, "0"));

  return `${year}-${paddedMonth}-${paddedDay}`;
}



export function genDefaultDateWithTimeZone() {
  return `,${moment.tz.guess()}`;
}



export function formatDateWithTimeZone(dateWithTimeZone: string) {
  const [
    date,
    timeZone
  ] = dateWithTimeZone.split(',');
  return `${date} (${timeZone})`;
}



export function timeString2DateObject(time: string) {
  const mTime = moment(time, timeFormat);
  return new Date(2000, 1, 1, mTime.hours(), mTime.minutes(), mTime.seconds());
}
