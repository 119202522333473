import React from 'react';
import _ from 'lodash';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  SkeletonRows,
  ValueWithLabel
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



export interface ISkillsSectionProps {
  employeeId: string;
}

export const SkillsSection: React.FC<ISkillsSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useSkillsSectionQuery(props.employeeId);



  return (
    <Box>
      <Box mb={3}>
        <Typography variant="h3">
          {t('adminEmployees:skills')}
        </Typography>
      </Box>

      {queryTuple.loading ? (
        <SkeletonRows numRows={3} />

      ) : (
        <>
          {queryTuple.data.skills.length === 0 ? (
            <Box>
              <em>{t('adminEmployees:noSkills')}</em>
            </Box>

          ) : (
            <>
              {_.map(queryTuple.data.skills, skill => (
                <Box
                  key={skill.id}
                  mb={4}>

                  <Grid container>
                    <ValueWithLabel
                      label={t('name')}
                      value={skill.name} />

                    <ValueWithLabel
                      label={t('description')}
                      value={skill.description} />
                  </Grid>

                </Box>
              ))}
            </>
          )}
        </>
      )}
    </Box>
  );
};
