import React from 'react';
import _ from 'lodash';

import {
  BaseModal,
  Form,
  genUserSelectFieldControllerComponent,
  fields,
  LoadingOverlay,
  errorUtil,
  RowBox,
  CellBox
} from 'modules';
import {
  useFieldArray,
  useForm
} from 'react-hook-form';
import {
  Box,
  Button,
  IconButton
} from '@mui/material';

import { Clear } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import * as uuid from 'uuid';



const UserSelectFieldController = genUserSelectFieldControllerComponent<dataService.IFormState>();
const TextFieldController = fields.genTextFieldControllerComponent<dataService.IFormState>();



export interface INewMessageModalProps {
  onAccept: (threadId: string) => void;
  onClose: () => void;
}

export const NewMessageModal: React.FC<INewMessageModalProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery();
  const [fnPost, postMutationTuple] = dataService.usePostMutation();

  const form = useForm<dataService.IFormState>({
    defaultValues: {
      users: [
        {
          _key: uuid.v4(),
          id: ''
        }
      ],
      message: ""
    }
  });

  const userFields = useFieldArray({
    control: form.control,
    name: 'users',
    keyName: '_key'
  });

  const isLoading =
    queryTuple.loading ||
    postMutationTuple.loading;



  async function handleSubmit(formState: dataService.IFormState) {
    const trMessage = formState.message.trim();
    if (!trMessage) {
      alert(t('errors.emptyMessage'));
      return;
    }

    const uniqueUsers = _.filter(_.uniqBy(formState.users, user => user.id), user => !!user.id);

    await errorUtil.errorWrap(async () => {
      const threadId = await fnPost({
        ...formState,
        message: trMessage,
        users: uniqueUsers
      });
      props.onAccept(threadId);
    });
  }



  function handleClickAddUser() {
    userFields.append({
      _key: uuid.v4(),
      id: ''
    });
  }



  function handleClickRemoveUser(index: number) {
    if (userFields.fields.length > 1) {
      userFields.remove(index);
    }
  }



  return (
    <BaseModal
      isOpen
      showButtons
      title={t('newMessage')}
      onClose={props.onClose}>

      {buttons => (
        <>
          <LoadingOverlay active={isLoading} />

          <Form onSubmit={form.handleSubmit(handleSubmit)}>
            {queryTuple.data.selfUserId && _.map(userFields.fields, (field, index) => (
              <RowBox key={field._key}>

                <CellBox>
                  <UserSelectFieldController
                    label={t('recipient')}
                    formControl={form.control}
                    fieldKey={`users.${index}.id`}
                    isRequired
                    isDisabled={isLoading}
                    removeUserIds={[queryTuple.data.selfUserId]} />
                </CellBox>

                <IconButton
                  onClick={() => handleClickRemoveUser(index)}>
                  <Clear />
                </IconButton>

              </RowBox>
            ))}

            <RowBox>
              <CellBox />

              <Button
                variant="text"
                onClick={handleClickAddUser}>
                {t('addRecipient')}
              </Button>
            </RowBox>

            <Box mb={3}>
              <TextFieldController
                label={t('message')}
                formControl={form.control}
                fieldKey="message"
                isRequired
                isDisabled={isLoading} />
            </Box>

            {buttons}
          </Form>
        </>
      )}

    </BaseModal>
  );
};
