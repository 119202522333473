import React from 'react';
import _ from 'lodash';

import {
  useTakeQuizActivityQuery,
  useEmployeeSubmitAssignedRequirementMutation
} from 'generated/graphql';

import * as types from './types';
import { enumCache } from 'modules';



export interface IAnswerOption {
  id: string;
  description: string;
}

export interface IQuestion {
  id: string;
  description: string;
  answerOptions: IAnswerOption[];
}

export interface IData {
  displayName: string;
  statusName: enumCache.IRequirementStatusName;
  questions: IQuestion[];
  questionsById: Record<string, IQuestion>;
  formState: types.IFormState;
}

export function useQuery(assignedRequirementId: string) {
  const tuple = useTakeQuizActivityQuery({
    variables: {
      assignedRequirementId
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlQuizPreset = tuple.data?.assignedRequirement?.preset.typeSpecificData?.__typename === 'RequirementPresetQuiz' ?
        tuple.data.assignedRequirement.preset.typeSpecificData :
        null;

      const questions = _.map(gqlQuizPreset?.questions ?? [], (gqlQuestion): IQuestion => ({
        id: gqlQuestion.id,
        description: gqlQuestion.description,
        answerOptions: _.map(gqlQuestion.answerOptions, (gqlAnswerOption): IAnswerOption => ({
          id: gqlAnswerOption.id,
          description: gqlAnswerOption.description
        }))
      }));

      const data: IData = {
        displayName: tuple.data?.assignedRequirement?.preset.displayName ?? "",
        statusName: tuple.data?.assignedRequirement?.status.name ?? 'pending',
        questions,
        questionsById: _.keyBy(questions, question => question.id),
        formState: {
          questions: _.map(questions, (question): types.IQuestion => ({
            _key: question.id,
            id: question.id,
            selectedAnswerId: ''
          }))
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useSubmitQuizMutation(assignedRequirementId: string) {
  const [fnMutate, tuple] = useEmployeeSubmitAssignedRequirementMutation();



  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id: assignedRequirementId,
            quizAnswerOptionIds: _.compact(_.map(formState.questions, question => question.selectedAnswerId ?? ''))
          }
        }
      });
    },
    [fnMutate]
  );



  return [
    fnWrappedMutate,
    tuple
  ] as const;
}
