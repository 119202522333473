import React from 'react';

import {
  AppBar,
  IMode
} from './AppBar/AppBar';
import {
  ActivityHeader,
  IActivityHeaderProps
} from './ActivityHeader/ActivityHeader';
import {
  Footer,
  IFooterProps
} from './Footer/Footer';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LoadingOverlay } from 'modules/layer0';
import { enumCache } from 'modules/layer2';
import { AuthRedirector } from 'modules/layer3';

export type { IMode as IAppBarMode } from './AppBar/AppBar';
export type {
  ITab,
  ITabOptions
} from './ActivityHeader/ActivityHeader';



interface IContainerBoxProps {
  useAuthBackground: boolean;
}

const ContainerBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'useAuthBackground'
})<IContainerBoxProps>(({useAuthBackground}) => ({
  flex: '1 0',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  ...(useAuthBackground && {
    backgroundColor: '#f4f9fd'
  })
}));

interface IBodyBoxProps {
  disablePadding?: boolean;
}

const BodyBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'disablePadding'
})<IBodyBoxProps>(({theme, disablePadding}) => ({
  flex: '1 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowY: 'auto',
  paddingTop: !disablePadding ? theme.spacing(4) : undefined,
  paddingLeft: !disablePadding ? theme.spacing(9) : undefined,
  paddingRight: !disablePadding ? theme.spacing(9) : undefined
}));

const FooterBox = styled(Box)(() => ({
  borderTop: '1px solid #F0F0F0'
}));



export interface IBaseActivityTemplateProps {
  mode: IMode;
  navigationBarSelectedLinkId?: string;
  isLoading?: boolean;
  disableNavigationBar?: boolean;
  disableBodyPadding?: boolean;
  useAuthBackground?: boolean;
  onlyAllowLoggedOutUsers?: boolean;
  redirectLoggedInUserToRoute?: string;
  requireOneOfPermissionCategories?: enumCache.IPermissionCategoryName[];
  headerOptions?: IActivityHeaderProps;
  footerOptions?: IFooterProps;
}

export const BaseActivityTemplate: React.FC<IBaseActivityTemplateProps> = props => {
  const [appBarIsLoading, setAppBarIsLoading] = React.useState(false);

  const isLoading = React.useMemo(
    () =>
      props.isLoading ||
      appBarIsLoading,
    [
      props.isLoading,
      appBarIsLoading
    ]
  );



  return (
    <ContainerBox useAuthBackground={!!props.useAuthBackground}>
      <LoadingOverlay
        active={isLoading} />

      <AuthRedirector
        type={props.onlyAllowLoggedOutUsers ?
          'onlyAllowLoggedOutUsers' :
          'onlyAllowLoggedInUsers'}
        redirectLoggedInUserToRoute={props.redirectLoggedInUserToRoute}
        requireOneOfPermissionCategories={props.requireOneOfPermissionCategories}>

        <AppBar
          mode={props.mode}
          navigationBarSelectedLinkId={props.navigationBarSelectedLinkId}
          setIsLoading={setAppBarIsLoading} />

        {props.headerOptions && (
          <ActivityHeader
            {...props.headerOptions} />
        )}

        <BodyBox
          disablePadding={props.disableBodyPadding}>
          {props.children}
        </BodyBox>

        {props.footerOptions && (
          <FooterBox>
            <Footer
              {...props.footerOptions} />
          </FooterBox>
        )}

      </AuthRedirector>
    </ContainerBox>
  );
};
