import React from 'react';

import {
  BaseActivityTemplate,
  Form,
  formUtil,
  paletteColors,
  SectionHeaderBox,
  fields,
  errorUtil
} from 'modules';
import {
  Grid,
  Box,
  Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as dataService from './dataService';
import * as types from './types';
import { useForm } from 'react-hook-form';



export const AdminEditMyProfileActivity: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery();
  const [fnSave, saveMutationTuple] = dataService.useSave();

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading;

  const form = useForm<types.IFormState>({
    defaultValues: {
      profilePicture: undefined
    }
  });
  const watchProfilePicture = form.watch('profilePicture');



  React.useEffect(
    () => {
      if (!queryTuple.loading && !queryTuple.data.administratorId) {
        navigate('/');
      }
    },
    [
      queryTuple.loading,
      queryTuple.data.administratorId
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        formUtil.setFieldsFromQuery(queryTuple.data.formState, form.setValue, [
          'profilePicture'
        ]);
      }
    },
    [
      queryTuple.loading,
      queryTuple.data.formState
    ]
  );



  async function handleSubmit(formState: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnSave(formState);
      navigate(-1);
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.administrators,
          title: t('editMyProfile')
        }}
        footerOptions={{
          onClickCancel: () => navigate(-1)
        }}>

        <Box mb={3}>
          <SectionHeaderBox>
            <Typography variant="h2">
              {t('profilePicture')}
            </Typography>
          </SectionHeaderBox>

          <Grid container>

            <Grid
              item
              md={6}>

              <fields.ProfilePictureInputField.FC
                apiFileKey={queryTuple.data.initProfilePictureFileKey}
                formFile={watchProfilePicture}
                onChange={fileData => form.setValue('profilePicture', fileData)} />

            </Grid>

          </Grid>
        </Box>

      </BaseActivityTemplate>
    </Form>
  );
};
