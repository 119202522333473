import _ from 'lodash';

import {
  green,
  orange,
  lightBlue,
  deepPurple,
  pink
} from '@mui/material/colors';

import { enumCache } from 'modules/layer2';
import * as chipGenerator from '../shared/chipGenerator';
import { map } from 'rxjs';



export const RequirementTypeChip = chipGenerator.genChipComponent<enumCache.IRequirementTypeName>({
  obsValueId2DisplayData: enumCache.subjAllEnums.asObservable()
    .pipe(map((value): Record<enumCache.IRequirementTypeName, chipGenerator.IValueDisplayData> => {
      const enumValues = value.requirementTypes;
      const enumValuesByName = _.keyBy(enumValues, enumValue => enumValue.name) as Record<enumCache.IRequirementTypeName, enumCache.IRequirementType>;

      return {
        document: {
          label: enumValuesByName.document?.displayName ?? "",
          backgroundColor: green['A700']
        },
        inService: {
          label: enumValuesByName.inService?.displayName ?? "",
          backgroundColor: deepPurple['A700']
        },
        requirement: {
          label: enumValuesByName.requirement?.displayName ?? "",
          backgroundColor: orange['A700']
        },
        training: {
          label: enumValuesByName.training?.displayName ?? "",
          backgroundColor: lightBlue['A700']
        },
        quiz: {
          label: enumValuesByName.quiz?.displayName ?? "",
          backgroundColor: pink['A700']
        }
      };
    }))
});
