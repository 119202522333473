import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useMessengerActivityThreadContentQuery,
  useMessengerPostMessageMutation,
  useMessengerMarkThreadAsReadMutation,
  useMessengerMarkMessageAsReadMutation
} from 'generated/graphql';

import { messengerService } from 'modules';
import * as uuid from 'uuid';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';



function __useMarkThreadAsReadMutation(threadId: string) {
  const [fnMutate, tuple] = useMessengerMarkThreadAsReadMutation({
    variables: {
      id: threadId
    }
  });



  const fnWrappedMutate = React.useCallback(
    async () => {
      messengerService.clearThreadUnreadMessages(threadId);
      await fnMutate();
    },
    [fnMutate]
  );



  return [
    fnWrappedMutate,
    tuple
  ] as const;
}



function __useMarkMessageAsReadMutation() {
  const [fnMutate, tuple] = useMessengerMarkMessageAsReadMutation();



  const fnWrappedMutate = React.useCallback(
    async (messageId: string) => {
      await fnMutate({
        variables: {
          id: messageId
        }
      });
    },
    [tuple]
  );



  return [
    fnWrappedMutate,
    tuple
  ] as const;
}



export interface IUser {
  id: string;
  fullName: string;
  isSelf: boolean;
  profilePictureFileKey: string;
}

export interface IMessage {
  id: string;
  timestamp: moment.Moment;
  authorUser: IUser;
  message: string;
  isInFlight: boolean;
}

export interface IData {
  selfUserId: string;
  displayName: string;
  otherUsers: IUser[];
  messages: IMessage[];
  usersById: Record<string, IUser>;
}

function __useQuery(threadId: string) {
  const {t} = useTranslation();

  const tuple = useMessengerActivityThreadContentQuery({
    variables: {
      threadId
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  const [fnMarkThread] = __useMarkThreadAsReadMutation(threadId);



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  React.useEffect(
    () => {
      if (tuple.called && !tuple.loading) {
        fnMarkThread();
      }
    },
    [
      tuple.called,
      tuple.loading
    ]
  );



  return React.useMemo(
    () => {
      const selfUserId = tuple.data?.loggedInUser?.id ?? '';
      const gqlThread = tuple.data?.loggedInUser?.messengerThreads[0];
      const users = _.map(gqlThread?.users ?? [], (gqlUser): IUser => ({
        id: gqlUser.id,
        fullName: `${gqlUser.fullName}${gqlUser.isArchived ? ` ${t('deletedTag')}` : ""}`,
        isSelf: gqlUser.id === selfUserId,
        profilePictureFileKey: gqlUser.profilePictureFile?.key ?? ''
      }));

      const usersById = _.keyBy(users, user => user.id);
      const otherUsers = _.filter(_.values(usersById), user => user.id !== selfUserId);

      const messages = _.map(gqlThread?.messages ?? [], (gqlMessage): IMessage => ({
        id: gqlMessage.id,
        timestamp: moment(gqlMessage.timestamp, moment.ISO_8601),
        authorUser: usersById[gqlMessage.author.id],
        message: gqlMessage.message,
        isInFlight: false
      }));

      const data: IData = {
        selfUserId,
        displayName: gqlThread?.displayName ?? "",
        otherUsers,
        messages: _.sortBy(messages, message => message.timestamp.unix()),
        usersById
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



interface ISubscriptionData {
  messages: IMessage[];
}

function __useSubscription(threadId: string) {
  const [fnMarkMessageAsRead] = __useMarkMessageAsReadMutation();

  const [messages, setMessages] = React.useState<IMessage[]>([]);



  React.useEffect(
    () => {
      const sub = messengerService.getObservable()
        .subscribe(data => {
          const thread = data.threadsById[threadId];
          if (thread) {
            setMessages(thread.messages);

            const newMessages = _.difference(thread.messages, messages);
            Promise.all(_.map(newMessages, message =>
              fnMarkMessageAsRead(message.id)));
          }
        });

      return () => sub.unsubscribe();
    },
    []
  );



  return React.useMemo(
    () => ({
      data: {
        messages
      } as ISubscriptionData
    }),
    [messages]
  );
}



function __usePostMessage(threadId: string) {
  const [fnMutate, tuple] = useMessengerPostMessageMutation();



  const fnWrappedMutate = React.useCallback(
    async (message: string) => {
      await fnMutate({
        variables: {
          input: {
            threadId,
            message
          }
        }
      });
    },
    [fnMutate]
  );



  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );



  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useDataService(threadId: string) {
  const queryTuple = __useQuery(threadId);
  const subTuple = __useSubscription(threadId);
  const [fnPost, postMutationTuple] = __usePostMessage(threadId);

  const [inFlightMessages, setInFlightMessages] = React.useState<IMessage[]>([]);



  React.useEffect(
    () => {
      messengerService.setOpenThreadId(threadId);
      return () =>
        messengerService.setOpenThreadId('');
    },
    []
  );



  const wrappedQueryTuple = React.useMemo(
    () => {
      const messages = _.uniqBy(
        [
          ...queryTuple.data.messages,
          ..._.filter(subTuple.data.messages, message =>
            message.authorUser.id !== queryTuple.data.selfUserId),
          ...inFlightMessages
        ],
        message => message.id
      );

      const sortedMessages = _.sortBy(
        messages,
        message => message.timestamp.unix()
      );

      return update(queryTuple, {
        data: {
          messages: {
            $set: sortedMessages
          }
        }
      });
    },
    [
      queryTuple,
      subTuple.data.messages,
      inFlightMessages
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        setInFlightMessages([]);
      }
    },
    [
      queryTuple.loading,
      setInFlightMessages
    ]
  );



  const fnWrappedPost = React.useCallback(
    async (message: string) => {
      setInFlightMessages(previous => [
        ...previous,
        {
          id: `*${uuid.v4()}`,
          authorUser: queryTuple.data.usersById[queryTuple.data.selfUserId] ?? {
            id: '',
            fullName: "",
            isSelf: true
          },
          message,
          timestamp: moment(),
          isInFlight: true
        }
      ]);
      await fnPost(message);
    },
    [
      queryTuple.data,
      fnPost,
      setInFlightMessages
    ]
  );



  return {
    queryTuple: wrappedQueryTuple,
    lastMessageId: _.last(wrappedQueryTuple.data.messages)?.id ?? '',
    fnPost: fnWrappedPost,
    postMutationTuple
  };
}
