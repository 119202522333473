import React from 'react';

import {
  Box,
  Button,
  ButtonGroup
} from '@mui/material';
import {
  authService,
  errorUtil,
  LoadingOverlay,
  AuthCard,
  AuthTitle,
  AuthDescription,
  Form,
  fields
} from 'modules';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import * as types from '../types';



interface IFormData {
  code: string;
}

const TextFieldController = fields.genTextFieldControllerComponent<IFormData>();



export interface IProps {
  onChangeState: (state: types.IState) => void;
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();

  const form = useForm<IFormData>({
    defaultValues: {
      code: ""
    }
  });

  const [fnConfirmCode, confirmCodeTuple] = dataService.useConfirmCode();

  const [isLoading, setIsLoading] = React.useState(false);
  const [mfaMethod, setMfaMethod] = React.useState<authService.IMfaMethod | ''>('');



  React.useEffect(
    () => {
      const challengeType = authService.getChallengeType();
      if (!challengeType) {
        alert(t('auth:errors.noChallengeTypeSet'));
      } else if (challengeType !== 'select') {
        setMfaMethod(challengeType);
      }
    },
    []
  );



  async function handleSelectMfaMethod(mfaMethod: authService.IMfaMethod) {
    setMfaMethod(mfaMethod);
    setIsLoading(true);
    await authService.answerSelectMfaChallenge(mfaMethod);
    setIsLoading(false);
  }



  async function processSubmit(formData: IFormData) {
    if (mfaMethod) {
      await errorUtil.errorWrap(async () => {
        await fnConfirmCode('login', formData.code, mfaMethod);
        props.onChangeState('done');
      });
    }
  }



  return (
    <>
      <LoadingOverlay
        active={confirmCodeTuple.isLoading || isLoading} />

      <AuthCard.Comp>
        <Form onSubmit={form.handleSubmit(processSubmit)}>

          <Box mb={1}>
            <AuthTitle.Comp>
              {t('auth:enterVerificationCode')}
            </AuthTitle.Comp>
          </Box>

          <Box mb={1}>
            <AuthDescription.Comp>
              {!mfaMethod &&
                t('auth:loginSelectMfaMethod')}

              {mfaMethod === 'sms' &&
                t('auth:loginInputSmsCode')}

              {mfaMethod === 'softwareToken' &&
                t('auth:loginInputTotpCode')}
            </AuthDescription.Comp>
          </Box>

          {!mfaMethod && (
            <Box mb={3}>
              <ButtonGroup
                size="small"
                variant="outlined"
                fullWidth>
                <Button
                  onClick={() => handleSelectMfaMethod('sms')}>
                  {t('auth:sms')}
                </Button>

                <Button
                  onClick={() => handleSelectMfaMethod('softwareToken')}>
                  {t('auth:authApp')}
                </Button>
              </ButtonGroup>
            </Box>
          )}

          {mfaMethod && (
            <>
              <Box mb={3}>
                <TextFieldController
                  label={t('auth:code')}
                  formControl={form.control}
                  fieldKey="code"
                  isRequired />
              </Box>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth>
                {t('auth:confirm')}
              </Button>
            </>
          )}

        </Form>
      </AuthCard.Comp>
    </>
  );
};
