import React from 'react';
import _ from 'lodash';

import {
  AfterVisitDataSchemaFieldsFragment,
  AfterVisitDataValueFieldsFragment,
  AfterVisitDataValueInput,
  useEditAfterVisitDataActivityQuery,
  useEmployeeSaveAfterVisitDataMutation
} from 'generated/graphql';

import * as types from './types';
import * as uuid from 'uuid';
import { enumCache } from 'modules';



export type ISchema = Record<string, types.ISchemaValue>;

export interface ISchemata {
  medications: ISchema;
  allMedsTaken: ISchema;
  justificationOfServices: ISchema;
}

export interface IData {
  patientName: string;
  visitStatusName: enumCache.IVisitStatusName | '';
  schemata: ISchemata;
  formState: types.IFormState;
}

export function useQuery(afterVisitDataId: string) {
  const tuple = useEditAfterVisitDataActivityQuery({
    variables: {
      afterVisitDataId
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlData = tuple.data;

      const schemata: ISchemata = {
        medications: __gqlSchemaValues2Client(gqlData?.medicationsSchema?.values ?? []),
        allMedsTaken: __gqlSchemaValues2Client(gqlData?.allMedsTakenSchema?.values ?? []),
        justificationOfServices: __gqlSchemaValues2Client(gqlData?.justificationOfServicesSchema?.values ?? [])
      };

      const gqlAfterVisitData = tuple.data?.afterVisitData;
      const gqlPatient = gqlAfterVisitData?.visit?.template.episode.patient;

      const data: IData = {
        patientName: gqlPatient ?
          `${gqlPatient.firstName} ${gqlPatient.lastName}` :
          "",
        visitStatusName: gqlAfterVisitData?.visit.visitStatus.name ?? '',
        schemata,
        formState: {
          medicationValues: __schema2FormValues(schemata.medications, gqlAfterVisitData?.medications ?? []),
          allMedsTakenValues: __schema2FormValues(schemata.allMedsTaken, gqlAfterVisitData?.allMedsTaken ? [gqlAfterVisitData.allMedsTaken] : []),
          justificationOfServicesValues: __schema2FormValues(schemata.justificationOfServices, gqlAfterVisitData?.justificationOfServices ?? [])
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



function __gqlSchemaValues2Client(gqlSchemaValues: AfterVisitDataSchemaFieldsFragment['values']) {
  return _.keyBy(
    _.map(gqlSchemaValues, (gqlValue): types.ISchemaValue => ({
      value: gqlValue.value,
      hasDescription: gqlValue.hasDescription,
      descriptionLabel: gqlValue.descriptionLabel ?? undefined
    })),
    value => value.value
  );
}



function __schema2FormValues(schema: Record<string, types.ISchemaValue>, gqlAfterVisitDataValues: AfterVisitDataValueFieldsFragment[]) {
  const afterVisitDataValuesByValue = _.keyBy(gqlAfterVisitDataValues, value => value.value);
  return _.map(_.values(schema), (schemaValue): types.IValue => {
    const afterVisitDataValue = afterVisitDataValuesByValue[schemaValue.value];

    return {
      _key: uuid.v4(),
      name: schemaValue.value,
      isChecked: !!afterVisitDataValue,
      description: afterVisitDataValue?.description ?? ""
    };
  });
}



export function useSaveMutation(afterVisitDataId: string) {
  const [fnMutate, tuple] = useEmployeeSaveAfterVisitDataMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id: afterVisitDataId,
            medicationValues: __values2GqlValueInput(formState.medicationValues),
            allMedsTakenValue: __values2GqlValueInputSingle(formState.allMedsTakenValues),
            justificationOfServicesValues: __values2GqlValueInput(formState.justificationOfServicesValues)
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



function __filterCheckedValues(values: types.IValue[]) {
  return _.filter(values, value => value.isChecked);
}



function __values2GqlValueInput(values: types.IValue[]) {
  const filteredValues = __filterCheckedValues(values);
  return _.map(filteredValues, (value): AfterVisitDataValueInput => ({
    value: value.name,
    description: value.description.trim()
  }));
}



function __values2GqlValueInputSingle(values: types.IValue[]): AfterVisitDataValueInput | null {
  const checkedValue = _.find(values, value => value.isChecked);
  return checkedValue ? {
    value: checkedValue.name,
    description: checkedValue.description
  } : null;
}
