import _ from 'lodash';



export interface IAppState {
  inviteCode: string;
}

let __appState: IAppState = __newAppState();



export function getAppState() {
  return _.cloneDeep(__appState);
}



function __newAppState(): IAppState {
  return {
    inviteCode: ''
  };
}



export function reset() {
  __appState = __newAppState();
}



export function setInviteCode(value: string) {
  __appState.inviteCode = value;
}
