import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  BaseActivityTemplate,
  errorUtil,
  Form,
  paletteColors
} from 'modules';
import {
  FieldPath,
  useForm
} from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import * as types from './types';
import * as dataService from './dataService';
import { Grid } from '@mui/material';
import { GeneralInformationSection } from './GeneralInformationSection/GeneralInformationSection';
import { ContactInformationSection } from './ContactInformationSection/ContactInformationSection';
import { SupervisorsAndPayRateSection } from './SupervisorsAndPayRateSection/SupervisorsAndPayRateSection';
import { AuthorizedWorkingHoursSection } from './AuthorizedWorkingHoursSection/AuthorizedWorkingHoursSection';
import { SkillsSection } from './SkillsSection/SkillsSection';
import { StatusCheckSection } from './StatusCheckSection/StatusCheckSection';



interface IParams {
  employeeId?: string;
}

export const EditEmployeeActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const form = useForm<types.IFormState>({
    defaultValues: {
      id: '',
      firstName: "",
      lastName: "",
      birthdate: null,
      employeeId: "",
      discipline: "",
      address: "",
      primaryPhone: "",
      secondaryPhone: "",
      supervisorUserId: '',
      altSupervisorUserId: '',
      payRate: "",
      dayOfWeek2WorkingHoursEntry: types.genEmptyDayOfWeek2WorkingHoursEntry(),
      skills: []
    }
  });

  const [fnQuery, queryTuple] = dataService.useEditAdministratorActivityLazyQuery();
  const [fnSave, saveMutationTuple] = dataService.useSaveEmployeeMutation();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveEmployeeMutation();

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading ||
    archiveMutationTuple.loading;



  React.useEffect(
    () => {
      types.init(t);
    },
    [t]
  );



  React.useEffect(
    () => {
      if (params.employeeId && !queryTuple.called) {
        fnQuery(params.employeeId);
      }
    },
    [
      params.employeeId,
      queryTuple.called
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        const keys: FieldPath<types.IFormState>[] = [
          'id',
          'firstName',
          'lastName',
          'birthdate',
          'employeeId',
          'discipline',
          'hireStatus',
          'address',
          'primaryPhone',
          'secondaryPhone',
          'supervisorUserId',
          'altSupervisorUserId',
          'payRate',
          'licenseCheck',
          'fraudCheck',
          'dayOfWeek2WorkingHoursEntry',
          'skills'
        ];

        const fieldPaths = [
          ...keys,
          ..._.flatMap(types.daysOfWeek, (dayOfWeek): FieldPath<types.IFormState>[] => [
            `dayOfWeek2WorkingHoursEntry.${dayOfWeek}.isEnabled`,
            `dayOfWeek2WorkingHoursEntry.${dayOfWeek}.startTime`,
            `dayOfWeek2WorkingHoursEntry.${dayOfWeek}.endTime`,
            `dayOfWeek2WorkingHoursEntry.${dayOfWeek}.tillEndOfDay`
          ])
        ];

        const formState = queryTuple.data.formState;
        _.forEach(fieldPaths, path => {
          form.setValue(path, _.get(formState, path), {shouldDirty: false});
        });
      }
    },
    [
      queryTuple.data,
      queryTuple.loading
    ]
  );



  async function handleSubmit(formData: types.IFormState) {
    let halt = false;
    _.forEach(formData.dayOfWeek2WorkingHoursEntry, entry => {
      if (entry.isEnabled) {
        const mStartTime = moment(`1970-01-01T${entry.startTime}`, moment.ISO_8601);
        const mEndTime = moment(`1970-01-01T${entry.endTime}`, moment.ISO_8601);
        if (entry.tillEndOfDay) {
          mEndTime.add(1, 'day');
        }

        if (mEndTime.isSameOrBefore(mStartTime)) {
          alert(t('errors.endTimeBeforeStartTime'));
          halt = true;
          return false;
        }
      }
    });

    if (halt) {
      return;
    }

    await errorUtil.errorWrap(async () => {
      await fnSave(formData);
      navigate(-1);
    });
  }



  async function handleConfirmDelete() {
    await errorUtil.errorWrap(async () => {
      await fnArchive(form.watch('id'));
      navigate(-1);
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        navigationBarSelectedLinkId="employee"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.employees,
          title: !params.employeeId ?
            t('adminEmployees:createEmployee') :
            (queryTuple.data.fullName || t('adminEmployees:editEmployee')),
          hasBackButton: true
        }}
        footerOptions={{
          hasDeleteButton: !!params.employeeId,
          deleteButtonLabel: t('adminEmployees:deleteEmployee'),
          onConfirmDelete: handleConfirmDelete,
          onClickCancel: () => navigate(-1)
        }}>

        <GeneralInformationSection
          formControl={form.control}
          email={queryTuple.data.email}
          isDisabled={isLoading} />

        <ContactInformationSection
          formControl={form.control}
          isDisabled={isLoading} />

        <SupervisorsAndPayRateSection
          formControl={form.control}
          isDisabled={isLoading} />

        <StatusCheckSection
          formControl={form.control}
          isDisabled={isLoading} />

        <Grid container>

          <Grid
            item
            md={6}>

            <AuthorizedWorkingHoursSection
              formControl={form.control}
              formWatch={form.watch}
              isDisabled={isLoading} />

          </Grid>

          <Grid
            item
            md={6}>

            <SkillsSection
              formControl={form.control}
              isDisabled={isLoading} />

          </Grid>

        </Grid>

      </BaseActivityTemplate>
    </Form>
  );
};
