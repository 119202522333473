import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  Form,
  paletteColors,
  errorUtil
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  RadioButtonGroup,
  IRadioControllerOption
} from './RadioButtonGroup/RadioButtonGroup';

import { useForm} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from './types';
import * as dataService from './dataService';
import objectHash from 'object-hash';

import { CheckboxGroup } from './CheckboxGroup/CheckboxGroup';
import { Medications } from './Medications/Medications';
import { GeneralNotes } from './GeneralNotes/GeneralNotes';
import { ProviderDiagnosisProcedureSection } from './ProviderDiagnosisProcedureSection/ProviderDiagnosisProcedureSection';
import { SurgicalProcedures } from './SurgicalProcedures/SurgicalProcedures';



interface IParams {
  episodeId: string;
}

export const EditEpisodeActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const form = useForm<types.IFormState>({
    defaultValues: {
      id: '',
      riskAssessmentValues: [],
      mentalStatusValues: [],
      neurologicalValues: [],
      dmeValues: [],
      suppliesValues: [],
      nutritionRequirementsValues: [],
      functionalLimitationsValues: [],
      activitiesPermittedValues: [],
      medications: [],
      diet: "",
      exerciseActivity: "",
      primaryDiagnosisValue: null,
      otherDiagnosisValues: []
    }
  });

  const queryTuple = dataService.useQuery(params.episodeId ?? '');
  const [fnSave, saveMutationTuple] = dataService.useSaveEpisodeMutation(params.episodeId ?? '');
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveEpisodeMutation(params.episodeId ?? '');

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading ||
    archiveMutationTuple.loading;



  const prognosisOptions = React.useMemo(
    () => _.map(queryTuple.data.prognosisSchema, (value): IRadioControllerOption => ({
      id: value.value,
      label: t(`adminEpisodes:prognosisValues.${value.value}`)
    })),
    [
      queryTuple.data.prognosisSchema,
      t
    ]
  );



  React.useEffect(
    () => {
      if (queryTuple.called && !queryTuple.loading) {
        const data = queryTuple.data.formState;
        form.setValue('primaryProviderId', data.primaryProviderId);
        form.setValue('primaryDiagnosisValue', data.primaryDiagnosisValue);
        form.setValue('otherDiagnosisValues', data.otherDiagnosisValues);
        form.setValue('surgicalProcedures', data.surgicalProcedures);
        form.setValue('riskAssessmentValues', data.riskAssessmentValues);
        form.setValue('mentalStatusValues', data.mentalStatusValues);
        form.setValue('neurologicalValues', data.neurologicalValues);
        form.setValue('psychosocialValues', data.psychosocialValues);
        form.setValue('dmeValues', data.dmeValues);
        form.setValue('suppliesValues', data.suppliesValues);
        form.setValue('prognosisValue', data.prognosisValue);
        form.setValue('safetyMeasuresValues', data.safetyMeasuresValues);
        form.setValue('nutritionRequirementsValues', data.nutritionRequirementsValues);
        form.setValue('functionalLimitationsValues', data.functionalLimitationsValues);
        form.setValue('activitiesPermittedValues', data.activitiesPermittedValues);
        form.setValue('medications', data.medications);
        form.setValue('diet', data.diet);
        form.setValue('exerciseActivity', data.exerciseActivity);
      }
    },
    [
      form,
      queryTuple.called,
      queryTuple.loading,
      queryTuple.data.formState
    ]
  );



  async function handleSubmit(formData: types.IFormState) {
    if (_.some(formData.otherDiagnosisValues, value => value.diagnosis === null || !value.diagnosis.icd10Code)) {
      alert(t('adminEpisodes:errors.otherDiagnosesEmptyDiagnosis'));
      return;
    }

    const hashes = _.map(formData.otherDiagnosisValues, value => objectHash([
      value.diagnosis?.icd10Code ?? '',
      value.diagnosis?.displayName ?? ''
    ]));
    const hashGroups = _.groupBy(hashes);
    if (_.some(_.values(hashGroups), hashGroup => hashGroup.length > 1)) {
      alert(t('adminEpisodes:errors.otherDiagnosesDuplicate'));
      return;
    }

    await errorUtil.errorWrap(async () => {
      await fnSave(formData);
      navigate(-1);
    });
  }



  async function handleConfirmDelete() {
    await errorUtil.errorWrap(async () => {
      await fnArchive();
      navigate(-1);
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.episodes,
          title: t('adminEpisodes:editEpisode')
        }}
        footerOptions={{
          hasDeleteButton: !queryTuple.data.hasParentEpisode,
          onConfirmDelete: handleConfirmDelete,
          onClickCancel: () => navigate(-1)
        }}>

        <CheckboxGroup
          form={form}
          fieldKey="riskAssessmentValues"
          title={t('adminEpisodes:riskAssessment')}
          schemaValuesByValue={queryTuple.data.riskAssessmentSchema}
          labelTranslationPrefix="adminEpisodes:riskAssessmentValues"
          isDisabled={isLoading} />

        <ProviderDiagnosisProcedureSection
          form={form}
          isDisabled={isLoading} />

        <SurgicalProcedures
          form={form}
          fieldKey="surgicalProcedures"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="mentalStatusValues"
          title={t('adminEpisodes:mentalStatus')}
          schemaValuesByValue={queryTuple.data.mentalStatusSchema}
          labelTranslationPrefix="adminEpisodes:mentalStatusValues"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="neurologicalValues"
          title={t('adminEpisodes:neurological')}
          schemaValuesByValue={queryTuple.data.neurologicalSchema}
          labelTranslationPrefix="adminEpisodes:neurologicalValues"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="psychosocialValues"
          title={t('adminEpisodes:psychosocial')}
          schemaValuesByValue={queryTuple.data.psychosocialSchema}
          labelTranslationPrefix="adminEpisodes:psychosocialValues"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="dmeValues"
          title={t('adminEpisodes:dme')}
          schemaValuesByValue={queryTuple.data.dmeSchema}
          labelTranslationPrefix="adminEpisodes:dmeValues"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="suppliesValues"
          title={t('adminEpisodes:supplies')}
          schemaValuesByValue={queryTuple.data.suppliesSchema}
          labelTranslationPrefix="adminEpisodes:suppliesValues"
          isDisabled={isLoading} />

        <RadioButtonGroup
          form={form}
          fieldKey="prognosisValue"
          title={t('adminEpisodes:prognosis')}
          options={prognosisOptions}
          labelTranslationPrefix="adminEpisodes:prognosisValues"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="safetyMeasuresValues"
          title={t('adminEpisodes:safetyMeasures')}
          schemaValuesByValue={queryTuple.data.safetyMeasuresSchema}
          labelTranslationPrefix="adminEpisodes:safetyMeasuresValues"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="nutritionRequirementsValues"
          title={t('adminEpisodes:nutritionRequirements')}
          schemaValuesByValue={queryTuple.data.nutritionRequirementsSchema}
          labelTranslationPrefix="adminEpisodes:nutritionRequirementsValues"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="functionalLimitationsValues"
          title={t('adminEpisodes:functionalLimitations')}
          schemaValuesByValue={queryTuple.data.functionalLimitationsSchema}
          labelTranslationPrefix="adminEpisodes:functionalLimitationsValues"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="activitiesPermittedValues"
          title={t('adminEpisodes:activitiesPermitted')}
          schemaValuesByValue={queryTuple.data.activitiesPermittedSchema}
          labelTranslationPrefix="adminEpisodes:activitiesPermittedValues"
          isDisabled={isLoading} />

        <Medications
          form={form}
          fieldKey="medications"
          isDisabled={isLoading} />

        <GeneralNotes
          formControl={form.control}
          isDisabled={isLoading} />

      </BaseActivityTemplate>
    </Form>
  );
};
