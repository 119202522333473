import React from 'react';

import {
  BaseActivityTemplate,
  errorUtil,
  InviteModal,
  paletteColors,
  EmployeeProfileTab,
  EmployeeRequirementsTab
} from 'modules';

import { Button } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Create } from '@mui/icons-material';
import * as dataService from './dataService';
import { useNavigate } from 'react-router-dom';
import { VisitsTab } from './VisitsTab/VisitsTab';



interface IParams {
  employeeId: string;
}

export const EmployeeProfileActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useEmployeeProfileActivityQuery(params.employeeId ?? '');
  const [fnInvite, inviteMutationTuple] = dataService.useInviteEmployeeMutation(params.employeeId ?? '');

  const [isInviteModalOpen, setIsInviteModalOpen] = React.useState(false);
  const [selectedTabId, setSelectedTabId] = React.useState('profile');

  const isLoading =
    queryTuple.loading ||
    inviteMutationTuple.loading;



  async function handleClickResendInvite() {
    const email = queryTuple.data.invite?.recipientEmail ?? '';
    if (email) {
      await errorUtil.errorWrap(async () => {
        await fnInvite(email);
      });
    }
  }



  function handleClickInviteUser() {
    setIsInviteModalOpen(true);
  }



  function handleClickEditData() {
    navigate(`/admin/employees/edit/${params.employeeId}`);
  }



  const elRightButtons = React.useMemo(
    () => selectedTabId === 'profile' ? [
      (!queryTuple.data.isLinkedToUser && queryTuple.data.invite) ? (
        <Button
          key="resendInvite"
          variant={queryTuple.data.invite.isInviteExpired ? 'contained' : 'outlined'}
          onClick={handleClickResendInvite}
          color="primary">
          {t('resendInvite')}
        </Button>
      ) : null,

      !queryTuple.data.isLinkedToUser ? (
        <Button
          key="inviteUser"
          variant="outlined"
          onClick={handleClickInviteUser}
          color="primary">
          {t('inviteUser')}
        </Button>
      ) : null,

      (
        <Button
          key="editData"
          variant="outlined"
          onClick={handleClickEditData}
          color="primary"
          startIcon={(
            <Create />
          )}>
          {t('editData')}
        </Button>
      )
    ] : [],
    [
      selectedTabId,
      queryTuple.data
    ]
  );



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        navigationBarSelectedLinkId="employees"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.employees,
          title: queryTuple.data.fullName,
          employeeId: queryTuple.data.employeeId,
          hasBackButton: true,
          elRightButtons,
          tabOptions: {
            tabs: [
              {
                id: 'profile',
                label: t('adminEmployees:profile')
              },
              {
                id: 'requirements',
                label: t('adminEmployees:requirements')
              },
              {
                id: 'visits',
                label: t('adminEmployees:visits')
              }
            ],
            selectedTabId,
            onSelectTab: setSelectedTabId
          }
        }}>

        {selectedTabId === 'profile' && (
          <EmployeeProfileTab
            employeeId={params.employeeId ?? ''} />
        )}

        {selectedTabId === 'requirements' && (
          <EmployeeRequirementsTab
            employeeId={params.employeeId} />
        )}

        {selectedTabId === 'visits' && (
          <VisitsTab
            employeeId={params.employeeId ?? ''} />
        )}

      </BaseActivityTemplate>

      {isInviteModalOpen && (
        <InviteModal
          isOpen
          isLoading={inviteMutationTuple.loading}
          fnInvite={fnInvite}
          onAccept={() => setIsInviteModalOpen(false)}
          onCancel={() => setIsInviteModalOpen(false)} />
      )}
    </>
  );
};
