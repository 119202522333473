import _ from 'lodash';

import {
  FieldPath,
  UseFormSetValue
} from 'react-hook-form';




export function setFieldsFromQuery<TFormState extends Record<string, any>, TFieldValues extends TFormState>(
  formState: TFormState,
  fnSetValue: UseFormSetValue<TFieldValues>,
  fieldPaths: FieldPath<TFieldValues>[]
) {
  _.forEach(fieldPaths, path => {
    fnSetValue(path, _.get(formState, path), {shouldDirty: false});
  });
}
