import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';

import { ValueWithLabel } from 'modules';
import { useTranslation } from 'react-i18next';



export interface IProviderProps {
  title: string;
  id: string;
  fullName: string;
  primaryPhone: string;
  faxNumber: string;
}

export const Provider: React.FC<IProviderProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={4}>
      <Box mb={2}>
        <Typography variant="h3">
          {props.title}
        </Typography>
      </Box>

      <Grid container>
        <ValueWithLabel
          label={t('fullName')}
          value={props.fullName}
          toRoute={`/admin/providers/profile/${props.id}`} />

        <ValueWithLabel
          label={t('primaryPhone')}
          value={props.primaryPhone || t('noValue')} />

        <ValueWithLabel
          label={t('adminProviders:faxNumber')}
          value={props.faxNumber || t('noValue')} />
      </Grid>
    </Box>
  );
};
