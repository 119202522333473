import React from 'react';

import {
  Box,
  IconButton
} from '@mui/material';
import {
  genTextFieldControllerComponent,
  paletteColors
} from 'modules';

import { Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import * as types from '../../types';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();

const ContainerBox = styled(Box)(({theme}) => ({
  borderBottom: '1px solid #9AB0D9',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  display: 'flex',
  alignItems: 'flex-start'
}));

const FieldsBox = styled(Box)(() => ({
  flex: '1 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));



export interface ISkillProps {
  formControl: Control<types.IFormState>;
  index: number;
  onClickDelete: () => void;
  isDisabled: boolean;
}

export const Skill: React.FC<ISkillProps> = props => {
  const {t} = useTranslation();



  return (
    <ContainerBox>
      <FieldsBox>
        <Box mb={3}>
          <TextFieldController
            formControl={props.formControl}
            fieldKey={`skills.${props.index}.name`}
            label={t('name')}
            isRequired
            isDisabled={props.isDisabled} />
        </Box>

        <Box mb={4}>
          <TextFieldController
            formControl={props.formControl}
            fieldKey={`skills.${props.index}.description`}
            label={t('description')}
            isRequired
            numLines={2}
            isDisabled={props.isDisabled} />
        </Box>
      </FieldsBox>

      <Box>
        <IconButton onClick={props.onClickDelete}>
          <Delete htmlColor={paletteColors.danger} />
        </IconButton>
      </Box>
    </ContainerBox>
  );
};
