import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';



export const SectionHeaderBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));
