import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  genTextFieldControllerComponent,
  genUserSelectFieldControllerComponent,
  validationUtil
} from 'modules';

import { Control } from 'react-hook-form';
import * as types from '../types';
import { useTranslation } from 'react-i18next';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();
const UserSelectFieldController = genUserSelectFieldControllerComponent<types.IFormState>();



export interface ISupervisorsAndPayRateSectionProps {
  formControl: Control<types.IFormState>;
  isDisabled: boolean;
}

export const SupervisorsAndPayRateSection: React.FC<ISupervisorsAndPayRateSectionProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('adminAdministrators:supervisorsAndPayRate')}
      </Typography>

      <Box mt={4}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={3}>

            <UserSelectFieldController
              fieldKey="supervisorUserId"
              label={t('adminAdministrators:supervisor')}
              formControl={props.formControl}
              typeName="administrator"
              isDisabled={props.isDisabled} />

          </Grid>

          <Grid
            item
            md={3}>

            <UserSelectFieldController
              fieldKey="altSupervisorUserId"
              label={t('adminAdministrators:altSupervisor')}
              formControl={props.formControl}
              typeName="administrator"
              isDisabled={props.isDisabled} />

          </Grid>

          <Grid
            item
            md={3}>

            <TextFieldController
              fieldKey="payRate"
              label={t('adminAdministrators:payRate')}
              formControl={props.formControl}
              isDisabled={props.isDisabled}
              extraValidationRules={{
                pattern: validationUtil.rules.nonNegativeUsdAmountOrEmpty
              }} />

          </Grid>

        </Grid>

      </Box>

    </Box>
  );
};
