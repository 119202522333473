import React from 'react';

import {
  Box,
  Grid
} from '@mui/material';
import {
  SkeletonRows,
  ValueWithLabel
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



export interface IPharmacySectionProps {
  patientId: string;
}

export const PharmacySection: React.FC<IPharmacySectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.patientId);



  return (
    <Box>
      {queryTuple.loading ? (
        <SkeletonRows numRows={1} />

      ) : (
        <Grid container>
          <ValueWithLabel
            label={t('patients:pharmacy')}
            value={queryTuple.data.name}
            toRoute={queryTuple.data.route} />
        </Grid>
      )}
    </Box>
  );
};
