import React from 'react';

import {
  Box,
  Grid
} from '@mui/material';
import {
  SkeletonRows,
  ValueWithLabel
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



export interface IEmergencyContactSectionProps {
  patientId: string;
}

export const EmergencyContactSection: React.FC<IEmergencyContactSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.patientId);



  return (
    <Box>
      {queryTuple.loading ? (
        <SkeletonRows numRows={4} />

      ) : (
        <Grid container>
          <ValueWithLabel
            label={t('patients:emergencyName')}
            value={queryTuple.data.name} />

          <ValueWithLabel
            label={t('patients:emergencyRelationship')}
            value={queryTuple.data.relationship} />

          <ValueWithLabel
            label={t('patients:emergencyPhone')}
            value={queryTuple.data.phone} />

          <ValueWithLabel
            label={t('patients:emergencyAddress')}
            value={queryTuple.data.address} />
        </Grid>
      )}
    </Box>
  );
};
