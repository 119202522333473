import React from 'react';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import {
  TimePicker,
  LocalizationProvider
} from '@mui/lab';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { AccessTime } from '@mui/icons-material';

import { validationUtil } from 'modules/layer0';
import { FieldContainer } from 'modules/layer1';



export const timeFormat = 'HH:mm';
export const rawTimeFormat = 'HH:mm:ss';



export interface ITimeFieldProps {
  label: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const TimeField: React.FC<ITimeFieldProps> = props => {
  return (
    <FieldContainer.Comp>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          showToolbar={false}
          inputFormat={timeFormat}
          label={props.label}
          value={props.value}
          onChange={value => props.onChange(value)}
          disabled={props.isDisabled}
          ampm={false}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              error={!!props.errorMessage}
              helperText={props.errorMessage}
              InputProps={{
                endAdornment: (
                  <AccessTime fontSize="small" />
                )
              }} />
          )} />
      </LocalizationProvider>
    </FieldContainer.Comp>
  );
};



export interface ITimeFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label: string;
  isDisabled?: boolean;
}

export function genTimeFieldControllerComponent<TFormState extends FieldValues>() {
  const TimeFieldController: React.FC<ITimeFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        name={props.fieldKey}
        control={props.formControl}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <TimeField
            label={props.label}
            value={field.value}
            onChange={value => field.onChange(value)}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled} />
        )} />
    );
  };

  return TimeFieldController;
}
