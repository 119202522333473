import React from 'react';
import _ from 'lodash';

import {
  genTableComponent,
  IRow
} from 'modules/layer2';

import { chips } from 'modules/layer4';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { LoadingOverlay } from 'modules/layer0';



const __columnIdList = [
  'name',
  'status',
  'type',
  'dueDate'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();



export interface IEmployeeRequirementsTabProps {
  employeeId?: string;
}

export const EmployeeRequirementsTab: React.FC<IEmployeeRequirementsTabProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.employeeId);

  const isLoading =
    queryTuple.loading;



  return (
    <>
      <LoadingOverlay
        active={isLoading} />

      <Table
        columns={[
          {
            id: 'name',
            label: t('name')
          },
          {
            id: 'status',
            label: t('status')
          },
          {
            id: 'type',
            label: t('type')
          },
          {
            id: 'dueDate',
            label: t('requirements:dueDate'),
            sortType: 'number'
          }
        ]}
        rows={_.map(queryTuple.data.requirements, (requirement): IRow<IColumnId> => ({
          id: requirement.id,
          columnId2Value: {
            name: {
              type: 'string',
              label: requirement.name,
              toRoute: props.employeeId ?
                `/admin/requirements/view/${requirement.id}`:
                `/employee/requirements/view/${requirement.id}`
            },
            status: {
              type: 'component',
              label: (
                <chips.RequirementStatusChip
                  valueId={requirement.statusName} />
              ),
              sortableValue: requirement.statusName
            },
            type: {
              type: 'component',
              label: (
                <chips.RequirementTypeChip
                  valueId={requirement.typeName} />
              ),
              sortableValue: requirement.typeName
            },
            dueDate: {
              type: 'string',
              label: t('requirements:dateAtTime', {
                date: requirement.dueDate.format('MM/DD/YYYY'),
                time: requirement.dueDate.format('HH:mm')
              }),
              sortableValue: requirement.dueDate.unix()
            }
          }
        }))}
        initSortOptions={{
          sortByColumnId: 'dueDate',
          sortDirection: 'descending'
        }} />
    </>
  );
};
