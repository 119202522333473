import React from 'react';

import {
  useEmployeeEditMyProfileActivityQuery,
  useEmployeeSaveMyProfileMutation
} from 'generated/graphql';

import * as types from './types';



export interface IData {
  employeeId: string;
  initProfilePictureFileKey: string;
  formState: types.IFormState;
}

export function useQuery() {
  const tuple = useEmployeeEditMyProfileActivityQuery({
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlUser = tuple.data?.loggedInUser;
      const gqlEmployee = gqlUser?.typeSpecificUser.__typename === 'Employee' ?
        gqlUser.typeSpecificUser :
        null;

      const data: IData = {
        employeeId: gqlEmployee?.id ?? '',
        initProfilePictureFileKey: gqlEmployee?.profilePictureFile?.key ?? '',
        formState: {
          profilePicture: undefined,
          firstName: gqlEmployee?.firstName ?? "",
          lastName: gqlEmployee?.lastName ?? "",
          address: gqlEmployee?.address ?? "",
          primaryPhone: gqlEmployee?.primaryPhone ?? "",
          secondaryPhone: gqlEmployee?.secondaryPhone ?? ""
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useSave() {
  const [fnMutate, tuple] = useEmployeeSaveMyProfileMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            profilePictureFile: formState.profilePicture ?
              `${formState.profilePicture.name}/${formState.profilePicture.dataUrl}` :
              formState.profilePicture,
            firstName: formState.firstName.trim(),
            lastName: formState.lastName.trim(),
            address: formState.address.trim(),
            primaryPhone: formState.primaryPhone.trim(),
            secondaryPhone: formState.secondaryPhone.trim()
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
