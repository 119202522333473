import React from 'react';

import {
  BaseActivityTemplate,
  Form,
  LoadingOverlay,
  fields,
  errorUtil,
  AuthCard,
  AuthTitle
} from 'modules';
import {
  Box,
  Button
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { useForm } from 'react-hook-form';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';



interface IFormState {
  challengeAnswer: string;
}

const TextFieldController = fields.genTextFieldControllerComponent<IFormState>();



export const SetUpTotpActivity: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useGetSetupCode();
  const [fnVerifyTotpToken, verifyTotpTokenTuple] = dataService.useVerifyTotpToken();

  const isLoading =
    queryTuple.isLoading ||
    verifyTotpTokenTuple.isLoading;

  const form = useForm<IFormState>({
    defaultValues: {
      challengeAnswer: ""
    }
  });



  async function handleSubmit(formState: IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnVerifyTotpToken(formState.challengeAnswer);
      navigate('/');
    });
  }



  return (
    <BaseActivityTemplate
      mode="auth"
      useAuthBackground>

      <LoadingOverlay
        active={isLoading} />

      <AuthCard.Comp>
        <Form onSubmit={form.handleSubmit(handleSubmit)}>

          <Box mb={1}>
            <AuthTitle.Comp>
              {t('auth:setUpAuthenticatorApp')}
            </AuthTitle.Comp>
          </Box>

          <Box mb={1}>
            <ol>
              <li>
                {t('auth:setUpAuthenticatorAppDescription.step1')}
              </li>

              <li>
                {t('auth:setUpAuthenticatorAppDescription.step2')}
              </li>

              <li>
                {t('auth:setUpAuthenticatorAppDescription.step3')}
              </li>
            </ol>

            <ul>
              <li>
                {t('auth:playStoreApps')}

                <ul>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                      target="_blank"
                      rel="noreferrer">
                      {t('auth:googleAuthenticator')}
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                      target="_blank"
                      rel="noreferrer">
                      {t('auth:microsoftAuthenticator')}
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                {t('auth:appStoreApps')}

                <ul>
                  <li>
                    <a
                      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                      target="_blank"
                      rel="noreferrer">
                      {t('auth:googleAuthenticator')}
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                      target="_blank"
                      rel="noreferrer">
                      {t('auth:microsoftAuthenticator')}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </Box>

          <Box mb={1}>
            {queryTuple.data.setupUri && (
              <QRCode value={queryTuple.data.setupUri} />
            )}
          </Box>

          <Box mb={3}>
            <TextFieldController
              label={t('auth:code')}
              formControl={form.control}
              fieldKey="challengeAnswer"
              isDisabled={isLoading} />
          </Box>

          <Button
            type="submit"
            variant="contained">
            {t('auth:confirm')}
          </Button>

        </Form>
      </AuthCard.Comp>

    </BaseActivityTemplate>
  );
};
