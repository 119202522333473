import React from 'react';

import {
  BaseActivityTemplate,
  paletteColors,
  HeaderLabelWithValue,
  chips,
  PatientDetailsTab,
  PatientVisitsTab
} from 'modules';

import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



interface IParams {
  patientId: string;
}

export const PatientProfileActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.patientId ?? '');

  const [selectedTabId, setSelectedTabId] = React.useState('patientDetails');



  return (
    <BaseActivityTemplate
      mode="employee"
      navigationBarSelectedLinkId="patients"
      isLoading={queryTuple.loading}
      headerOptions={{
        backgroundColor: paletteColors.patients,
        title: queryTuple.data.fullName,
        hasBackButton: true,
        elRight: (
          <HeaderLabelWithValue
            label={t('status')}>
            <chips.PatientStatusChip
              valueId={queryTuple.data.patientStatusName} />
          </HeaderLabelWithValue>
        ),
        tabOptions: {
          tabs: [
            {
              id: 'patientDetails',
              label: t('patients:patientDetails')
            },
            {
              id: 'visits',
              label: t('patients:visits')
            }
          ],
          selectedTabId,
          onSelectTab: setSelectedTabId
        }
      }}>

      {selectedTabId === 'patientDetails' && (
        <PatientDetailsTab
          patientId={params.patientId ?? ''} />
      )}

      {selectedTabId === 'visits' && (
        <PatientVisitsTab
          patientId={params.patientId ?? ''}
          userType="employee" />
      )}

    </BaseActivityTemplate>
  );
};
