import React from 'react';

import {
  Box,
  Modal as BaseModal,
  CircularProgress,
  Avatar as BaseAvatar
} from '@mui/material';

import { protectedFileUtil } from 'modules/layer1';

import { styled } from '@mui/material/styles';



interface IAvatarProps {
  size?: string;
}

const Avatar = styled(BaseAvatar, {
  shouldForwardProp: prop =>
    prop !== 'size'
})<IAvatarProps>(({size}) => ({
  width: size ?? '100px',
  height: size ?? '100px'
}));

interface IContainerBoxProps {
  fillContainer: boolean;
  size?: string;
}

const ContainerBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'fillContainer'
})<IContainerBoxProps>(({fillContainer, size}) => ({
  position: 'relative',
  width: fillContainer ?
    '100%' :
    (size ?? '100px'),
  height: fillContainer ?
    '100%' :
    (size ?? '100px'),
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const ImageImg = styled('img')(() => ({
  width: '100%'
}));

const ProgressContainerBox = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const Modal = styled(BaseModal)(() => ({
  '& .MuiBox-root': {
    outline: 'none'
  }
}));

interface ILightBoxBoxProps {
  useBackgroundColor: boolean;
}

const LightBoxBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'useBackgroundColor'
})<ILightBoxBoxProps>(({useBackgroundColor}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: '90vh',
  backgroundColor: useBackgroundColor ? '#fff' : undefined,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const LightBoxImageImg = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%'
}));



export interface IProtectedImageProps {
  fileKey: string;
  asAvatar?: boolean;
  fillContainer?: boolean;
  disableDefaultContainerSize?: boolean;
  useBackgroundColor?: boolean;
  disableLightBox?: boolean;
  size?: string;
  disableSpinner?: boolean;
}

export const ProtectedImage: React.FC<IProtectedImageProps> = props => {
  const refImg = React.useRef<HTMLImageElement>(null);

  const [isLightBoxOpen, setIsLightBoxOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [avatarSrc, setAvatarSrc] = React.useState('');



  React.useEffect(
    () => {
      (async () => {
        setIsLoading(true);
        const dataUrl = await protectedFileUtil.getDataUrl(props.fileKey);
        setIsLoading(false);

        if (refImg.current) {
          refImg.current.src = dataUrl;
        } else if (props.asAvatar) {
          setAvatarSrc(dataUrl);
        }
      })();
    },
    [
      props.fileKey,
      props.asAvatar,
      refImg
    ]
  );



  const handleSetLightBoxImgRef = React.useCallback(
    (elImg: HTMLImageElement | null) => {
      if (elImg) {
        if (props.asAvatar) {
          elImg.src = avatarSrc;
        } else if (refImg.current) {
          elImg.src = refImg.current.src;
        }
      }
    },
    [
      refImg,
      props.asAvatar,
      avatarSrc
    ]
  );



  return (
    <>
      <ContainerBox
        fillContainer={!!props.fillContainer}
        size={props.size}
        onClick={() => (!isLoading && !props.disableLightBox) ? setIsLightBoxOpen(true) : null}>

        {isLoading && !props.disableSpinner && (
          <ProgressContainerBox>
            <CircularProgress />
          </ProgressContainerBox>
        )}

        {props.asAvatar ? (
          <Avatar
            src={avatarSrc || undefined}
            size={props.size}>
            {props.children}
          </Avatar>

        ) : (
          <ImageImg
            ref={refImg}
            width={props.size}
            height={props.size} />
        )}


      </ContainerBox>

      {isLightBoxOpen && (
        <Modal
          open
          onClose={() => setIsLightBoxOpen(false)}>

          <LightBoxBox
            useBackgroundColor={!!props.useBackgroundColor}
            onClick={() => setIsLightBoxOpen(false)}>
            <LightBoxImageImg
              ref={handleSetLightBoxImgRef} />
          </LightBoxBox>

        </Modal>
      )}
    </>
  );
};
