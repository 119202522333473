import React from 'react';

import {
  useEmployeeProfileProfileTabStatusCheckSectionQuery as useBaseQuery
} from 'generated/graphql';



export interface IData {
  licenseCheck: string;
  fraudCheck: string;
}

export function useStatusCheckSectionQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlEmployee = tuple.data?.employee;
      const data: IData = {
        licenseCheck: gqlEmployee?.licenseCheck ?? "",
        fraudCheck: gqlEmployee?.fraudCheck ?? ""
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
