import React from 'react';

import {
  useUseLatestPatientSignatureModalQuery
} from 'generated/graphql';



export interface IData {
  fileKey: string;
}

export function useQuery(visitId :string) {
  const tuple = useUseLatestPatientSignatureModalQuery({
    variables: {
      visitId
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const data: IData = {
        fileKey: tuple.data?.visit?.episode.patient.latestSignature?.file.key ?? ''
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
