import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';



export const RowBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
}));
