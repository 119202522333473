import React from 'react';

import {
  useRegisterFormSectionQuery as useBaseQuery,
  useResendRegistrationConfirmationCodeMutation as useBaseResendRegistrationConfirmationCodeMutation
} from 'generated/graphql';

import { authService } from 'modules';



export interface IData {
  isValidInvite: boolean;
}

export function useQuery(inviteCode: string) {
  const tuple = useBaseQuery({
    variables: {
      inviteCode
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const data: IData = {
        isValidInvite: !!tuple.data?.inviteByCode?.id
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useRegister() {
  const [authServiceIsLoading, setAuthServiceIsLoading] = React.useState(false);

  async function fnRegister({
    email, phoneNumber, password
  }: {
    email: string;
    phoneNumber: string;
    password: string;
  }) {
    try {
      setAuthServiceIsLoading(true);
      await authService.register({
        email,
        phoneNumber,
        password
      });

    } finally {
      setAuthServiceIsLoading(false);
    }
  }

  return [
    fnRegister,
    {
      loading: authServiceIsLoading
    }
  ] as const;
}



export function useResendConfirmationCodeMutation() {
  const [fnMutate, tuple] = useBaseResendRegistrationConfirmationCodeMutation();



  const fnWrappedMutate = React.useCallback(
    async (email: string) => {
      await fnMutate({
        variables: {
          email
        }
      });
    },
    [fnMutate]
  );



  return [
    fnWrappedMutate,
    tuple
  ] as const;
}
