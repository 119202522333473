import React from 'react';

import {
  Box,
  Button
} from '@mui/material';
import {
  Controller,
  useForm
} from 'react-hook-form';
import {
  authService,
  BaseActivityTemplate,
  LoadingOverlay,
  validationUtil,
  TextField,
  AuthCard,
  AuthTitle,
  AuthDescription
} from 'modules';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';



const FieldContainerBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));



interface IFormData {
  code: string;
  password: string;
  confirmPassword: string;
}

export const ChangePasswordActivity: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);



  const {control, handleSubmit} = useForm<IFormData>({
    defaultValues: {
      code: "",
      password: "",
      confirmPassword: ""
    }
  });



  async function processSubmit(formData: IFormData) {
    if (formData.password !== formData.confirmPassword) {
      alert(t('auth:errors.passwordsDoNotMatch'));
      return;
    }

    try {
      await authService.forgotPasswordSubmit(formData.code, formData.password);
      navigate('/auth/login');

    } catch (err: any) {
      alert(err?.message ?? t('errors.unknown'));

    } finally {
      setIsLoading(false);
    }
  }



  return (
    <BaseActivityTemplate
      mode="auth"
      useAuthBackground
      onlyAllowLoggedOutUsers>

      <LoadingOverlay
        active={isLoading} />

      <AuthCard.Comp>
        <form
          onSubmit={handleSubmit(processSubmit)}
          autoComplete="off">

          <Box mb={1}>
            <AuthTitle.Comp>
              {t('auth:changePassword')}
            </AuthTitle.Comp>
          </Box>

          <Box mb={1}>
            <AuthDescription.Comp>
              {t('auth:changePasswordDescription')}
            </AuthDescription.Comp>
          </Box>

          <FieldContainerBox>
            <Controller
              name="code"
              control={control}
              rules={{required: validationUtil.rules.required}}
              render={({field, formState}) => (
                <TextField
                  label={t('auth:code')}
                  value={field.value}
                  onChange={field.onChange}
                  errorMessage={formState.errors.code?.message} />
              )} />
          </FieldContainerBox>

          <FieldContainerBox>
            <Controller
              name="password"
              control={control}
              rules={{
                required: validationUtil.rules.required,
                validate: validationUtil.isValidPassword
              }}
              render={({field, formState}) => (
                <TextField
                  label={t('auth:password')}
                  type="password"
                  value={field.value}
                  onChange={field.onChange}
                  errorMessage={formState.errors.password?.message} />
              )} />
          </FieldContainerBox>

          <FieldContainerBox>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: validationUtil.rules.required
              }}
              render={({field, formState}) => (
                <TextField
                  label={t('auth:confirmPassword')}
                  type="password"
                  value={field.value}
                  onChange={field.onChange}
                  errorMessage={formState.errors.confirmPassword?.message} />
              )} />
          </FieldContainerBox>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth>
            {t('auth:confirm')}
          </Button>

        </form>
      </AuthCard.Comp>

    </BaseActivityTemplate>
  );
};
