import React from 'react';

import {
  BaseActivityTemplate,
  appStateUtil
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import * as types from './types';
import * as RegisterFormSection from './RegisterFormSection/RegisterFormSection';
import * as ConfirmRegisterCodeSection from './ConfirmRegisterCodeSection/ConfirmRegisterCodeSection';



interface IParams {
  inviteCode: string;
}

export const RegisterActivity: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<Partial<IParams>>();

  const [state, setState] = React.useState<types.IState>('register');



  function handleChangeState(newState: types.IState) {
    if (newState === 'done') {
      appStateUtil.setInviteCode(params.inviteCode ?? '');
      navigate('/auth/login', {replace: true});

    } else {
      setState(newState);
    }
  }



  return (
    <BaseActivityTemplate
      mode="auth"
      useAuthBackground
      onlyAllowLoggedOutUsers
      redirectLoggedInUserToRoute={`/auth/confirmNewUser/${params.inviteCode}`}>

      {state === 'register' && (
        <RegisterFormSection.Comp
          inviteCode={params.inviteCode ?? ''}
          onChangeState={handleChangeState} />
      )}

      {state === 'confirmRegisterCode' && (
        <ConfirmRegisterCodeSection.Comp
          inviteCode={params.inviteCode ?? ''}
          onChangeState={handleChangeState} />
      )}

    </BaseActivityTemplate>
  );
};
