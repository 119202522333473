import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  CellBox,
  chips,
  errorUtil,
  FileInputField,
  Form,
  HeaderLabelWithValue,
  IFileData,
  paletteColors,
  RowBox
} from 'modules';
import {
  Box,
  Button,
  IconButton
} from '@mui/material';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  useFieldArray,
  useForm
} from 'react-hook-form';

import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import * as types from './types';
import * as uuid from 'uuid';



interface IParams {
  requirementId: string;
}

export const EditRequirementActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.requirementId ?? '');
  const [fnSubmit, submitMutationTuple] = dataService.useSubmit(params.requirementId ?? '');

  const form = useForm<types.IFormState>({
    defaultValues: {
      files: []
    }
  });

  const files = useFieldArray({
    control: form.control,
    name: 'files',
    keyName: '_key'
  });

  const isLoading =
    queryTuple.loading ||
    submitMutationTuple.loading;



  function handleSelectFile(fileData: IFileData) {
    files.append({
      _key: uuid.v4(),
      data: fileData
    });
  }



  function handleDeleteFile(index: number) {
    files.remove(index);
  }



  async function handleSubmit(formState: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnSubmit(formState);
      navigate(-1);
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="employee"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.requirements,
          title: queryTuple.data.displayName,
          hasBackButton: true,
          elRight: !queryTuple.loading ? (
            <RowBox>
              <Box ml={3}>
                <HeaderLabelWithValue
                  label={t('status')}>
                  <chips.RequirementStatusChip
                    valueId={queryTuple.data.statusName} />
                </HeaderLabelWithValue>
              </Box>

              <Box ml={3}>
                <HeaderLabelWithValue
                  label={t('type')}>
                  <chips.RequirementTypeChip
                    valueId={queryTuple.data.typeName} />
                </HeaderLabelWithValue>
              </Box>
            </RowBox>
          ) : undefined
        }}
        footerOptions={{
          saveButtonLabel: t('requirements:submitAssignment'),
          onClickCancel: () => navigate(-1)
        }}>

        <Box mb={3}>
          {queryTuple.data.description}
        </Box>

        <Box mb={3}>
          <FileInputField
            onSelectFile={handleSelectFile}>
            {handleClick => (
              <Button
                variant="contained"
                onClick={handleClick}>
                {t('uploadFile')}
              </Button>
            )}
          </FileInputField>
        </Box>

        <Box mb={3}>
          {_.map(files.fields, (field, index) => (
            <RowBox key={field._key}>
              <IconButton
                size="small"
                onClick={() => handleDeleteFile(index)}>
                <Delete />
              </IconButton>

              <CellBox>
                {field.data.name}
              </CellBox>
            </RowBox>
          ))}
        </Box>

      </BaseActivityTemplate>
    </Form>
  );
};
