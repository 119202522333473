import React from 'react';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';



const ContainerBox = styled(Box)(({theme}) => ({
  marginTop: theme.spacing(1),
  flex: '1 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));



export const Comp: React.FC = props => {
  return (
    <ContainerBox>
      {props.children}
    </ContainerBox>
  );
};
