import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  SkeletonRows,
  ValueWithLabel,
  dateUtil
} from 'modules';
import {
  Divider,
  Grid
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { EpisodeDataValueList } from './EpisodeDataValueList/EpisodeDataValueList';
import { MedicationList } from './MedicationList/MedicationList';



export interface IDetailsTabProps {
  episodeId: string;
}

export const DetailsTab: React.FC<IDetailsTabProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.episodeId);



  return queryTuple.loading ? (
    <SkeletonRows numRows={6} />

  ) : (
    <Grid container>

      <Grid
        item
        md={4}>

        <Grid container>
          <ValueWithLabel
            label={t('adminEpisodes:start')}
            value={queryTuple.data.startTimestamp || t('noValue')} />

          <ValueWithLabel
            label={t('adminEpisodes:end')}
            value={queryTuple.data.endTimestamp || t('noValue')} />

          <ValueWithLabel
            label={t('adminEpisodes:transferred')}
            value={queryTuple.data.transferTimestamp || t('noValue')} />

          <ValueWithLabel
            label={t('timeZone')}
            value={queryTuple.data.timeZone || t('noValue')} />
        </Grid>

      </Grid>

      <Grid
        item
        md={4}>

        <Grid container>
          <ValueWithLabel
            label={t('adminEpisodes:primaryProvider')}
            value={queryTuple.data.primaryProvider?.fullName ?? t('noValue')}
            toRoute={queryTuple.data.primaryProvider?.id ?
              `/admin/providers/profile/${queryTuple.data.primaryProvider.id}` :
              undefined} />

          <ValueWithLabel
            label={t('adminEpisodes:primaryDiagnosis')}
            value={queryTuple.data.primaryDiagnosis ?
              `[${queryTuple.data.primaryDiagnosis.icd10Code}] ${queryTuple.data.primaryDiagnosis.displayName}` :
              t('noValue')} />

          <ValueWithLabel
            label={t('adminEpisodes:otherDiagnoses')}
            value={queryTuple.data.otherDiagnoses.length === 0 ? t('noValue') : undefined}>
            {queryTuple.data.otherDiagnoses.length > 0 ? (
              <ul>
                {_.map(queryTuple.data.otherDiagnoses, diagnosis => (
                  <li key={diagnosis.id}>
                    [{diagnosis.icd10Code}] {diagnosis.displayName}
                  </li>
                ))}
              </ul>

            ) : (
              <div />
            )}
          </ValueWithLabel>

          <ValueWithLabel
            label={t('adminEpisodes:surgicalProcedures.surgicalProcedures')}
            value={queryTuple.data.surgicalProcedures.length === 0 ? t('noValue') : undefined}>
            {queryTuple.data.surgicalProcedures.length > 0 ? (
              <ul>
                {_.map(queryTuple.data.surgicalProcedures, procedure => (
                  <li key={procedure.id}>
                    {procedure.code ? `[${procedure.code}] ` : ""}
                    {procedure.displayName}
                    {dateUtil.dateWithTimeZone2Date(procedure.procedureDateWithTz) ?
                      ` (${moment(dateUtil.dateWithTimeZone2Date(procedure.procedureDateWithTz)).format('YYYY-MM-DD')} ${dateUtil.dateWithTimeZone2TimeZone(procedure.procedureDateWithTz)})` :
                      ""}
                  </li>
                ))}
              </ul>

            ) : (
              <div />
            )}
          </ValueWithLabel>
        </Grid>

      </Grid>

      <Grid
        item
        md={4}>

        <Grid container>
          <ValueWithLabel
            label={t('adminEpisodes:prognosis')}
            value={queryTuple.data.prognosis ?
              t(`adminEpisodes:prognosisValues.${queryTuple.data.prognosis}`) :
              t('noValue')} />

          <ValueWithLabel
            label={t('adminEpisodes:diet')}
            value={queryTuple.data.diet || t('noValue')} />

          <ValueWithLabel
            label={t('adminEpisodes:exerciseActivity')}
            value={queryTuple.data.exerciseActivity || t('noValue')} />

          {queryTuple.data.statusName === 'inactive' && (
            <ValueWithLabel
              label={t('adminEpisodes:inactiveNote')}
              value={queryTuple.data.inactiveNote || t('noValue')} />
          )}
        </Grid>

      </Grid>

      <Grid
        item
        md={12}>
        <Divider />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:riskAssessment')}
          labelTranslationPrefix="adminEpisodes:riskAssessmentValues"
          values={queryTuple.data.riskAssessment} />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:mentalStatus')}
          labelTranslationPrefix="adminEpisodes:mentalStatusValues"
          values={queryTuple.data.mentalStatus} />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:neurological')}
          labelTranslationPrefix="adminEpisodes:neurologicalValues"
          values={queryTuple.data.neurological} />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:psychosocial')}
          labelTranslationPrefix="adminEpisodes:psychosocialValues"
          values={queryTuple.data.psychosocial} />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:dme')}
          labelTranslationPrefix="adminEpisodes:dmeValues"
          values={queryTuple.data.dme} />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:supplies')}
          labelTranslationPrefix="adminEpisodes:suppliesValues"
          values={queryTuple.data.supplies} />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:safetyMeasures')}
          labelTranslationPrefix="adminEpisodes:safetyMeasuresValues"
          values={queryTuple.data.safetyMeasures} />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:nutritionRequirements')}
          labelTranslationPrefix="adminEpisodes:nutritionRequirementsValues"
          values={queryTuple.data.nutritionRequirements} />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:functionalLimitations')}
          labelTranslationPrefix="adminEpisodes:functionalLimitationsValues"
          values={queryTuple.data.functionalLimitations} />
      </Grid>

      <Grid
        item
        md={4}>
        <EpisodeDataValueList
          label={t('adminEpisodes:activitiesPermitted')}
          labelTranslationPrefix="adminEpisodes:activitiesPermittedValues"
          values={queryTuple.data.activitiesPermitted} />
      </Grid>

      <Grid
        item
        md={4}>
        <MedicationList
          medications={queryTuple.data.medications} />
      </Grid>

    </Grid>
  );
};
