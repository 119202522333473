import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  genTableComponent,
  IRow,
  paletteColors,
  chips,
  ImportCsvModal
} from 'modules';
import {
  Box,
  Button,
  TextField,
  Checkbox
} from '@mui/material';

import { Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as dataService from './dataService';
import { columnDescriptions } from './columnDescriptions';
import { styled } from '@mui/material/styles';



const FilterRowBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));



const __columnIdList = [
  'firstName',
  'lastName',
  'emrNumber',
  'patientStatus',
  'socDate',
  'branch'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();



export const PatientsActivity: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.usePatientsActivityQuery();
  const [fnImport, importMutationTuple] = dataService.useImportPatientsMutation();

  const [filterText, setFilterText] = React.useState("");
  const [onlyShowAdmitted, setOnlyShowAdmitted] = React.useState(false);
  const [isImportCsvModalOpen, setIsImportCsvModalOpen] = React.useState(false);

  const isLoading =
    queryTuple.loading ||
    importMutationTuple.loading;



  const filteredPatients = React.useMemo(
    () => {
      const trFilterText = filterText.trim().toLowerCase();
      const filteredByName = trFilterText ?
        _.filter(queryTuple.data.patients, patient =>
          `${patient.firstName} ${patient.lastName}`.toLowerCase().indexOf(trFilterText) >= 0) :
        queryTuple.data.patients;

      return onlyShowAdmitted ?
        _.filter(filteredByName, patient =>
          patient.patientStatus.name === 'admitted') :
        filteredByName;
    },
    [
      filterText,
      onlyShowAdmitted,
      queryTuple.data.patients
    ]
  );



  function handleAcceptImportCsvModal() {
    queryTuple.refetch();
    setIsImportCsvModalOpen(false);
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        navigationBarSelectedLinkId="patients"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.patients,
          title: t('patients:patients'),
          elRightButtons: [
            (
              <Button
                key="import"
                variant="outlined"
                onClick={() => setIsImportCsvModalOpen(true)}>
                {t('patients:importPatients')}
              </Button>
            ),
            (
              <Button
                key="create"
                variant="contained"
                onClick={() => navigate('/admin/patients/create')}>
                {t('patients:addNewPatient')}
              </Button>
            )
          ]
        }}>

        <FilterRowBox>
          <Box width="312px">
            <TextField
              label={t('patients:findAPatient')}
              value={filterText}
              onChange={event => setFilterText(event.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <Search color="inherit" />
                )
              }} />
          </Box>

          <Box>
            <Checkbox
              checked={onlyShowAdmitted}
              onChange={event => setOnlyShowAdmitted(event.target.checked)} />

            <span>
              {t('patients:showOnlyAdmittedPatients')}
            </span>
          </Box>
        </FilterRowBox>

        <Table
          columns={[
            {
              id: 'firstName',
              label: t('firstName')
            },
            {
              id: 'lastName',
              label: t('lastName')
            },
            {
              id: 'emrNumber',
              label: t('patients:emrNumber')
            },
            {
              id: 'patientStatus',
              label: t('patients:patientStatus')
            },
            {
              id: 'socDate',
              label: t('patients:socDate')
            },
            {
              id: 'branch',
              label: t('patients:branch')
            }
          ]}
          rows={_.map(filteredPatients, (patient): IRow<IColumnId> => ({
            id: patient.id,
            columnId2Value: {
              firstName: {
                type: 'string',
                label: patient.firstName,
                toRoute: `/admin/patients/profile/${patient.id}`
              },
              lastName: {
                type: 'string',
                label: patient.lastName,
                toRoute: `/admin/patients/profile/${patient.id}`
              },
              emrNumber: {
                type: 'string',
                label: patient.emrNumber
              },
              patientStatus: {
                type: 'component',
                label: (
                  <chips.PatientStatusChip
                    valueId={patient.patientStatus.name} />
                ),
                sortableValue: patient.patientStatus.displayName.toLowerCase()
              },
              socDate: {
                type: 'string',
                label: patient.socDate
              },
              branch: {
                type: 'string',
                label: patient.branch
              }
            }
          }))}
          initSortOptions={{
            sortByColumnId: 'lastName',
            sortDirection: 'ascending'
          }} />

      </BaseActivityTemplate>

      {isImportCsvModalOpen && (
        <ImportCsvModal
          isOpen
          columnDescriptions={columnDescriptions}
          fnImport={fnImport}
          isLoading={importMutationTuple.loading}
          onAccept={handleAcceptImportCsvModal}
          onCancel={() => setIsImportCsvModalOpen(false)} />
      )}
    </>
  );
};
