import React from 'react';

import {
  Box,
  Button as BaseButton,
  Typography
} from '@mui/material';

import { ResponsiveImage } from 'modules/layer2';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import * as types from '../../../types';



interface IButtonProps {
  isSelected: boolean;
}

const Button = styled(BaseButton, {
  shouldForwardProp: prop =>
    prop !== 'isSelected'
})<IButtonProps>(({theme, isSelected}) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  ...(isSelected && {
    backgroundColor: '#F4F9FD'
  })
}));

interface ILabelTypographyProps {
  isSelected: boolean;
  hasSolidLabel: boolean;
}

const LabelTypography = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isSelected' &&
    prop !== 'hasSolidLabel'
})<ILabelTypographyProps>(({theme, isSelected, hasSolidLabel}) => ({
  fontWeight: 400,
  fontSize: '14px',
  color: theme.palette.secondary.main,
  textTransform: 'none',

  ...(isSelected && !hasSolidLabel && {
    color: theme.palette.primary.main
  }),

  ...(hasSolidLabel && {
    color: '#000'
  })
}));



export const LinkButton: React.FC<types.ILinkButtonProps> = props => {
  const navigate = useNavigate();



  function handleClick() {
    navigate(props.toRoute);
  }



  return (
    <Button
      isSelected={props.isSelected}
      onClick={handleClick}>

      {props.elIcon ?? (
        <ResponsiveImage
          svgUrl={props.isSelected ?
            props.selectedIconUrl :
            props.iconUrl} />
      )}

      {props.label && (
        <Box ml={1}>
          <LabelTypography
            isSelected={props.isSelected}
            hasSolidLabel={!!props.hasSolidLabel}>
            {props.label}
          </LabelTypography>
        </Box>
      )}

    </Button>
  );
};
