import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  paletteColors,
  SectionHeaderBox
} from 'modules';
import {
  Button,
  Typography
} from '@mui/material';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { Create } from '@mui/icons-material';
import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';



interface IParams {
  taskSetTemplateId: string;
}

export const ViewTaskSetTemplateActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.taskSetTemplateId ?? '');

  const isLoading =
    queryTuple.loading;



  return (
    <BaseActivityTemplate
      mode="admin"
      isLoading={isLoading}
      requireOneOfPermissionCategories={[
        'fullAdmin'
      ]}
      headerOptions={{
        backgroundColor: paletteColors.taskSetTemplates,
        title: queryTuple.data.displayName,
        elRightButtons: [
          (
            <Button
              key="editData"
              variant="contained"
              onClick={() => navigate(`/admin/taskSetTemplates/edit/${params.taskSetTemplateId}`)}
              startIcon={(
                <Create />
              )}>
              {t('editData')}
            </Button>
          )
        ],
        hasBackButton: true
      }}>

      <SectionHeaderBox>
        <Typography variant="h2">
          {t('visits:tasks')}
        </Typography>
      </SectionHeaderBox>

      <ul>
        {_.map(queryTuple.data.tasks, task => (
          <li key={task.id}>
            {task.title}

            {task.description && (
              <ul>
                <li>
                  {task.description}
                </li>
              </ul>
            )}
          </li>
        ))}
      </ul>

    </BaseActivityTemplate>
  );
};
