import React from 'react';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import {
  Autocomplete,
  TextField
} from '@mui/material';

import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';

import { validationUtil } from 'modules/layer0';
import { FieldContainer } from 'modules/layer1';

export type {
  ITask,
  ITaskSetTemplate
} from './dataService';





export interface ITaskSetTemplateSelectFieldProps {
  label?: string;
  value: dataService.ITaskSetTemplate | null;
  onChange: (value: dataService.ITaskSetTemplate | null) => void;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const TaskSetTemplateSelectField: React.FC<ITaskSetTemplateSelectFieldProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery();



  return (
    <FieldContainer.Comp>
      <Autocomplete
        options={queryTuple.data.taskSetTemplates}
        value={props.value}
        onChange={(_event, value) => props.onChange(value)}
        getOptionLabel={value => value?.id ?
          value.displayName :
          ""}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        disabled={props.isDisabled || queryTuple.loading}
        loading={queryTuple.loading}
        loadingText={t('loading')}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label ?? t('taskSetTemplate')}
            error={!!props.errorMessage}
            helperText={props.errorMessage} />
        )} />
    </FieldContainer.Comp>
  );
};



export interface ITaskSetTemplateSelectFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label?: string;
  isDisabled?: boolean;
}

export function genTaskSetTemplateSelectFieldControllerComponent<TFormState extends FieldValues>() {
  const TaskSetTemplateSelectFieldController: React.FC<ITaskSetTemplateSelectFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        control={props.formControl}
        name={props.fieldKey}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <TaskSetTemplateSelectField
            label={props.label}
            value={field.value}
            onChange={field.onChange}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled} />
        )} />
    );
  };

  return TaskSetTemplateSelectFieldController;
}
