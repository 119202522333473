import React from 'react';

import {
  usePatientProfileActivityPatientDetailsTabAddressSectionQuery as useBaseQuery
} from 'generated/graphql';



export interface IData {
  address: string;
  locationNote: string;
}

export function useQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlPatient = tuple.data?.patient;
      const data: IData = {
        address: gqlPatient?.address ?? "",
        locationNote: gqlPatient?.locationNote ?? ""
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
