import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import { messengerService } from 'modules';
import * as types from './types';



export interface IMessage {
  id: string;
  timestamp: moment.Moment;
  message: string;
}

export interface IThread {
  id: string;
  displayName: string;
  users: types.IUser[];
  lastMessage: IMessage | null;
  numUnreadMessages: number;
}

export interface IData {
  threads: IThread[];
}

export function useQuery() {
  const [threadsById, setThreadsById] = React.useState<Record<string, messengerService.IThread>>({});



  React.useEffect(
    () => {
      const sub = messengerService.getObservable()
        .subscribe(messageData => {
          setThreadsById(messageData.threadsById);
        });

      return () => sub.unsubscribe();
    },
    [setThreadsById]
  );



  return React.useMemo(
    () => {
      const threads = _.map(_.values(threadsById), (gqlThread): IThread => {
        const id = gqlThread.id;
        const gqlLastMessage = _.last(threadsById[id]?.messages ?? []);

        return {
          id,
          displayName: gqlThread.displayName,
          users: _.map(
            _.filter(gqlThread.users, user => !user.isSelf),
            (user): types.IUser => ({
              id: user.id,
              name: user.fullName,
              profilePictureFileKey: user.profilePictureFileKey
            })),
          lastMessage: gqlLastMessage ? {
            id: gqlLastMessage.id,
            timestamp: moment(gqlLastMessage.timestamp, moment.ISO_8601),
            message: gqlLastMessage.message
          } : null,
          numUnreadMessages: threadsById[id]?.numUnreadMessages ?? 0
        };
      });

      const data: IData = {
        threads: _.sortBy(threads, thread => -(thread.lastMessage?.timestamp.unix() ?? 0))
      };

      return {
        data
      };
    },
    [threadsById]
  );
}
