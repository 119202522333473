import _ from 'lodash';

import { ValidationRule } from 'react-hook-form';
import i18n from 'i18next';



export const reUuid = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
export const rePhoneNumber = /^\+[0-9]{11,15}$/;
export const reNonNegativeUsdAmount = /^\s*[0-9]{0,10}\.[0-9]{2}\s*$/;
export const reNonNegativeUsdAmountOrEmpty = /^(?:\s*[0-9]{0,10}\.[0-9]{2}\s*)$|^(?:\s*)$/;
export const reTemperature = /^[0-9]{2,3}(?:\.[0-9]{1,2})?$/;
export const reUrl = /^(?:(?:http)|(?:https)):\/\//;
export const reSsn = /^[0-9]{9}$/;



function __generateRules() {
  return {
    phoneNumber: {
      value: rePhoneNumber,
      message: i18n.t('errors.phoneNumberFormat')
    } as ValidationRule<RegExp>,

    nonNegativeUsdAmount: {
      value: reNonNegativeUsdAmount,
      message: i18n.t('errors.nonNegativeUsdAmountFormat')
    } as ValidationRule<RegExp>,

    nonNegativeUsdAmountOrEmpty: {
      value: reNonNegativeUsdAmountOrEmpty,
      message: i18n.t('errors.nonNegativeUsdAmountFormat')
    } as ValidationRule<RegExp>,

    url: {
      value: reUrl,
      message: i18n.t('errors.urlFormat')
    } as ValidationRule<RegExp>,

    ssn: {
      value: reSsn,
      message: i18n.t('errors.ssnFormat')
    } as ValidationRule<RegExp>,

    genIntegerRule: ({
      min, max
    } : {
      min?: number;
      max?: number;
    }) => {
      return (value: string) => {
        const iValue = +value;
        if (_.isNaN(iValue)) {
          return i18n.t('errors.invalidNumber');
        } else if (!_.isInteger(iValue)) {
          return i18n.t('errors.wholeNumberRequired');
        } else if (!_.isUndefined(min) && iValue < min) {
          return i18n.t('errors.numberLessThanMinimum', {minimum: min});
        } else if (!_.isUndefined(max) && iValue > max) {
          return i18n.t('errors.numberGreaterThanMaximum', {maximum: max});
        } else {
          return true;
        }
      };
    },

    genNumberRule: ({
      min, max
    } : {
      min?: number;
      max?: number;
    }) => {
      return (value: string) => {
        const iValue = +value;
        if (_.isNaN(iValue)) {
          return i18n.t('errors.invalidNumber');
        } else if (!_.isUndefined(min) && iValue < min) {
          return i18n.t('errors.numberLessThanMinimum', {minimum: min});
        } else if (!_.isUndefined(max) && iValue > max) {
          return i18n.t('errors.numberGreaterThanMaximum', {maximum: max});
        } else {
          return true;
        }
      };
    },

    temperatureRule: {
      value: reTemperature,
      message: i18n.t('errors.invalidTemperature')
    } as ValidationRule<RegExp>,

    required: {
      value: true,
      message: i18n.t('errors.required')
    } as ValidationRule<boolean>,

    validDate: {
      validate: (value: Date | null) => {
        if (value && _.isNaN(value.getTime())) {
          return i18n.t('errors.invalidDate');
        }
        return true;
      }
    }
  };
}



export let rules = __generateRules();



/**
 * Must be called after `i18n` is initialized.
 */
export function init() {
  rules = __generateRules();
}



export function isValidPassword(password: string) {
  if (password.length < 12) {
    return i18n.t('auth:errors.passwordLengthTooShort');
  }

  if (!password.match(/[a-z]/)) {
    return i18n.t('auth:errors.passwordLowercaseRequired');
  }

  if (!password.match(/[A-Z]/)) {
    return i18n.t('auth:errors.passwordUppercaseRequired');
  }

  if (!password.match(/[0-9]/)) {
    return i18n.t('auth:errors.passwordNumeralRequired');
  }

  if (!password.match(/[!@#$%^&*()\-_+={}\[\]:;'"<>,.\\/?|`~]/)) {
    return i18n.t('auth:errors.passwordSymbolRequired');
  }

  return true;
}
