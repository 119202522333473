import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';

import { SectionHeaderBox } from 'modules/layer1';
import { SkeletonRows } from 'modules/layer2';
import { DataValueList } from 'modules/layer4';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



export interface IVisitAfterVisitDataSectionProps {
  afterVisitDataId: string;
}

export const VisitAfterVisitDataSection: React.FC<IVisitAfterVisitDataSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.afterVisitDataId);



  return queryTuple.loading ? (
    <SkeletonRows numRows={12} />

  ) : (
    <Box mb={3}>
      <SectionHeaderBox>
        <Typography variant="h2">
          {t('visits:afterVisitData')}
        </Typography>
      </SectionHeaderBox>

      <Grid container>

        <Grid
          item
          md={4}>

          <Grid container>
            <DataValueList.Comp
              label={t('visits:medications')}
              labelTranslationPrefix="visits:medicationValues"
              values={queryTuple.data.medications} />

            <DataValueList.Comp
              label={t('visits:allMedsTaken')}
              labelTranslationPrefix="visits:allMedsTakenValues"
              values={queryTuple.data.allMedsTaken} />

            <DataValueList.Comp
              label={t('visits:justificationOfServices')}
              labelTranslationPrefix="visits:justificationOfServicesValues"
              values={queryTuple.data.justificationOfServices} />
          </Grid>

        </Grid>

      </Grid>
    </Box>
  );
};
