import React from 'react';
import _ from 'lodash';

import {
  usePatientProfileActivityQuery as useBaseQuery
} from 'generated/graphql';

import { IPatientStatusName } from 'modules/layer3/selectFields';



export interface IData {
  fullName: string;
  showSsn: boolean;
  patientStatusName: IPatientStatusName;
}

export function usePatientProfileActivityQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlPatient = tuple.data?.patient;
      const gqlCategories = tuple.data?.loggedInUser?.typeSpecificUser.permissionCategories ?? [];
      const categoryNames = _.map(gqlCategories, gqlCategory => gqlCategory.name);
      const showSsn = _.includes(categoryNames, 'fullAdmin');

      const data: IData = {
        fullName: `${gqlPatient?.firstName ?? ""} ${gqlPatient?.lastName ?? ""}`,
        showSsn,
        patientStatusName: gqlPatient?.patientStatus.name ?? 'pending'
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
