import React from 'react';

import {
  LoadingOverlay,
  AuthCard,
  AuthTitle,
  AuthDescription,
  appStateUtil,
  BaseActivityTemplate
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';



interface IParams {
  inviteCode?: string;
}

export const PostRegisterMakeApiUserActivity: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams<Partial<IParams>>();

  const refInviteCode = React.useRef(params.inviteCode || appStateUtil.getAppState().inviteCode);

  const queryTuple = dataService.useProcess(refInviteCode.current);

  const noInviteCode =
    !queryTuple.loading &&
    !queryTuple.data.hasApiUser &&
    !refInviteCode.current;



  React.useEffect(
    () => {
      if (!queryTuple.loading && queryTuple.data.hasApiUser) {
        navigate('/', {replace: true});
      }
    },
    [
      queryTuple.loading,
      queryTuple.data.hasApiUser
    ]
  );



  return (
    <BaseActivityTemplate
      mode="auth"
      useAuthBackground>

      <LoadingOverlay
        active={queryTuple.loading} />

      {noInviteCode && (
        <AuthCard.Comp>
          <AuthTitle.Comp>
            {t('error')}
          </AuthTitle.Comp>

          <AuthDescription.Comp>
            {t('auth:errors.noInviteCode')}
          </AuthDescription.Comp>
        </AuthCard.Comp>
      )}

    </BaseActivityTemplate>
  );
};
