import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  Form,
  paletteColors,
  fields,
  IRadioControllerOption,
  ConfirmationModal,
  errorUtil
} from 'modules';
import {
  Box,
  Button,
  Typography
} from '@mui/material';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { useForm } from 'react-hook-form';
import * as dataService from './dataService';
import * as types from './types';
import { useTranslation } from 'react-i18next';



const RadioController = fields.genRadioControllerComponent<types.IFormState>();



interface IParams {
  assignedRequirementId: string;
}

export const TakeQuizActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.assignedRequirementId ?? '');
  const [fnSubmitQuizMutation, submitQuizMutationTuple] = dataService.useSubmitQuizMutation(params.assignedRequirementId ?? '');

  const isLoading =
    queryTuple.loading ||
    submitQuizMutationTuple.loading;

  const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);

  const form = useForm<types.IFormState>({
    defaultValues: {
      questions: []
    }
  });

  const watchForm = form.watch();



  const currentQuestion = React.useMemo(
    () => queryTuple.data.questions[currentQuestionIndex],
    [
      currentQuestionIndex,
      queryTuple.data.questionsById
    ]
  );



  const areAllQuestionsAnswered = React.useMemo(
    () => _.every(watchForm.questions, question => !!question.selectedAnswerId),
    [watchForm]
  );



  React.useEffect(
    () => {
      if (queryTuple.data.statusName === 'submitted' || queryTuple.data.statusName === 'completed') {
        navigate(-1);
      }
    },
    [
      queryTuple.data.statusName,
      history
    ]
  );



  function handleSubmit() {
    setIsConfirmModalOpen(true);
  }



  async function handleConfirmSubmit() {
    setIsConfirmModalOpen(false);
    await errorUtil.errorWrap(async () => {
      await fnSubmitQuizMutation(watchForm);
      navigate(-1);
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="employee"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.requirements,
          title: queryTuple.data.displayName,
          hasBackButton: true,

          elRightButtons: !queryTuple.loading ?
            _.compact([
              currentQuestionIndex > 0 ? (
                <Button
                  key="previousQuestion"
                  variant="contained"
                  onClick={() => setCurrentQuestionIndex(previous => previous - 1)}>
                  {t('requirements:quiz.previousQuestion')}
                </Button>
              ) : null,

              currentQuestionIndex < queryTuple.data.questions.length - 1 ? (
                <Button
                  key="nextQuestion"
                  variant="contained"
                  onClick={() => setCurrentQuestionIndex(previous => previous + 1)}>
                  {t('requirements:quiz.nextQuestion')}
                </Button>

              ) : (
                <Button
                  key="submitQuiz"
                  type="submit"
                  variant="contained">
                  {t('requirements:quiz.submitQuiz')}
                </Button>
              )
            ]) :
            []
        }}>

        {currentQuestion && (
          <Box>
            <Box mb={3}>
              <Typography>
                {currentQuestion.description}
              </Typography>
            </Box>

            <RadioController
              key={currentQuestionIndex}
              formControl={form.control}
              fieldKey={`questions.${currentQuestionIndex}.selectedAnswerId`}
              options={_.map(queryTuple.data.questions[currentQuestionIndex].answerOptions, (answerOption): IRadioControllerOption => ({
                id: answerOption.id,
                label: answerOption.description
              }))} />
          </Box>
        )}

        {isConfirmModalOpen && (
          <ConfirmationModal
            isOpen
            title={t('areYouSure')}
            message={areAllQuestionsAnswered ?
              t('requirements:quiz.confirmSubmission') :
              t('requirements:quiz.confirmIncompleteSubmission')}
            onAccept={handleConfirmSubmit}
            onCancel={() => setIsConfirmModalOpen(false)} />
        )}

      </BaseActivityTemplate>
    </Form>
  );
};
