import React from 'react';

import {
  Control,
  Controller,
  FieldPath,
  FieldValues
} from 'react-hook-form';
import {
  Autocomplete,
  TextField
} from '@mui/material';

import * as dataService from './dataService';

import { validationUtil } from 'modules/layer0';
import { FieldContainer } from 'modules/layer1';



export interface IProviderSelectFieldProps {
  label?: string;
  value: string;
  onChange: (value: string) => void;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const ProviderSelectField: React.FC<IProviderSelectFieldProps> = props => {
  const queryTuple = dataService.useProviderSelectFieldQuery();

  const value = React.useMemo(
    () => queryTuple.data.providersById[props.value] ?? {
      id: '',
      formattedName: ""
    },
    [
      queryTuple.data.providersById,
      props.value
    ]
  );



  return (
    <FieldContainer.Comp>
      <Autocomplete
        options={queryTuple.data.providers}
        value={value}
        onChange={(_event, value) => props.onChange(value?.id ?? '')}
        getOptionLabel={provider => provider.formattedName}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={queryTuple.loading || props.isDisabled}
        renderInput={params => (
          <TextField
            {...params}
            label={props.label}
            error={!!props.errorMessage}
            helperText={props.errorMessage} />
        )} />
    </FieldContainer.Comp>
  );
};



export interface IProviderSelectFieldControllerProps<TFormState extends FieldValues> {
  formControl: Control<TFormState>;
  fieldKey: FieldPath<TFormState>;
  isRequired?: boolean;
  label: string;
  isDisabled?: boolean;
}

export function genProviderSelectFieldControllerComponent<TFormState extends FieldValues>() {
  const ProviderSelectFieldController: React.FC<IProviderSelectFieldControllerProps<TFormState>> = props => {
    return (
      <Controller
        control={props.formControl}
        name={props.fieldKey}
        rules={{
          required: props.isRequired ?
            validationUtil.rules.required :
            undefined
        }}
        render={({field, fieldState}) => (
          <ProviderSelectField
            label={props.label}
            value={field.value}
            onChange={field.onChange}
            errorMessage={(fieldState.error as any)?.message}
            isDisabled={props.isDisabled} />
        )} />
    );
  };

  return ProviderSelectFieldController;
}
