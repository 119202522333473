import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  Box,
  Button,
  Typography
} from '@mui/material';
import {
  DetailedListItem,
  LoadingOverlay,
  chips,
  dateUtil
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { styled } from '@mui/material/styles';



const SectionHeaderBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));



export interface IEpisodesTabProps {
  patientId: string;
}

export const EpisodesTab: React.FC<IEpisodesTabProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.patientId);
  const [fnAdd, fnAddMutationTuple] = dataService.useAddEpisodeMutation(props.patientId, async () => {
    await queryTuple.refetch();
  });

  const currentEpisode = queryTuple.data.episodes[0] ?? null;
  const pastEpisodes = queryTuple.data.episodes.slice(1);

  const isLoading =
    queryTuple.loading ||
    fnAddMutationTuple.loading;



  function genDetailedListItem(episode: dataService.IEpisode) {
    let subtitle = "";
    if (episode.startTimestamp && episode.endTimestamp) {
      subtitle += `${episode.startTimestamp.format(dateUtil.dateFormat)} - ${episode.endTimestamp.format(dateUtil.dateFormat)}`;
    }
    if (episode.transferTimestamp) {
      subtitle += ` (${t('adminEpisodes:transferredOn', {date: episode.transferTimestamp.format(dateUtil.dateFormat)})})`;
    }

    return (
      <DetailedListItem
        key={episode.id}
        title={t('episodeDisplayName', {ranking: episode.ranking})}
        subtitle={subtitle.trim()}
        additionalInfo={(episode.statusName === 'inactive' && !!episode.inactiveType) ? (
          <chips.EpisodeInactiveTypeChip
            valueId={episode.inactiveType} />

        ) : (
          <chips.EpisodeStatusChip
            valueId={episode.statusName} />
        )}
        toRoute={`/admin/episodes/profile/${episode.id}`} />
    );
  }



  return (
    <Box>
      <LoadingOverlay active={isLoading} />

      <SectionHeaderBox>
        <Typography variant="h2">
          {t('patients:episodes')}
        </Typography>

        {(!currentEpisode || currentEpisode.statusName === 'inactive' || currentEpisode.endTimestamp?.isBefore(moment())) && (
          <Button
            variant="contained"
            onClick={async () => await fnAdd()}>
            {t('patients:addAnEpisode')}
          </Button>
        )}
      </SectionHeaderBox>

      {!currentEpisode ? (
        <Typography>
          {t('patients:noEpisodes')}
        </Typography>

      ) : (
        <>
          <Box mb={2}>
            <Typography variant="h3">
              {t('patients:currentEpisode')}
            </Typography>
          </Box>

          {genDetailedListItem(currentEpisode)}

          <Box mb={2}>
            <Typography variant="h3">
              {t('patients:pastEpisodes')}
            </Typography>
          </Box>

          {pastEpisodes.length === 0 ? (
            <Typography>
              {t('patients:noPastEpisodes')}
            </Typography>

          ) : (
            <>
              {_.map(pastEpisodes, episode =>
                genDetailedListItem(episode))}
            </>
          )}
        </>
      )}

    </Box>
  );
};
