import React from 'react';

import {
  BaseActivityTemplate,
  paletteColors,
  LoginCodePhoneNumberModal,
  authService,
  ProtectedImage,
  Avatar,
  ValueWithLabel
} from 'modules';
import {
  Button,
  Grid
} from '@mui/material';

import { Create } from '@mui/icons-material';
import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



export const AdminMyProfileActivity: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const queryTuple = dataService.useQuery();

  const [isLoginCodePhoneNumberModalOpen, setIsLoginCodePhoneNumberModalOpen] = React.useState(false);



  async function handleClickLogOut() {
    await authService.logOut();
    navigate('/');
  }



  function handleClickEditData() {
    navigate('/admin/myProfile/edit');
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        isLoading={queryTuple.loading}
        headerOptions={{
          backgroundColor: paletteColors.administrators,
          title: queryTuple.data.fullName,
          elRightButtons: [
            (
              <Button
                key="logOut"
                variant="outlined"
                onClick={handleClickLogOut}>
                {t('auth:logOut')}
              </Button>
            ),
            (
              <Button
                key="changeLoginCodePhoneNumber"
                variant="outlined"
                onClick={() => setIsLoginCodePhoneNumberModalOpen(true)}>
                {t('changeLoginCodePhoneNumber')}
              </Button>
            ),
            (
              <Button
                key="editData"
                variant="outlined"
                onClick={handleClickEditData}
                startIcon={(
                  <Create />
                )}>
                {t('editData')}
              </Button>
            )
          ]
        }}>

        <Grid container>
          <Grid
            item
            md={6}>
            <Grid container>
              <ValueWithLabel label={t('profilePicture')}>
                {queryTuple.data.profilePictureFileKey ? (
                  <ProtectedImage
                    fileKey={queryTuple.data.profilePictureFileKey}
                    asAvatar />

                ) : (
                  <Avatar.FC />
                )}
              </ValueWithLabel>
            </Grid>
          </Grid>
        </Grid>

      </BaseActivityTemplate>

      {isLoginCodePhoneNumberModalOpen && (
        <LoginCodePhoneNumberModal
          onAccept={() => setIsLoginCodePhoneNumberModalOpen(false)}
          onClose={() => setIsLoginCodePhoneNumberModalOpen(false)} />
      )}
    </>
  );
};
