import _ from 'lodash';

import Amplify, {
  Auth,
  Hub
} from 'aws-amplify';

import awsExports from 'aws-exports.js';
import { HubCapsule } from '@aws-amplify/core';
import { BehaviorSubject } from 'rxjs';



export type IMfaMethod =
  'sms' |
  'softwareToken';

export type IChallengeType =
  'select' |
  IMfaMethod;



const __awsChallengeType2Client: Record<string, IChallengeType> = {
  'SELECT_MFA_TYPE': 'select',
  'SMS_MFA': 'sms',
  'SOFTWARE_TOKEN_MFA': 'softwareToken'
};



export type IStatus =
  'noAttemptMade' |
  'notLoggedIn' |
  'loggedIn';

export interface IAuthState {
  status: IStatus;
  authProviderUserId: string;
  accessToken: string;
}



const __subjAuthState = new BehaviorSubject<IAuthState>({
  status: 'noAttemptMade',
  authProviderUserId: '',
  accessToken: ''
});

let __registerEmail = '';
let __lastUser: any = null;
let __lastForgotPasswordEmailUsed = '';
let __challengeType: IChallengeType | '' = '';



export async function init() {
  Amplify.configure(awsExports);
  Hub.listen('auth', __handleAuthEvent);
  await __establishCurrentSession();
}



async function __establishCurrentSession() {
  let authState: IAuthState;

  try {
    const session = await Auth.currentSession();
    authState = {
      status: 'loggedIn',
      authProviderUserId: session?.getIdToken().decodePayload()['sub'],
      accessToken: session?.getAccessToken().getJwtToken()
    };

  } catch (err: any) {
    authState = {
      status: 'notLoggedIn',
      authProviderUserId: '',
      accessToken: ''
    };
  }

  if (process.env.REACT_APP_ENV_NAME !== 'prod') {
    console.log(authState);
    console.log(JSON.stringify({
      "Authorization": `Bearer ${authState.accessToken}`
    }));
  }

  __subjAuthState.next(authState);
  return authState;
}



function __handleAuthEvent(data: HubCapsule) {
  const eventName = data.payload.event;
  if (
    eventName === 'signIn' ||
    eventName === 'signUp' ||
    eventName === 'signOut'
  ) {
    __establishCurrentSession();
  }
}



export function getAuthStateObservable() {
  return __subjAuthState.asObservable();
}



export async function getAuthState(): Promise<IAuthState> {
  return await __establishCurrentSession();
}



export async function register({
  email, phoneNumber, password
}: {
  email: string;
  phoneNumber: string;
  password: string;
}) {
  __registerEmail = email;
  try {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        phone_number: phoneNumber
      }
    });

  } catch (err: any) {
    if (err?.code === 'UsernameExistsException') {
      throw new Error('EMAIL_EXISTS');
    } else {
      throw err;
    }
  }
}



export function canConfirmRegisterCode() {
  return !!__registerEmail;
}



export async function confirmRegisterCode(code: string) {
  await Auth.confirmSignUp(__registerEmail, code);
  __registerEmail = '';
  await __establishCurrentSession();
}



export type ITotpStatus =
  'totpInactive' |
  'totpActive';

export async function logIn({
  email, password
}: {
  email: string;
  password: string;
}) {
  try {
    await __resetMfaMethod(email);
    __lastUser = await Auth.signIn({
      username: email,
      password
    });

    const rawMfasCanChoose = JSON.parse(__lastUser?.challengeParam?.MFAS_CAN_CHOOSE ?? '[]');
    __challengeType = __awsChallengeType2Client[__lastUser.challengeName] ?? '';

    const numMfas = _.isArray(rawMfasCanChoose) ? rawMfasCanChoose.length : 0;
    const totpStatus: ITotpStatus = numMfas === 2 ? 'totpActive' : 'totpInactive';
    __setHasTotp(totpStatus === 'totpActive');
    return totpStatus;

  } catch (err: any) {
    if (err.code === 'UserNotConfirmedException') {
      __registerEmail = email;
    }
    throw err;
  }
}



export function getChallengeType() {
  return __challengeType;
}



export function canConfirmLoginCode() {
  return !!__lastUser;
}



export async function confirmLoginCode(code: string, mfaMethod: IMfaMethod) {
  await Auth.confirmSignIn(__lastUser, code, mfaMethod === 'softwareToken' ? 'SOFTWARE_TOKEN_MFA' : 'SMS_MFA');
  const user = await Auth.currentAuthenticatedUser();
  __lastUser = null;
  await __establishCurrentSession();
}



export async function forgotPassword(email: string) {
  await Auth.forgotPassword(email);
  __lastForgotPasswordEmailUsed = email;
}



export async function forgotPasswordSubmit(code: string, password: string) {
  await Auth.forgotPasswordSubmit(__lastForgotPasswordEmailUsed, code, password);
  __lastForgotPasswordEmailUsed = '';
}



export async function updatePhoneNumber(phoneNumber: string) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    phone_number: phoneNumber
  });
}



export async function logOut() {
  await Auth.signOut();
  await __establishCurrentSession();
}



interface IHasTotpCache {
  hasValue: boolean;
  value: boolean;
}

const __hasTotpCache: IHasTotpCache = {
  hasValue: false,
  value: false
};

function __setHasTotp(value: boolean) {
  __hasTotpCache.hasValue = true;
  __hasTotpCache.value = value;
}

export async function hasTotp() {
  if (__hasTotpCache.hasValue) {
    return __hasTotpCache.value;
  }

  const user = await Auth.currentAuthenticatedUser();
  try {
    await Auth.setPreferredMFA(user, 'TOTP');

  } catch (err: any) {
    if (err?.code === 'InvalidParameterException') {
      __setHasTotp(false);
      return __hasTotpCache.value;
    }
    throw err;
  }

  __setHasTotp(true);
  return __hasTotpCache.value;
}



export async function getEmail() {
  const user = await Auth.currentAuthenticatedUser();
  const rawEmail = user?.attributes?.email;
  return _.isString(rawEmail) ? rawEmail : "";
}



export async function setUpTotp() {
  const user = await Auth.currentAuthenticatedUser();
  return await Auth.setupTOTP(user);
}



export async function verifyTotpToken(code: string) {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.verifyTotpToken(user, code);
  __setHasTotp(true);
}



export async function setPreferredMfa(mfaMethod: IMfaMethod) {
  if (__lastUser) {
    await Auth.setPreferredMFA(__lastUser, mfaMethod === 'softwareToken' ? 'TOTP' : 'SMS');
  }
}



export async function answerSelectMfaChallenge(mfaMethod: IMfaMethod) {
  if (__lastUser) {
    await new Promise<void>((resolve, reject) =>
      __lastUser.sendMFASelectionAnswer(
        mfaMethod === 'softwareToken' ? 'SOFTWARE_TOKEN_MFA' : 'SMS_MFA',
        {
          onFailure: (err: any) => reject(err),
          mfaRequired: (challengeName: string, challengeParameters: string) => resolve(),
          totpRequired: (challengeName: string, challengeParameters: string) => resolve()
        }
      )
    );
  }
}



async function __resetMfaMethod(email: string) {
  await fetch(process.env.REACT_APP_API_URL ?? '', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: `
        mutation ResetUserPreferredMfaMethod($email: String!) {
          resetUserPreferredMfaMethod(email: $email)
        }
      `,
      variables: {
        email
      }
    })
  });
}
