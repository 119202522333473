import React from 'react';
import _ from 'lodash';

import {
  Typography,
  Box,
  IconButton
} from '@mui/material';
import {
  Delete,
  AddCircle,
  Edit
} from '@mui/icons-material';
import {
  ProtectedImage,
  SectionHeaderBox
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as types from '../types';
import { styled } from '@mui/material/styles';

import { Image } from './Image/Image';



const RowBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center'
}));

const TitleBox = styled(Box)(({theme}) => ({
  flex: '1 0',
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3)
}));



export interface IImagesSectionProps {
  images: types.IImage[];
  onClickAdd: () => void;
  onClickEdit: (index: number) => void;
  onClickDelete: (index: number) => void;
}

export const ImagesSection: React.FC<IImagesSectionProps> = props => {
  const {t} = useTranslation();



  return (
    <>
      <SectionHeaderBox>
        <Typography variant="h2">
          {t('visits:images')}
        </Typography>

        <IconButton
          size="small"
          onClick={props.onClickAdd}>
          <AddCircle />
        </IconButton>
      </SectionHeaderBox>

      {_.map(props.images, (image, index) => (
        <RowBox key={image.id}>

          {image.url && (
            <Image url={image.url} />
          )}

          {!image.url && image.key && (
            <ProtectedImage
              fileKey={image.key} />
          )}

          <TitleBox>
            {image.title}
          </TitleBox>

          <IconButton
            size="small"
            onClick={() => props.onClickEdit(index)}>
            <Edit />
          </IconButton>

          <IconButton
            size="small"
            onClick={() => props.onClickDelete(index)}>
            <Delete />
          </IconButton>

        </RowBox>
      ))}
    </>
  );
};
