import { authService } from 'modules/layer0';



const __apiRootUrl = process.env.REACT_APP_API_URL?.slice(0, -8) ?? '';



export async function fetchFile(accessToken: string, fileKey: string) {
  const headers = new Headers();
  headers.append('Authorization', `Bearer ${accessToken}`);
  headers.append('Origin', window.location.origin);

  const response = await fetch(`${__apiRootUrl}/file/${fileKey}`, {
    method: 'GET',
    headers
  });

  return await response.blob();
}



let __fileKey2DataUrl: Record<string, Promise<string>> = {};
let __accessToken = '';



export async function getDataUrl(fileKey: string) {
  if (!fileKey) {
    return '';
  }

  const dataUrl = __fileKey2DataUrl[fileKey];

  if (!dataUrl && __accessToken) {
    __fileKey2DataUrl[fileKey] = __downloadFile(fileKey);
  }

  return __fileKey2DataUrl[fileKey];
}



async function __downloadFile(fileKey: string) {
  const blob = await fetchFile(__accessToken, fileKey);
  return URL.createObjectURL(blob);
}



export async function forceDownloadFromFileKey(fileKey: string, fileName: string) {
  const dataUrl = await getDataUrl(fileKey);
  const elAnchor = document.createElement('a');
  elAnchor.href = dataUrl;
  elAnchor.download = fileName;
  elAnchor.click();
}



export function forceDownloadEncodedFile(encodedFile: string) {
  const elAnchor = document.createElement('a');

  const [fileName, ...encodedContentPieces] = encodedFile.split('/');
  const encodedContent = encodedContentPieces.join('/');

  elAnchor.href = encodedContent;
  elAnchor.download = fileName;
  elAnchor.click();
}



export function init() {
  authService.getAuthStateObservable().subscribe(authState => {
    __accessToken = authState.accessToken;
    if (__accessToken === '') {
      __fileKey2DataUrl = {};
    }
  });
}
