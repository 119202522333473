import React from 'react';
import _ from 'lodash';

import {
  TaskSetTemplateTaskInput,
  useEditTaskSetTemplateActivityLazyQuery,
  useSaveTaskSetTemplateMutation,
  useArchiveTaskSetTemplateMutation
} from 'generated/graphql';

import * as types from './types';



export interface IData {
  displayName: string;
  formState: types.IFormState;
}

export function useLazyQuery() {
  const [fnQuery, tuple] = useEditTaskSetTemplateActivityLazyQuery({
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  const fnWrappedQuery = React.useCallback(
    (id: string) => {
      fnQuery({
        variables: {
          id
        }
      });
    },
    [fnQuery]
  );



  const wrappedTuple = React.useMemo(
    () => {
      const gqlTemplate = tuple.data?.taskSetTemplate;
      const displayName = gqlTemplate?.displayName ?? "";

      const data: IData = {
        displayName,
        formState: {
          id: gqlTemplate?.id ?? '',
          displayName,
          tasks: _.map(gqlTemplate?.tasks ?? [], (gqlTask): types.ITask => ({
            _key: gqlTask.id,
            title: gqlTask.title,
            description: gqlTask.description
          }))
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );



  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



export function useSaveMutation() {
  const [fnMutate, tuple] = useSaveTaskSetTemplateMutation();



  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id: formState.id || undefined,
            displayName: formState.displayName.trim(),
            tasks: _.map(formState.tasks, (task): TaskSetTemplateTaskInput => ({
              title: task.title,
              description: task.description
            }))
          }
        }
      });
    },
    [fnMutate]
  );



  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );



  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useArchiveMutation() {
  const [fnMutate, tuple] = useArchiveTaskSetTemplateMutation();



  const fnWrappedMutate = React.useCallback(
    async (id: string) => {
      await fnMutate({
        variables: {
          id
        }
      });
    },
    [fnMutate]
  );



  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );



  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
