import React from 'react';

import {
  BaseActivityTemplate,
  paletteColors,
  EmployeeRequirementsTab
} from 'modules';

import { useTranslation } from 'react-i18next';



export const EmployeeCenterTasksActivity: React.FC = () => {
  const {t} = useTranslation();



  return (
    <BaseActivityTemplate
      mode="employee"
      navigationBarSelectedLinkId="ecTasks"
      headerOptions={{
        backgroundColor: paletteColors.requirements,
        title: t('requirements:employeeCenterTasks')
      }}>

      <EmployeeRequirementsTab />

    </BaseActivityTemplate>
  );
};
