import React from 'react';
import _ from 'lodash';

import { BaseNavigationBar } from '../shared/BaseNavigationBar/BaseNavigationBar';
import { useTranslation } from 'react-i18next';
import * as types from '../types';
import * as dataService from './dataService';

import { assetUtil } from 'modules/layer0';



export interface IAdminNavigationBarProps {
  selectedLinkId?: string;
  setIsLoading: (value: boolean) => void;
}

export const AdminNavigationBar: React.FC<IAdminNavigationBarProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery();



  const leftLinks = React.useMemo(
    () => _.compact([
      (
        queryTuple.data.permissionCategories.has('fullAdmin') ||
        queryTuple.data.permissionCategories.has('supervisor')
      ) ?
        {
          id: 'patients',
          props: {
            iconUrl: assetUtil.svgPatients,
            selectedIconUrl: assetUtil.svgPatientsSelected,
            label: 'navigation.patients',
            toRoute: '/admin/patients',
            isSelected: false
          }
        } :
        null,

      {
        id: 'employees',
        props: {
          iconUrl: assetUtil.svgEmployees,
          selectedIconUrl: assetUtil.svgEmployeesSelected,
          label: 'navigation.employees',
          toRoute: '/admin/employees',
          isSelected: false
        }
      },

      queryTuple.data.permissionCategories.has('fullAdmin') ?
        {
          id: 'admin',
          props: {
            iconUrl: assetUtil.svgAdmin,
            selectedIconUrl: assetUtil.svgAdminSelected,
            label: "navigation.admin",
            toRoute: '/admin/administrators',
            isSelected: false
          }
        } :
        null
    ]),
    [queryTuple.data.permissionCategories]
  );



  const dropdownMenuLinks = React.useMemo(
    (): types.IDropdownMenuLink[] => _.compact([
      (
        queryTuple.data.permissionCategories.has('fullAdmin') ||
        queryTuple.data.permissionCategories.has('supervisor')
      ) ?
        {
          id: 'pharmacies',
          label: t('pharmacies'),
          toRoute: '/admin/pharmacies'
        } :
        null,

      (
        queryTuple.data.permissionCategories.has('fullAdmin') ||
        queryTuple.data.permissionCategories.has('supervisor')
      ) ?
        {
          id: 'providers',
          label: t('providers'),
          toRoute: '/admin/providers'
        } :
        null,

      {
        id: 'requirements',
        label: t('requirements'),
        toRoute: '/admin/requirements'
      },

      queryTuple.data.permissionCategories.has('fullAdmin') ?
        {
          id: 'taskSetTemplates',
          label: t('taskSetTemplates'),
          toRoute: '/admin/taskSetTemplates'
        } :
        null,

      (
        queryTuple.data.permissionCategories.has('fullAdmin') ||
        queryTuple.data.permissionCategories.has('humanResources')
      ) ?
        {
          id: 'timesheets',
          label: t('timesheets'),
          toRoute: '/admin/timesheets'
        } :
        null
    ]),
    [
      t,
      queryTuple.data.permissionCategories
    ]
  );



  return (
    <BaseNavigationBar
      leftLinks={leftLinks}
      dropdownMenuLinks={dropdownMenuLinks}
      selectedLinkId={props.selectedLinkId}
      setIsLoading={props.setIsLoading} />
  );
};
