import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  paletteColors,
  genTableComponent,
  IRow as _IRow
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';



const __columnIds = [
  'displayName',
  'tasks'
] as const;
type IColumnId = typeof __columnIds[number];
type IRow = _IRow<IColumnId>;

const Table = genTableComponent<IColumnId>();



const __numTasksToShow = 10;



export const TaskSetTemplatesActivity: React.FC = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery();

  const isLoading =
    queryTuple.loading;



  return (
    <BaseActivityTemplate
      mode="admin"
      isLoading={isLoading}
      requireOneOfPermissionCategories={[
        'fullAdmin'
      ]}
      headerOptions={{
        backgroundColor: paletteColors.taskSetTemplates,
        title: t('taskSetTemplates'),
        elRightButtons: [
          (
            <Button
              key="create"
              variant="contained"
              onClick={() => navigate('/admin/taskSetTemplates/create')}>
              {t('visits:addTemplate')}
            </Button>
          )
        ]
      }}>

      <Table
        columns={[
          {
            id: 'displayName',
            label: t('name')
          },
          {
            id: 'tasks',
            label: t('visits:tasks')
          }
        ]}
        rows={_.map(queryTuple.data.taskSetTemplates, (template): IRow => ({
          id: template.id,
          columnId2Value: {
            displayName: {
              type: 'string',
              label: template.displayName,
              toRoute: template.route
            },
            tasks: {
              type: 'string',
              label: `${_.map(_.take(template.tasks, __numTasksToShow), task => task.title).join(", ")}${template.tasks.length > __numTasksToShow ? ", ..." : ""}`
            }
          }
        }))} />

    </BaseActivityTemplate>
  );
};
