import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useEmployeeViewRequirementActivityQuery
} from 'generated/graphql';

import { enumCache } from 'modules';



export interface IFile {
  key: string;
  name: string;
}

export interface IData {
  displayName: string;
  typeName: enumCache.IRequirementTypeName;
  statusName: enumCache.IRequirementStatusName;
  dueTimestamp: moment.Moment | null;
  lastSubmittedTimestamp: moment.Moment | null;
  description: string;
  file: IFile | null;
  url: string;
  employeeFiles: IFile[];
  quizScore: number | null;
}

export function useQuery(id: string) {
  const tuple = useEmployeeViewRequirementActivityQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlRequirement = tuple.data?.assignedRequirement;
      const data: IData = {
        displayName: gqlRequirement?.preset.displayName ?? "",
        typeName: gqlRequirement?.preset.type.name ?? 'requirement',
        statusName: gqlRequirement?.status.name ?? 'pending',
        dueTimestamp: gqlRequirement?.dueTimestamp ?
          moment(gqlRequirement.dueTimestamp, moment.ISO_8601) :
          null,
        lastSubmittedTimestamp: gqlRequirement?.lastSubmittedTimestamp ?
          moment(gqlRequirement.lastSubmittedTimestamp, moment.ISO_8601) :
          null,
        description: gqlRequirement?.preset.description ?? "",
        file: gqlRequirement?.preset.file ? {
          key: gqlRequirement?.preset.file?.key ?? '',
          name: gqlRequirement?.preset.file?.fileName ?? ""
        } : null,
        url: gqlRequirement?.preset.url ?? "",
        employeeFiles: _.map(gqlRequirement?.employeeFiles ?? [], (gqlFile): IFile => ({
          key: gqlFile.key,
          name: gqlFile.fileName
        })),
        quizScore: gqlRequirement?.typeSpecificData?.__typename === 'AssignedRequirementQuiz' ?
          (gqlRequirement.typeSpecificData.score ?? null) :
          null
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
