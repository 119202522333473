import {
  lightGreen,
  red,
  indigo,
  cyan,
  deepOrange
} from '@mui/material/colors';



export const paletteColors = {
  danger: '#E74C3C',
  administrators: '#EAF2FA',
  employees: '#FEF4F4',
  patients: '#F4F9FD',
  episodes: '#EAFFEA',
  diagnoses: red[100],
  pharmacies: cyan[100],
  providers: lightGreen[100],
  requirements: '#FEFEE9',
  taskSetTemplates: deepOrange[100],
  timesheets: '#FAF5E7',
  visitTemplates: indigo[100],
  visits: indigo[200],
  messenger: '#E7FFFF'
};
