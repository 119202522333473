import React from 'react';
import _ from 'lodash';

import { styled } from '@mui/material/styles';



const HiddenDiv = styled('div')(() => ({
  display: 'none'
}));



const __maxFileSizeBytes = 10 * 1024 * 1024;



export interface IFileData {
  name: string;
  dataUrl: string;
  file: File;
}



export function fileData2EncodedString(fileData: IFileData) {
  return `${fileData.name}/${fileData.dataUrl}`;
}



export interface IFileInputFieldProps {
  acceptTypes?: string[];
  children: (handleClick: () => void) => JSX.Element;
  onSelectFile: (fileData: IFileData) => void;
}

export const FileInputField: React.FC<IFileInputFieldProps> = props => {
  const refInput = React.useRef<HTMLInputElement>(null);



  function handleClick() {
    refInput.current?.click();
  }



  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const file = event.target.files?.item(0) ?? null;
    if (file) {
      if (file.size > __maxFileSizeBytes) {
        alert("File size too big; cannot exceed 10 MiB."); // TODO useTranslate()
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        const result = reader.result;
        if (_.isString(result)) {
          props.onSelectFile({
            name: file.name,
            dataUrl: result,
            file
          });

          event.target.value = '';
        }
      }, false);
      reader.readAsDataURL(file);
    }
  };



  return (
    <>
      <HiddenDiv>
        <input
          ref={refInput}
          type="file"
          accept={props.acceptTypes ?
            _.join(props.acceptTypes, ', ') :
            undefined}
          onChange={handleChange} />
      </HiddenDiv>

      {props.children(handleClick)}
    </>
  );
};
