import React from 'react';
import _ from 'lodash';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';



const ButtonRowBox = styled(Box)(({theme}) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
}));

const ButtonContainerBox = styled(Box)(({theme}) => ({
  flex: '1 0',
  '&:not(:first-of-type)': {
    marginLeft: theme.spacing(2)
  }
}));



export interface IButtonRowProps {
  elButtons: JSX.Element[];
}

export const ButtonRow: React.FC<IButtonRowProps> = props => {
  return (
    <ButtonRowBox>
      {_.map(props.elButtons, elButton => (
        <ButtonContainerBox key={elButton.key}>
          {elButton}
        </ButtonContainerBox>
      ))}
    </ButtonRowBox>
  );
};
