import React from 'react';

import {
  usePatientProfileActivityPatientDetailsTabContactDetailsSectionQuery as useBaseQuery
} from 'generated/graphql';

import { useTranslation } from 'react-i18next';



export interface IData {
  primaryPhone: string;
  secondaryPhone: string;
}

export function useQuery(id: string) {
  const {t} = useTranslation();
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlPatient = tuple.data?.patient;
      const data: IData = {
        primaryPhone: gqlPatient?.primaryPhone ?? t('noValue'),
        secondaryPhone: gqlPatient?.secondaryPhone ?? t('noValue')
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
