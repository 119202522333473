import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';



export const HeaderBox = styled(Box)(({theme}) => ({
  height: theme.spacing(5),
  marginBottom: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));
