import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
  BaseModal,
  fields,
  selectFields,
  Form,
  enumCache,
  SectionHeaderBox,
  genEnumSelectFieldControllerComponent,
  validationUtil,
  RowBox,
  CellBox,
  errorUtil
} from 'modules';
import {
  useFieldArray,
  useForm
} from 'react-hook-form';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Button
} from '@mui/material';

import { Delete } from '@mui/icons-material';
import * as uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import * as types from './types';
import * as dataService from './dataService';



const EmployeeSelectFieldController = selectFields.genEmployeeSelectFieldControllerComponent<types.IFormState>();
const RecurrenceTypeSelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, enumCache.IRequirementRecurrenceTypeName>(selectFields.RequirementRecurrenceTypeSelectField);
const TimestampFieldController = fields.genTimestampFieldControllerComponent<types.IFormState>();
const TimeZoneSelectFieldController = selectFields.genTimeZoneSelectFieldControllerComponent<types.IFormState>();
const DayOfWeekSelectFieldController = genEnumSelectFieldControllerComponent<types.IFormState, enumCache.IDayOfWeekName>(selectFields.DayOfWeekSelectField);
const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();



export interface ICreateRecurrenceModalProps {
  presetId: string;
  onAccept: () => void;
  onCancel: () => void;
}

export const CreateRecurrenceModal: React.FC<ICreateRecurrenceModalProps> = props => {
  const {t} = useTranslation();

  const [fnSave, saveMutationTuple] = dataService.useSave(props.presetId);

  const form = useForm<types.IFormState>({
    defaultValues: {
      assignedEmployees: [],
      recurrenceTypeName: 'once',
      timeZone: moment.tz.guess(),
      recurOnce: {
        anchorTimestamp: moment().add(1, 'week').toDate()
      },
      recurWeekly: {
        dayOfWeekName: 'friday'
      },
      recurMonthly: {
        anchorTimestamp: moment().add(1, 'month').startOf('month').toDate(),
        numMonthsInterval: "1",
        monthOffset: "0",
        dayOfMonth: "1"
      },
      recurYearly: {
        anchorTimestamp: moment().add(1, 'week').toDate(),
        numYearsInterval: "1"
      }
    }
  });

  const employeeFields = useFieldArray({
    control: form.control,
    name: 'assignedEmployees',
    keyName: '_key'
  });

  const isLoading =
    saveMutationTuple.loading;

  const formState = form.watch();
  const recurrenceTypeName = form.watch('recurrenceTypeName');

  const recurrenceDescription = React.useMemo(
    () => {
      const type2TKey: Record<enumCache.IRequirementRecurrenceTypeName | '', string> = {
        '': '',
        'once': 'recurOnce',
        'weekly': 'recurWeekly',
        'monthly': 'recurMonthly',
        'yearly': 'recurYearly'
      };

      const tKey = type2TKey[recurrenceTypeName];
      return tKey ? t(`requirements:createRecurrenceModal.${tKey}`) : "";
    },
    [
      t,
      recurrenceTypeName
    ]
  );



  const upcomingAssignmentDates = React.useMemo(
    () => dataService.genUpcomingAssignmentDates(formState),
    [formState]
  );



  const minDate = React.useMemo(
    () => moment().add(1, 'hour').toDate(),
    []
  );



  function handleClickAddEmployee() {
    employeeFields.append({
      _key: uuid.v4(),
      id: ''
    });
  }



  function handleClickRemoveEmployee(index: number) {
    employeeFields.remove(index);
  }



  async function handleSubmit(formState: types.IFormState) {
    if (formState.assignedEmployees.length === 0) {
      alert(t('requirements:errors.zeroAssignedEmployees'));
      return;
    }

    await errorUtil.errorWrap(async () => {
      await fnSave(formState);
      props.onAccept();
    });
  }



  return (
    <BaseModal
      title={t('requirements:createRecurrence')}
      isOpen
      isDoubleWide
      showButtons
      onClose={props.onCancel}
      isLoading={isLoading}>

      {buttons => (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          <Grid container>

            <Grid
              item
              md={6}>

              <SectionHeaderBox>
                <Typography variant="h2">
                  {t('requirements:assignedEmployees')}
                </Typography>

                <Button
                  variant="contained"
                  onClick={handleClickAddEmployee}>
                  {t('requirements:addEmployee')}
                </Button>
              </SectionHeaderBox>

              {_.map(employeeFields.fields, (field, index) => (
                <RowBox key={field._key}>
                  <CellBox>
                    <EmployeeSelectFieldController
                      formControl={form.control}
                      fieldKey={`assignedEmployees.${index}.id`}
                      isRequired
                      isDisabled={isLoading}
                      onlyEmployeesWithAttachedUsers />
                  </CellBox>

                  <IconButton
                    size="small"
                    onClick={() => handleClickRemoveEmployee(index)}>
                    <Delete />
                  </IconButton>
                </RowBox>
              ))}

            </Grid>

            <Grid
              item
              md={6}>

              <SectionHeaderBox>
                <Typography variant="h2">
                  {t('requirements:recurrenceConfiguration')}
                </Typography>
              </SectionHeaderBox>

              <Box mb={1}>
                <RecurrenceTypeSelectFieldController
                  formControl={form.control}
                  fieldKey="recurrenceTypeName"
                  isRequired
                  isDisabled={isLoading} />
              </Box>

              <Box mb={3}>
                <Typography variant="body1">
                  {recurrenceDescription}
                </Typography>
              </Box>

              {recurrenceTypeName === 'once' && (
                <>
                  <Box mb={3}>
                    <TimestampFieldController
                      formControl={form.control}
                      fieldKey="recurOnce.anchorTimestamp"
                      label={t('requirements:anchorTimestamp')}
                      min={minDate}
                      isRequired
                      isDisabled={isLoading} />
                  </Box>
                </>
              )}

              {recurrenceTypeName === 'weekly' && (
                <>
                  <Box mb={3}>
                    <DayOfWeekSelectFieldController
                      formControl={form.control}
                      fieldKey="recurWeekly.dayOfWeekName"
                      isRequired
                      isDisabled={isLoading} />
                  </Box>
                </>
              )}

              {recurrenceTypeName === 'monthly' && (
                <>
                  <Box mb={3}>
                    <Box mb={1}>
                      <Typography variant="body1">
                        {t('requirements:createRecurrenceModal.monthlyAnchorTimestamp')}
                      </Typography>
                    </Box>

                    <TimestampFieldController
                      formControl={form.control}
                      fieldKey="recurMonthly.anchorTimestamp"
                      label={t('requirements:anchorTimestamp')}
                      min={minDate}
                      isRequired
                      isDisabled={isLoading} />
                  </Box>

                  <Box mb={3}>
                    <Box mb={1}>
                      <Typography variant="body1">
                        {t('requirements:createRecurrenceModal.numMonthsInterval')}
                      </Typography>
                    </Box>

                    <TextFieldController
                      formControl={form.control}
                      fieldKey="recurMonthly.numMonthsInterval"
                      label={t('requirements:numMonthsInterval')}
                      extraValidationRules={{
                        validate: validationUtil.rules.genIntegerRule({
                          min: 1,
                          max: 12
                        })
                      }}
                      isRequired
                      isDisabled={isLoading} />
                  </Box>

                  <Box mb={3}>
                    <Box mb={1}>
                      <Typography variant="body1">
                        {t('requirements:createRecurrenceModal.dayOfMonth')}
                      </Typography>
                    </Box>

                    <TextFieldController
                      formControl={form.control}
                      fieldKey="recurMonthly.dayOfMonth"
                      label={t('requirements:dayOfMonth')}
                      extraValidationRules={{
                        validate: validationUtil.rules.genIntegerRule({
                          min: 1,
                          max: 31
                        })
                      }}
                      isRequired
                      isDisabled={isLoading} />
                  </Box>
                </>
              )}

              {recurrenceTypeName === 'yearly' && (
                <>
                  <Box mb={3}>
                    <Box mb={1}>
                      <Typography variant="body1">
                        {t('requirements:createRecurrenceModal.yearlyAnchorTimestamp')}
                      </Typography>
                    </Box>

                    <TimestampFieldController
                      formControl={form.control}
                      fieldKey="recurYearly.anchorTimestamp"
                      label={t('requirements:anchorTimestamp')}
                      min={minDate}
                      isRequired
                      isDisabled={isLoading} />
                  </Box>

                  <Box mb={3}>
                    <Box mb={1}>
                      <Typography variant="body1">
                        {t('requirements:createRecurrenceModal.numYearsInterval')}
                      </Typography>
                    </Box>

                    <TextFieldController
                      formControl={form.control}
                      fieldKey="recurYearly.numYearsInterval"
                      label={t('requirements:numYearsInterval')}
                      extraValidationRules={{
                        validate: validationUtil.rules.genIntegerRule({
                          min: 1,
                          max: 10
                        })
                      }}
                      isRequired
                      isDisabled={isLoading} />
                  </Box>
                </>
              )}

              <Box mb={3}>
                <TimeZoneSelectFieldController
                  formControl={form.control}
                  fieldKey="timeZone"
                  label={t('timeZone')}
                  isRequired
                  isDisabled={isLoading} />
              </Box>

              {upcomingAssignmentDates.length > 0 && (
                <>
                  <SectionHeaderBox>
                    <Typography variant="h2">
                      {t('requirements:upcomingAssignmentDates')}
                    </Typography>
                  </SectionHeaderBox>

                  <ul>
                    {_.map(upcomingAssignmentDates, (date, index) => (
                      <li key={index}>
                        {`${date.format('dddd, MMMM Do, YYYY')} at ${date.format('HH:mm')}`}
                      </li>
                    ))}
                  </ul>
                </>
              )}

            </Grid>

          </Grid>

          {buttons}
        </Form>
      )}

    </BaseModal>
  );
};
