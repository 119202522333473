import React from 'react';
import _ from 'lodash';

import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import {
  selectFields,
  fields,
  enumCache,
  RowBox,
  CellBox,
  SectionHeaderBox
} from 'modules';
import {
  Control,
  useFieldArray
} from 'react-hook-form';

import { Close } from '@mui/icons-material';
import * as types from '../types';
import { useTranslation } from 'react-i18next';
import * as uuid from 'uuid';



const PermissionCategorySelectFieldController = fields.genEnumSelectFieldControllerComponent<types.IFormState, enumCache.IPermissionCategoryName>(selectFields.PermissionCategorySelectField.Comp);



export interface IProps {
  formControl: Control<types.IFormState>;
  isDisabled: boolean;
}

export const Comp: React.FC<IProps> = props => {
  const {t} = useTranslation();

  const permissionCategoryFields = useFieldArray({
    control: props.formControl,
    name: 'permissionCategories',
    keyName: '_key'
  });



  function handleClickAdd() {
    permissionCategoryFields.append({
      _key: uuid.v4(),
      name: ''
    });
  }



  function handleClickDelete(index: number) {
    permissionCategoryFields.remove(index);
  }



  return (
    <Box mb={6}>
      <Grid container>

        <Grid
          item
          md={3}>

          <SectionHeaderBox>
            <Typography variant="h2">
              {t('permissionCategories')}
            </Typography>

            {!props.isDisabled && (
              <Button onClick={handleClickAdd}>
                {t('add')}
              </Button>
            )}
          </SectionHeaderBox>

          {_.map(permissionCategoryFields.fields, (field, index) => (
            <RowBox key={field._key}>

              <CellBox>
                <PermissionCategorySelectFieldController
                  formControl={props.formControl}
                  fieldKey={`permissionCategories.${index}.name`}
                  isRequired
                  isDisabled={props.isDisabled} />
              </CellBox>

              {!props.isDisabled && (
                <IconButton onClick={() => handleClickDelete(index)}>
                  <Close fontSize="small" />
                </IconButton>
              )}

            </RowBox>
          ))}

        </Grid>

      </Grid>
    </Box>
  );
};
