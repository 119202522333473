import _ from 'lodash';
import moment from 'moment';

import { validationUtil } from 'modules';
import papa from 'papaparse';



export type IFormatType =
  'date' |
  'phone' |
  'employeeId' |
  'usdAmount' |
  'timeToMinutes' |
  'ssn';

export interface IColumnDescription {
  id: string;
  label: string;
  formatType?: IFormatType;
  comment?: string;
  enumValues?: string[];
  isRequired?: boolean;
}



export interface IParseReturn {
  rows: Record<string, string>[];
  extraColumnIds: string[];
  formatErrors: string[];
}

export async function parse(columnDescriptions: IColumnDescription[], file: File): Promise<IParseReturn> {
  const rawData = await __rawParse(file);

  const allColumnIds = _.map(columnDescriptions, columnDescription =>
    columnDescription.id);
  const requiredColumnIds = _.chain(columnDescriptions)
    .filter(column => !!column.isRequired)
    .map(column => column.id)
    .value();

  const formatErrors: string[] = [];
  formatErrors.push(...__validateAllRowsHaveAllRequiredColumns(requiredColumnIds, rawData.data));
  formatErrors.push(...__getFormatErrorsForAllRows(columnDescriptions, rawData.data));

  if (formatErrors.length > 0) {
    return {
      rows: [],
      extraColumnIds: [],
      formatErrors
    };
  }

  const extraColumnIds = __getExtraColumnIds(allColumnIds, rawData.meta.fields ?? []);
  const rows = __pruneExtraColumns(extraColumnIds, rawData.data);

  return {
    rows,
    extraColumnIds,
    formatErrors: []
  };
}



function __validateAllRowsHaveAllRequiredColumns(requiredColumnIds: string[], rows: any[]) {
  return _.reduce(rows, (errorList, row, rowIndex) => {
    const missingColumnIds = __getMissingRequiredColumnIdsForRow(requiredColumnIds, row);
    if (missingColumnIds.length > 0) {
      const formattedColumnIds = _.map(missingColumnIds, columnId => `"${columnId}"`);
      errorList.push(`[Row ${rowIndex+2}] Missing data for required columns: ${formattedColumnIds.join(", ")}.`);
    }

    return errorList;
  }, [] as string[]);
}



interface IFormatTypeData {
  properFormat: string;
  example: string;
  fnValidate: (value: string) => boolean;
}

const __formatType2Data: Partial<Record<IFormatType, IFormatTypeData>> = {
  date: {
    properFormat: "YYYY-MM-DD",
    example: "2021-09-03",
    fnValidate: value => {
      if (!value.match(/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/)) {
        return false;
      }

      const mValue = moment(value, 'YYYY-MM-DD');
      if (!mValue.isValid()) {
        return false;
      }

      if (moment().isSameOrBefore(mValue)) {
        return false;
      }

      return true;
    }
  },

  phone: {
    properFormat: "+xxxxxxxxxxx",
    example: "+16085551234",
    fnValidate: value =>
      !!value.match(validationUtil.rePhoneNumber)
  },

  timeToMinutes: {
    properFormat: "xx:xx",
    example: "09:30",
    fnValidate: value => {
      if (!value.match(/^[0-9]{2}:[0-9]{2}$/)) {
        return false;
      }

      return moment(value, 'HH:mm').isValid();
    }
  },

  usdAmount: {
    properFormat: "xxx.xx",
    example: "125.00",
    fnValidate: value =>
      !!value.match(/^[0-9]*\.[0-9]{2}$/) &&
      value.length <= 13
  },

  ssn: {
    properFormat: "xxxxxxxxx",
    example: "123456789",
    fnValidate: value =>
      !!value.match(validationUtil.reSsn)
  }
};

function __getFormatErrorsForAllRows(columnDescriptions: IColumnDescription[], rows: any[]) {
  const formatColumns = _.filter(columnDescriptions, column =>
    !!column.formatType || !!column.enumValues);

  return _.reduce(rows, (errorList, row, rowIndex) => {
    const newErrors = _.compact(
      _.map(formatColumns, column => {
        const value = row[column.id] ?? "";

        if (column.enumValues) {
          if (value && !_.includes(column.enumValues, value)) {
            const formattedEnumValues = _.map(column.enumValues, enumValue => `"${enumValue}"`);
            return `[Row ${rowIndex+2}] Invalid data for ${column.id} column: "${value}". Must be one of the following: ${formattedEnumValues.join(", ")}.`;

          } else {
            return null;
          }
        }

        if (!column.formatType) {
          return null;
        }

        const formatData = __formatType2Data[column.formatType];
        if (!formatData) {
          return null;
        }

        return (value && !formatData.fnValidate(value)) ?
          `[Row ${rowIndex+2}] Invalid data for ${column.id} column: "${value}". Proper format: "${formatData.properFormat}". Example: "${formatData.example}".` :
          null;
      }));

    errorList.push(...newErrors);
    return errorList;
  }, [] as string[]);
}



function __getExtraColumnIds(allColumnIds: string[], columnIdsFromFile: string[]) {
  return _.difference(columnIdsFromFile, allColumnIds);
}



function __getMissingRequiredColumnIdsForRow(requiredColumnIds: string[], row: any) {
  return _.filter(requiredColumnIds, columnId =>
    !row[columnId]);
}



function __rawParse(file: File) {
  return new Promise<papa.ParseResult<any>>((resolve, reject) => {
    papa.parse(file, {
      header: true,
      delimiter: ',',
      skipEmptyLines: true,
      error: err => reject(err),
      complete: results => resolve(results)
    });
  });
}



function __pruneExtraColumns(extraColumnIds: string[], rows: any[]) {
  return extraColumnIds.length === 0 ?
    rows :
    _.map(rows, row =>
      _.pickBy(row, (value, key) =>
        !_.includes(extraColumnIds, key)));
}
