import React from 'react';

import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  BaseActivityTemplate,
  paletteColors,
  Form,
  errorUtil,
  formUtil
} from 'modules';

import { RadioButtonGroup } from './RadioButtonGroup/RadioButtonGroup';
import { CheckboxGroup } from './CheckboxGroup/CheckboxGroup';
import * as types from './types';
import * as dataService from './dataService';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';



interface IParams {
  afterVisitDataId: string;
}

export const EditAfterVisitDataActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.afterVisitDataId ?? '');
  const [fnSave, saveMutationTuple] = dataService.useSaveMutation(params.afterVisitDataId ?? '');

  const form = useForm<types.IFormState>({
    defaultValues: {
      medicationValues: [],
      allMedsTakenValues: [],
      justificationOfServicesValues: []
    }
  });

  const isLoading =
    queryTuple.loading ||
    saveMutationTuple.loading;



  React.useEffect(
    () => {
      if (!queryTuple.loading && queryTuple.data.visitStatusName !== 'clockedOut') {
        navigate(-1);
      }
    },
    [
      queryTuple.loading,
      queryTuple.data.visitStatusName
    ]
  );



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        formUtil.setFieldsFromQuery(queryTuple.data.formState, form.setValue, [
          'medicationValues',
          'allMedsTakenValues',
          'justificationOfServicesValues'
        ]);
      }
    },
    [
      queryTuple.loading,
      queryTuple.data,
      form
    ]
  );



  async function handleSubmit(formState: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnSave(formState);
      navigate(-1);
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="employee"
        isLoading={isLoading}
        headerOptions={{
          backgroundColor: paletteColors.visits,
          title: queryTuple.data.patientName,
          hasBackButton: true
        }}
        footerOptions={{
          hasDeleteButton: false,
          onClickCancel: () => navigate(-1)
        }}>

        <CheckboxGroup
          form={form}
          fieldKey="medicationValues"
          title={t('visits:medications')}
          schemaValuesByValue={queryTuple.data.schemata.medications}
          labelTranslationPrefix="visits:medicationValues"
          isDisabled={isLoading} />

        <RadioButtonGroup
          form={form}
          fieldKey="allMedsTakenValues"
          title={t('visits:allMedsTaken')}
          schemaValuesByValue={queryTuple.data.schemata.allMedsTaken}
          labelTranslationPrefix="visits:allMedsTakenValues"
          isDisabled={isLoading} />

        <CheckboxGroup
          form={form}
          fieldKey="justificationOfServicesValues"
          title={t('visits:justificationOfServices')}
          schemaValuesByValue={queryTuple.data.schemata.justificationOfServices}
          labelTranslationPrefix="visits:justificationOfServicesValues"
          isDisabled={isLoading} />

      </BaseActivityTemplate>
    </Form>
  );
};
