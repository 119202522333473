import React from 'react';
import moment from 'moment-timezone';

import {
  BaseActivityTemplate,
  errorUtil,
  Form,
  paletteColors,
  SectionHeaderBox,
  fields,
  selectFields,
  RowBox,
  CellBox
} from 'modules';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import {
  useForm,
  useFieldArray
} from 'react-hook-form';

import { Delete } from '@mui/icons-material';
import * as uuid from 'uuid';
import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';
import * as types from './types';



const DateWithTimeZoneFieldController = fields.genDateWithTimeZoneFieldControllerComponent<types.IFormState>();
const TextFieldController = fields.genTextFieldControllerComponent<types.IFormState>();
const EmployeeSelectFieldController = selectFields.genEmployeeSelectFieldControllerComponent<types.IFormState>();



interface IParams {
  visitId: string;
}

export const EditVisitActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.visitId ?? '');
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveVisitMutation(params.visitId ?? '');
  const [fnSave, saveMutationTuple] = dataService.useSaveVisitMutation(params.visitId ?? '');

  const isLoading =
    queryTuple.loading ||
    archiveMutationTuple.loading ||
    saveMutationTuple.loading;

  const form = useForm<types.IFormState>({
    defaultValues: {
      dateWithTz: `,${moment.tz.guess()}`,
      assignedEmployees: []
    }
  });

  const employeeFields = useFieldArray({
    control: form.control,
    name: 'assignedEmployees',
    keyName: '_key'
  });



  React.useEffect(
    () => {
      if (!queryTuple.loading) {
        form.setValue('dateWithTz', queryTuple.data.formState.dateWithTz, {shouldDirty: true});
        form.setValue('location', queryTuple.data.formState.location, {shouldDirty: true});
        form.setValue('assignedEmployees', queryTuple.data.formState.assignedEmployees, {shouldDirty: true});
      }
    },
    [
      queryTuple.loading,
      queryTuple.data
    ]
  );



  function addEmployee() {
    employeeFields.append({
      _key: uuid.v4(),
      id: ''
    });
  }



  function removeEmployee(index: number) {
    employeeFields.remove(index);
  }



  async function handleConfirmDelete() {
    await errorUtil.errorWrap(async () => {
      await fnArchive();
      navigate(`/admin/episodes/profile/${queryTuple.data.episodeId}`);
    });
  }



  async function handleSubmit(formState: types.IFormState) {
    await errorUtil.errorWrap(async () => {
      await fnSave(formState);
      navigate(-1);
    }, {
      'DATE_NOT_WITHIN_EPISODE_RANGE': t('visits:errors.DATE_NOT_WITHIN_EPISODE_RANGE')
    });
  }



  return (
    <Form onSubmit={form.handleSubmit(handleSubmit)}>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.visits,
          title: queryTuple.data.displayName,
          hasBackButton: true
        }}
        footerOptions={{
          hasDeleteButton: true,
          onConfirmDelete: handleConfirmDelete,
          onClickCancel: () => navigate(-1)
        }}>

        <Box mb={3}>
          <Grid container>
            <Grid
              item
              md={6}>

              <SectionHeaderBox>
                <Typography variant="h2">
                  {t('visits:date')}
                </Typography>
              </SectionHeaderBox>

              <DateWithTimeZoneFieldController
                formControl={form.control}
                fieldKey="dateWithTz"
                label={t('visits:date')}
                isRequired
                isDisabled={isLoading}
                disableTimeZone />

            </Grid>

            <Grid
              item
              md={6}>

              <SectionHeaderBox>
                <Typography variant="h2">
                  {t('visits:location')}
                </Typography>
              </SectionHeaderBox>

              <TextFieldController
                formControl={form.control}
                fieldKey="location"
                label={t('visits:location')}
                isRequired
                isDisabled={isLoading} />

            </Grid>
          </Grid>
        </Box>

        <Box mb={3}>
          <SectionHeaderBox>
            <Typography variant="h2">
              {t('visits:assignedEmployees')}
            </Typography>

            <Button
              onClick={addEmployee}>
              {t('visits:addEmployee')}
            </Button>
          </SectionHeaderBox>

          {employeeFields.fields.map((field, index) => (
            <RowBox key={field._key}>
              <CellBox>
                <EmployeeSelectFieldController
                  formControl={form.control}
                  fieldKey={`assignedEmployees.${index}.id`}
                  isRequired
                  isDisabled={isLoading} />
              </CellBox>

              <IconButton
                size="small"
                onClick={() => removeEmployee(index)}>
                <Delete />
              </IconButton>
            </RowBox>
          ))}
        </Box>

      </BaseActivityTemplate>
    </Form>
  );
};
