import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useRequirementsActivityPresetsTabQuery as useBaseQuery
} from 'generated/graphql';

import { enumCache } from 'modules';



export interface IRequirementPreset {
  id: string;
  type: enumCache.IRequirementTypeName;
  displayName: string;
  metaCreated: moment.Moment;
  isLocked: boolean;
}

export interface IData {
  presets: IRequirementPreset[];
}

export function usePresetsTabQuery() {
  const tuple = useBaseQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  return React.useMemo(
    () => {
      const gqlPresets = tuple.data?.requirementPresets ?? [];
      const data: IData = {
        presets: _.map(gqlPresets, (gqlPreset): IRequirementPreset => ({
          id: gqlPreset.id,
          type: gqlPreset.type.name,
          displayName: gqlPreset.displayName,
          metaCreated: moment(gqlPreset.metaCreated, moment.ISO_8601),
          isLocked: gqlPreset.isLocked
        }))
      };

      return {
        ...tuple,
        data,
        refetch: async () => {
          await tuple.refetch();
        }
      };
    },
    [tuple]
  );
}
