import React from 'react';

import {
  BaseModal,
  FileInputField,
  IFileData,
  Form,
  fields
} from 'modules';
import {
  Typography,
  Box
} from '@mui/material';

import * as types from '../types';
import { useForm } from 'react-hook-form';
import * as uuid from 'uuid';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';



const ContainerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));

interface IImageContainerDivProps {
  isPlaceholder: boolean;
}

const ImageContainerBox = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isPlaceholder'
})<IImageContainerDivProps>(({isPlaceholder}) => ({
  width: 300,
  height: 300,
  alignSelf: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: isPlaceholder ? '1px dashed #999' : undefined
}));

const ImageImg = styled('img')(() => ({
  display: 'block',
  width: '100%'
}));



interface IFormState {
  image: types.IImage;
}

const TextFieldController = fields.genTextFieldControllerComponent<IFormState>();



export interface IEditImageModalProps {
  initImage: types.IImage | null;
  onAccept: (image: types.IImage) => void;
  onCancel: () => void;
}

export const EditImageModal: React.FC<IEditImageModalProps> = props => {
  const {t} = useTranslation();

  const form = useForm<IFormState>({
    defaultValues: {
      image: props.initImage ?? {
        id: `*${uuid.v4()}`,
        title: "",
        url: "",
        name: "",
        file: null
      }
    }
  });

  const fileUrl = form.watch('image.url');
  const hasFile = !!form.watch('image.file');



  function handleSelectFile(fileData: IFileData) {
    form.setValue('image.name', fileData.name);
    form.setValue('image.url', fileData.dataUrl);
    form.setValue('image.file', fileData.file);
  }



  function handleSubmit(formState: IFormState) {
    if (!formState.image.url) {
      alert(t('visits:pleaseSelectAFile'));
      return;
    }

    props.onAccept(formState.image);
  }



  return (
    <BaseModal
      isOpen
      title={props.initImage ?
        t('visits:editImage') :
        t('visits:addImage')}
      onClose={props.onCancel}
      showButtons>

      {buttons => (
        <Form onSubmit={form.handleSubmit(handleSubmit)}>
          <ContainerBox>

            <Box mb={3}>
              <TextFieldController
                formControl={form.control}
                fieldKey="image.title"
                label={t('visits:title')} />
            </Box>

            <FileInputField
              acceptTypes={[
                'image/png',
                'image/jpeg'
              ]}
              onSelectFile={handleSelectFile}>

              {handleClick => (
                <ImageContainerBox
                  isPlaceholder={!hasFile}
                  onClick={handleClick}>

                  {hasFile ? (
                    <ImageImg src={fileUrl} />

                  ) : (
                    <Typography variant="body1">
                      {t('visits:clickToSelectImage')}
                    </Typography>
                  )}

                </ImageContainerBox>
              )}

            </FileInputField>

            {buttons}

          </ContainerBox>
        </Form>
      )}

    </BaseModal>
  );
};
