import React from 'react';
import _ from 'lodash';

import {
  useEmployeeProfileProfileTabSkillsSectionQuery as useBaseQuery
} from 'generated/graphql';



export interface ISkill {
  id: string;
  name: string;
  description: string;
}

export interface IData {
  skills: ISkill[];
}

export function useSkillsSectionQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlSkills = tuple.data?.employee?.skills ?? [];
      const data: IData = {
        skills: _.map(gqlSkills, (gqlSkill): ISkill => ({
          id: gqlSkill.id,
          name: gqlSkill.name,
          description: gqlSkill.description
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
