import React from 'react';
import _ from 'lodash';

import {
  useViewRequirementPresetActivityQuery
} from 'generated/graphql';
import {
  enumCache,
  QuizContentSection
} from 'modules';



export interface IFile {
  key: string;
  name: string;
}

export interface IData {
  type: enumCache.IRequirementTypeName;
  displayName: string;
  description: string;
  isLocked: boolean;
  file: IFile | null;
  url: string;
  quizPreset: QuizContentSection.IQuiz | null;
}

export function useQuery(id: string) {
  const tuple = useViewRequirementPresetActivityQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlPreset = tuple.data?.requirementPreset;
      const data: IData = {
        type: gqlPreset?.type.name ?? 'requirement',
        displayName: gqlPreset?.displayName ?? "",
        description: gqlPreset?.description ?? "",
        isLocked: gqlPreset?.isLocked ?? false,
        file: gqlPreset?.file ? {
          key: gqlPreset.file.key,
          name: gqlPreset.file.fileName
        } : null,
        url: gqlPreset?.url ?? "",

        quizPreset: (gqlPreset?.typeSpecificData && gqlPreset.typeSpecificData.__typename === 'RequirementPresetQuiz') ? {
          id: gqlPreset.typeSpecificData.id,
          score: null,
          questions: _.map(gqlPreset.typeSpecificData.questions, (gqlQuestion): QuizContentSection.IQuizQuestion => ({
            id: gqlQuestion.id,
            description: gqlQuestion.description,
            answerOptions: _.map(gqlQuestion.answerOptions, (gqlAnswerOption): QuizContentSection.IQuizQuestionAnswerOption => ({
              id: gqlAnswerOption.id,
              description: gqlAnswerOption.description,
              isCorrect: gqlQuestion.correctAnswerOption.id === gqlAnswerOption.id,
              isSelected: false
            }))
          }))
        } : null
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
