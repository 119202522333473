import React from 'react';
import _ from 'lodash';

import {
  useEditAdministratorActivityLazyQuery as useBaseLazyQuery,
  useSaveAdministratorMutation as useBaseSaveAdministratorMutation,
  useArchiveAdministratorMutation as useBaseArchiveAdministratorMutation,
  PermissionCategoryName
} from 'generated/graphql';

import * as types from './types';
import { dateUtil } from 'modules';



export interface IData {
  formState: types.IFormState;
  fullName: string;
  email: string;
  isSelf: boolean;
}

export function useEditAdministratorActivityLazyQuery() {
  const [fnQuery, tuple] = useBaseLazyQuery({fetchPolicy: 'no-cache'});

  const fnWrappedQuery = React.useCallback(
    (administratorId: string) => fnQuery({
      variables: {
        administratorId
      }
    }),
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const gqlAdmin = tuple.data?.administrator;
      const data: IData = {
        formState: {
          id: gqlAdmin?.id ?? '',
          firstName: gqlAdmin?.firstName ?? "",
          lastName: gqlAdmin?.lastName ?? "",
          birthdate: dateUtil.dateString2DateOrNull(gqlAdmin?.birthdate ?? ''),
          employeeId: gqlAdmin?.employeeId ?? "",
          address: gqlAdmin?.address ?? "",
          primaryPhone: gqlAdmin?.primaryPhone ?? "",
          secondaryPhone: gqlAdmin?.secondaryPhone ?? "",
          supervisorUserId: gqlAdmin?.supervisor?.id ?? '',
          altSupervisorUserId: gqlAdmin?.altSupervisor?.id ?? '',
          payRate: gqlAdmin?.payRate ?? "",
          permissionCategories: _.map(gqlAdmin?.permissionCategories ?? [], (category): types.IPermissionCategory => ({
            _key: category.id,
            name: category.name
          }))
        },
        fullName: `${gqlAdmin?.firstName ?? ""} ${gqlAdmin?.lastName ?? ""}`.trim(),
        email: gqlAdmin?.siteUserFull?.email ?? "",
        isSelf: gqlAdmin?.siteUserFull?.id === tuple.data?.loggedInUser?.id
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



export function useSaveAdministratorMutation() {
  const [fnMutate, tuple] = useBaseSaveAdministratorMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id: formState.id || undefined,
            firstName: formState.firstName.trim(),
            lastName: formState.lastName.trim(),
            birthdate: formState.birthdate || null,
            employeeId: formState.employeeId.trim(),
            address: formState.address.trim(),
            primaryPhone: formState.primaryPhone.trim(),
            secondaryPhone: formState.secondaryPhone.trim(),
            supervisorUserId: formState.supervisorUserId || null,
            altSupervisorUserId: formState.altSupervisorUserId || null,
            payRate: formState.payRate.trim() || null,
            permissionCategoryNames: _.compact(_.map(formState.permissionCategories, (category): PermissionCategoryName | '' => category.name))
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useArchiveAdministratorMutation() {
  const [fnMutate, tuple] = useBaseArchiveAdministratorMutation();

  const fnWrappedMutate = React.useCallback(
    async (id: string) => {
      await fnMutate({
        variables: {
          id
        }
      });
    },
    [fnMutate]
  );

  const fnWrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    fnWrappedTuple
  ] as const;
}
