import React from 'react';
import _ from 'lodash';

import {
  SkeletonRows,
  DetailedListItem,
  ValueWithLabel,
  chips,
  errorUtil
} from 'modules';
import {
  Box,
  Button,
  Grid,
  Typography
} from '@mui/material';
import {
  GenerateVisitsModal,
  IGenerateVisitsData
} from './GenerateVisitsModal/GenerateVisitsModal';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';



const SectionHeaderBox = styled(Box)(({theme}) => ({
  marginBottom: theme.spacing(3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));



export interface ICarePlanTabProps {
  episodeId: string;
}

export const CarePlanTab: React.FC<ICarePlanTabProps> = props => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.episodeId);
  const [fnGenerateVisits, generateVisitsMutationTuple] = dataService.useGenerateVisitsFromTemplateMutation(queryTuple.refetch);

  const isLoading =
    queryTuple.loading ||
    generateVisitsMutationTuple.loading;

  const [generateVisitsModalOpenForTemplateId, setGenerateVisitsModalOpenForTemplateId] = React.useState('');



  async function handleAcceptGenerateVisitsModal(data: IGenerateVisitsData) {
    await errorUtil.errorWrap(async () => {
      await fnGenerateVisits({
        visitTemplateId: generateVisitsModalOpenForTemplateId,
        dayOfWeekNames: data.daysOfWeek,
        assignedEmployeeIds: data.assignedEmployeeIds
      });
      setGenerateVisitsModalOpenForTemplateId('');
    });
  }



  return queryTuple.loading ? (
    <SkeletonRows numRows={10} />

  ) : (
    <>
      <Box>
        <Box mb={3}>
          <SectionHeaderBox>
            <Typography variant="h2">
              {t('visits:visitTemplates')}
            </Typography>

            {queryTuple.data.canEdit && (
              <Button
                variant="contained"
                onClick={() => navigate(`/admin/visitTemplates/create/${props.episodeId}`)}>
                {t('visits:addVisitTemplate')}
              </Button>
            )}
          </SectionHeaderBox>

          {queryTuple.data.visitTemplates.length === 0 && (
            <div>
              {t('visits:noVisitTemplates')}
            </div>
          )}

          {_.map(queryTuple.data.visitTemplates, visitTemplate => (
            <DetailedListItem
              key={visitTemplate.id}
              title={visitTemplate.displayName}
              toRoute={`/admin/visitTemplates/view/${visitTemplate.id}`}
              elLeft={queryTuple.data.canCreateVisits ? (
                <Button
                  onClick={() => setGenerateVisitsModalOpenForTemplateId(visitTemplate.id)}>
                  {t('visits:createVisits')}
                </Button>
              ) : undefined}
              buryLeftClickEvent
              additionalInfo={(
                <Grid container>
                  <ValueWithLabel
                    label={t('visits:visitCategory')}
                    value={t(`visits:visitCategories.${visitTemplate.visitCategoryName}`)} />

                  <ValueWithLabel
                    label={t('visits:shift')}
                    value={t(`visits:shifts.${visitTemplate.shiftName}`)} />

                  <ValueWithLabel
                    label={t('visits:frequency')}
                    value={t('visits:frequencyValue', {
                      amount: visitTemplate.frequencyAmount,
                      unit: t(`visits:frequencyUnits.${visitTemplate.frequencyUnitName}`)
                    })} />

                  <ValueWithLabel
                    label={t('visits:location')}
                    value={visitTemplate.location} />

                  <ValueWithLabel
                    label={t('visits:note')}
                    value={visitTemplate.note} />
                </Grid>
              )} />
          ))}
        </Box>

        <Box mb={3}>
          <SectionHeaderBox>
            <Typography variant="h2">
              {t('visits:visits')}
            </Typography>
          </SectionHeaderBox>

          {queryTuple.data.visits.length === 0 && (
            <div>
              {t('visits:noVisits')}
            </div>
          )}

          {_.map(queryTuple.data.visits, visit => (
            <DetailedListItem
              key={visit.id}
              title={visit.displayName}
              toRoute={`/admin/visits/view/${visit.id}`}
              additionalInfo={(
                <Grid container>
                  <Grid
                    item
                    md={4}>
                    <chips.VisitStatusChip
                      valueId={visit.visitStatusName} />
                  </Grid>

                  <Grid
                    item
                    md={4}>
                    {visit.date}
                  </Grid>

                  <Grid
                    item
                    md={4}>
                    {t(`visits:shifts.${visit.shiftName}`)}
                  </Grid>
                </Grid>
              )} />
          ))}
        </Box>
      </Box>

      {generateVisitsModalOpenForTemplateId && (
        <GenerateVisitsModal
          isLoading={isLoading}
          onAccept={handleAcceptGenerateVisitsModal}
          onCancel={() => setGenerateVisitsModalOpenForTemplateId('')} />
      )}
    </>
  );
};
