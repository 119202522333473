import React from 'react';

import {
  usePatientProfileActivityPatientDetailsTabDetailsSectionQuery as useBaseQuery
} from 'generated/graphql';



export interface IData {
  firstName: string;
  lastName: string;
  gender: string;
  birthdate: string;
  medicareNumber: string;
  medicaidNumber: string;
  emrNumber: string;
  ssn: string;
  note: string;
}

export function useQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlPatient = tuple.data?.patient;
      const data: IData = {
        firstName: gqlPatient?.firstName ?? "",
        lastName: gqlPatient?.lastName ?? "",
        gender: gqlPatient?.gender?.displayName ?? "",
        birthdate: gqlPatient?.birthdate ?? "",
        medicareNumber: gqlPatient?.medicareNumber ?? "",
        medicaidNumber: gqlPatient?.medicaidNumber ?? "",
        emrNumber: gqlPatient?.emrNumber ?? "",
        ssn: gqlPatient?.ssn ?? "",
        note: gqlPatient?.note ?? ""
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
