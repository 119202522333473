import React from 'react';
import _ from 'lodash';

import {
  GenderName,
  PatientStatusName,
  useEditPatientActivityLazyQuery as useBaseLazyQuery,
  useSavePatientMutation as useBaseSavePatientMutation,
  useArchivePatientMutation as useBaseArchivePatientMutation
} from 'generated/graphql';

import * as types from './types';
import { dateUtil } from 'modules';



export interface IData {
  showSsn: boolean;
  formState: types.IFormState;
}

export function useEditPatientActivityLazyQuery() {
  const [fnQuery, tuple] = useBaseLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(
    () => {
      if (tuple.error?.message) {
        alert(tuple.error.message);
      }
    },
    [tuple.error?.message]
  );

  const fnWrappedQuery = React.useCallback(
    (patientId: string) => fnQuery({
      variables: {
        patientId
      }
    }),
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const gqlPatient = tuple.data?.patient;
      const gqlCategories = tuple.data?.loggedInUser?.typeSpecificUser.permissionCategories ?? [];
      const categoryNames = _.map(gqlCategories, gqlCategory => gqlCategory.name);
      const showSsn = _.includes(categoryNames, 'fullAdmin');

      const data: IData = {
        showSsn,
        formState: {
          id: gqlPatient?.id ?? '',
          firstName: gqlPatient?.firstName ?? "",
          lastName: gqlPatient?.lastName ?? "",
          patientStatus: gqlPatient?.patientStatus.name ?? '',
          birthdate: dateUtil.dateString2DateOrNull(gqlPatient?.birthdate ?? ''),
          gender: gqlPatient?.gender?.name ?? '',
          medicareNumber: gqlPatient?.medicareNumber ?? "",
          medicaidNumber: gqlPatient?.medicaidNumber ?? "",
          emrNumber: gqlPatient?.emrNumber ?? "",
          address: gqlPatient?.address ?? "",
          locationNote: gqlPatient?.locationNote ?? "",
          primaryPhone: gqlPatient?.primaryPhone ?? "",
          secondaryPhone: gqlPatient?.secondaryPhone ?? "",
          socDate: dateUtil.dateString2DateOrNull(gqlPatient?.socDate ?? ''),
          referralDate: dateUtil.dateString2DateOrNull(gqlPatient?.referralDate ?? ''),
          primaryProviderId: gqlPatient?.primaryProvider?.id ?? '',
          secondaryProviderId: gqlPatient?.secondaryProvider?.id ?? '',
          referringProviderId: gqlPatient?.referringProvider?.id ?? '',
          pharmacyId: gqlPatient?.pharmacy?.id ?? '',
          ssn: gqlPatient?.ssn ?? "",
          note: gqlPatient?.note ?? "",
          emergencyName: gqlPatient?.emergencyName ?? "",
          emergencyRelationship: gqlPatient?.emergencyRelationship ?? "",
          emergencyPhone: gqlPatient?.emergencyPhone ?? "",
          emergencyAddress: gqlPatient?.emergencyAddress ?? ""
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



export function useSavePatientMutation() {
  const [fnMutate, tuple] = useBaseSavePatientMutation();

  const fnWrappedMutate = React.useCallback(
    async (showSsn: boolean, formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id: formState.id || undefined,
            firstName: formState.firstName.trim(),
            lastName: formState.lastName.trim(),
            patientStatusName: formState.patientStatus as PatientStatusName,
            birthdate: formState.birthdate || null,
            genderName: (formState.gender || null) as GenderName | null,
            medicareNumber: formState.medicareNumber.trim(),
            medicaidNumber: formState.medicaidNumber.trim(),
            emrNumber: formState.emrNumber.trim(),
            address: formState.address.trim(),
            locationNote: formState.locationNote.trim(),
            primaryPhone: formState.primaryPhone.trim(),
            secondaryPhone: formState.secondaryPhone.trim(),
            socDate: formState.socDate || null,
            referralDate: formState.referralDate || null,
            primaryProviderId: formState.primaryProviderId || null,
            secondaryProviderId: formState.secondaryProviderId || null,
            referringProviderId: formState.referringProviderId || null,
            pharmacyId: formState.pharmacyId || null,
            ssn: showSsn ? formState.ssn.trim() : undefined,
            note: formState.note.trim(),
            emergencyName: formState.emergencyName.trim(),
            emergencyRelationship: formState.emergencyRelationship.trim(),
            emergencyPhone: formState.emergencyPhone.trim(),
            emergencyAddress: formState.emergencyAddress.trim()
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useArchivePatientMutation() {
  const [fnMutate, tuple] = useBaseArchivePatientMutation();

  const fnWrappedMutate = React.useCallback(
    async (id: string) => {
      await fnMutate({
        variables: {
          id
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
