import React from 'react';
import _ from 'lodash';

import {
  useEmployeeSelectFieldQuery as useBaseQuery
} from 'generated/graphql';



export interface IEmployee {
  id: string;
  fullName: string;
  hasAttachedUser: boolean;
}

export interface IData {
  employees: IEmployee[];
  employeesById: Record<string, IEmployee>;
}

export function useEmployeeSelectFieldQuery() {
  const tuple = useBaseQuery();

  return React.useMemo(
    () => {
      const gqlEmployees = tuple.data?.employees ?? [];

      const employees = _.map(gqlEmployees, (gqlEmployee): IEmployee => ({
        id: gqlEmployee.id,
        fullName: `${gqlEmployee.firstName} ${gqlEmployee.lastName}`.trim(),
        hasAttachedUser: !!gqlEmployee.siteUserLite
      }));

      const sortedEmployees = _.sortBy(employees, employee =>
        employee.fullName.toLowerCase());

      sortedEmployees.push({
        id: '',
        fullName: "",
        hasAttachedUser: false
      });

      return {
        ...tuple,
        data: {
          employees: sortedEmployees,
          employeesById: _.keyBy(employees, employee => employee.id)
        } as IData
      };
    },
    [tuple]
  );
}
