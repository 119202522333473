import _ from 'lodash';

import { enumCache } from 'modules/layer2';
import * as chipGenerator from '../shared/chipGenerator';
import { map } from 'rxjs';



export const RequirementStatusChip = chipGenerator.genChipComponent<enumCache.IRequirementStatusName>({
  obsValueId2DisplayData: enumCache.subjAllEnums.asObservable()
    .pipe(map((value): Record<enumCache.IRequirementStatusName, chipGenerator.IValueDisplayData> => {
      const enumValues = value.requirementStatuses;
      const enumValuesByName = _.keyBy(enumValues, enumValue => enumValue.name) as Record<enumCache.IRequirementStatusName, enumCache.IRequirementStatus>;

      return {
        completed: {
          label: enumValuesByName.completed?.displayName ?? "",
          backgroundColor: '#263238'
        },
        pending: {
          label: enumValuesByName.pending?.displayName ?? "",
          backgroundColor: '#317FF4'
        },
        rejected: {
          label: enumValuesByName.rejected?.displayName ?? "",
          backgroundColor: '#E74C3C'
        },
        submitted: {
          label: enumValuesByName.submitted?.displayName ?? "",
          backgroundColor: '#00CE84'
        }
      };
    }))
});
