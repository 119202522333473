import React from 'react';

import { styled } from '@mui/material/styles';



const BaseForm = styled('form')(() => ({
  flex: '1 0',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));



export interface IFormProps {
  onSubmit: (e?: React.BaseSyntheticEvent<any, any, any> | undefined) => Promise<void>;
}

export const Form: React.FC<IFormProps> = props => {
  return (
    <BaseForm
      onSubmit={props.onSubmit}
      autoComplete="off">
      {props.children}
    </BaseForm>
  );
};

