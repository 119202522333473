import React from 'react';
import _ from 'lodash';

import {
  enumCache,
  fields,
  genEnumSelectFieldComponent
} from 'modules/layer2';

import i18next from 'i18next';



function useQuery(): fields.IUseGetEnumValuesTuple<enumCache.IMissedVisitReasonName, enumCache.IMissedVisitReason> {
  const [values, setValues] = React.useState<enumCache.IMissedVisitReason[]>([]);

  React.useEffect(
    () => {
      const sub = enumCache.subjAllEnums.subscribe(data =>
        setValues(_.filter(data.missedVisitReasons, value =>
          value.isUserSelectable)));
      return () => sub.unsubscribe();
    },
    []
  );

  return {
    loading: values.length === 0,
    data: {
      enumValues: values
    }
  };
}



export const MissedVisitReasonSelectField = genEnumSelectFieldComponent<enumCache.IMissedVisitReasonName, enumCache.IMissedVisitReason>(
  useQuery,
  () => i18next.t('visits:missedVisitReason')
);
