import React from 'react';

import { BaseActivityTemplate } from 'modules';
import { useNavigate } from 'react-router-dom';

import * as types from './types';
import * as LoginFormSection from './LoginFormSection/LoginFormSection';
import * as ConfirmLoginCodeSection from './ConfirmLoginCodeSection/ConfirmLoginCodeSection';



export const LoginActivity: React.FC = () => {
  const navigate = useNavigate();

  const [state, setState] = React.useState<types.IState>('login');



  function handleChangeState(state: types.IState) {
    if (state === 'done') {
      navigate('/', {replace: true});

    } else if (state === 'changePassword') {
      navigate('/auth/changePassword');

    } else {
      setState(state);
    }
  }



  return (
    <BaseActivityTemplate
      mode="auth"
      useAuthBackground
      onlyAllowLoggedOutUsers>

      {state === 'login' && (
        <LoginFormSection.Comp
          onChangeState={handleChangeState} />
      )}

      {state === 'confirmLoginCode' && (
        <ConfirmLoginCodeSection.Comp
          onChangeState={handleChangeState} />
      )}

    </BaseActivityTemplate>
  );
};
