import React from 'react';

import {
  Box,
  Button
} from '@mui/material';

import { DeleteForever } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { paletteColors } from 'modules/layer0';
import { ConfirmationModal } from 'modules/layer2';



const ContainerBox = styled(Box)(({theme}) => ({
  paddingTop: theme.spacing(3),
  paddingRight: theme.spacing(9),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(9),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

const ButtonRowBox = styled(Box)(() => ({
  display: 'flex'
}));

const ButtonContainerBox = styled(Box)(({theme}) => ({
  marginLeft: theme.spacing(2)
}));

const DeleteButton = styled(Button)(() => ({
  borderColor: paletteColors.danger,
  color: paletteColors.danger,
  backgroundColor: '#fff'
}));



export interface IFooterProps {
  hasDeleteButton?: boolean;
  deleteButtonLabel?: string;
  saveButtonLabel?: string;
  onConfirmDelete?: () => void;
  onClickCancel: () => void;
}

export const Footer: React.FC<IFooterProps> = props => {
  const {t} = useTranslation();

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = React.useState(false);



  function handleConfirmDelete() {
    setIsDeleteConfirmationModalOpen(false);
    if (props.onConfirmDelete) {
      props.onConfirmDelete();
    }
  }



  return (
    <>
      <ContainerBox>
        <Box>
          {props.hasDeleteButton && (
            <DeleteButton
              variant="outlined"
              startIcon={(
                <DeleteForever />
              )}
              onClick={() => setIsDeleteConfirmationModalOpen(true)}>
              {props.deleteButtonLabel ?? t('delete')}
            </DeleteButton>
          )}
        </Box>

        <ButtonRowBox>
          <ButtonContainerBox>
            <Button
              variant="outlined"
              onClick={props.onClickCancel}>
              {t('cancel')}
            </Button>
          </ButtonContainerBox>

          <ButtonContainerBox>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation>
              {props.saveButtonLabel ?? t('save')}
            </Button>
          </ButtonContainerBox>
        </ButtonRowBox>
      </ContainerBox>

      <ConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        title="Confirm removal"
        message="Are you sure you want to delete? This action cannot be undone."
        acceptButtonLabel="Delete"
        cancelButtonLabel="Cancel"
        onAccept={handleConfirmDelete}
        onCancel={() => setIsDeleteConfirmationModalOpen(false)} />
    </>
  );
};
