import React from 'react';

import { authService } from 'modules';
import update from 'immutability-helper';



export interface ITuple {
  isLoading: boolean;
}

export function useLogIn() {
  const [tuple, setTuple] = React.useState<ITuple>({
    isLoading: false
  });

  async function fnLogIn(email: string, password: string) {
    try {
      setTuple(previous => update(previous, {
        isLoading: {
          $set: true
        }
      }));

      const totpStatus = await authService.logIn({
        email,
        password
      });

      return totpStatus;

    } finally {
      setTuple(previous => update(previous, {
        isLoading: {
          $set: false
        }
      }));
    }
  }

  return [
    fnLogIn,
    tuple
  ] as const;
}
