import React from 'react';
import _ from 'lodash';

import {
  useMessengerPostMessageMutation,

  NewDisciplineMessageModalDocument,
  NewDisciplineMessageModalQueryResult,
  NewDisciplineMessageModalQueryVariables
} from 'generated/graphql';
import {
  enumCache,
  apolloService
} from 'modules';




export interface IDiscipline {
  _key: string;
  name: enumCache.IDisciplineName | '';
}

export interface IFormState {
  disciplines: IDiscipline[];
  message: string;
}



export function usePostMutation() {
  const [client, setClient] = React.useState<apolloService.IApolloClient | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const [fnMutate, tuple] = useMessengerPostMessageMutation();



  React.useEffect(
    () => {
      const sub = apolloService.getClientObservable()
        .subscribe(value => setClient(value));

      return () => sub.unsubscribe();
    },
    []
  );



  const fnWrappedMutate = React.useCallback(
    async (formState: IFormState) => {
      if (client) {
        setIsLoading(true);

        const queryResult = await client.query<NewDisciplineMessageModalQueryResult['data'], NewDisciplineMessageModalQueryVariables>({
          query: NewDisciplineMessageModalDocument,
          variables: {
            disciplineNames: _.compact(_.map(formState.disciplines, discipline => discipline.name))
          },
          fetchPolicy: 'no-cache'
        });

        const siteUserIds = _.compact(_.map(queryResult.data?.employees ?? [], employee =>
          employee.siteUserLite?.id));

        const retVal = await fnMutate({
          variables: {
            input: {
              recipientUserIds: siteUserIds,
              message: formState.message.trim()
            }
          }
        });

        setIsLoading(true);
        return retVal.data?.messengerPostMessage.thread.id ?? '';
      }

      return '';
    },
    [
      fnMutate,
      client
    ]
  );



  return [
    fnWrappedMutate,
    {
      ...tuple,
      loading: isLoading || tuple.loading,
      data: null
    }
  ] as const;
}
