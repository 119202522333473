import _ from 'lodash';
import moment from 'moment';

import {
  MessengerServiceDocument as QueryDocument,
  MessengerServiceQueryResult as QueryResult,
  MessengerServiceQueryVariables as QueryVariables,

  MessengerMessagePostedDocument as SubscriptionDocument,
  MessengerMessagePostedSubscriptionResult as SubscriptionResult
} from 'generated/graphql';
import {
  Observable,
  BehaviorSubject,
  Subscription
} from 'rxjs';

import { authService } from 'modules/layer0';
import { apolloService } from 'modules/layer1';

import update from 'immutability-helper';
import i18next from 'i18next';



export interface IUser {
  id: string;
  fullName: string;
  isSelf: boolean;
  profilePictureFileKey: string;
}

export interface IMessage {
  id: string;
  threadId: string;
  timestamp: moment.Moment;
  authorUser: IUser;
  message: string;
  isInFlight: boolean;
}

export interface IThread {
  id: string;
  displayName: string;
  users: IUser[];
  messages: IMessage[];
  numUnreadMessages: number;
}

export interface IMessageWithThread {
  message: IMessage;
  thread: IThread;
}

export interface IData {
  threadsById: Record<string, IThread>;
}

const __subject = new BehaviorSubject<IData>({
  threadsById: {}
});

let __subscription: Subscription | null = null;
let __openThreadId = '';



interface IQueryReturn {
  selfUserId: string;
  threadsById: Record<string, IThread>;
}

async function __runQuery(client: apolloService.IApolloClient): Promise<IQueryReturn> {
  const queryResult = await client.query<QueryResult['data'], QueryVariables>({
    query: QueryDocument,
    fetchPolicy: 'no-cache'
  });

  const gqlSelfUser = queryResult.data?.loggedInUser;
  const selfUserId = gqlSelfUser?.id ?? '';

  return {
    selfUserId: gqlSelfUser?.id ?? '',
    threadsById: _.mapValues(
      _.keyBy(gqlSelfUser?.messengerThreads ?? [], thread => thread.id),
      (gqlThread): IThread => {
        const users = _.map(gqlThread.users, (gqlUser): IUser => ({
          id: gqlUser.id,
          fullName: `${gqlUser.fullName}${gqlUser.isArchived ? ` ${i18next.t('deletedTag')}` : ""}`,
          isSelf: gqlUser.id === selfUserId,
          profilePictureFileKey: gqlUser.profilePictureFile?.key ?? ''
        }));

        const nonSelfUserNames = _.join(
          _.map(
            _.filter(users, user => !user.isSelf),
            user => user.fullName),
          ", ");

        return ({
          id: gqlThread.id,
          displayName: gqlThread.displayName || nonSelfUserNames,
          users,
          messages: _.map(gqlThread.messages, (gqlMessage): IMessage => ({
            id: gqlMessage.id,
            authorUser: {
              id: '',
              fullName: "",
              isSelf: false,
              profilePictureFileKey: ''
            },
            message: gqlMessage.message,
            isInFlight: false,
            threadId: gqlThread.id,
            timestamp: moment(gqlMessage.timestamp, moment.ISO_8601)
          })),
          numUnreadMessages: gqlThread.numSelfUnreadMessages
        });
      })
  };
}



function __makeSubscriptionObservable(client: apolloService.IApolloClient, selfUserId: string) {
  return new Observable<IMessageWithThread | null>(subscriber => {
    const observable = client.subscribe<SubscriptionResult['data']>({
      query: SubscriptionDocument
    });

    const rawSub = observable.subscribe(res => {
      const gqlMessage = res.data?.messengerMessagePosted ?? null;
      const gqlThread = gqlMessage?.thread ?? null;

      const threadUsers = _.map(gqlThread?.users ?? [], (gqlUser): IUser => ({
        id: gqlUser.id,
        fullName: `${gqlUser.fullName}${gqlUser.isArchived ? ` ${i18next.t('deletedTag')}` : ""}`,
        isSelf: gqlUser.id === selfUserId,
        profilePictureFileKey: gqlUser.profilePictureFile?.key ?? ''
      }));
      const threadUsersById = _.keyBy(threadUsers, user => user.id);
      const firstNonSelfUser = _.first(_.filter(threadUsers, user => !user.isSelf)) ?? null;

      subscriber.next((gqlMessage && gqlThread) ? {
        message: {
          id: gqlMessage.id,
          threadId: gqlMessage.thread.id,
          timestamp: moment(gqlMessage.timestamp, moment.ISO_8601),
          message: gqlMessage.message,
          authorUser: threadUsersById[gqlMessage.author.id] ?? {
            id: gqlMessage.author.id,
            fullName: "",
            isSelf: false
          },
          isInFlight: false
        },
        thread: {
          id: gqlThread.id,
          displayName: gqlThread.displayName || firstNonSelfUser?.fullName || "",
          messages: [],
          users: threadUsers,
          numUnreadMessages: 0
        }
      } : null);
    });

    subscriber.next();

    return () => rawSub.unsubscribe();
  });
}



function __initSubscription(client: apolloService.IApolloClient, selfUserId: string) {
  const rawObservable = __makeSubscriptionObservable(client, selfUserId);

  return rawObservable.subscribe(messageWithThread => {
    if (messageWithThread) {
      const newValue = _.cloneDeep(__subject.value);
      if (!newValue.threadsById[messageWithThread.message.threadId]) {
        newValue.threadsById[messageWithThread.message.threadId] = _.cloneDeep(messageWithThread.thread);
      }

      __subject.next(update(newValue, {
        threadsById: {
          [messageWithThread.message.threadId]: {
            messages: {
              $push: [messageWithThread.message]
            },
            numUnreadMessages: {
              $apply: previous => (messageWithThread.message.threadId !== __openThreadId && !messageWithThread.message.authorUser.isSelf) ?
                previous + 1 :
                0
            }
          }
        }
      }));
    }
  });
}



export async function init() {
  let lastAuthStateStatus: authService.IStatus | '' = '';

  authService.getAuthStateObservable()
    .subscribe(async authState => {
      if (lastAuthStateStatus !== authState.status) {
        lastAuthStateStatus = authState.status;
        apolloService.refreshClient();
      }
    });

  apolloService.getClientObservable()
    .subscribe(async client => {
      if (client) {
        if (__subscription) {
          __subscription.unsubscribe();
          __subscription = null;
        }

        const queryResult = await __runQuery(client);
        __subject.next({
          threadsById: queryResult.threadsById
        });
        __subscription = __initSubscription(client, queryResult.selfUserId);
      }
    });
}



export function getObservable() {
  return __subject.asObservable();
}



export function clearThreadUnreadMessages(threadId: string) {
  if (__subject.value.threadsById[threadId]) {
    __subject.next(update(__subject.value, {
      threadsById: {
        [threadId]: {
          numUnreadMessages: {
            $set: 0
          }
        }
      }
    }));
  }
}



export function setOpenThreadId(threadId: string) {
  __openThreadId = threadId;
}
