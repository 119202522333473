import React from 'react';
import _ from 'lodash';

import {
  chips,
  dateUtil,
  genTableComponent,
  IRow,
  LoadingOverlay
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



const __columnIds = [
  'patient',
  'visit',
  'visitStatus',
  'date',
  'shift',
  'clockInTimestamp',
  'clockOutTimestamp',
  'clockedDurationHours'
] as const;
type IColumnId = typeof __columnIds[number];

const Table = genTableComponent<IColumnId>();



export interface IVisitsTabProps {
  employeeId: string;
}

export const VisitsTab: React.FC<IVisitsTabProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(props.employeeId);



  return (
    <>
      <LoadingOverlay
        active={queryTuple.loading} />

      <Table
        columns={[
          {
            id: 'patient',
            label: t('visits:patient')
          },
          {
            id: 'visit',
            label: t('visits:visit')
          },
          {
            id: 'visitStatus',
            label: t('visits:visitStatus')
          },
          {
            id: 'date',
            label: t('date')
          },
          {
            id: 'shift',
            label: t('shift')
          },
          {
            id: 'clockInTimestamp',
            label: t('visits:clockInTime')
          },
          {
            id: 'clockOutTimestamp',
            label: t('visits:clockOutTime')
          },
          {
            id: 'clockedDurationHours',
            label: t('visits:durationHours')
          }
        ]}
        rows={_.map(queryTuple.data.visits, (visit): IRow<IColumnId> => ({
          id: visit.id,
          columnId2Value: {
            patient: {
              type: 'string',
              label: visit.patient.fullName,
              toRoute: visit.patient.route
            },
            visit: {
              type: 'string',
              label: visit.name,
              toRoute: visit.route
            },
            visitStatus: {
              type: 'component',
              label: (
                <chips.VisitStatusChip
                  valueId={visit.status} />
              ),
              sortableValue: visit.status
            },
            date: {
              type: 'string',
              label: visit.date ?
                `${visit.date.format(dateUtil.dateFormat)} (${visit.timeZone})` :
                t('noValue'),
              sortableValue: visit.date?.unix() ?? 0
            },
            shift: {
              type: 'string',
              label: t(`shifts.${visit.shift}`)
            },
            clockInTimestamp: {
              type: 'string',
              label: visit.clockInTimestamp?.format(dateUtil.timestampFormat) ?? t('noValue'),
              sortableValue: visit.clockInTimestamp?.unix() ?? 0
            },
            clockOutTimestamp: {
              type: 'string',
              label: visit.clockOutTimestamp?.format(dateUtil.timestampFormat) ?? t('noValue'),
              sortableValue: visit.clockOutTimestamp?.unix() ?? 0
            },
            clockedDurationHours: {
              type: 'string',
              label: visit.clockedDurationHours.toFixed(2),
              sortableValue: visit.clockedDurationHours
            }
          }
        }))} />
    </>
  );
};
