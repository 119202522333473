import i18n from 'i18next';



export function removeGraphqlErrorPrefix(errorMessage: string) {
  return errorMessage.replace(/^GraphQL error: /, '');
}



export async function errorWrap<T>(fn: () => Promise<T>, errorCode2Message?: Record<string, string>) {
  try {
    return await fn();

  } catch (err: any) {
    console.error(err);
    if (errorCode2Message) {
      const errorCode = removeGraphqlErrorPrefix(err.message);
      const message = errorCode2Message[errorCode];
      if (message) {
        alert(message);
      } else {
        alert(i18n.t('error:unknown'));
      }

    } else {
      alert(err.message);
    }
  }
}
