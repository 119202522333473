import React from 'react';

import {
  BaseModal,
  SectionHeaderBox
} from 'modules';
import {
  Box,
  Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { SignatureCapturePad } from './SignatureCapturePad/SignatureCapturePad';
import { Subject } from 'rxjs';



export interface ISignatureCaptureModalProps {
  onAccept: (dataUrl: string) => void;
  onCancel: () => void;
}

export const SignatureCaptureModal: React.FC<ISignatureCaptureModalProps> = props => {
  const {t} = useTranslation();

  const refSubjGetImage = React.useRef(new Subject<void>());



  return (
    <BaseModal
      isOpen
      showButtons
      expandWidthToFit
      onAccept={() => refSubjGetImage.current.next()}
      onClose={props.onCancel}>

      {buttons => (
        <Box>
          <SectionHeaderBox>
            <Typography variant="h1">
              {t('visits:signatureEntry')}
            </Typography>
          </SectionHeaderBox>

          <Box p={1}>
            <SignatureCapturePad
              obsGetImage={refSubjGetImage.current}
              onCaptureImage={props.onAccept} />
          </Box>

          {buttons}
        </Box>
      )}

    </BaseModal>
  );
};
