import React from 'react';

import {
  useEmployeeMyProfileActivityQuery
} from 'generated/graphql';



export interface IData {
  employeeId: string;
  fullName: string;
}

export function useQuery() {
  const tuple = useEmployeeMyProfileActivityQuery({fetchPolicy: 'no-cache'});

  return React.useMemo(
    () => {
      const data: IData = {
        employeeId: tuple.data?.loggedInUser?.typeSpecificUser.__typename === 'Employee' ?
          tuple.data.loggedInUser.typeSpecificUser.id :
          '',
        fullName: tuple.data?.loggedInUser?.fullName ?? ""
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
