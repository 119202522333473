import React from 'react';

import { Card as BaseCard } from '@mui/material';
import { styled } from '@mui/material/styles';



const ContainerDiv = styled('div')(({theme}) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const Card = styled(BaseCard)(() => ({
  width: '407px'
}));

const BodyDiv = styled('div')(({theme}) => ({
  padding: theme.spacing(4),
  flex: '1 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch'
}));



export const Comp: React.FC = props => {
  return (
    <ContainerDiv>
      <Card>
        <BodyDiv>
          {props.children}
        </BodyDiv>
      </Card>
    </ContainerDiv>
  );
};
