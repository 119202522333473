import React from 'react';
import _ from 'lodash';

import {
  useMedicationSelectFieldLazyQuery
} from 'generated/graphql';



export interface IData {
  medications: string[];
}

const __debounceIntervalMs = 2000;

export function useLazyQuery() {
  const [fnQuery, tuple] = useMedicationSelectFieldLazyQuery({fetchPolicy: 'no-cache'});
  const [isLoading, setIsLoading] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState<number | null>(null);



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  const fnWrappedQuery = React.useCallback(
    (terms: string) => {
      setIsLoading(true);
      if (timeoutId !== null) {
        window.clearTimeout(timeoutId);
        setTimeoutId(null);
      }

      setTimeoutId(window.setTimeout(
        () => {
          fnQuery({
            variables: {
              terms
            }
          });
          setTimeoutId(null);
          setIsLoading(false);
        },
        __debounceIntervalMs
      ));
    },
    [
      fnQuery,
      timeoutId
    ]
  );



  const wrappedTuple = React.useMemo(
    () => {
      const gqlMedications = tuple.data?.searchMedications ?? [];
      const medications = _.map(gqlMedications, gqlMedication => gqlMedication.term);
      const finalIsLoading = tuple.loading || isLoading;

      return {
        ...tuple,
        loading: finalIsLoading,
        data: {
          medications
        } as IData
      };
    },
    [
      tuple,
      isLoading
    ]
  );



  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}
