import React from 'react';
import _ from 'lodash';

import {
  useTaskSetTemplateSelectFieldQuery
} from 'generated/graphql';



export interface ITask {
  title: string;
  description: string;
}

export interface ITaskSetTemplate {
  id: string;
  displayName: string;
  tasks: ITask[];
}

export interface IData {
  taskSetTemplates: ITaskSetTemplate[];
}

export function useQuery() {
  const tuple = useTaskSetTemplateSelectFieldQuery({fetchPolicy: 'no-cache'});



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const taskSetTemplates = _.map(tuple.data?.taskSetTemplates ?? [], (gqlTemplate): ITaskSetTemplate => ({
        id: gqlTemplate.id,
        displayName: gqlTemplate.displayName,
        tasks: _.map(gqlTemplate.tasks, (gqlTask): ITask => ({
          title: gqlTask.title,
          description: gqlTask.description
        }))
      }));

      const data: IData = {
        taskSetTemplates: [
          ..._.sortBy(taskSetTemplates, template => template.displayName.toLowerCase()),
          {
            id: '',
            displayName: "",
            tasks: []
          }
        ]
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
