import React from 'react';
import moment from 'moment';

import {
  useEmployeeProfileProfileTabGeneralInformationSectionQuery as useBaseQuery
} from 'generated/graphql';

import { useTranslation } from 'react-i18next';



export interface IData {
  profilePictureKey: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  email: string;
  birthdate: string;
  hireStatusDisplayName: string;
  disciplineDisplayName: string;
  address: string;
  primaryPhone: string;
  secondaryPhone: string;
  supervisorId: string;
  altSupervisorId: string;
  payRate: string;
}

export function useEmployeeProfileProfileTabGeneralInformationSectionQuery(id: string) {
  const {t} = useTranslation();
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlEmployee = tuple.data?.employee;
      const data: IData = {
        profilePictureKey: gqlEmployee?.profilePictureFile?.key ?? '',
        firstName: gqlEmployee?.firstName || t('noValue'),
        lastName: gqlEmployee?.lastName || t('noValue'),
        employeeId: gqlEmployee?.employeeId || t('noValue'),
        email: gqlEmployee?.siteUserFull?.email || t('noValue'),
        birthdate: gqlEmployee?.birthdate ?
          moment(gqlEmployee.birthdate, moment.ISO_8601).format('YYYY-MM-DD') :
          t('noValue'),
        hireStatusDisplayName: gqlEmployee?.hireStatus.displayName || "",
        disciplineDisplayName: gqlEmployee?.discipline?.displayName || t('noValue'),
        address: gqlEmployee?.address || t('noValue'),
        primaryPhone: gqlEmployee?.primaryPhone || t('noValue'),
        secondaryPhone: gqlEmployee?.secondaryPhone || t('noValue'),
        supervisorId: gqlEmployee?.supervisor?.id ?? '',
        altSupervisorId: gqlEmployee?.altSupervisor?.id ?? '',
        payRate: gqlEmployee?.payRate ?
          `$${gqlEmployee.payRate} / hour` :
          t('noValue')
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
