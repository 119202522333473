import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import {
  EpisodeDataMedicationInput,
  EpisodeDataSchemaFieldsFragment,
  EpisodeDataValueFieldsFragment,
  EpisodeDataValueInput,
  useEditEpisodeActivityQuery as useBaseQuery,
  useSaveEpisodeMutation as useBaseSaveEpisodeMutation,
  useArchiveEpisodeMutation as useBaseArchiveEpisodeMutation,
  EpisodeDataSurgicalProcedureInput,
  DiagnosisInput
} from 'generated/graphql';

import * as uuid from 'uuid';
import * as types from './types';



export type ISchema = Record<string, types.ISchemaValue>;

export interface IData {
  hasParentEpisode: boolean;
  riskAssessmentSchema: ISchema;
  mentalStatusSchema: ISchema;
  neurologicalSchema: ISchema;
  psychosocialSchema: ISchema;
  dmeSchema: ISchema;
  suppliesSchema: ISchema;
  prognosisSchema: ISchema;
  safetyMeasuresSchema: ISchema;
  nutritionRequirementsSchema: ISchema;
  functionalLimitationsSchema: ISchema;
  activitiesPermittedSchema: ISchema;
  formState: types.IFormState;
}

export function useQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => {
      const gqlData = tuple.data;
      const riskAssessmentSchema = __gqlSchemaValues2Client(gqlData?.riskAssessmentSchema?.values ?? []);
      const mentalStatusSchema = __gqlSchemaValues2Client(gqlData?.mentalStatusSchema?.values ?? []);
      const neurologicalSchema = __gqlSchemaValues2Client(gqlData?.neurologicalSchema?.values ?? []);
      const psychosocialSchema = __gqlSchemaValues2Client(gqlData?.psychosocialSchema?.values ?? []);
      const dmeSchema = __gqlSchemaValues2Client(gqlData?.dmeSchema?.values ?? []);
      const suppliesSchema = __gqlSchemaValues2Client(gqlData?.suppliesSchema?.values ?? []);
      const prognosisSchema = __gqlSchemaValues2Client(gqlData?.prognosisSchema?.values ?? []);
      const safetyMeasuresSchema = __gqlSchemaValues2Client(gqlData?.safetyMeasuresSchema?.values ?? []);
      const nutritionRequirementsSchema = __gqlSchemaValues2Client(gqlData?.nutritionRequirementsSchema?.values ?? []);
      const functionalLimitationsSchema = __gqlSchemaValues2Client(gqlData?.functionalLimitationsSchema?.values ?? []);
      const activitiesPermittedSchema = __gqlSchemaValues2Client(gqlData?.activitiesPermittedSchema?.values ?? []);

      const gqlEpisodeData = gqlData?.episode?.episodeData;

      const data: IData = {
        hasParentEpisode: !!gqlData?.episode?.parentEpisode,
        riskAssessmentSchema,
        mentalStatusSchema,
        neurologicalSchema,
        psychosocialSchema,
        dmeSchema,
        suppliesSchema,
        prognosisSchema,
        safetyMeasuresSchema,
        nutritionRequirementsSchema,
        functionalLimitationsSchema,
        activitiesPermittedSchema,
        formState: {
          id: '',
          riskAssessmentValues: __schema2FormValues(riskAssessmentSchema, gqlEpisodeData?.riskAssessment ?? []),
          primaryProviderId: gqlEpisodeData?.primaryProvider?.id ?? '',
          primaryDiagnosisValue: gqlEpisodeData?.primaryDiagnosis ? {
            id: gqlEpisodeData.primaryDiagnosis.id,
            displayName: gqlEpisodeData.primaryDiagnosis.displayName,
            icd10Code: gqlEpisodeData.primaryDiagnosis.icd10Code
          } : null,
          otherDiagnosisValues: _.map(gqlEpisodeData?.otherDiagnoses ?? [], (gqlDx): types.IOtherDiagnosisValue => ({
            _key: uuid.v4(),
            diagnosis: {
              id: gqlDx.id,
              displayName: gqlDx.displayName,
              icd10Code: gqlDx.icd10Code
            }
          })),
          surgicalProcedures: _.map(gqlEpisodeData?.surgicalProcedures ?? [], (gqlProcedure): types.ISurgicalProcedure => ({
            id: gqlProcedure.id,
            code: gqlProcedure.code,
            displayName: gqlProcedure.displayName,
            procedureDateWithTz: gqlProcedure.procedureDate ?? `,${moment.tz.guess()}`
          })),
          mentalStatusValues: __schema2FormValues(mentalStatusSchema, gqlEpisodeData?.mentalStatus ?? []),
          neurologicalValues: __schema2FormValues(neurologicalSchema, gqlEpisodeData?.neurological ?? []),
          psychosocialValues: __schema2FormValues(psychosocialSchema, gqlEpisodeData?.psychosocial ?? []),
          dmeValues: __schema2FormValues(dmeSchema, gqlEpisodeData?.dme ?? []),
          suppliesValues: __schema2FormValues(suppliesSchema, gqlEpisodeData?.supplies ?? []),
          prognosisValue: gqlEpisodeData?.prognosis?.value ?? '',
          safetyMeasuresValues: __schema2FormValues(safetyMeasuresSchema, gqlEpisodeData?.safetyMeasures ?? []),
          nutritionRequirementsValues: __schema2FormValues(nutritionRequirementsSchema, gqlEpisodeData?.nutritionRequirements ?? []),
          functionalLimitationsValues: __schema2FormValues(functionalLimitationsSchema, gqlEpisodeData?.functionalLimitations ?? []),
          activitiesPermittedValues: __schema2FormValues(activitiesPermittedSchema, gqlEpisodeData?.activitiesPermitted ?? []),
          medications: _.map(gqlEpisodeData?.medications ?? [], (gqlMedication): types.IMedication => ({
            id: gqlMedication.id,
            name: gqlMedication.name,
            dosage: gqlMedication.dosage,
            instructions: gqlMedication.instructions
          })),
          diet: gqlEpisodeData?.diet ?? "",
          exerciseActivity: gqlEpisodeData?.exerciseActivity ?? ""
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



function __gqlSchemaValues2Client(gqlSchemaValues: EpisodeDataSchemaFieldsFragment['values']) {
  return _.keyBy(
    _.map(gqlSchemaValues, (gqlValue): types.ISchemaValue => ({
      value: gqlValue.value,
      hasDescription: gqlValue.hasDescription,
      descriptionLabel: gqlValue.descriptionLabel ?? undefined,
      childValues: _.map(gqlValue.children, gqlChild => gqlChild.value)
    })),
    value => value.value
  );
}



function __schema2FormValues(schema: Record<string, types.ISchemaValue>, gqlEpisodeDataValues: EpisodeDataValueFieldsFragment[]) {
  const episodeDataValuesByValue = _.keyBy(gqlEpisodeDataValues, value => value.value);
  return _.map(_.values(schema), (schemaValue): types.IValue => {
    const episodeDataValue = episodeDataValuesByValue[schemaValue.value];
    const childDataValuesByName = _.keyBy(episodeDataValue?.childValues ?? []);

    return {
      _key: uuid.v4(),
      name: schemaValue.value,
      isChecked: !!episodeDataValue,
      description: episodeDataValue?.description ?? "",
      childValues: _.map(schemaValue.childValues, (schemaValue): types.IChildValue => ({
        _key: uuid.v4(),
        name: schemaValue,
        isChecked: !!childDataValuesByName[schemaValue]
      }))
    };
  });
}



export function useSaveEpisodeMutation(id: string) {
  const [fnMutate, tuple] = useBaseSaveEpisodeMutation();

  const fnWrappedMutate = React.useCallback(
    async (formData: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id,
            episodeData: {
              primaryProviderId: formData.primaryProviderId || null,
              primaryDiagnosis: formData.primaryDiagnosisValue ? {
                displayName: formData.primaryDiagnosisValue.displayName,
                icd10Code: formData.primaryDiagnosisValue.icd10Code
              } : null,
              otherDiagnoses: _.map(
                _.filter(formData.otherDiagnosisValues, value => !!value.diagnosis),
                (formDx): DiagnosisInput => ({
                  displayName: formDx.diagnosis?.displayName ?? "",
                  icd10Code: formDx.diagnosis?.icd10Code ?? ""
                })),
              surgicalProcedures: _.map(formData.surgicalProcedures, (procedure): EpisodeDataSurgicalProcedureInput => ({
                code: procedure.code,
                displayName: procedure.displayName,
                procedureDate: procedure.procedureDateWithTz
              })),
              riskAssessmentValues: __values2GqlValueInput(formData.riskAssessmentValues),
              mentalStatusValues: __values2GqlValueInput(formData.mentalStatusValues),
              neurologicalValues: __values2GqlValueInput(formData.neurologicalValues),
              psychosocialValues: __values2GqlValueInput(formData.psychosocialValues),
              dmeValues: __values2GqlValueInput(formData.dmeValues),
              suppliesValues: __values2GqlValueInput(formData.suppliesValues),
              prognosisValue: formData.prognosisValue ? {
                value: formData.prognosisValue
              } : null,
              safetyMeasuresValues: __values2GqlValueInput(formData.safetyMeasuresValues),
              nutritionRequirementsValues: __values2GqlValueInput(formData.nutritionRequirementsValues),
              functionalLimitationsValues: __values2GqlValueInput(formData.functionalLimitationsValues),
              activitiesPermittedValues: __values2GqlValueInput(formData.activitiesPermittedValues),
              medications: _.map(formData.medications, (medication): EpisodeDataMedicationInput => ({
                name: medication.name.trim(),
                dosage: medication.dosage.trim(),
                instructions: medication.instructions.trim()
              })),
              diet: formData.diet.trim(),
              exerciseActivity: formData.exerciseActivity.trim()
            }
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



function __filterCheckedValues(values: types.IValue[]) {
  return _.filter(values, value => value.isChecked);
}



function __values2GqlValueInput(values: types.IValue[]) {
  const filteredValues = __filterCheckedValues(values);
  return _.map(filteredValues, (value): EpisodeDataValueInput => ({
    value: value.name,
    description: value.description.trim(),
    childValues: _.map(
      _.filter(value.childValues, childValue => childValue.isChecked),
      childValue => childValue.name)
  }));
}



export function useArchiveEpisodeMutation(id: string) {
  const [fnMutate, tuple] = useBaseArchiveEpisodeMutation({
    variables: {
      id
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      await fnMutate();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
