import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useMyVisitsActivityLazyQuery
} from 'generated/graphql';
import {
  dateUtil,
  enumCache
} from 'modules';



export interface IShift {
  name: enumCache.IShiftName;
  ranking: number;
}

export interface IVisit {
  id: string;
  displayName: string;
  patientName: string;
  otherAssignedEmployeeNames: string[];
  visitStatusName: enumCache.IVisitStatusName;
  date: moment.Moment;
  shift: IShift;
  location: string;
}

export interface IData {
  dayTimestamp2Visits: Record<string, IVisit[]>;
}

export function useLazyQuery() {
  const [fnQuery, tuple] = useMyVisitsActivityLazyQuery({
    fetchPolicy: 'no-cache'
  });

  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );

  const fnWrappedQuery = React.useCallback(
    (startDate: moment.Moment, endDate: moment.Moment) =>
      fnQuery({
        variables: {
          filter: {
            startDate: startDate.toISOString(true),
            endDate: endDate.toISOString(true)
          }
        }
      }),
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const rawStartDate = tuple.variables?.filter?.startDate ?? moment().startOf('week').toISOString(true);
      const mStartDate = moment(rawStartDate, moment.ISO_8601);

      const gqlTypeSpecificUser = tuple.data?.loggedInUser?.typeSpecificUser;
      const employeeId = gqlTypeSpecificUser?.__typename === 'Employee' ?
        gqlTypeSpecificUser.id :
        '';
      const gqlUser = tuple.data?.loggedInUser?.typeSpecificUser;
      const gqlVisits = gqlUser?.__typename === 'Employee' ?
        gqlUser.assignedVisits :
        [];

      const visits = _.sortBy(
        _.map(gqlVisits, (gqlVisit): IVisit => ({
          id: gqlVisit.id,
          displayName: gqlVisit.displayName,
          patientName: `${gqlVisit.episode.patient.firstName} ${gqlVisit.episode.patient.lastName}`,
          otherAssignedEmployeeNames: _.map(
            _.filter(gqlVisit.assignedEmployees, assignedEmployee => assignedEmployee.employee.id !== employeeId),
            gqlAssignedEmployee => `${gqlAssignedEmployee.employee.firstName} ${gqlAssignedEmployee.employee.lastName}`),
          visitStatusName: gqlVisit.visitStatus.name,
          date: dateUtil.dateWithTimeZone2Moment(gqlVisit.date) ?? moment(),
          shift: {
            name: gqlVisit.shift.name,
            ranking: gqlVisit.shift.ranking
          },
          location: gqlVisit.location
        })),
        [
          visit => visit.date.unix(),
          visit => visit.shift.ranking
        ]
      );

      const allDayTimestampsForWeek = _.times(7, index =>
        mStartDate.clone().add(index, 'days'));

      const baseDayTimestamp2Visits = _.zipObject(
        _.map(allDayTimestampsForWeek, timestamp => timestamp.toISOString(true)),
        _.map(allDayTimestampsForWeek, (): IVisit[] => [])
      );

      const data: IData = {
        dayTimestamp2Visits: _.assignIn(
          baseDayTimestamp2Visits,
          _.groupBy(visits, visit => visit.date.clone().startOf('day').toISOString(true)))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}
