import React from 'react';
import moment from 'moment';

import {
  SetEpisodeStatusActiveDataInput,
  SetEpisodeStatusInactiveDataInput,
  useEpisodeProfileActivityQuery as useBaseQuery,
  useSetEpisodeStatusMutation as useBaseSetEpisodeStatusMutation,
  useRecertifyEpisodeMutation as useBaseRecertifyEpisodeMutation,
  useResumeEpisodeMutation as useBaseResumeEpisodeMutation
} from 'generated/graphql';

import { useTranslation } from 'react-i18next';
import { enumCache } from 'modules';
import * as types from './types';



export interface IParentEpisode {
  id: string;
  ranking: number;
}

export interface IData {
  displayName: string;
  statusName: enumCache.IEpisodeStatusName;
  inactiveType: enumCache.IEpisodeInactiveTypeName | '';
  continuationType: enumCache.IEpisodeContinuationTypeName | '';
  parentEpisode: IParentEpisode | null;
  isCurrent: boolean;
  isWithinTimeRange: boolean;
}

export function useEpisodeProfileActivityQuery(id: string) {
  const {t} = useTranslation();
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  return React.useMemo(
    () => {
      const gqlEpisode = tuple.data?.episode;
      const mEndTimestamp = gqlEpisode?.endTimestamp ?
        moment(gqlEpisode.endTimestamp, moment.ISO_8601) :
        null;

      const data: IData = {
        displayName: gqlEpisode?.id ?
          t('episodeDisplayNameWithPatient', {
            ranking: gqlEpisode?.ranking ?? 0,
            patientName: gqlEpisode?.patient.lastName ?? ""
          }) :
          "",
        statusName: gqlEpisode?.status.name ?? 'pending',
        inactiveType: gqlEpisode?.inactiveType?.name ?? '',
        continuationType: gqlEpisode?.continuationType?.name ?? '',
        parentEpisode: gqlEpisode?.parentEpisode ? {
          id: gqlEpisode.parentEpisode.id,
          ranking: gqlEpisode.parentEpisode.ranking
        } : null,
        isCurrent: gqlEpisode?.isCurrent ?? false,
        isWithinTimeRange: mEndTimestamp ?
          moment().isBefore(mEndTimestamp) :
          false
      };

      return {
        ...tuple,
        data,
        refetch: async () => {
          await tuple.refetch();
        }
      };
    },
    [tuple]
  );
}



export interface ISetEpisodeStatusArgs {
  statusName: enumCache.IEpisodeStatusName;
  activeStartDate?: string;
  inactiveType?: types.IInactiveType;
  inactiveNote?: string;
}

export function useSetEpisodeStatusMutation(fnRefetch: () => Promise<void>, id: string) {
  const [fnMutate, tuple] = useBaseSetEpisodeStatusMutation();

  const fnWrappedMutate = React.useCallback(
    async (args: ISetEpisodeStatusArgs) => {
      await fnMutate({
        variables: {
          input: {
            episodeId: id,
            statusName: args.statusName,
            activeData: args.statusName === 'active' ? ({
              startDate: args.activeStartDate ?? ''
            } as SetEpisodeStatusActiveDataInput) : undefined,
            inactiveData: args.statusName === 'inactive' ? ({
              inactiveTypeName: args.inactiveType ?? 'discharge',
              note: args.inactiveNote?.trim() ?? ""
            } as SetEpisodeStatusInactiveDataInput) : undefined
          }
        }
      });
      await fnRefetch();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useRecertifyEpisodeMutation(id: string) {
  const [fnMutate, tuple] = useBaseRecertifyEpisodeMutation({
    variables: {
      id
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      const response = await fnMutate();
      return response.data?.recertifyEpisode.id ?? '';
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: tuple.data?.recertifyEpisode.id ?? ''
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useResumeEpisodeMutation(id: string) {
  const [fnMutate, tuple] = useBaseResumeEpisodeMutation({
    variables: {
      id
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      const response = await fnMutate();
      return response.data?.resumeEpisode.id ?? '';
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
