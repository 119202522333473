import imgHeaderLogo1x from 'assets/images/headerLogo.png';
import imgHeaderLogo2x from 'assets/images/headerLogo@2x.png';
import imgHeaderLogo3x from 'assets/images/headerLogo@3x.png';

import imgLogo1x from 'assets/images/logo.png';
import imgLogo2x from 'assets/images/logo@2x.png';
import imgLogo3x from 'assets/images/logo@3x.png';

import imgBackArrow1x from 'assets/images/backArrow.png';
import imgBackArrow2x from 'assets/images/backArrow@2x.png';
import imgBackArrow3x from 'assets/images/backArrow@3x.png';

import _svgAdmin from 'assets/images/navigationBar/admin.svg';
import _svgAdminSelected from 'assets/images/navigationBar/admin-selected.svg';
import _svgEmployees from 'assets/images/navigationBar/employees.svg';
import _svgEmployeesSelected from 'assets/images/navigationBar/employees-selected.svg';
import _svgPatients from 'assets/images/navigationBar/patients.svg';
import _svgPatientsSelected from 'assets/images/navigationBar/patients-selected.svg';
import _svgMessage from 'assets/images/navigationBar/message.svg';
import _svgNotification from 'assets/images/navigationBar/notification.svg';



export const imgsHeaderLogo = [
  imgHeaderLogo1x,
  imgHeaderLogo2x,
  imgHeaderLogo3x
];

export const imgsLogo = [
  imgLogo1x,
  imgLogo2x,
  imgLogo3x
];

export const imgsBackArrow = [
  imgBackArrow1x,
  imgBackArrow2x,
  imgBackArrow3x
];

export const svgAdmin = _svgAdmin;
export const svgAdminSelected = _svgAdminSelected;
export const svgEmployees = _svgEmployees;
export const svgEmployeesSelected = _svgEmployeesSelected;
export const svgPatients = _svgPatients;
export const svgPatientsSelected = _svgPatientsSelected;
export const svgMessage = _svgMessage;
export const svgNotification = _svgNotification;
