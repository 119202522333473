import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  chips,
  HeaderLabelWithValue,
  paletteColors,
  ProtectedFileDownloadButton,
  RowBox,
  ValueWithLabel,
  SectionHeaderBox,
  dateUtil
} from 'modules';
import {
  Box,
  Grid,
  Button,
  Typography
} from '@mui/material';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



interface IParams {
  requirementId: string;
}

export const ViewRequirementActivity: React.FC = () => {
  const params = useParams<Partial<IParams>>();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const queryTuple = dataService.useQuery(params.requirementId ?? '');

  const isLoading =
    queryTuple.loading;



  const typeHasSubmittedFiles = (
    queryTuple.data.typeName === 'document' ||
    queryTuple.data.typeName === 'inService' ||
    queryTuple.data.typeName === 'requirement' ||
    queryTuple.data.typeName === 'training'
  );



  return (
    <BaseActivityTemplate
      mode="employee"
      isLoading={isLoading}
      headerOptions={{
        backgroundColor: paletteColors.requirements,
        title: queryTuple.data.displayName,
        hasBackButton: true,

        elRightButtons: _.compact([
          (!queryTuple.loading && typeHasSubmittedFiles && (queryTuple.data.statusName === 'pending' || queryTuple.data.statusName === 'rejected')) ? (
            <Button
              variant="contained"
              onClick={() => navigate(`/employee/requirements/edit/${params.requirementId}`)}>
              {t('requirements:uploadFiles')}
            </Button>
          ) : null,

          (!queryTuple.loading && queryTuple.data.typeName === 'quiz' && (queryTuple.data.statusName === 'pending' || queryTuple.data.statusName === 'rejected')) ? (
            <Button
              variant="contained"
              onClick={() => navigate(`/employee/requirements/takeQuiz/${params.requirementId}`)}>
              {t('requirements:quiz.takeQuiz')}
            </Button>
          ) : null
        ]),

        elRight: !queryTuple.loading ? (
          <RowBox>
            <Box ml={3}>
              <HeaderLabelWithValue
                label={t('status')}>
                <chips.RequirementStatusChip
                  valueId={queryTuple.data.statusName} />
              </HeaderLabelWithValue>
            </Box>

            <Box ml={3}>
              <HeaderLabelWithValue
                label={t('type')}>
                <chips.RequirementTypeChip
                  valueId={queryTuple.data.typeName} />
              </HeaderLabelWithValue>
            </Box>
          </RowBox>
        ) : undefined
      }}>

      <Box mb={3}>
        <Grid container>

          <Grid
            item
            md={6}>

            <Grid container>

              <ValueWithLabel
                label={t('requirements:description')}
                value={queryTuple.data.description} />

              <ValueWithLabel
                label={t('requirements:attachedFile')}
                value={!queryTuple.data.file ? '' : undefined}>
                {queryTuple.data.file && (
                  <ProtectedFileDownloadButton
                    fileKey={queryTuple.data.file.key}
                    fileName={queryTuple.data.file.name} />
                )}
              </ValueWithLabel>

              <ValueWithLabel
                label={t('requirements:url')}
                value={queryTuple.data.url}
                toExternalUrl={queryTuple.data.url} />

            </Grid>

          </Grid>

          <Grid
            item
            md={6}>

            <Grid container>

              <ValueWithLabel
                label={t('requirements:dueDate')}
                value={queryTuple.data.dueTimestamp?.format(dateUtil.displayTimestampFormat) ?? ""} />

              <ValueWithLabel
                label={t('requirements:lastSubmittedDate')}
                value={queryTuple.data.lastSubmittedTimestamp?.format(dateUtil.displayTimestampFormat) ?? ""} />

              {queryTuple.data.typeName === 'quiz' && queryTuple.data.quizScore !== null && (
                <ValueWithLabel
                  label={t('requirements:quiz.score')}
                  value={queryTuple.data.quizScore.toString() + "%"} />
              )}

            </Grid>

          </Grid>

        </Grid>
      </Box>

      {typeHasSubmittedFiles && queryTuple.data.statusName !== 'pending' && (
        <Box mb={3}>
          <SectionHeaderBox>
            <Typography variant="h2">
              {t('requirements:employeeUploadedFiles')}
            </Typography>
          </SectionHeaderBox>

          {queryTuple.data.employeeFiles.length === 0 ? (
            <Typography variant="body1">
              {t('requirements:noFiles')}
            </Typography>

          ) : (
            <>
              {_.map(queryTuple.data.employeeFiles, file => (
                <Box
                  key={file.key}
                  mb={1}>
                  <ProtectedFileDownloadButton
                    fileKey={file.key}
                    fileName={file.name} />
                </Box>
              ))}
            </>
          )}

        </Box>
      )}

    </BaseActivityTemplate>
  );
};
