import React from 'react';
import _ from 'lodash';

import {
  BaseActivityTemplate,
  paletteColors,
  genTableComponent,
  IRow,
  RowOptionsButton,
  ConfirmationModal,
  errorUtil,
  ImportCsvModal
} from 'modules';

import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { columnDescriptions } from './columnDescriptions';



const __columnIdList = [
  'firstName',
  'lastName',
  'primaryPhone',
  'secondaryPhone',
  'faxNumber',
  'options'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();



export const ProvidersActivity: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const queryTuple = dataService.useProvidersActivityQuery();
  const [fnArchive, archiveMutationTuple] = dataService.useArchiveProviderMutation(queryTuple.refetch);
  const [fnImport, importMutationTuple] = dataService.useImportProvidersMutation();

  const [providerIdToDelete, setProviderIdToDelete] = React.useState('');
  const [isImportCsvModalOpen, setIsImportCsvModalOpen] = React.useState(false);

  const isLoading =
    queryTuple.loading ||
    archiveMutationTuple.loading ||
    importMutationTuple.loading;



  async function handleConfirmDeleteProvider() {
    setProviderIdToDelete('');
    await errorUtil.errorWrap(async () => {
      await fnArchive(providerIdToDelete);
    });
  }



  async function importProviders(rows: any[]) {
    await fnImport(_.map(rows, (row): dataService.IImportProvider => ({
      firstName: row.firstName ?? "",
      lastName: row.lastName ?? "",
      primaryPhone: row.primaryPhone ?? "",
      secondaryPhone: row.secondaryPhone ?? "",
      faxNumber: row.faxNumber ?? ""
    })));
  }



  function handleAcceptImportCsvModal() {
    queryTuple.refetch();
    setIsImportCsvModalOpen(false);
  }



  return (
    <>
      <BaseActivityTemplate
        mode="admin"
        isLoading={isLoading}
        requireOneOfPermissionCategories={[
          'fullAdmin',
          'supervisor'
        ]}
        headerOptions={{
          backgroundColor: paletteColors.providers,
          title: t('providers'),
          elRightButtons: [
            (
              <Button
                key="import"
                variant="outlined"
                onClick={() => setIsImportCsvModalOpen(true)}>
                {t('adminProviders:importProviders')}
              </Button>
            ),
            (
              <Button
                key="create"
                variant="contained"
                onClick={() => navigate('/admin/providers/create')}>
                {t('adminProviders:addNewProvider')}
              </Button>
            )
          ]
        }}>

        <Table
          columns={[
            {
              id: 'firstName',
              label: t('firstName')
            },
            {
              id: 'lastName',
              label: t('lastName')
            },
            {
              id: 'primaryPhone',
              label: t('primaryPhone')
            },
            {
              id: 'secondaryPhone',
              label: t('secondaryPhone')
            },
            {
              id: 'faxNumber',
              label: t('adminProviders:faxNumber')
            },
            {
              id: 'options',
              label: ""
            }
          ]}
          rows={_.map(queryTuple.data.providers, (provider): IRow<IColumnId> => ({
            id: provider.id,
            columnId2Value: {
              firstName: {
                type: 'string',
                label: provider.firstName,
                toRoute: `/admin/providers/profile/${provider.id}`
              },
              lastName: {
                type: 'string',
                label: provider.lastName,
                toRoute: `/admin/providers/profile/${provider.id}`
              },
              primaryPhone: {
                type: 'string',
                label: provider.primaryPhone
              },
              secondaryPhone: {
                type: 'string',
                label: provider.secondaryPhone
              },
              faxNumber: {
                type: 'string',
                label: provider.faxNumber
              },
              options: {
                type: 'component',
                label: (
                  <RowOptionsButton
                    onClickDelete={() => setProviderIdToDelete(provider.id)} />
                ),
                sortableValue: ''
              }
            }
          }))}
          initSortOptions={{
            sortByColumnId: 'lastName',
            sortDirection: 'ascending'
          }} />

      </BaseActivityTemplate>

      <ConfirmationModal
        isOpen={!!providerIdToDelete}
        title={t('adminProviders:deleteProvider')}
        message={t('adminProviders:deleteProviderConfirmationModal')}
        acceptButtonLabel={t('delete')}
        onAccept={handleConfirmDeleteProvider}
        onCancel={() => setProviderIdToDelete('')} />

      {isImportCsvModalOpen && (
        <ImportCsvModal
          isOpen
          columnDescriptions={columnDescriptions}
          fnImport={importProviders}
          isLoading={importMutationTuple.loading}
          onAccept={handleAcceptImportCsvModal}
          onCancel={() => setIsImportCsvModalOpen(false)} />
      )}
    </>
  );
};
