import React from 'react';

import {
  Button,
  Box
} from '@mui/material';
import {
  authService,
  LoadingOverlay
} from 'modules/layer0';

import { protectedFileUtil } from 'modules/layer1';
import { Download as BaseDownload } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';



const ButtonContentBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const Download = styled(BaseDownload)(() => ({
  display: 'block'
}));

const Anchor = styled('a')(() => ({
  display: 'none'
}));



export interface IProtectedFileDownloadButtonProps {
  fileKey: string;
  fileName?: string;
}

export const ProtectedFileDownloadButton: React.FC<IProtectedFileDownloadButtonProps> = props => {
  const {t} = useTranslation();

  const refAnchor = React.useRef<HTMLAnchorElement>(null);

  const [isDownloading, setIsDownloading] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState('');



  React.useEffect(
    () => {
      const sub = authService.getAuthStateObservable().subscribe(authState => {
        setAccessToken(authState.accessToken);
      });

      return () => sub.unsubscribe();
    },
    []
  );



  function handleClickButton() {
    const elAnchor = refAnchor.current;
    if (accessToken && elAnchor) {
      (async () => {
        setIsDownloading(true);
        const blob = await protectedFileUtil.fetchFile(accessToken, props.fileKey);
        setIsDownloading(false);

        const dataUrl = URL.createObjectURL(blob);
        elAnchor.href = dataUrl;
        elAnchor.download = props.fileName ?? props.fileKey;
        elAnchor.click();

        URL.revokeObjectURL(dataUrl);
      })();
    }
  }



  return (
    <>
      <LoadingOverlay
        active={isDownloading} />

      <Anchor ref={refAnchor} />

      <Button
        variant="contained"
        onClick={handleClickButton}>

        <ButtonContentBox>
          <Box mr={1}>
            <Download />
          </Box>

          {props.fileName || t('downloadFile')}
        </ButtonContentBox>

      </Button>
    </>
  );
};
