import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';
import {
  genTextFieldControllerComponent,
  validationUtil
} from 'modules';

import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from '../types';



const TextFieldController = genTextFieldControllerComponent<types.IFormState>();



export interface IContactDetailsSectionProps {
  formControl: Control<types.IFormState>;
  isDisabled: boolean;
}

export const ContactDetailsSection: React.FC<IContactDetailsSectionProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('patients:contactDetails')}
      </Typography>

      <Box mt={4}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={9}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey="address"
              label={t('address')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey="locationNote"
              label={t('locationNote')}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey="primaryPhone"
              label={t('primaryPhone')}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={props.isDisabled} />
          </Grid>

          <Grid
            item
            md={3}>
            <TextFieldController
              formControl={props.formControl}
              fieldKey="secondaryPhone"
              label={t('secondaryPhone')}
              extraValidationRules={{
                pattern: validationUtil.rules.phoneNumber
              }}
              isDisabled={props.isDisabled} />
          </Grid>

        </Grid>

      </Box>

    </Box>
  );
};
