import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  BaseActivityTemplate,
  DetailedListItem,
  SectionHeaderBox,
  ValueWithLabel,
  chips,
  paletteColors,
  TimePeriodNavigator,
  dateUtil
} from 'modules';
import {
  Box,
  Typography,
  Grid
} from '@mui/material';

import * as dataService from './dataService';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';



const Ul = styled('ul')(() => ({
  margin: 0,
  padding: 0
}));

const Li = styled('li')(() => ({
  margin: 0,
  padding: 0
}));

const __duration = moment.duration(1, 'week');



export const MyVisitsActivity: React.FC = () => {
  const {t} = useTranslation();

  const [fnQuery, queryTuple] = dataService.useLazyQuery();

  const [selectedWeek, setSelectedWeek] = React.useState(moment().startOf('week'));

  const isLoading =
    queryTuple.loading;



  React.useEffect(
    () => {
      fnQuery(selectedWeek, selectedWeek.clone().endOf('week'));
    },
    [selectedWeek]
  );



  return (
    <BaseActivityTemplate
      mode="employee"
      navigationBarSelectedLinkId="myVisits"
      headerOptions={{
        backgroundColor: paletteColors.visits,
        title: t('myVisits')
      }}
      isLoading={isLoading}>

      <Box mb={6}>
        <TimePeriodNavigator
          periodStart={selectedWeek}
          duration={__duration}
          onChangePeriodStart={setSelectedWeek}
          formatLabel={week => t('visits:weekOf', {date: week.format(dateUtil.displayDateFormat)})} />
      </Box>

      {_.map(queryTuple.data.dayTimestamp2Visits, (visits, dayTimestamp) => {
        const mDate = moment(dayTimestamp, moment.ISO_8601);

        return (
          <Box
            key={dayTimestamp}
            mb={3}>

            <SectionHeaderBox>
              <Typography variant="h2">
                {mDate.format('dddd, MMMM Do, YYYY')}
              </Typography>
            </SectionHeaderBox>

            {visits.length === 0 ? (
              <>
                {t('visits:noVisits')}
              </>

            ) : (
              <Box>
                {_.map(visits, visit => (
                  <DetailedListItem
                    key={visit.id}
                    title={visit.patientName}
                    subtitle={visit.displayName}
                    additionalInfo={(
                      <Grid container>

                        <ValueWithLabel
                          label={t('visits:visitStatus')}>
                          <chips.VisitStatusChip
                            valueId={visit.visitStatusName} />
                        </ValueWithLabel>

                        <ValueWithLabel
                          label={t('visits:shift')}
                          value={t(`visits:shifts.${visit.shift.name}`)} />

                        <ValueWithLabel
                          label={t('visits:location')}
                          value={visit.location} />

                        <ValueWithLabel
                          label={t('visits:otherAssignedEmployees')}
                          value={visit.otherAssignedEmployeeNames.length === 0 ? t('noValue') : undefined}>
                          {visit.otherAssignedEmployeeNames.length > 0 && (
                            <Ul>
                              {_.map(visit.otherAssignedEmployeeNames, (name, index) => (
                                <Li key={index}>
                                  {name}
                                </Li>
                              ))}
                            </Ul>
                          )}
                        </ValueWithLabel>

                      </Grid>
                    )}
                    toRoute={`/employee/visit/${visit.id}`} />
                ))}
              </Box>
            )}

          </Box>
        );
      })}

    </BaseActivityTemplate>
  );
};
