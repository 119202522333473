import React from 'react';

import {
  Avatar as BaseAvatar,
  Box,
  Grid,
  Typography,
  Skeleton
} from '@mui/material';
import {
  ProtectedImage,
  SkeletonRows,
  UserLink,
  ValueWithLabel
} from 'modules';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import * as dataService from './dataService';



const Avatar = styled(BaseAvatar)(() => ({
  width: '100px',
  height: '100px'
}));



export interface IGeneralInformationSectionProps {
  employeeId: string;
}

export const GeneralInformationSection: React.FC<IGeneralInformationSectionProps> = props => {
  const {t} = useTranslation();

  const queryTuple = dataService.useEmployeeProfileProfileTabGeneralInformationSectionQuery(props.employeeId);



  return (
    <Box>
      <Box mb={3}>
        <Typography variant="h3">
          {t('adminEmployees:generalInformation')}
        </Typography>
      </Box>

      {queryTuple.loading ? (
        <>
          <Box display="flex">
            <Box flex="1">
              <Skeleton
                variant="text" />
            </Box>

            <Skeleton
              variant="circular"
              width={100}
              height={100} />
          </Box>

          <SkeletonRows numRows={13} />
        </>

      ) : (
        <Grid container>
          <ValueWithLabel label={t('profilePicture')}>
            {queryTuple.data.profilePictureKey ? (
              <ProtectedImage
                fileKey={queryTuple.data.profilePictureKey}
                asAvatar />

            ) : (
              <Avatar />
            )}
          </ValueWithLabel>

          <ValueWithLabel
            label={t('firstName')}
            value={queryTuple.data.firstName} />

          <ValueWithLabel
            label={t('lastName')}
            value={queryTuple.data.lastName} />

          <ValueWithLabel
            label={t('employeeId')}
            value={queryTuple.data.employeeId} />

          <ValueWithLabel
            label={t('email')}
            value={queryTuple.data.email} />

          <ValueWithLabel
            label={t('birthdate')}
            value={queryTuple.data.birthdate} />

          <ValueWithLabel
            label={t('adminEmployees:hireStatus')}
            value={queryTuple.data.hireStatusDisplayName} />

          <ValueWithLabel
            label={t('adminEmployees:discipline')}
            value={queryTuple.data.disciplineDisplayName} />

          <ValueWithLabel
            label={t('address')}
            value={queryTuple.data.address} />

          <ValueWithLabel
            label={t('primaryPhone')}
            value={queryTuple.data.primaryPhone} />

          <ValueWithLabel
            label={t('secondaryPhone')}
            value={queryTuple.data.secondaryPhone} />

          <ValueWithLabel label={t('supervisor')}>
            <UserLink userId={queryTuple.data.supervisorId} />
          </ValueWithLabel>

          <ValueWithLabel label={t('altSupervisor')}>
            <UserLink userId={queryTuple.data.altSupervisorId} />
          </ValueWithLabel>

          <ValueWithLabel
            label={t('payRate')}
            value={queryTuple.data.payRate} />
        </Grid>
      )}
    </Box>
  );
};
