import React from 'react';
import moment from 'moment';

import {
  ChevronLeft,
  ChevronRight
} from '@mui/icons-material';
import {
  Box,
  IconButton
} from '@mui/material';

import { styled } from '@mui/material/styles';



const ContainerBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center'
}));



export interface ITimePeriodNavigatorProps {
  periodStart: moment.Moment;
  duration: moment.Duration;
  onChangePeriodStart: (value: moment.Moment) => void;
  formatLabel: (periodStart: moment.Moment, duration: moment.Duration) => string;
}

export const TimePeriodNavigator: React.FC<ITimePeriodNavigatorProps> = props => {
  function handleClickLeft() {
    props.onChangePeriodStart(props.periodStart.clone().subtract(props.duration));
  }



  function handleClickRight() {
    props.onChangePeriodStart(props.periodStart.clone().add(props.duration));
  }



  return (
    <ContainerBox>
      <IconButton
        size="small"
        onClick={handleClickLeft}>
        <ChevronLeft />
      </IconButton>

      {props.formatLabel(props.periodStart, props.duration)}

      <IconButton
        size="small"
        onClick={handleClickRight}>
        <ChevronRight />
      </IconButton>
    </ContainerBox>
  );
};
