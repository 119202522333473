import React from 'react';

import {
  useUserLinkLazyQuery as useBaseLazyQuery
} from 'generated/graphql';



export interface IData {
  id: string;
  fullName: string;
  profilePictureUrl: string;
  routeUrl: string;
}

export function useUserLinkLazyQuery() {
  const [fnQuery, tuple] = useBaseLazyQuery();

  const fnWrappedQuery = React.useCallback(
    (id: string) => fnQuery({
      variables: {
        id
      }
    }),
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const gqlUser = tuple.data?.siteUserLite;

      const id = gqlUser?.id ?? '';
      const typeName = tuple.data?.siteUserLite?.typeName;
      let routeUrl = '';
      if (typeName === 'administrator') {
        routeUrl = `/admin/administrators/profile/${id}`;
      } else if (typeName === 'employee') {
        routeUrl = `/admin/employees/profile/${id}`;
      }

      const data: IData = {
        id,
        fullName: gqlUser?.fullName ?? "",
        profilePictureUrl: '',
        routeUrl
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}
