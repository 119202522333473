import React from 'react';
import _ from 'lodash';

import {
  usePatientStatusSelectFieldQuery as useBaseQuery
} from 'generated/graphql';

import { enumCache } from 'modules/layer2';



export type IPatientStatusName = enumCache.IPatientStatusName;
export type IPatientStatus = enumCache.IPatientStatus;

export interface IData {
  enumValues: IPatientStatus[];
}

export function usePatientStatusSelectFieldQuery() {
  const tuple = useBaseQuery();

  return React.useMemo(
    () => {
      const data: IData = {
        enumValues: _.map(tuple.data?.patientStatuses ?? [], (gqlPatientStatus): IPatientStatus => ({
          id: gqlPatientStatus.id,
          name: gqlPatientStatus.name,
          displayName: gqlPatientStatus.displayName
        }))
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}
