import _ from 'lodash';

import { enumCache } from 'modules/layer2';
import * as chipGenerator from '../shared/chipGenerator';
import { map } from 'rxjs';



export const PatientStatusChip = chipGenerator.genChipComponent<enumCache.IPatientStatusName>({
  obsValueId2DisplayData: enumCache.subjAllEnums.asObservable()
    .pipe(map((value): Record<enumCache.IPatientStatusName, chipGenerator.IValueDisplayData> => {
      const enumValues = value.patientStatuses;
      const enumValuesByName = _.keyBy(enumValues, enumValue => enumValue.name) as Record<enumCache.IPatientStatusName, enumCache.IGenericEnum<enumCache.IPatientStatusName>>;

      return {
        admitReady: {
          label: enumValuesByName.admitReady?.displayName ?? "",
          backgroundColor: '#2ECC71'
        },
        admitted: {
          label: enumValuesByName.admitted?.displayName ?? "",
          backgroundColor: '#126F4D'
        },
        newProcessing: {
          label: enumValuesByName.newProcessing?.displayName ?? "",
          backgroundColor: '#F9B100'
        },
        notAdmitted: {
          label: enumValuesByName.notAdmitted?.displayName ?? "",
          backgroundColor: '#374253'
        },
        pending: {
          label: enumValuesByName.pending?.displayName ?? "",
          backgroundColor: '#00A3FF'
        },
        discharged: {
          label: enumValuesByName.discharged?.displayName ?? "",
          backgroundColor: "#000"
        }
      };
    }))
});
