import React from 'react';

import {
  Backdrop as BaseBackdrop,
  CircularProgress
} from '@mui/material';

import { styled } from '@mui/material/styles';



const Backdrop = styled(BaseBackdrop)(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff'
}));



export interface ILoadingOverlayProps {
  active: boolean;
}

export const LoadingOverlay: React.FC<ILoadingOverlayProps> = props => {
  return props.active ? (
    <Backdrop open={props.active}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : null;
};
