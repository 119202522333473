import React from 'react';

import {
  useEmployeeProfileActivityQuery as useBaseQuery,
  useInviteEmployeeMutation as useBaseInviteEmployeeMutation
} from 'generated/graphql';



export interface IInvite {
  recipientEmail: string;
  isInviteExpired: boolean;
}

export interface IData {
  fullName: string;
  employeeId: string;
  isLinkedToUser: boolean;
  invite: IInvite | null;
}

export function useEmployeeProfileActivityQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlEmployee = tuple.data?.employee;
      const data: IData = {
        fullName: `${gqlEmployee?.firstName ?? ""} ${gqlEmployee?.lastName ?? ""}`.trim(),
        employeeId: gqlEmployee?.employeeId ?? "",
        isLinkedToUser: !!gqlEmployee?.siteUserFull,
        invite: gqlEmployee?.invite ? {
          recipientEmail: gqlEmployee.invite.recipientEmail,
          isInviteExpired: gqlEmployee.invite.isExpired ?? false
        } : null
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useInviteEmployeeMutation(employeeId: string) {
  const [fnMutate, tuple] = useBaseInviteEmployeeMutation();

  const fnWrappedMutate = React.useCallback(
    async (email: string) => {
      await fnMutate({
        variables: {
          employeeId,
          email
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
