import React from 'react';
import _ from 'lodash';

import {
  useEditRequirementPresetActivityLazyQuery,
  useSaveRequirementPresetMutation,
  useArchiveRequirementPresetMutation,
  SaveRequirementPresetQuizQuestionInput,
  SaveRequirementPresetQuizQuestionAnswerOptionInput
} from 'generated/graphql';

import { fields } from 'modules';
import * as types from './types';



export interface IData {
  isLocked: boolean;
  fileName: string;
  formState: types.IFormState;
}

export function useLazyQuery() {
  const [fnQuery, tuple] = useEditRequirementPresetActivityLazyQuery({
    fetchPolicy: 'no-cache'
  });

  const fnWrappedQuery = React.useCallback(
    (id: string) => {
      fnQuery({
        variables: {
          id
        }
      });
    },
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const gqlPreset = tuple.data?.requirementPreset;
      const data: IData = {
        isLocked: gqlPreset?.isLocked ?? false,
        fileName: gqlPreset?.file?.fileName ?? "",
        formState: {
          id: gqlPreset?.id ?? '',
          requirementTypeName: gqlPreset?.type.name ?? 'requirement',
          displayName: gqlPreset?.displayName ?? "",
          description: gqlPreset?.description ?? "",
          fileData: null,
          url: gqlPreset?.url ?? "",

          quizPreset: gqlPreset?.typeSpecificData?.__typename === 'RequirementPresetQuiz' ? {
            questions: _.map(gqlPreset.typeSpecificData.questions, (gqlQuestion): types.IQuizQuestion => ({
              _key: gqlQuestion.id,
              description: gqlQuestion.description,
              answerOptions: _.map(gqlQuestion.answerOptions, (gqlAnswerOption): types.IQuizQuestionAnswerOption => ({
                _key: gqlAnswerOption.id,
                description: gqlAnswerOption.description,
                isCorrect: gqlAnswerOption.id === gqlQuestion.correctAnswerOption.id
              }))
            }))

          } : {
            questions: []
          }
        }
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



export function useSaveMutation(id?: string) {
  const [fnMutate, tuple] = useSaveRequirementPresetMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id,
            typeName: formState.requirementTypeName,
            displayName: formState.displayName.trim(),
            description: formState.description.trim(),
            file: formState.fileData ?
              fields.fileData2EncodedString(formState.fileData) :
              undefined,
            url: formState.url.trim(),

            quiz: formState.requirementTypeName === 'quiz' ? {
              questions: _.map(formState.quizPreset.questions, (question): SaveRequirementPresetQuizQuestionInput => ({
                description: question.description,
                correctAnswerOptionIndex: _.findIndex(question.answerOptions, option => option.isCorrect),
                answerOptions: _.map(question.answerOptions, (answerOption): SaveRequirementPresetQuizQuestionAnswerOptionInput => ({
                  description: answerOption.description
                }))
              }))
            } : undefined
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useArchiveMutation(id?: string) {
  const [fnMutate, tuple] = useArchiveRequirementPresetMutation({
    variables: {
      id: id ?? ''
    }
  });

  const fnWrappedMutate = React.useCallback(
    async () => {
      await fnMutate();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
