import React from 'react';
import _ from 'lodash';

import {
  genTableComponent,
  IRow
} from 'modules/layer2';
import {
  Box,
  Typography
} from '@mui/material';

import { BaseModal } from 'modules/layer1';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import { IColumnDescription } from '../csvParser';



const __columnIdList = [
  'id',
  'label',
  'isRequired',
  'comment',
  'possibleValues'
] as const;
type IColumnId = typeof __columnIdList[number];

const Table = genTableComponent<IColumnId>();

export interface IColumnsModalProps {
  columnDescriptions: IColumnDescription[];
  onClose: () => void;
}

export const ColumnsModal: React.FC<IColumnsModalProps> = props => {
  const {t} = useTranslation();



  return (
    <BaseModal
      isOpen
      title={t('importCsvModal.columnsModal.title')}
      disableDefaultWidth
      onClose={props.onClose}>

      {() => (
        <>
          <Box mb={2}>
            <Typography>
              {t('importCsvModal.columnsModal.description')}
            </Typography>
          </Box>

          <Table
            disableSorting
            columns={[
              {
                id: 'id',
                label: t('id')
              },
              {
                id: 'label',
                label: t('importCsvModal.columnsModal.label')
              },
              {
                id: 'isRequired',
                label: t('importCsvModal.columnsModal.isRequired')
              },
              {
                id: 'comment',
                label: t('importCsvModal.columnsModal.comment')
              },
              {
                id: 'possibleValues',
                label: t('importCsvModal.columnsModal.possibleValues')
              }
            ]}
            rows={_.map(props.columnDescriptions, (columnDescription): IRow<IColumnId> => ({
              id: columnDescription.id,
              columnId2Value: {
                id: {
                  type: 'component',
                  label: (
                    <strong>
                      <code>
                        {columnDescription.id}
                      </code>
                    </strong>
                  ),
                  sortableValue: columnDescription.id
                },
                label: {
                  type: 'string',
                  label: columnDescription.label
                },
                isRequired: {
                  type: 'component',
                  label: columnDescription.isRequired ? (
                    <Check />

                  ) : (
                    <div />
                  ),
                  sortableValue: ''
                },
                comment: {
                  type: 'string',
                  label: (() => {
                    const formatTypeComment = columnDescription.formatType ?
                      t(`importCsvModal.columnsModal.formatTypeComment.${columnDescription.formatType}`) :
                      "";

                    return `${formatTypeComment} ${columnDescription.comment ?? ""}`;
                  })()
                },
                possibleValues: {
                  type: 'component',
                  label: columnDescription.enumValues ? (
                    <ul>
                      {_.map(columnDescription.enumValues, enumValue => (
                        <li key={enumValue}>
                          {enumValue}
                        </li>
                      ))}
                    </ul>

                  ) : (
                    <div />
                  ),
                  sortableValue: ''
                }
              }
            }))} />
        </>
      )}

    </BaseModal>
  );
};
