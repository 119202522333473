import React from 'react';

import {
  useEditProviderActivityLazyQuery as useBaseLazyQuery,
  useSaveProviderMutation as useBaseSaveProviderMutation,
  useArchiveProviderMutation as useBaseArchiveProviderMutation
} from 'generated/graphql';

import * as types from './types';



export interface IData {
  formState: types.IFormState;
  fullName: string;
}

export function useEditProviderActivityLazyQuery() {
  const [fnQuery, tuple] = useBaseLazyQuery({fetchPolicy: 'no-cache'});

  React.useEffect(
    () => {
      if (tuple.error?.message) {
        alert(tuple.error.message);
      }
    },
    [tuple.error?.message]
  );

  const fnWrappedQuery = React.useCallback(
    (providerId: string) => fnQuery({
      variables: {
        providerId
      }
    }),
    [fnQuery]
  );

  const wrappedTuple = React.useMemo(
    () => {
      const gqlProvider = tuple.data?.provider;
      const data: IData = {
        formState: {
          id: gqlProvider?.id ?? '',
          firstName: gqlProvider?.firstName ?? "",
          lastName: gqlProvider?.lastName ?? "",
          primaryPhone: gqlProvider?.primaryPhone ?? "",
          secondaryPhone: gqlProvider?.secondaryPhone ?? "",
          faxNumber: gqlProvider?.faxNumber ?? ""
        },
        fullName: `${gqlProvider?.firstName ?? ""} ${gqlProvider?.lastName ?? ""}`.trim()
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );

  return [
    fnWrappedQuery,
    wrappedTuple
  ] as const;
}



export function useSaveProviderMutation() {
  const [fnMutate, tuple] = useBaseSaveProviderMutation();

  const fnWrappedMutate = React.useCallback(
    async (formState: types.IFormState) => {
      await fnMutate({
        variables: {
          input: {
            id: formState.id || undefined,
            firstName: formState.firstName,
            lastName: formState.lastName,
            primaryPhone: formState.primaryPhone,
            secondaryPhone: formState.secondaryPhone,
            faxNumber: formState.faxNumber
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useArchiveProviderMutation() {
  const [fnMutate, tuple] = useBaseArchiveProviderMutation();

  const fnWrappedMutate = React.useCallback(
    (id: string) => fnMutate({
      variables: {
        id
      }
    }),
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
