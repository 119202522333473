import React from 'react';

import {
  Box,
  Grid,
  Typography
} from '@mui/material';

import { selectFields } from 'modules';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as types from '../types';



const PharmacySelectFieldController = selectFields.genPharmacySelectFieldControllerComponent<types.IFormState>();



export interface IPharmacySectionProps {
  formControl: Control<types.IFormState>;
  isDisabled: boolean;
}

export const PharmacySection: React.FC<IPharmacySectionProps> = props => {
  const {t} = useTranslation();



  return (
    <Box mb={6}>

      <Typography variant="h2">
        {t('patients:pharmacy')}
      </Typography>

      <Box mt={4}>

        <Grid
          container
          spacing={2}>

          <Grid
            item
            md={3}>
            <PharmacySelectFieldController
              formControl={props.formControl}
              fieldKey="pharmacyId"
              label={t('patients:pharmacy')}
              isDisabled={props.isDisabled} />
          </Grid>

        </Grid>

      </Box>

    </Box>
  );
};
