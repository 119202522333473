import React from 'react';

import {
  useAdministratorProfileActivityQuery as useBaseQuery,
  useInviteAdministratorMutation as useBaseInviteAdministratorMutation
} from 'generated/graphql';



export interface IInvite {
  recipientEmail: string;
  isInviteExpired: boolean;
}

export interface IData {
  firstName: string;
  lastName: string;
  fullName: string;
  employeeId: string;
  isLinkedToUser: boolean;
  invite: IInvite | null;
}

export function useAdministratorProfileActivityQuery(id: string) {
  const tuple = useBaseQuery({
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  });

  return React.useMemo(
    () => {
      const gqlAdmin = tuple.data?.administrator;
      const firstName = gqlAdmin?.firstName ?? "";
      const lastName = gqlAdmin?.lastName ?? "";
      const data: IData = {
        firstName,
        lastName,
        fullName: `${firstName} ${lastName}`.trim(),
        employeeId: gqlAdmin?.employeeId ?? "",
        isLinkedToUser: !!gqlAdmin?.siteUserFull,
        invite: gqlAdmin?.invite ? {
          recipientEmail: gqlAdmin.invite.recipientEmail,
          isInviteExpired: gqlAdmin.invite.isExpired ?? false
        } : null
      };

      return {
        ...tuple,
        data
      };
    },
    [tuple]
  );
}



export function useInviteAdministratorMutation(administratorId: string) {
  const [fnMutate, tuple] = useBaseInviteAdministratorMutation();

  const fnWrappedMutate = React.useCallback(
    async (email: string) => {
      await fnMutate({
        variables: {
          administratorId,
          email
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
