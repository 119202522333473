import React from 'react';

import { authService } from 'modules';



export type ICodeType =
  'register' |
  'login';

export interface ITuple {
  isLoading: boolean;
}

export function useConfirmCode() {
  const [tuple, setTuple] = React.useState<ITuple>({
    isLoading: false
  });

  async function fnConfirmCode(type: ICodeType, code: string, mfaMethod: authService.IMfaMethod) {
    try {
      setTuple({isLoading: true});
      if (type === 'register') {
        await authService.confirmRegisterCode(code);

      } else if (type === 'login') {
        await authService.confirmLoginCode(code, mfaMethod);
      }

    } finally {
      setTuple({isLoading: false});
    }
  }

  return [
    fnConfirmCode,
    tuple
  ] as const;
}
