import React from 'react';

import {
  usePostRegisterMakeApiUserSectionQuery,
  useConfirmSiteUserRegistrationMutation
} from 'generated/graphql';

import { errorUtil } from 'modules';
import { useTranslation } from 'react-i18next';



interface IData {
  hasApiUser: boolean;
}

function __useQuery() {
  const tuple = usePostRegisterMakeApiUserSectionQuery({
    fetchPolicy: 'no-cache'
  });



  React.useEffect(
    () => {
      if (tuple.error) {
        alert(tuple.error);
      }
    },
    [tuple.error]
  );



  return React.useMemo(
    () => ({
      ...tuple,
      data: {
        hasApiUser: !!tuple.data?.loggedInUser?.id
      } as IData
    }),
    [tuple]
  );
}



function __useMakeApiUserMutation() {
  const [fnMutate, tuple] = useConfirmSiteUserRegistrationMutation();



  const fnWrappedMutate = React.useCallback(
    async (inviteCode: string) => {
      await fnMutate({
        variables: {
          inviteCode
        }
      });
    },
    [fnMutate]
  );



  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );



  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}



export function useProcess(inviteCode: string) {
  const queryTuple = __useQuery();
  const [fnMakeApiUser, makeApiUserMutationTuple] = __useMakeApiUserMutation();
  const {t} = useTranslation();



  React.useEffect(
    () => {
      if (queryTuple.called && !queryTuple.loading && !queryTuple.data.hasApiUser && inviteCode) {
        (async () => {
          await errorUtil.errorWrap(async () => {
            await fnMakeApiUser(inviteCode);
          }, {
            INVALID_INVITE_CODE: t('auth:errors.INVALID_INVITE_CODE'),
            AUTH_USER_ALREADY_HAS_USER_IN_DATABASE: t('auth:errors.AUTH_USER_ALREADY_HAS_USER_IN_DATABASE')
          });
        })();
      }
    },
    [
      queryTuple,
      fnMakeApiUser
    ]
  );



  React.useEffect(
    () => {
      if (makeApiUserMutationTuple.called && !makeApiUserMutationTuple.loading && !makeApiUserMutationTuple.error) {
        queryTuple.refetch();
      }
    },
    [
      makeApiUserMutationTuple.called,
      makeApiUserMutationTuple.loading,
      makeApiUserMutationTuple.error
    ]
  );



  const wrappedTuple = React.useMemo(
    () => ({
      ...queryTuple,
      error: queryTuple.error ?? makeApiUserMutationTuple.error,
      loading: queryTuple.loading || makeApiUserMutationTuple.loading
    }),
    [
      queryTuple,
      makeApiUserMutationTuple
    ]
  );



  return wrappedTuple;
}
