import React from 'react';

import {
  useEmployeeMarkVisitAsMissedMutation as useBaseEmployeeMarkVisitAsMissedMutation
} from 'generated/graphql';

import { enumCache } from 'modules';



export function useEmployeeMarkVisitAsMissedMutation(visitId: string) {
  const [fnMutate, tuple] = useBaseEmployeeMarkVisitAsMissedMutation();

  const fnWrappedMutate = React.useCallback(
    async (missedVisitReasonName: enumCache.IMissedVisitReasonName) => {
      await fnMutate({
        variables: {
          input: {
            id: visitId,
            missedVisitReasonName
          }
        }
      });
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
