import React from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  useEpisodeProfileActivityCarePlanTabQuery as useBaseQuery,
  useGenerateVisitsFromTemplateMutation as useBaseGenerateVisitsFromTemplateMutation
} from 'generated/graphql';
import {
  dateUtil,
  enumCache
} from 'modules';

import { enums } from 'modules';



export interface IVisitTemplate {
  id: string;
  displayName: string;
  visitCategoryName: enumCache.IVisitCategoryName;
  shiftName: enumCache.IShiftName;
  frequencyAmount: number;
  frequencyUnitName: enumCache.IVisitTemplateFrequencyUnitName;
  note: string;
  location: string;
}

export interface IVisit {
  id: string;
  visitStatusName: enumCache.IVisitStatusName;
  displayName: string;
  date: string;
  shiftName: enumCache.IShiftName;
}

export interface IData {
  canEdit: boolean;
  canCreateVisits: boolean;
  visitTemplates: IVisitTemplate[];
  visits: IVisit[];
}

export function useQuery(episodeId: string) {
  const tuple = useBaseQuery({
    variables: {
      episodeId
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  return React.useMemo(
    () => {
      const gqlEpisode = tuple.data?.episode;
      const statusName = gqlEpisode?.status.name;
      const data: IData = {
        canEdit: statusName === 'pending',
        canCreateVisits: statusName === 'active' && gqlEpisode?.endTimestamp && moment().isBefore(moment(gqlEpisode.endTimestamp, moment.ISO_8601)),
        visitTemplates: _.map(gqlEpisode?.visitTemplates ?? [], (gqlTemplate): IVisitTemplate => ({
          id: gqlTemplate.id,
          displayName: gqlTemplate.displayName,
          visitCategoryName: gqlTemplate.visitCategory.name,
          shiftName: gqlTemplate.shift.name,
          frequencyAmount: gqlTemplate.frequencyAmount,
          frequencyUnitName: gqlTemplate.frequencyUnit.name,
          note: gqlTemplate.note,
          location: gqlTemplate.location
        })),
        visits: _.map(gqlEpisode?.visits ?? [], (gqlVisit): IVisit => ({
          id: gqlVisit.id,
          visitStatusName: gqlVisit.visitStatus.name,
          displayName: gqlVisit.displayName,
          date: dateUtil.formatDateWithTimeZone(gqlVisit.date),
          shiftName: gqlVisit.shift.name
        }))
      };

      return {
        ...tuple,
        data,
        refetch: async () => {
          await tuple.refetch();
        }
      };
    },
    [tuple]
  );
}



export interface IGenerateVisitsFromTemplateArgs {
  visitTemplateId: string;
  dayOfWeekNames: enums.IDayOfWeek[];
  assignedEmployeeIds: string[];
}

export function useGenerateVisitsFromTemplateMutation(fnRefetch: () => Promise<void>) {
  const [fnMutate, tuple] = useBaseGenerateVisitsFromTemplateMutation();

  const fnWrappedMutate = React.useCallback(
    async ({visitTemplateId, dayOfWeekNames, assignedEmployeeIds}: IGenerateVisitsFromTemplateArgs) => {
      await fnMutate({
        variables: {
          input: {
            visitTemplateId,
            dayOfWeekNames,
            assignedEmployeeIds
          }
        }
      });
      await fnRefetch();
    },
    [fnMutate]
  );

  const wrappedTuple = React.useMemo(
    () => ({
      ...tuple,
      data: null
    }),
    [tuple]
  );

  return [
    fnWrappedMutate,
    wrappedTuple
  ] as const;
}
